/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/button-has-type */
import React from 'react';
import { PropTypes } from 'prop-types';
import './_IconButton.scss';
import clsx from 'clsx';

const IconButton = ({ onClick, iconClass, ariaLabel, autoFocus, divRef, className, ...others }) => (
  <button
    className={clsx('IconButton', className)}
    aria-label={ariaLabel}
    onClick={onClick}
    autoFocus={autoFocus}
    ref={divRef}
    {...others}
  >
    <i className={`icon-${iconClass}`} />
  </button>
);
IconButton.propTypes = {
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
};

export default IconButton;
