const carglassModule = {
  CARGLASS: {
    SUBTITLE: 'A **Carglass** é **líder mundial** em serviços automotivos',
    INFO: {
      TITLE: 'Plano Auto',
      SUBTITLE: 'Seu carro fica bem. E você também.',
      DESCRIPTION:
        'Seu carro já quebrou no meio da estrada e você não teve ninguém para recorrer? Já se irritou ao ver o para-brisa trincado ou a porta ralada? Com o Plano Auto você fica protegido contra os danos do dia a dia que sempre acontecem!',
    },
    COVERAGES: {
      FAST_REPAIR: {
        TITLE: 'reparo rápido',
        DESCRIPTION:
          'referência em funilaria rápida no Brasil, consertamos de forma rápida ralados e amassados de até 50 cm.',
      },
      SUPER_HAMMER: {
        TITLE: 'supermartelinho',
        DESCRIPTION:
          'reparamos rapidamente amassados de até 30 cm na lataria, mantendo a originalidade do seu veículo.',
      },
      GLASSES: {
        TITLE: 'vidros',
        DESCRIPTION: 'líder mundial em reparo e troca de vidros. Reparamos 1 vidro a cada 2 segundos no mundo',
      },
      TIRES: {
        TITLE: 'pneus, rodas e suspensão',
        DESCRIPTION: 'Trocamos ou reparamos pneus, rodas e o sistema de suspensão, para você sempre andar tranquilo',
      },
      WINCH: {
        TITLE: 'guincho e assistências',
        DESCRIPTION:
          'Coberturas de guincho, chaveiro, troca de pneus, socorro mecânico e elétrico. 24 horas durante os 7 dias da semana',
      },
    },
    INFO_REDIRECT: {
      TITLE: 'Seu carro protegido a partir de:',
      VALUE: 'R$ 19,90/mês',
      RATE: '+ Taxa de utilização do serviço',
      BUTTON: 'ir para o site do parceiro',
      TEXT: 'Você continuará a contratação pelo site da Carglass',
    },
  },
};

export default carglassModule;
