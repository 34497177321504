import { createResource } from '@zup-next/redux-resource';
import { getOffersByLanding } from 'api/Offers/offers';
import { getFaq } from 'api/Faq/faq';
import { getAutoOffer } from 'api/E2E/Auto/getAutoOffer';
import offerSuccessHandle from 'store/auto/store/sagas/offer/offerSuccessHandle';

const offer = createResource('OFFER', { load: getAutoOffer }, { load: offerSuccessHandle });

const landing = createResource('LANDING', {
  load: getOffersByLanding,
});

const faq = createResource('FAQ', {
  load: getFaq,
});

export default {
  offer,
  landing,
  faq,
};
