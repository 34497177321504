import Markdown from 'components/Markdown';
import { useHistory } from 'react-router-dom';
import logo from 'assets/images/logo-seguros-itau.png';
import DropdownSelector from 'components/DropdownSelector';
import { useAllowedChannelsConsumer } from 'providers/AllowedChannelsProvider';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import DropdownContainer from '../DropdownContainer';
import GetMenuItems from '../menuItems.js';
import { useStyles } from './styles';
import { useRemoteConfigConsumer } from 'providers/RemoteConfigProvider';

export default function DesktopHeader() {
  const styles = useStyles();
  const history = useHistory();
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();
  const remoteConfig = useRemoteConfigConsumer();

  let MENU_ITEMS = GetMenuItems(useAllowedChannelsConsumer());
  MENU_ITEMS = MENU_ITEMS.filter((item) => !item.hiddenOnDesktop);

  MENU_ITEMS = MENU_ITEMS.map((menuItem) => {
    let type;

    if (menuItem.items) type = 'dropdown';
    else if (menuItem.children) type = 'container';
    else type = 'link';

    return { type, ...menuItem };
  });

  const handleClickLogo = () => {
    dispatchAnalyticsEvent('HOME', 'A1');
  };

  const handleSelectMenuDropdownItem = (value, menuItem) => {
    const selectedValue = menuItem.items.find((item) => item.label === value);

    if (selectedValue) {
      dispatchAnalyticsEvent('HOME', 'A3', [menuItem.defaultOption.label, selectedValue.label]);
    }

    if (typeof selectedValue?.onClick?.path === 'string') {
      if (selectedValue?.onClick?.openInANewTab) {
        return window.open(selectedValue.onClick.path, '_blank');
      }
      return history.push(selectedValue.onClick.path);
    }
    if (selectedValue?.onClick?.action) {
      switch (selectedValue.onClick.action) {
        case 'focusContacts': {
          document.querySelector('.content-wrapper').scrollTo({
            top: document.querySelector('.content-wrapper').scrollHeight,
            left: 0,
            behavior: 'smooth',
          });
          document.querySelector('#contacts-wrapper').focus();
          break;
        }
        case 'focusCancellation': {
          document.querySelector('.content-wrapper').scrollTo({
            top: document.querySelector('.content-wrapper').scrollHeight,
            left: 0,
            behavior: 'smooth',
          });
          document.querySelector('#cancellation-wrapper').focus();
          break;
        }
        default:
          break;
      }
    }
  };

  function MenuItems() {
    return MENU_ITEMS.map((menuItem, index) => {
      switch (menuItem.type) {
        case 'dropdown': {
          return (
            <DropdownSelector
              key={index}
              id={`menu-item-${menuItem.title}`}
              className={menuItem.alignRight && styles.alignRight}
              options={menuItem.items}
              defaultOption={menuItem.defaultOption}
              ariaLabel={menuItem.defaultOption.ariaLabel}
              onClick={(value) => dispatchAnalyticsEvent('HOME', 'A2', [value])}
              onSelect={(value) => handleSelectMenuDropdownItem(value, menuItem)}
              data-testid="dropdown-menu-item"
              unselectable
              hideSelectedBorder
              expansionSide={menuItem.alignRight ? 'left' : 'right'}
            />
          );
        }
        case 'link': {
          const redirect = () => () => {
            if (menuItem?.onClick) {
              dispatchAnalyticsEvent('HOME', 'A2', [menuItem?.title?.replaceAll('*', '')]);
              history.push(menuItem.onClick.path);
            }
          };

          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a key={index} className={styles.menuLink} href="#" onClick={redirect()} data-testid="menu-item">
              <Markdown source={menuItem.title} />
            </a>
          );
        }
        case 'container': {
          return (
            <DropdownContainer key={index} label={menuItem.title} data-testid="menu-item">
              {menuItem.children}
            </DropdownContainer>
          );
        }
        default: {
          return undefined;
        }
      }
    });
  }

  return (
    <nav className={styles.root}>
      <a href="/" onClick={handleClickLogo}>
        <img className={styles.logoImg} src={logo} alt="Logo Itaú" />
      </a>
      <MenuItems />

      {remoteConfig?.ENABLE_AUTH && <access-button alignRight style={{ marginLeft: 'auto' }} />}
    </nav>
  );
}
