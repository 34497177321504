export const Types = {
  AUTH_RESID: 'auth/AUTH_RESID',
  AUTH_RESID_LOAD: 'auth/AUTH_RESID_LOAD',
  AUTH_RESID_SUCCESS: 'auth/AUTH_RESID_SUCCESS',
  AUTH_RESID_ERROR: 'auth/AUTH_RESID_ERROR',
  AUTH_REMOVE: 'auth/AUTH_REMOVE',
};

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.AUTH_RESID_LOAD: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case Types.AUTH_RESID: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case Types.AUTH_RESID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
      };
    }
    case Types.AUTH_RESID_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
      };
    }
    case Types.AUTH_REMOVE: {
      return {};
    }
    default:
      return state;
  }
}

export function authResid(data) {
  return {
    type: Types.AUTH_RESID,
    data,
  };
}

export function authResidLoad() {
  return {
    type: Types.AUTH_RESID_LOAD,
  };
}

export function authResidError() {
  return {
    type: Types.AUTH_RESID_ERROR,
  };
}

export function authRemove() {
  return {
    type: Types.AUTH_REMOVE,
  };
}
