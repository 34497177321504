import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ toRem, palette, breakpoints }) => {
  const styles = {
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      fontSize: toRem(20),
      color: palette.primary.main,
      marginLeft: props => (props.handleBack ? toRem(-28) : 0),
      [breakpoints.down('sm')]: {
        marginBottom: '1rem',
      },
    },
    title: {
      fontSize: toRem(18),
      color: palette.primary.main,
      display: 'inline',
      lineHeight: '1.5rem',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      color: palette.primary.main,
      cursor: 'pointer',
    },
    backArrow: {
      marginRight: toRem(8),
    },
    description: {
      marginTop: toRem(10),
      marginBottom: toRem(20),
    },
  };

  return styles;
});
