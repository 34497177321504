import createOfferAction from './createOfferAction';
import profileSubmitAction from './profileSubmitAction';
import authDataSubmitSagaAction from './authDataSubmitSagaAction';
import createPaymentAction from './createPaymenteAction';
import hiringSubmitSagaAction from './hiringSubmitSagaAction';
import { Creators as PetCreators, Types as PetTypes } from '../../ducks/pet';
import { Creators as WelfareCreators, Types as WelfareTypes } from '../../ducks/welfare';
import pet from '../selectors/pet';
import welfare from '../selectors/welfare';

import scopes from '../../../auth/scopes';

const realizaEffects = (type, creator, selector, simulationToken, offerType, authToken, stateName, scope) => ({
  [type.TRIGGER_OFFER_SAGA_ACTION]: createOfferAction(creator),
  [type.TRIGGER_PROFILE_SUBMIT_SAGA_ACTION]: profileSubmitAction(creator),
  [type.TRIGGER_AUTH_DATA_SUBMIT_SAGA_ACTION]: authDataSubmitSagaAction(
    creator,
    selector,
    simulationToken,
    offerType,
    authToken,
    stateName,
  ),
  [type.TRIGGER_PAYMENT_SAGA_ACTION]: createPaymentAction(creator, selector),
  [type.TRIGGER_HIRING_REQUEST_SUBMIT_ACTION]: hiringSubmitSagaAction(creator, selector, offerType, scope),
});

export const petEffects = realizaEffects(
  PetTypes,
  PetCreators,
  pet,
  'pet_simular',
  'assistencia-pet',
  'pet_autenticar_email',
  'pet',
  scopes.PET,
);

export const welfareEffects = realizaEffects(
  WelfareTypes,
  WelfareCreators,
  welfare,
  'bemestar_simular',
  'assistencia-saude',
  'bemestar_autenticar_email',
  'welfare',
  scopes.WELFARE,
);
