import palette from './palette';
import { toRem } from './functions';

export default {
  useNextVariants: true,
  fontFamily: 'ItauText-Regular',
  fontSize: 16,
  h1: {
    fontFamily: 'ItauText-XBold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: toRem(32),
    lineHeight: toRem(44),
  },
  h2: {
    fontFamily: 'ItauText-Bold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: toRem(36),
    lineHeight: toRem(40),
  },
  h3: {
    fontFamily: 'ItauText-Bold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: toRem(32),
    lineHeight: toRem(36),
  },
  h4: {
    fontFamily: 'ItauText-Bold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: toRem(28),
    lineHeight: toRem(32),
  },
  h5: {
    fontFamily: 'ItauText-Bold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: toRem(24),
    lineHeight: toRem(28),
  },
  h6: {
    fontFamily: 'ItauText-Bold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: toRem(20),
    lineHeight: toRem(24),
  },
};
