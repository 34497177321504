import {
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ENVIRONMENTS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
} from 'trackingV2/constants';

const tagsLabels = {
  T1: 'LS:NL:NCC:Home:AssistenciaBem-Estar',
  A1: 'BTN:Home:Bem-Estar:PacotePrincipal:{0}', // [ExpandirDetalhes|RecolherDetalhes]
  A2: 'BTN:Home:Bem-Estar:{0}:VerCondicoesGerais', // [PacotePrincipal|MaisAssistencias]
  A3: 'BTN:Home:Bem-Estar:MaisAssistencias:{0}', // [ExpandirDetalhes|RecolherDetalhes]
  A4: 'BTN:Home:Bem-Estar:ContrateOnline:{0}', // [assistencias da home]
  A5: 'BTN:Home:Bem-Estar:ExpandirPerguntaFrequente:{0}', // [PerguntaClicada]
  A6: 'BTN:Home:Bem-Estar:VerMaisPerguntasFrequentes',
  A7: 'LS:NL:NCC:Bem-Estar:ModalFakeFeature',
  A8: 'BTN:Bem-Estar:FakeFeature:Confirmar:{0}', // [assistencias modal]
};

export const ANALYTICS_WELFARE_HOME = {
  T1: {
    nome: tagsLabels.T1,
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: tagsLabels.A1,
    noInteraction: false,
  },
  A2: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: tagsLabels.A2,
    noInteraction: false,
  },
  A3: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: tagsLabels.A3,
    noInteraction: false,
  },
  A4: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: tagsLabels.A4,
    noInteraction: false,
  },
  A5: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: tagsLabels.A5,
    noInteraction: false,
  },
  A6: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: tagsLabels.A6,
    noInteraction: false,
  },
  A7: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: tagsLabels.A7,
    noInteraction: false,
  },
  A8: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: tagsLabels.A8,
    noInteraction: false,
  },
};
