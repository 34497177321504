/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
import React, { createContext } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import { getFCP, getLCP, getCLS, getTTFB, getFID } from 'web-vitals';
import config from '../config';
import { getLog } from '../tracking/Logging.js';

export const AnalyticsContext = createContext();
const { REACT_APP_ENVIRONMENT, REACT_APP_VERSION } = process.env;
const { ENABLE_GOOGLE_ANALYTICS } = config;

export default function AnalyticsProvider({ children }) {
  const site = {
    nome: 'LJSEG',
    ambiente: 'NL',
    negocio: 'LojaDeSeguros',
    tipoDeCanal: `web AM:${REACT_APP_ENVIRONMENT}|UA:${navigator.userAgent}|`,
    versaoAmbiente: `LojaDeSeguros@${REACT_APP_VERSION}`,
  };

  const track = data => { // TODO É ISSO QUE É  ENVIADO PARA O ANALITICS?
    if (ENABLE_GOOGLE_ANALYTICS) {
      window.analyticsData = data;
      if (window?._frameworkDA) window._frameworkDA.Track();
    } else {
      return console.log('[analyticsSend] - analyticsSend', data);
    }
  };

  const pageLoad = page => {
    const defaultData = {
      site,
      page: {
        secao: page.secao,
        url: page.url,
        nome: page.nome,
      },
      rule: 'pageLoad',
    };
    track(defaultData);
  };

  const logEvent = ({ category, action, label = undefined, value = undefined, valueOnlyNumber = undefined }) => {
    if (Boolean(category) === false || Boolean(action) === false || Boolean(label) === false) return;
    const defaultData = {
      ...getLog({ category, action, label, value, valueOnlyNumber }),
      path: 'Seguros',
      rule: 'customLink',
    };
    track(defaultData);
  };


  const sendWebVitals = () => {
    const sendWebVitalsGAEvents = ({ name, delta, id, entries }) => {
      window.analyticsData = {
        rule: 'customLink',
        events: {
          category: 'Web Vitals',
          action: name,
          label: id,
          eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
          nonInteraction: true,
        },
      };
      window._frameworkDA.Track();
    };
    
    getFCP(sendWebVitalsGAEvents);
    getLCP(sendWebVitalsGAEvents);
    getCLS(sendWebVitalsGAEvents);
    getTTFB(sendWebVitalsGAEvents);
    getFID(sendWebVitalsGAEvents);
  };

  return (
    <AnalyticsContext.Provider value={{ pageLoad, logEvent, sendWebVitals }}>{children}</AnalyticsContext.Provider>
  );
}

AnalyticsProvider.propTypes = { children: oneOfType([arrayOf(node), node]).isRequired };
