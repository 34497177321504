import moment from 'moment';

/* eslint-disable no-shadow */
export const Types = {
  ADD: 'AUTH_SCOPES/ADD',
  ADD_SECOND_STEP: 'AUTH_SCOPES/ADD_SECOND_STEP',
  REMOVE: 'AUTH_SCOPES/REMOVE',
  SET_TIMEOUT: 'AUTH_SCOPES/SET_TIMEOUT',
};

const initialState = {
  data: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.ADD: {
      const addNewDataInArray = (state, action) => {
        if (state.data.length > 0) {
          const currentScopeIndex = state.data.findIndex((auth) => auth.scope === action.data.scope);
          if (currentScopeIndex > -1) {
            state.data[currentScopeIndex] = action.data;
            return state.data;
          }
          return state.data.concat(action.data.data || action.data);
        }
        return [...state.data, action.data];
      };

      return {
        ...state,
        data: addNewDataInArray(state, action),
      };
    }
    case Types.ADD_SECOND_STEP: {
      return {
        ...state,
        data: state.data.map((authScope) => {
          if (authScope.scope !== action.scope) return authScope;
          return { ...authScope, token: true };
        }),
      };
    }
    case Types.REMOVE: {
      return {
        ...state,
        data: state.data.filter((authScope) => authScope.scope !== action.scope),
      };
    }
    case Types.SET_TIMEOUT: {
      const {
        data: { scope, time, unit },
      } = action;

      const { data } = state;

      return {
        ...state,
        data: data.map((s) => {
          if (s.scope === scope) {
            return {
              ...s,
              expiresAt: moment().add(time, unit),
            };
          }
          return s;
        }),
      };
    }
    default:
      return state;
  }
}

export function addAuthScope(authScope) {
  return {
    type: Types.ADD,
    data: authScope,
  };
}

export function addSecondStepScope(scope) {
  return {
    type: Types.ADD_SECOND_STEP,
    scope,
  };
}

export function removeAuthScope(scope) {
  return {
    type: Types.REMOVE,
    scope,
  };
}

export function setTimeoutAuthScope(data) {
  return {
    type: Types.SET_TIMEOUT,
    data,
  };
}
