import palette from '../palette';

export default {
  root: {
    '&$focusVisible': {
      outlineColor: palette.focusColor,
      outlineStyle: 'solid',
      outlineWidth: '2px',
    },
  },
};
