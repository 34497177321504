/* eslint-disable no-param-reassign */
export const clean = string => {
  const htmlRegex = /<[^>]*>?/gm;
  const otherSymbolsRegex = /\*/g;
  const stringWithoutHtml = string.replace(htmlRegex, '');
  const stringFinalResult = stringWithoutHtml.replace(otherSymbolsRegex, '');
  return stringFinalResult;
};

export const mountAriaLabel = (index, length, label) => `Item ${index + 1} de ${length}, ${label}`;

export const groupTexts = (...itens) => {
  const removeHtml = /(<([^>]+)>)/gi;
  let fullText = '';
  itens.forEach(item => {
    fullText += ` ${item},`;
  });
  return fullText.replace(removeHtml, '');
};

export const removeSpecialCharactersAndAccentsUnderline = (str = '', preffix = '', suffix = '') =>
  `${preffix}${str
    .replace(/[áàãâä]/iu, 'a')
    .replace(/[éèêë]/iu, 'e')
    .replace(/[íìîï]/iu, 'i')
    .replace(/[óòõôö]/iu, 'o')
    .replace(/[úùûü]/iu, 'u')
    .replace(/[ç]/iu, 'c')
    .replace(/[^a-z0-9]/iu, '_')
    .replace(/ /g, '_')}${suffix}`;

export const removeSpecialCharactersAndAccents = string =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[;!/?]/g, '');

export const wordToUpperCamelCase = word => {
  const upperCamelCasedWord = word.charAt(0).toUpperCase() + word.slice(1);

  return upperCamelCasedWord;
};

export const phraseToUpperCamelCase = phrase => {
  const splittedPhrase = phrase.split(' ');
  const splittedAndUpperCamelCasedPhrase = splittedPhrase.map(wordToUpperCamelCase);
  const joinedAndUpperCamelCasedPhrase = splittedAndUpperCamelCasedPhrase.join('');

  return joinedAndUpperCamelCasedPhrase;
};
