import i18next from 'i18next';

const SHOWCASE = {
  TITLE: i18next.t('SHOWCASE.TITLE'),
  CARDS: [
    {
      IMG_URL: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/home/vitrine_odonto.png',
      TEXT: i18next.t('SHOWCASE.CARDS.ODONTO.TEXT'),
      BUTTON_TEXT: i18next.t('SHOWCASE.CARDS.BUTTON_TEXT'),
      LINK: i18next.t('SHOWCASE.CARDS.ODONTO.LINK'),
      CONTEXT_MOBILE: i18next.t('SHOWCASE.CARDS.ODONTO.CONTEXT_MOBILE'),
      CONTEXT: i18next.t('SHOWCASE.CARDS.ODONTO.CONTEXT'),
      LABEL: i18next.t('SHOWCASE.CARDS.RELEASE_LABEL'),
    },
    {
      IMG_URL: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/home/vitrine_resid.png',
      TEXT: i18next.t('SHOWCASE.CARDS.RESID.TEXT'),
      BUTTON_TEXT: i18next.t('SHOWCASE.CARDS.BUTTON_TEXT'),
      LINK: i18next.t('SHOWCASE.CARDS.RESID.LINK'),
      CONTEXT_MOBILE: i18next.t('SHOWCASE.CARDS.RESID.CONTEXT_MOBILE'),
      CONTEXT: i18next.t('SHOWCASE.CARDS.RESID.CONTEXT'),
    },
    {
      IMG_URL: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/home/vitrine_auto.png',
      TEXT: i18next.t('SHOWCASE.CARDS.AUTO.TEXT'),
      BUTTON_TEXT: i18next.t('SHOWCASE.CARDS.BUTTON_TEXT'),
      LINK: i18next.t('SHOWCASE.CARDS.AUTO.LINK'),
      CONTEXT_MOBILE: i18next.t('SHOWCASE.CARDS.AUTO.CONTEXT_MOBILE'),
      CONTEXT: i18next.t('SHOWCASE.CARDS.AUTO.CONTEXT'),
    },
  ],
  INFO_TITLE: i18next.t('SHOWCASE.INFO_TITLE'),
  INFO: {
    TITLE_1: i18next.t('SHOWCASE.INFO.TITLE_1'),
    TEXT_1: i18next.t('SHOWCASE.INFO.TEXT_1'),
  },
  SHOW_MORE: i18next.t('SHOWCASE.SHOW_MORE'),
};

export default SHOWCASE;
