const cancellationModule = {
  CANCELLATION: {
    PDF_CANCELLATION_CONDITIONS:
      'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/termos/{{type}}/condicao_de_cancelamento.pdf',
    TITLE: 'cancelamento',
    SUBTITLE: 'para iniciar o pedido de cancelamento, informe os dados do responsável pela compra',
    HELPER_TEXT:
      '* Para cancelar um Plano Odontológico Jovem, é necessário utilizar os dados (CPF, Celular e E-mail) do pagador informados durante a contratação',
    BUTTON: {
      TEXT: 'continuar',
    },

    BANNER_INFO: {
      MESSAGE: 'existe {{count}} erro que precisa ser corrigido',
      MESSAGE_PLURAL: 'existem {{count}} erros que precisam ser corrigidos',
    },

    ALERT_MODAL: {
      TITLE: 'algo deu errado',
      SUBTITLE: 'verifique os dados digitados e tente novamente mais tarde',
    },

    DOUBT: {
      TITLE: 'você já solicitou o cancelamento ou tem dúvidas?',
      TITLE2: 'ainda está com dúvidas?',
      DESCRIPTION: 'ligue para a nossa central Itaú corretora de seguros',
      TIME_AVAILABLE: '33663111 de segunda a sexta das 9h ás 18h',
      TIME_AVAILABLE_REGION_METROPOLITAN: 'capitais e regiões metropolitanas 4090 1125 de 2ª a 6ª das 8h às 20h',
      TIME_AVAILABLE_REGION_OTHERS: 'demais regiões 0800 791 1125 de 2ª a 6ª das 8h às 20h',
    },
    RECEIPT_QUESTIONS: {
      TITLE: 'ainda está com dúvidas?',
      DESCRIPTION: 'ligue para a nossa central Itaú corretora de seguros',
      TIME_AVAILABLE_REGION_METROPOLITAN: 'capitais e regiões metropolitanas 4090 1125 de 2ª a 6ª das 8h às 20h',
      TIME_AVAILABLE_REGION_OTHERS: 'demais regiões 0800 791 1125 de 2ª a 6ª das 8h às 20h',
    },
  },
};

export default cancellationModule;
