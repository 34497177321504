const sharedLanding = {
  RECEIPT: {
    STEPS: {
      TYPE: 'success',
      ICON: 'icon-itaufonts_chat_enviado',
      ICON_EMPTY: '',
      TEXT_EMAIL: 'recebimento do e-mail com resumo da sua compra',
      CONFIRM_PAYMENT: 'confirmação de pagamento, aguarde um e-mail de boas-vindas com as regras do produto',
      INSTRUCTIONS: 'você poderá começar a utilizar suas assistências de acordo com a carência de cada uma delas :)',
    },
    SUBTITLE: 'vamos ver os próximos passos? são eles:',
    HEADER: 'o número da sua adesão é:',
    INFO:
      'Para cancelamento em até 7 dias da data de compra, você receberá o estorno do valor total da assistência, conforme o Código de Defesa do Consumidor',
  },
  SUMMARY: {
    RESUME: 'resumo da compra',
    PROFILE_SECTION: {
      TITLE: 'dados do **titular da assistência**',
      NAME: 'nome',
      CPF: 'CPF',
      EMAIL: 'e-mail',
      CELLPHONE: 'celular',
      BIRTHDAY: 'data de nascimento',
      PAYMENT_METHOD: 'forma de pagamento',
    },
    ADDRESS_SECTION: {
      TITLE: 'endereço de **correspondência**',
      CEP: 'CEP',
      ADDRESS: 'endereço',
      ADDRESS_NUMBER: 'número',
      COMPLEMENT: 'complemento',
    },
    ASSISTANCES: {
      TITLE: 'assistências do **seu pacote**',
      ADDITIONAL: 'assistências adicionais',
      PRINCIPAL_PACKAGE: 'pacote principal',
      VALUES: 'valores',
      FOOTER_TEXT: '**total do seu pacote:** {{price}} /mês',
    },
    BUTTONS: {
      FINISH: 'Finalizar',
      BACK: 'Voltar',
    },
  },
};

export default sharedLanding;
