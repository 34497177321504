const autoModule = {
  INFORMATION_STEP: {
    PARTICULAR_CAR: 'lembrando que cobrimos apenas veículos de uso particular',
    CAR_PLATE: 'placa do carro',
  },
  SCHEDULED_INSPECTION_PAGE: {
    DESCRIPTION_1: 'identificamos que você já possui uma vistoria agendada para o seu veículo.',
    SUBTITLE: 'precisa reagendar sua vistoria?',
    DESCRIPTION_2:
      ' não se preocupe, caso você não possa comparecer na data marcada, entraremos em contato para um novo agendamento.',
  },
};
export default autoModule;
