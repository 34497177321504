import { call, put, select } from 'redux-saga/effects';
import { setPreSelectedCoverage, Types } from 'store/ducks/residencial';
import { Types as AuthTypes } from 'store/ducks/auth';
import {
  residencialCoverageSelector,
  residencialRequestSelector,
  residencialSelectedAssistanceSelector,
} from 'store/sagas/selectors/residencial';
import { residCoveragesCoverageSelector } from 'store/sagas/selectors/residCoverage';
import { BIKE_COVERAGE } from 'utils/constants';
import { postSimulation } from 'api/E2E/Resid/Simulations';
import { setErrorSagaAction } from '../../ducks/httpErrors';

export function* setInitialPreSelectedCoverage() {
  const residInitialCoverages = yield select(residCoveragesCoverageSelector);
  const mandatoryCoverages = residInitialCoverages
    .filter((item) => item.cobertura_obrigatoria)
    .map((item) => item.id_cobertura);

  for (const mandatoryCoveragesKey of mandatoryCoverages) {
    yield put(setPreSelectedCoverage(mandatoryCoveragesKey));
  }
}

function* getCoverages(isFinal) {
  const { excluidas, selecionadas, todas } = yield select(residencialCoverageSelector);
  if (isFinal) {
    return selecionadas.map((s) => ({
      id_cobertura: s.id_cobertura,
      importancia_segurada: s.importancia_segurada,
    }));
  }

  const idsExcluidas = excluidas.map((e) => e.id_cobertura);
  return todas
    .filter((fc) => !idsExcluidas.includes(fc.id_cobertura))
    .map((s) => {
      const coberturaSelecionada = selecionadas.find((sl) => sl.id_cobertura === s.id_cobertura);
      if (coberturaSelecionada) {
        return {
          id_cobertura: coberturaSelecionada.id_cobertura,
          importancia_segurada: coberturaSelecionada.importancia_segurada,
        };
      }
      return { id_cobertura: s.id_cobertura, importancia_segurada: s.importancia_segurada };
    });
}

function* setCoverages(isFinal, coberturas, novasCoberturas) {
  const todasAsCoberturas = yield select(residCoveragesCoverageSelector);
  let coberturasSimuladas = {};
  if (!isFinal) {
    const { selecionadas } = yield select(residencialCoverageSelector);
    const idsSelecionadas = selecionadas.map((cs) => cs.id_cobertura);
    const idNovasCoberturas = novasCoberturas.map((nc) => nc.id_cobertura);
    const coberturasFundidas = todasAsCoberturas
      .filter((c) => idNovasCoberturas.includes(c.id_cobertura) || c.identificador === BIKE_COVERAGE.identificador)
      .map((c) => ({
        ...c,
        ...coberturas.find((co) => co.id_cobertura === c.id_cobertura),
      }));
    const todasNovas = coberturasFundidas;
    const excluidasNovas = coberturasFundidas.filter((cf) => cf.identificador === BIKE_COVERAGE.identificador);
    const idsRespostaExcluidas = excluidasNovas.map((e) => e.id_cobertura);
    const fixasNovas = coberturasFundidas.filter((cf) => idsSelecionadas.includes(cf.id_cobertura));
    const selecionadasNovas = fixasNovas;
    let naoSelecionadasNovas = coberturasFundidas.filter((cf) => !idsSelecionadas.includes(cf.id_cobertura));
    naoSelecionadasNovas = naoSelecionadasNovas.filter((cf) => !idsRespostaExcluidas.includes(cf.id_cobertura));
    const preSelecionadasNovas = idsSelecionadas.map((cs) => ({ [cs]: true }));
    coberturasSimuladas = {
      excluidas: excluidasNovas,
      fixas: fixasNovas,
      naoSelecionadas: naoSelecionadasNovas,
      preSelecionadas: preSelecionadasNovas,
      selecionadas: selecionadasNovas,
      todas: todasNovas,
    };
  } else {
    const { excluidas, selecionadas, todas } = yield select(residencialCoverageSelector);
    const fixasNovas = selecionadas.map((s) => ({
      ...s,
      ...coberturas.find((co) => co.id_cobertura === s.id_cobertura),
    }));
    const selecionadasNovas = fixasNovas;
    const idsSelecionadasNovas = selecionadasNovas.map((ns) => ns.id_cobertura);
    const idsExcluidas = excluidas.map((e) => e.id_cobertura);
    const preSelecionadasNovas = selecionadasNovas.map((ns) => ({ [ns.id_cobertura]: true }));

    let naoSelecionadasNovas = todas.filter((cf) => !idsSelecionadasNovas.includes(cf.id_cobertura));
    naoSelecionadasNovas = naoSelecionadasNovas.filter((cf) => !idsExcluidas.includes(cf.id_cobertura));
    coberturasSimuladas = {
      excluidas,
      fixas: fixasNovas,
      naoSelecionadas: naoSelecionadasNovas,
      preSelecionadas: preSelecionadasNovas,
      selecionadas: selecionadasNovas,
      todas,
    };
  }

  yield put({ type: Types.SET_COVERAGES, data: coberturasSimuladas });
}

function* setSimulation(isFinal, captchaSimular, captchaAutenticar, divergent, enableLoginResid) {
  const requisicao = yield select(residencialRequestSelector);
  const novasCoberturas = yield getCoverages(isFinal);
  requisicao.oferta.produtos[0].coberturas = novasCoberturas;
  requisicao.oferta.produtos[0].assistencias = [];
  let headers = {};

  if (isFinal) {
    const authSessionStorage = sessionStorage.getItem('auth:safe');
    if (authSessionStorage) {
      const authOnStorageSession = JSON.parse(authSessionStorage);
      headers = {
        'token-sts': authOnStorageSession.accessToken,
        identifier: authOnStorageSession.identifier,
      };
    }

    const assistenciasSelecionada = yield select(residencialSelectedAssistanceSelector);
    if (assistenciasSelecionada.id_assistencia) {
      requisicao.oferta.produtos[0].assistencias = [{ id_assistencia: assistenciasSelecionada.id_assistencia }];
    }
  }

  if (divergent) {
    requisicao.proponente = {
      contatos: requisicao?.proponente.contatos,
      data_nascimento: requisicao?.proponente.data_nascimento
    }
  }

  const simulacao = yield call(postSimulation, requisicao, captchaSimular, headers, enableLoginResid);
  const { coberturas } = simulacao.produtos[0];
  yield setCoverages(isFinal, coberturas, novasCoberturas);
  yield put({ type: Types.LOAD_SIMULATION_SUCCESS, data: simulacao });

  if (isFinal && !enableLoginResid) {
    yield put({ type: AuthTypes.AUTH_RESID, data: captchaAutenticar });
  }
}

export default function* loadSimulation(action) {
  const { data } = action;
  const { isFinalSimulation, captchaSimular, captchaAutenticar, divergent, enableLoginResid } = data;
  if (enableLoginResid) {
    yield put({ type: Types.SET_DIVERGENT_DATA_QUOTATION_HOLDER, data: false });
  }

  try {
    yield setSimulation(isFinalSimulation, captchaSimular, captchaAutenticar, divergent, enableLoginResid);
    yield put({type: Types.LOAD_SIMULATION_RESET});
  } catch (err) {
    yield put({type: Types.LOAD_SIMULATION_RESET});
    const { data, status } = err.response;
    if (status === 400 && data?.erros?.includes('cpf')) {
      yield put({ type: Types.SET_DIVERGENT_DATA_QUOTATION_HOLDER, data: true });
      yield put({ type: Types.SET_DIVERGENT_SECOND_SIMULATION, data: true });
      return;
    } else if (status === 400 && data?.mensagem === 'Error validating authentication') {
      yield put({ type: Types.SET_ERROR_VALIDATION_AUTH });
    } else if(status === 400 && data?.mensagem === 'Erro ao enviar simulação') {
        yield put({ type: Types.LOAD_SIMULATION_ERROR, err });
        yield put(setErrorSagaAction(action));
    } else {
      yield put({ type: Types.LOAD_SIMULATION_ERROR, err });
      yield put(setErrorSagaAction(action));
    }
  }
}
