import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import useDropDown from './hooks/useDropDown';
import { useStyles, DropdownListContainer } from './styles';

export default function DropdownSelector({
  id,
  options,
  defaultOption,
  initialOption,
  ariaLabel,
  onSelect,
  hideSelectedBorder,
  unselectable,
  openOnMouseOver,
  className,
  expansionSide,
  onClick,
  disabled,
  ...props
}) {
  const {
    opened,
    listRef,
    onHide,
    iconOption,
    selectedOption,
    setSelectedOption,
    idOption,
    setIconOption,
  } = useDropDown({
    unselectable,
    defaultOption,
    onSelect,
    id,
    openOnMouseOver,
    onClick,
    disabled,
  });
  const styles = useStyles({ hideSelectedBorder, expansionSide, disabled });
  const [dropdownContainerRef, dropdownContainerBounds] = useMeasure({ polyfill: ResizeObserver });
  const maxHeight = dropdownContainerBounds.height + 10;

  useEffect(() => {
    if (initialOption?.label) setSelectedOption(initialOption.label);
    if (initialOption?.iconDefault) setIconOption(initialOption.iconDefault);
  }, []);

  useEffect(() => {
    if (initialOption?.label) setSelectedOption(initialOption.label);
    if (initialOption?.iconDefault) setIconOption(initialOption.iconDefault);
  }, []);

  const getSelectedOption = () => {
    const selectedProps = {};
    const selectedOptionDescription = options.find(option => option.label === selectedOption)?.description;
    if (selectedOption !== defaultOption.label) selectedProps['aria-label'] = 'selecionado';
    return (
      <>
        <i id={`default-icon-dynamic-${id}`} aria-hidden className={iconOption} />
        <span
          role="option"
          aria-live="assertive"
          {...selectedProps}
          aria-selected={selectedOption !== defaultOption.label}
          data-item-id={idOption}
          className="selected-option-label"
        >
          {selectedOption}
        </span>
        {selectedOptionDescription ? (
          <span className={clsx(styles.dropDownItemDescription, 'selected-option-description')}>
            {selectedOptionDescription}
          </span>
        ) : null}
        <i aria-hidden className="icon-itaufonts_seta_down" />
      </>
    );
  };

  const getElementA11yProps = (elementType, item) => {
    switch (elementType) {
      case 'listContainer': {
        return unselectable
          ? {
              role: 'menu',
            }
          : {
              role: 'listbox',
            };
      }
      case 'listItem': {
        return unselectable
          ? {
              role: 'menuitem',
              'aria-label': `${item.label} ${unselectable ? 'link' : ''}`,
            }
          : {
              role: 'option',
              'aria-selected': selectedOption === item.label,
            };
      }
      case 'defaultListItem': {
        return unselectable
          ? {
              role: 'button',
            }
          : {
              role: 'option',
              'aria-selected': selectedOption === defaultOption.label,
            };
      }
      default:
        return {};
    }
  };

  return (
    <ClickAwayListener onClickAway={onHide}>
      <div className={clsx(styles.dropDownWrapper, className)} {...props}>
        <div className={styles.dropDownInit}>
          <div
            aria-live="assertive"
            aria-label={ariaLabel}
            className={`${opened && styles.hide} ${styles.dropDownItemSelected}`}
            id={`dropDownSelected-${id}`}
            aria-expanded={opened}
            aria-controls={`dropDownList-${id}`}
            aria-disabled={disabled}
            role="listbox"
            tabIndex="0"
          >
            {getSelectedOption()}
          </div>
          <div className={styles.dropDownListContainer}>
            <DropdownListContainer
              id={`dropDownList-${id}`}
              aria-expanded={opened}
              className={styles.dropDownContainer}
              ref={listRef}
              {...getElementA11yProps('listContainer')}
              maxHeight={maxHeight}
              opened={opened}
            >
              <div ref={dropdownContainerRef}>
                <div
                  className={`dropDownItem-${id} ${styles.dropDownFixedOption}`}
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex="0"
                  {...getElementA11yProps('defaultListItem')}
                  id={`default-option-${id}`}
                  data-testid="dropDownListItem"
                >
                  <div className={styles.dropDownFixedOptionContent}>
                    {defaultOption.iconDefault && (
                      <i id={`default-icon-initial-${id}`} aria-hidden className={defaultOption.iconDefault} />
                    )}
                    <span className={styles.dropDownFixedOptionText}>{defaultOption.label}</span>
                    <i aria-hidden className="icon-itaufonts_seta_up" />
                  </div>
                  <div className={styles.dropDownFixedOptionShadowHidder} />
                </div>
                <div className={styles.dropContainerBoxShadowRight}>
                  <div className={styles.dropContainerBoxShadowBottom}>
                    {options
                      .filter(item => !item.disabled)
                      .map(item => {
                        const idItem = item.label.replace(/[|&;!?$%@"<> ()+,]/g, '');
                        return (
                          <li
                            key={`${idItem}-option-${id}`}
                            className={`dropDownItem-${id} ${styles.dropDowItem}`}
                            id={`${idItem}-option-${id}`}
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            tabIndex="0"
                            data-item-id={item.id}
                            {...getElementA11yProps('listItem', item)}
                          >
                            <span data-item-id={item.id}>
                              {item.iconDefault && (
                                <i id={`${idItem}-icon-${id}`} aria-hidden className={item.iconDefault} />
                              )}
                              <span>{item.label}</span>
                            </span>
                            {item.description ? (
                              <span data-item-id={`${item.id}-description`} className={styles.dropDownItemDescription}>
                                {item.description}
                              </span>
                            ) : null}
                          </li>
                        );
                      })}
                  </div>
                </div>
              </div>
            </DropdownListContainer>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

DropdownSelector.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      iconDefault: PropTypes.string,
      iconHover: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  defaultOption: PropTypes.object.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  unselectable: PropTypes.bool,
  openOnMouseOver: PropTypes.bool,
  className: PropTypes.string,
  expansionSide: PropTypes.string,
  disabled: PropTypes.bool,
};

DropdownSelector.defaultProps = {
  unselectable: false,
  openOnMouseOver: false,
  expansionSide: 'right',
};
