import { put } from 'redux-saga/effects';
import moment from 'moment';

export default function genericProfileSubmitAction(creator) {
  return function* profileSubmitAction({ data }) {
    const replacer = value => value && value.replace(/\D/g, '');

    const proponent = {
      cpf: replacer(data.holderCpf),
      nome: data.holderName,
      data_nascimento: moment(data.holderBirthday, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      endereco: {
        cep: replacer(data.cep),
        cidade: data.city,
        estado: data.state,
        logradouro: data.address,
        numero: data.addressNumber,
        bairro: data.neighborhood,
        complemento: data.complement,
      },
    };

    yield put(creator.createHiringRequestProponent({ cpf: proponent.cpf, nome: proponent.nome }));
    yield put(creator.createQuotationRequestProponent(proponent));
  };
}
