import axios from 'axios';

import { performance } from 'config';

export const getE2EAllowedChannels = async () => {
  const trace = performance.trace('getE2EAllowedChannels');
  trace.start();
  const response = await axios.get('v1/seguros/channels/search');
  trace.stop();
  return response.data;
};
