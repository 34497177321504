import { useRef, useEffect, useState } from 'react';
import {
  SPACEBAR_KEY_CODE,
  ENTER_KEY_CODE,
  DOWN_ARROW_KEY_CODE,
  UP_ARROW_KEY_CODE,
  ESCAPE_KEY_CODE,
} from '../constants';

const useDropDown = ({ defaultOption, onSelect, id, unselectable, openOnMouseOver, onClick, disabled }) => {
  let refItem;
  let dropDownListRef;
  const listItemsIds = [];
  const [iconOption, setIconOption] = useState(defaultOption.iconDefault);
  const [selectedOption, setSelectedOption] = useState(defaultOption.label);
  const [opened, setOpened] = useState(false);
  const listRef = useRef();

  const closeDropDown = () => {
    setOpened(false);

    if (refItem) refItem.focus();
  };

  const openDropdown = () => {
    if (disabled) return;

    setOpened(true);
    if (typeof onClick === 'function') onClick(defaultOption.label);
    setTimeout(() => document.querySelector(`#default-option-${id}`).focus(), 500);
  };

  const onHide = () => closeDropDown();

  const setSelectedListItem = event => {
    let selectedElement = event.target;
    let isDefaultOption = false;

    /** Get clicked element parent while it's not an <li> or the default option div */
    while (selectedElement.tagName !== 'LI' && selectedElement.id?.search('default-option') === -1) {
      selectedElement = selectedElement.parentElement;
      if (selectedElement.id?.search('default-option') === -1) isDefaultOption = true;
    }

    const optionElement = selectedElement.querySelector('span:first-of-type');
    const optionValue = optionElement.innerText;
    const { itemId } = optionElement.dataset;
    const iconClassName = selectedElement.querySelector('i:first-of-type')?.classList?.value;

    if (onSelect && typeof onSelect === 'function') onSelect(optionValue, itemId);

    if (!unselectable) {
      setSelectedOption(optionValue);
      setIconOption(iconClassName);
    } else if (isDefaultOption) {
      closeDropDown();
      return;
    }

    closeDropDown();
  };

  const focusNextListItem = (direction, event) => {
    const activeElementId = document.activeElement.id;
    const currentActiveElementIndex = listItemsIds.indexOf(activeElementId);
    if (direction === DOWN_ARROW_KEY_CODE) {
      const currentActiveElementIsNotLastItem = currentActiveElementIndex < listItemsIds.length - 1;

      if (currentActiveElementIsNotLastItem) {
        const nextListItemId = listItemsIds[currentActiveElementIndex + 1];
        document.querySelector(`#${nextListItemId}`).focus();
        event.preventDefault();
      } else {
        document.querySelector(`#${listItemsIds[0]}`).focus();
        event.preventDefault();
      }
    } else if (direction === UP_ARROW_KEY_CODE) {
      const currentActiveElementIsNotFirstItem = currentActiveElementIndex > 0;
      if (currentActiveElementIsNotFirstItem) {
        const nextListItemId = listItemsIds[currentActiveElementIndex - 1];
        document.querySelector(`#${nextListItemId}`).focus();
        event.preventDefault();
      } else {
        document.querySelector(`#${listItemsIds[listItemsIds.length - 1]}`).focus();
        event.preventDefault();
      }
    }
  };

  const toggleListVisibility = event => {
    const dropDownOpened = SPACEBAR_KEY_CODE.includes(event.keyCode) || event.keyCode === ENTER_KEY_CODE;

    if (event.keyCode === DOWN_ARROW_KEY_CODE) {
      focusNextListItem(DOWN_ARROW_KEY_CODE, event);
    }

    if (event.keyCode === UP_ARROW_KEY_CODE) {
      focusNextListItem(UP_ARROW_KEY_CODE, event);
    }

    if (event.type === 'click' || dropDownOpened) {
      event.preventDefault();
      openDropdown();
    }

    if (event.type === 'mouseenter' && openOnMouseOver) {
      event.preventDefault();
      openDropdown();
    }

    if (event.type === 'mouseleave' && openOnMouseOver) {
      event.preventDefault();
      closeDropDown();
    }
  };

  useEffect(() => {
    if (!listRef.current) return;

    refItem = document.querySelector(`#dropDownSelected-${id}`);
    dropDownListRef = document.querySelector(`#dropDownList-${id}`);
    refItem.addEventListener('click', event => toggleListVisibility(event));
    refItem.addEventListener('keydown', event => toggleListVisibility(event));
    refItem.addEventListener('mouseenter', event => toggleListVisibility(event));
    dropDownListRef.addEventListener('mouseleave', event => toggleListVisibility(event));

    const listItems = document.querySelectorAll(`.dropDownItem-${id}`);
    listItems.forEach(item => listItemsIds.push(item.id));

    listRef.current.addEventListener('click', e => {
      setSelectedListItem(e);
    });

    listRef.current.addEventListener('keydown', e => {
      switch (e.keyCode) {
        case ENTER_KEY_CODE:
          setSelectedListItem(e);
          break;

        case DOWN_ARROW_KEY_CODE:
          focusNextListItem(DOWN_ARROW_KEY_CODE, e);
          break;

        case UP_ARROW_KEY_CODE:
          focusNextListItem(UP_ARROW_KEY_CODE, e);
          break;

        case ESCAPE_KEY_CODE:
          closeDropDown();
          break;

        default:
          if (e.shiftKey && e.key === 'Tab') {
            focusNextListItem(UP_ARROW_KEY_CODE, e);
          } else if (e.key === 'Tab') {
            focusNextListItem(DOWN_ARROW_KEY_CODE, e);
          }

          if (SPACEBAR_KEY_CODE.includes(e.keyCode)) {
            e.preventDefault();
            setSelectedListItem(e);
          }
          break;
      }
    });
  }, [listRef]);

  return {
    refItem,
    listItemsIds,
    selectedOption,
    setSelectedOption,
    iconOption,
    setIconOption,
    listRef,
    closeDropDown,
    onHide,
    opened,
  };
};

export default useDropDown;
