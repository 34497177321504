import i18next from 'i18n';
import { ANALYTICS_FAKEDOOR_VIDA_FLOW } from 'tracking';

export default {
  main: {
    title: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.TITLE'),
    subtitle: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.SUBTITLE'),
    firebaseCollection: 'fakedoor-lifeInsurance',
    analytics: {
      click: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_SAIBAMAIS, value: '$Value$', constant: true },
      close: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_FECHAR_MENU },
    },
    researchs: [
      {
        id: 0,
        researchId: 0,
        title: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.RESEARCHS.AWARDABLE.TITLE'),
        description: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.RESEARCHS.AWARDABLE.DESCRIPTION'),
        startButtonText: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.RESEARCHS.AWARDABLE.START_BUTTON_TEXT'),
      },
      {
        id: 1,
        researchId: 1,
        title: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.RESEARCHS.CAPITALIZATION.TITLE'),
        description: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.RESEARCHS.CAPITALIZATION.DESCRIPTION'),
        startButtonText: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.RESEARCHS.CAPITALIZATION.START_BUTTON_TEXT'),
      },
      {
        id: 2,
        researchId: 2,
        title: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.RESEARCHS.COVERAGES.TITLE'),
        description: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.RESEARCHS.COVERAGES.DESCRIPTION'),
        startButtonText: i18next.t('LIFE_INSURANCE_RESEARCH.MAIN.RESEARCHS.COVERAGES.START_BUTTON_TEXT'),
      },
    ],
  },
  researchs: [
    {
      id: 0,
      title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.TITLE'),
      pages: [
        {
          id: 0,
          type: 'question-radiogroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.0.TITLE'),
          description: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.0.DESCRIPTION'),
          content: {
            options: [
              {
                id: 'ef233b0a-fbf9-4ac0-b279-5947e9b0efa6',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.0.CONTENT.OPTIONS.0'),
                value: '18 a 35 anos',
              },
              {
                id: '89c2c749-85d0-4faa-a0a1-23a5cc2a5c98',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.0.CONTENT.OPTIONS.1'),
                value: '36 a 45 anos',
              },
              {
                id: '0fe95ad2-40d1-46ac-9363-638a88d88bce',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.0.CONTENT.OPTIONS.2'),
                value: '46 a 55 anos',
              },
              {
                id: '52e8fc8f-4dc6-40a0-8fd6-c73432f808a8',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.0.CONTENT.OPTIONS.3'),
                value: '56 a 65 anos',
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_SELECT_AGE, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 1,
          type: 'information-infocardgroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.TITLE'),
          backButton: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.BACK_BUTTON'),
          content: {
            items: [
              {
                id: '4ac02126-60d4-4fcd-8b03-357ae6c6a38e',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '18 a 35 anos',
                },
              },
              {
                id: 'dab66a72-0870-4212-bdae-526916c0df38',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '300.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '18 a 35 anos',
                },
              },
              {
                id: '93a7b69d-b7fa-4819-9851-9dec1f44fbcd',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '50,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '18 a 35 anos',
                },
              },
              {
                id: '74cedf7a-3c4e-4014-bae9-e6514e6a6e62',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '36 a 45 anos',
                },
              },
              {
                id: '111a9323-78a4-41bf-a9fc-18c7d45d3cf8',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '600.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '36 a 45 anos',
                },
              },
              {
                id: '011d7d7d-93e7-4abb-95d7-487dea2bc43a',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '100,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '36 a 45 anos',
                },
              },
              {
                id: '938649f8-6497-4b00-92c6-ee680e2a6953',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '46 a 55 anos',
                },
              },
              {
                id: '60b14758-e3d9-43c5-b84c-c9b6bbeb50a9',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '2.000.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '46 a 55 anos',
                },
              },
              {
                id: '8f3ded44-76f5-431d-8d50-42461dcf1a7a',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '333,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '46 a 55 anos',
                },
              },
              {
                id: '5e44aad7-c23f-44c4-84f3-86b410862da8',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '56 a 65 anos',
                },
              },
              {
                id: '3bde632e-fe22-49e5-9f61-7a94bdfd9714',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '6.000.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '56 a 65 anos',
                },
              },
              {
                id: '8ac5cb6c-cfc3-4d2c-84a0-ca4005abeedc',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.1.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '1000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 0,
                  answerValue: '56 a 65 anos',
                },
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_PRICE_CONTINUE },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_PRICE_CHANGE_AGE },
          },
        },
        {
          id: 2,
          type: 'question-radiogroup_with_information',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.TITLE'),
          content: {
            options: [
              {
                id: '782d6f70-6bba-4574-84e7-1465d7821f52',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.LABEL'),
                value: '60 meses',
                information: [
                  {
                    id: 'd5c814f3-b880-4b43-bb4c-60e38c0a8e06',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '500d5886-00f8-4fd1-9421-58a116f4cceb',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '3.000,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '27d4fd0a-fc9e-4a0a-b23c-b31a19d94816',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '1f4a046b-e6ec-48c3-87d5-afb4a7e094b5',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '6.000,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '89766432-c7a1-435b-a1f4-55f56bb5f944',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '18c71a11-3e9d-4b32-bcf2-e10c6d92fd42',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '19.980,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'c080cdbb-ec37-43a1-9160-c0cbb8e0c77a',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: 'af9f409d-a85f-454c-a122-0c90fcfea625',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '60.000,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: 'b2fdf9de-5a47-4ff7-9d35-97016b3e9399',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.LABEL'),
                value: '48 meses',
                information: [
                  {
                    id: '2e3a436d-05fb-4cd6-9704-57d576afee17',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '78cb9919-3a40-4ce5-8086-844665954c92',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '2.242,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '0e9a378a-97e7-4d2a-9c84-980c541a426a',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '8abb484b-1adf-4b70-a3b8-d06a8e59a2f2',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '4.484,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '15b9d529-f048-48a9-a943-c24bf205bb79',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '744d2b70-c360-407b-b89a-046d35d6dc2d',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '14.931,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '28fce1e6-cfea-480e-b1fe-5c3572e88e09',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: 'a1827d8d-a03d-457d-8a65-5d9885043672',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '44.837,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: '06d153e4-4dbc-44e5-824a-2566e0e89428',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.LABEL'),
                value: '36 meses',
                information: [
                  {
                    id: 'c7fbd849-dd5a-487b-98ca-681c36f17fe3',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'b40595a9-702c-4f1e-ba2e-093fdd07614f',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '1.451,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '6b043916-e110-4a17-9f79-79681ca91208',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '0cc6798c-020b-457d-a2b2-1d0979514c67',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '2.903,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '69364693-2de0-4b99-968f-0cd233470899',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'b4c93a28-db29-49e0-a76c-6bdd6682ca64',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '9.666,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'd2dbb59f-3720-4883-bf4c-667b711e1833',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: '437afdd3-a81f-4ffd-8651-bc8328b3ea55',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '29.027,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: '34663fbf-bf45-4967-a07b-f385ff54f992',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.LABEL'),
                value: '24 meses',
                information: [
                  {
                    id: '8e3ae8c2-3ba4-4a8c-9f49-05269be53f94',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'f6456ab6-7d5b-422a-904f-2fa56f3a4d4f',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '770,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'ace3a920-1772-4a18-aa0a-659ff7faa735',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '143f6625-dfe5-4650-8670-cc3ff8a8690f',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '1.539,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '6884b767-02d8-4a8e-907e-13cd55994dd1',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'efcb57a7-2244-4c79-ad10-f7da02f6eaa5',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '5.126,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'dc3d79d9-b5d1-4bcc-a3bf-2b96635cf9d4',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: '820afedf-b8f0-4c3b-aac5-e2a2bccc3225',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '15.394,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: '3e352230-d1c1-4bb6-9367-53f5f2e29a64',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.LABEL'),
                value: '12 meses',
                information: [
                  {
                    id: 'e9a4d23f-2be2-41da-9fcc-438f64df9e56',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'f1b2f1f2-005e-4cc3-97b1-43d27fd53d77',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '305,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'c90fa58d-e9a9-4d1e-abda-9761a248417e',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: 'f7e8d520-0e8d-400a-b5bc-08f9166fbefd',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '610,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '98784f9c-cf88-4258-ae5d-9f946dce761d',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '1ee7bb45-b23f-47c1-abc9-59bd29f741fb',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '2.031,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '605ff184-dab5-4d6a-b005-fa0601187028',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: '88d7a66a-7985-41cc-9142-75131f5f28cc',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.2.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '6.098,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 0,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_REDEMPTION_TIME_OPTIONS, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 3,
          type: 'question-radiogroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.3.TITLE'),
          content: {
            options: [
              {
                id: '335dfbb1-d854-406e-bda2-b53109003139',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.3.CONTENT.OPTIONS.0.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.3.CONTENT.OPTIONS.0.DESCRIPTION',
                ),
                value: 'capitalização',
              },
              {
                id: '59872e00-ca55-4f9c-b32d-e9538c519421',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.3.CONTENT.OPTIONS.1.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.3.CONTENT.OPTIONS.1.DESCRIPTION',
                ),
                value: 'fundo investimento',
              },
              {
                id: '4203d9da-fa20-4348-be92-a4a75e73835a',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.3.CONTENT.OPTIONS.2.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.3.CONTENT.OPTIONS.2.DESCRIPTION',
                ),
                value: 'IPCA',
              },
              {
                id: 'eb8183d4-fbc6-4692-984a-a6125598a1a3',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.3.CONTENT.OPTIONS.3.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.3.CONTENT.OPTIONS.3.DESCRIPTION',
                ),
                value: 'outro',
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_REDEMPTION_TYPE_OPTIONS, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 4,
          type: 'question-contact',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.4.TITLE'),
          subtitle: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.4.SUBTITLE'),
          description: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.AWARDABLE.PAGES.4.DESCRIPTION'),
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_CLIENT_INFORMATION },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
      ],
    },
    {
      id: 1,
      title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.TITLE'),
      pages: [
        {
          id: 0,
          type: 'question-radiogroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.0.TITLE'),
          content: {
            displayMode: 'vertical',
            options: [
              {
                id: '9843d39c-b6a0-40e1-aa26-40d41e0e3961',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.0.CONTENT.OPTIONS.0'),
                value: 'Porque Capitalização me dá a chance de ganhar dinheiro semanalmente, e ainda fico protegido.',
              },
              {
                id: 'e18faef4-1237-4a39-b18d-582dd763dfc0',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.0.CONTENT.OPTIONS.1'),
                value: 'Porque é uma forma simples de guardar dinheiro durante a vigência do contrato do Seguro',
              },
              {
                id: '7be825fc-3f50-4cc1-859b-7f3732157e1c',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.0.CONTENT.OPTIONS.2'),
                value: 'Porque Capitalização sempre é indicada pelo gerente do meu banco.',
              },
              {
                id: '7da25248-433a-44ab-bbd7-a918f4025a9e',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.0.CONTENT.OPTIONS.3'),
                value: 'Outro motivo que não está na lista.',
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_WHY_CAPITALIZATION, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 1,
          type: 'question-radiogroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.1.TITLE'),
          description: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.1.DESCRIPTION'),
          content: {
            options: [
              {
                id: '5b7c7081-8133-4fe5-974b-e6fe52a05e00',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.1.CONTENT.OPTIONS.0'),
                value: '18 a 35 anos',
              },
              {
                id: '08b6d9b4-ed3d-4b4a-ae29-c6afb75b75a8',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.1.CONTENT.OPTIONS.1'),
                value: '36 a 45 anos',
              },
              {
                id: '6191e8b6-1114-40f5-8212-e047e564e2df',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.1.CONTENT.OPTIONS.2'),
                value: '46 a 55 anos',
              },
              {
                id: '8682982e-319c-48e0-b3f1-088664751d85',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.1.CONTENT.OPTIONS.3'),
                value: '56 a 65 anos',
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_SELECT_AGE, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 2,
          type: 'information-infocardgroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.TITLE'),
          backButton: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.BACK_BUTTON'),
          content: {
            items: [
              {
                id: 'b6b121e6-e19c-427d-8ab4-7e2923b469cc',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '18 a 35 anos',
                },
              },
              {
                id: 'b7e99ed5-b6f3-40d4-be0e-abdbcac9edb3',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '300.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '18 a 35 anos',
                },
              },
              {
                id: 'ba04ff87-84ea-407e-9c8b-73246e19c9a9',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '50,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '18 a 35 anos',
                },
              },
              {
                id: 'c834906c-210b-4509-81dc-093517372215',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '36 a 45 anos',
                },
              },
              {
                id: '65b07493-01bf-492a-9f14-9fd05ce6f445',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '600.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '36 a 45 anos',
                },
              },
              {
                id: 'a18e732b-800b-48dd-bdd9-92d559323a99',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '100,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '36 a 45 anos',
                },
              },
              {
                id: 'd17930ea-5c8d-4171-b75a-b4617bcb6572',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '46 a 55 anos',
                },
              },
              {
                id: '257fc1b9-29ff-4af3-9030-80cf04c4a1f7',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '2.000.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '46 a 55 anos',
                },
              },
              {
                id: 'c089cb0a-c1c3-4974-93b3-8a7c70a3bd9e',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '333,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '46 a 55 anos',
                },
              },
              {
                id: 'c465d9a3-3931-4107-b0db-cc7d7fc5ffa4',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '56 a 65 anos',
                },
              },
              {
                id: 'da6fb380-6172-48b0-b5a1-7b0c6c816fb8',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '6.000.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '56 a 65 anos',
                },
              },
              {
                id: '0213a40b-0343-4c75-aa08-97f3dd6d211d',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.2.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '1000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 1,
                  answerValue: '56 a 65 anos',
                },
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_PRICE_CONTINUE },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_PRICE_CHANGE_AGE },
          },
        },
        {
          id: 3,
          type: 'question-radiogroup_with_information',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.TITLE'),
          content: {
            options: [
              {
                id: '782d6f70-6bba-4574-84e7-1465d7821f52',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.LABEL'),
                value: '60 meses',
                information: [
                  {
                    id: 'd5c814f3-b880-4b43-bb4c-60e38c0a8e06',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '500d5886-00f8-4fd1-9421-58a116f4cceb',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '3.000,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '27d4fd0a-fc9e-4a0a-b23c-b31a19d94816',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '1f4a046b-e6ec-48c3-87d5-afb4a7e094b5',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '6.000,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '89766432-c7a1-435b-a1f4-55f56bb5f944',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '18c71a11-3e9d-4b32-bcf2-e10c6d92fd42',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '19.980,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'c080cdbb-ec37-43a1-9160-c0cbb8e0c77a',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: 'af9f409d-a85f-454c-a122-0c90fcfea625',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '60.000,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: 'b2fdf9de-5a47-4ff7-9d35-97016b3e9399',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.LABEL'),
                value: '48 meses',
                information: [
                  {
                    id: '2e3a436d-05fb-4cd6-9704-57d576afee17',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '78cb9919-3a40-4ce5-8086-844665954c92',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '2.242,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '0e9a378a-97e7-4d2a-9c84-980c541a426a',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '8abb484b-1adf-4b70-a3b8-d06a8e59a2f2',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '4.484,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '15b9d529-f048-48a9-a943-c24bf205bb79',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '744d2b70-c360-407b-b89a-046d35d6dc2d',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '14.931,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '28fce1e6-cfea-480e-b1fe-5c3572e88e09',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: 'a1827d8d-a03d-457d-8a65-5d9885043672',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '44.837,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: '06d153e4-4dbc-44e5-824a-2566e0e89428',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.LABEL'),
                value: '36 meses',
                information: [
                  {
                    id: 'c7fbd849-dd5a-487b-98ca-681c36f17fe3',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'b40595a9-702c-4f1e-ba2e-093fdd07614f',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '1.451,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '6b043916-e110-4a17-9f79-79681ca91208',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '0cc6798c-020b-457d-a2b2-1d0979514c67',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '2.903,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '69364693-2de0-4b99-968f-0cd233470899',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'b4c93a28-db29-49e0-a76c-6bdd6682ca64',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '9.666,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'd2dbb59f-3720-4883-bf4c-667b711e1833',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: '437afdd3-a81f-4ffd-8651-bc8328b3ea55',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '29.027,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: '34663fbf-bf45-4967-a07b-f385ff54f992',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.LABEL'),
                value: '24 meses',
                information: [
                  {
                    id: '8e3ae8c2-3ba4-4a8c-9f49-05269be53f94',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'f6456ab6-7d5b-422a-904f-2fa56f3a4d4f',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '770,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'ace3a920-1772-4a18-aa0a-659ff7faa735',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '143f6625-dfe5-4650-8670-cc3ff8a8690f',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '1.539,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '6884b767-02d8-4a8e-907e-13cd55994dd1',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'efcb57a7-2244-4c79-ad10-f7da02f6eaa5',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '5.126,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'dc3d79d9-b5d1-4bcc-a3bf-2b96635cf9d4',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: '820afedf-b8f0-4c3b-aac5-e2a2bccc3225',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '15.394,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: '3e352230-d1c1-4bb6-9367-53f5f2e29a64',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.LABEL'),
                value: '12 meses',
                information: [
                  {
                    id: 'e9a4d23f-2be2-41da-9fcc-438f64df9e56',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'f1b2f1f2-005e-4cc3-97b1-43d27fd53d77',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '305,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'c90fa58d-e9a9-4d1e-abda-9761a248417e',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: 'f7e8d520-0e8d-400a-b5bc-08f9166fbefd',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '610,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '98784f9c-cf88-4258-ae5d-9f946dce761d',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '1ee7bb45-b23f-47c1-abc9-59bd29f741fb',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '2.031,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '605ff184-dab5-4d6a-b005-fa0601187028',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: '88d7a66a-7985-41cc-9142-75131f5f28cc',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.3.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '6.098,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 1,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_REDEMPTION_TIME_OPTIONS, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 4,
          type: 'question-radiogroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.4.TITLE'),
          content: {
            options: [
              {
                id: '84a25666-3570-45a7-b119-2ba4d616429b',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.4.CONTENT.OPTIONS.0.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.4.CONTENT.OPTIONS.0.DESCRIPTION',
                ),
                value: 'dinheiro',
              },
              {
                id: '1fce70a0-7135-4485-90e3-82ae6ab1af16',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.4.CONTENT.OPTIONS.1.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.4.CONTENT.OPTIONS.1.DESCRIPTION',
                ),
                value: 'descontos',
              },
              {
                id: 'a5aab616-87cc-47e6-84c0-467295e4e137',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.4.CONTENT.OPTIONS.2.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.4.CONTENT.OPTIONS.2.DESCRIPTION',
                ),
                value: 'prêmios',
              },
              {
                id: 'c3f7ecc2-ee43-4585-9651-daa9830b62bd',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.4.CONTENT.OPTIONS.3.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.4.CONTENT.OPTIONS.3.DESCRIPTION',
                ),
                value: 'outros',
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_CAPITALIZATION_PRIZE, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 5,
          type: 'question-contact',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.5.TITLE'),
          subtitle: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.5.SUBTITLE'),
          description: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.CAPITALIZATION.PAGES.5.DESCRIPTION'),
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_CLIENT_INFORMATION },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
      ],
    },
    {
      id: 2,
      title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.TITLE'),
      pages: [
        {
          id: 0,
          type: 'question-selectable_chip_group',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.TITLE'),
          description: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.DESCRIPTION'),
          content: {
            options: [
              {
                id: 'ad363b22-19dd-44de-af74-3f879e997ea7',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.0.LABEL'),
                value: 'diária p/ incapacidade temp',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.0.INFO'),
              },
              {
                id: '3443b732-5f91-459e-b208-c0425b24f5e6',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.1.LABEL'),
                value: 'Indenização especial p/ morte acidental',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.1.INFO'),
              },
              {
                id: 'f0d60bd6-1a6b-43c9-a322-8bf8b093d61e',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.2.LABEL'),
                value: 'cônjuge e filhos',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.2.INFO'),
              },
              {
                id: '662adb31-0ddc-42f1-8648-0276ed53d9f0',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.3.LABEL'),
                value: 'dias de internação',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.3.INFO'),
              },
              {
                id: 'a2e739ec-e093-4e46-98db-a2a32341eaff',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.4.LABEL'),
                value: 'check up médico',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.4.INFO'),
              },
              {
                id: 'f98c0508-c579-4ca0-8fb0-c4560da9d670',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.5.LABEL'),
                value: 'invalidez p/ acidente',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.5.INFO'),
              },
              {
                id: 'a69fee18-4036-4c8e-b680-f7d85c3b0e8d',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.6.LABEL'),
                value: 'invalidez p/ doença',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.6.INFO'),
              },
              {
                id: '2435229b-8bf3-4d89-abad-79ed9d0e3e9a',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.7.LABEL'),
                value: 'viagem',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.7.INFO'),
              },
              {
                id: '1840072e-1f99-4d81-ba07-6b89139afc22',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.8.LABEL'),
                value: 'sorteios',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.8.INFO'),
              },
              {
                id: '8fb79bb6-408b-401a-a13c-105e3e444f9d',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.9.LABEL'),
                value: 'desc. farmácia',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.9.INFO'),
              },
              {
                id: 'a8f9b0e0-c7b7-4a4e-bed5-befe73440c32',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.10.LABEL'),
                value: '2ª opção médica',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.10.INFO'),
              },
              {
                id: '308bbf1d-3791-4400-bf41-70cc8146a072',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.11.LABEL'),
                value: 'doenças graves',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.11.INFO'),
              },
              {
                id: 'd21c4d33-2d26-4ea0-a046-19c32188aafc',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.12.LABEL'),
                value: 'orientação nutricional',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.12.INFO'),
              },
              {
                id: 'e852fc76-80a2-49ab-818b-0fde89b6199e',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.13.LABEL'),
                value: 'assist. funeral',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.13.INFO'),
              },
              {
                id: 'c1b8ec4b-3bbd-45af-9273-7870102e03bf',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.14.LABEL'),
                value: 'assist. pet',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.14.INFO'),
              },
              {
                id: 'c6d9a179-3917-45f6-9a04-61da44dd7043',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.15.LABEL'),
                value: 'assist. veicular',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.15.INFO'),
              },
              {
                id: 'f9203d4f-051e-4627-9129-0deb5767ed4c',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.16.LABEL'),
                value: 'assist. bike',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.16.INFO'),
              },
              {
                id: '4915f355-43d3-4632-b3dc-a2527f9e3c11',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.17.LABEL'),
                value: 'assist. domiciliar',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.17.INFO'),
              },
              {
                id: 'c6d5663b-06a9-4a2c-902b-3e05fbf7801c',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.18.LABEL'),
                value: 'Spotify',
                info: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.0.CONTENT.OPTIONS.18.INFO'),
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_MODULAR_COVERAGE_CHOICE, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
            clickChipInfo: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_MODULAR_COVERAGE_DETAILS, value: '$Value$' },
          },
        },
        {
          id: 1,
          type: 'question-radiogroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.1.TITLE'),
          content: {
            options: [
              {
                id: '9832ba67-a6c8-41fc-b4de-7dc68bcfdd86',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.1.CONTENT.OPTIONS.0.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.1.CONTENT.OPTIONS.0.DESCRIPTION',
                ),
                value: 'dinheiro',
              },
              {
                id: 'aaf2badd-8ec9-48a2-b506-dce2b9de7f25',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.1.CONTENT.OPTIONS.1.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.1.CONTENT.OPTIONS.1.DESCRIPTION',
                ),
                value: 'descontos',
              },
              {
                id: '3700ee39-8099-4c39-865e-9209f57c44cb',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.1.CONTENT.OPTIONS.2.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.1.CONTENT.OPTIONS.2.DESCRIPTION',
                ),
                value: 'prêmios',
              },
              {
                id: 'b581a275-9fc4-4f82-b041-3ca1fbeb39fd',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.1.CONTENT.OPTIONS.3.LABEL'),
                description: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.1.CONTENT.OPTIONS.3.DESCRIPTION',
                ),
                value: 'Outro',
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_CAPITALIZATION_PRIZE, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 2,
          type: 'question-radiogroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.2.TITLE'),
          description: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.2.DESCRIPTION'),
          content: {
            options: [
              {
                id: '58a97aef-f283-47c6-aba9-b2947808916c',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.2.CONTENT.OPTIONS.0'),
                value: '18 a 35 anos',
              },
              {
                id: '2ab73bee-8d4b-45a5-9c1c-2b139599694e',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.2.CONTENT.OPTIONS.1'),
                value: '36 a 45 anos',
              },
              {
                id: 'ec64db13-39de-4748-be39-0c6e47b09ea6',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.2.CONTENT.OPTIONS.2'),
                value: '46 a 55 anos',
              },
              {
                id: '22dc10ce-18f8-41a8-8052-5b6527a8d429',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.2.CONTENT.OPTIONS.2'),
                value: '56 a 65 anos',
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_SELECT_AGE, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 3,
          type: 'information-infocardgroup',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.TITLE'),
          backButton: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.BACK_BUTTON'),
          content: {
            items: [
              {
                id: 'b5dc8194-3709-4dc3-9d03-c3fb548d6c49',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '18 a 35 anos',
                },
              },
              {
                id: '2b324f39-0f80-4465-be0e-8146c5fb70f5',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '300.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '18 a 35 anos',
                },
              },
              {
                id: '22ab0ed3-d111-4e48-b38d-9c264cbd1d46',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '50,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '18 a 35 anos',
                },
              },
              {
                id: '77a0e7ae-ec06-441b-a1c8-06ff1777acf8',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '36 a 45 anos',
                },
              },
              {
                id: '8b14a2fd-9a57-4cf0-b31e-2746dc136eb3',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '600.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '36 a 45 anos',
                },
              },
              {
                id: 'c3d542f7-b250-4fbd-a65f-1e5182247269',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '100,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '36 a 45 anos',
                },
              },
              {
                id: 'aba1bd73-3bae-4c91-b808-bc8bd207a27c',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '46 a 55 anos',
                },
              },
              {
                id: '46c2b1b1-e45d-457a-a885-40ec7cfedeac',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '2.000.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '46 a 55 anos',
                },
              },
              {
                id: '5ca8bf0d-60be-4cf4-9893-d81720bbff81',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '333,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '46 a 55 anos',
                },
              },
              {
                id: '695cd8c3-95f6-4e96-af5f-2ec4a2ebc570',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.0.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.0.INFORMATION',
                  { insuranceValue: '50.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '56 a 65 anos',
                },
              },
              {
                id: 'd26d8006-19bd-4c38-8e97-c7a0334878d2',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.1.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.1.INFORMATION',
                  { drawValue: '6.000.000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '56 a 65 anos',
                },
              },
              {
                id: '01539a18-6f02-4c3f-a78b-f2b9aefe44db',
                title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.2.TITLE'),
                information: i18next.t(
                  'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.3.CONTENT.ITEMS.2.INFORMATION',
                  { monthlyInvestment: '1000,00' },
                ),
                derivesFromAnswer: {
                  pageId: 2,
                  answerValue: '56 a 65 anos',
                },
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_PRICE_CONTINUE },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_PRICE_CHANGE_AGE },
          },
        },
        {
          id: 4,
          type: 'question-radiogroup_with_information',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.TITLE'),
          content: {
            options: [
              {
                id: '782d6f70-6bba-4574-84e7-1465d7821f52',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.LABEL'),
                value: '60 meses',
                information: [
                  {
                    id: 'd5c814f3-b880-4b43-bb4c-60e38c0a8e06',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '500d5886-00f8-4fd1-9421-58a116f4cceb',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '3.000,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '27d4fd0a-fc9e-4a0a-b23c-b31a19d94816',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '1f4a046b-e6ec-48c3-87d5-afb4a7e094b5',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '6.000,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '89766432-c7a1-435b-a1f4-55f56bb5f944',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '18c71a11-3e9d-4b32-bcf2-e10c6d92fd42',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '19.980,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'c080cdbb-ec37-43a1-9160-c0cbb8e0c77a',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '100' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: 'af9f409d-a85f-454c-a122-0c90fcfea625',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.0.INFORMATION.1.INFORMATION',
                      { rescueValue: '60.000,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: 'b2fdf9de-5a47-4ff7-9d35-97016b3e9399',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.LABEL'),
                value: '48 meses',
                information: [
                  {
                    id: '2e3a436d-05fb-4cd6-9704-57d576afee17',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '78cb9919-3a40-4ce5-8086-844665954c92',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '2.242,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '0e9a378a-97e7-4d2a-9c84-980c541a426a',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '8abb484b-1adf-4b70-a3b8-d06a8e59a2f2',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '4.484,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '15b9d529-f048-48a9-a943-c24bf205bb79',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '744d2b70-c360-407b-b89a-046d35d6dc2d',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '14.931,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '28fce1e6-cfea-480e-b1fe-5c3572e88e09',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '93,41' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: 'a1827d8d-a03d-457d-8a65-5d9885043672',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.1.INFORMATION.1.INFORMATION',
                      { rescueValue: '44.837,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: '06d153e4-4dbc-44e5-824a-2566e0e89428',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.LABEL'),
                value: '36 meses',
                information: [
                  {
                    id: 'c7fbd849-dd5a-487b-98ca-681c36f17fe3',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'b40595a9-702c-4f1e-ba2e-093fdd07614f',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '1.451,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: '6b043916-e110-4a17-9f79-79681ca91208',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '0cc6798c-020b-457d-a2b2-1d0979514c67',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '2.903,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '69364693-2de0-4b99-968f-0cd233470899',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'b4c93a28-db29-49e0-a76c-6bdd6682ca64',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '9.666,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'd2dbb59f-3720-4883-bf4c-667b711e1833',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '80,63' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: '437afdd3-a81f-4ffd-8651-bc8328b3ea55',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.2.INFORMATION.1.INFORMATION',
                      { rescueValue: '29.027,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: '34663fbf-bf45-4967-a07b-f385ff54f992',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.LABEL'),
                value: '24 meses',
                information: [
                  {
                    id: '8e3ae8c2-3ba4-4a8c-9f49-05269be53f94',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'f6456ab6-7d5b-422a-904f-2fa56f3a4d4f',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '770,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'ace3a920-1772-4a18-aa0a-659ff7faa735',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '143f6625-dfe5-4650-8670-cc3ff8a8690f',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '1.539,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '6884b767-02d8-4a8e-907e-13cd55994dd1',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'efcb57a7-2244-4c79-ad10-f7da02f6eaa5',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '5.126,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: 'dc3d79d9-b5d1-4bcc-a3bf-2b96635cf9d4',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '64,14' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: '820afedf-b8f0-4c3b-aac5-e2a2bccc3225',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.3.INFORMATION.1.INFORMATION',
                      { rescueValue: '15.394,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
              {
                id: '3e352230-d1c1-4bb6-9367-53f5f2e29a64',
                label: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.LABEL'),
                value: '12 meses',
                information: [
                  {
                    id: 'e9a4d23f-2be2-41da-9fcc-438f64df9e56',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'f1b2f1f2-005e-4cc3-97b1-43d27fd53d77',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '305,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '18 a 35 anos',
                    },
                  },
                  {
                    id: 'c90fa58d-e9a9-4d1e-abda-9761a248417e',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: 'f7e8d520-0e8d-400a-b5bc-08f9166fbefd',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '610,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '36 a 45 anos',
                    },
                  },
                  {
                    id: '98784f9c-cf88-4258-ae5d-9f946dce761d',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '1ee7bb45-b23f-47c1-abc9-59bd29f741fb',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '2.031,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '46 a 55 anos',
                    },
                  },
                  {
                    id: '605ff184-dab5-4d6a-b005-fa0601187028',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.0.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.0.INFORMATION',
                      { rescuePercentage: '50,82' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                  {
                    id: '88d7a66a-7985-41cc-9142-75131f5f28cc',
                    title: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.1.TITLE',
                    ),
                    information: i18next.t(
                      'LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.4.CONTENT.OPTIONS.4.INFORMATION.1.INFORMATION',
                      { rescueValue: '6.098,00' },
                    ),
                    derivesFromAnswer: {
                      pageId: 2,
                      answerValue: '56 a 65 anos',
                    },
                  },
                ],
              },
            ],
          },
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_REDEMPTION_TIME_OPTIONS, value: '$Value$' },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
        {
          id: 5,
          type: 'question-contact',
          title: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.5.TITLE'),
          subtitle: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.5.SUBTITLE'),
          description: i18next.t('LIFE_INSURANCE_RESEARCH.RESEARCHS.COVERAGES.PAGES.5.DESCRIPTION'),
          analytics: {
            continue: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_CLIENT_INFORMATION },
            goBack: { event: ANALYTICS_FAKEDOOR_VIDA_FLOW.VIDA_VOLTAR },
          },
        },
      ],
    },
  ],
};
