import React from 'react';
import { Grid } from '@material-ui/core';
import FocusTrap from 'focus-trap-react';
import GetMenuItems from '../../../menuItems';
import OptionDropdown from '../OptionDropdown';
import logo from '../../../../../assets/images/logo-seguros-itau.png';
import { useStyles } from './MobileHeaderModalContent.styles';
import { useRemoteConfigConsumer } from 'providers/RemoteConfigProvider';

const MobileHeaderModalContent = ({ closeButtonAction, allowedChannels }) => {
  const styles = useStyles();
  const dropdownItems = GetMenuItems(allowedChannels);
  const remoteConfig = useRemoteConfigConsumer();

  const dropDownContent = () =>
    dropdownItems?.map((item, index) => (
      <OptionDropdown
        testId={`dropdownContent-${index + 1}`}
        key={index}
        content={item}
        closeMenu={closeButtonAction}
      />
    ));

  return (
    <div className={styles.root}>
      <FocusTrap active>
        <div>
          <div className={styles.rowContainer}>
            <button type="button" aria-label="Menu da loja de seguros" aria-live="polite" onClick={closeButtonAction}>
              <i className={`${'icon-itaufonts_fechar'} ${styles.hamburgerIcon}`} />
            </button>
            <img className={styles.image} alt="Logo Itau" src={logo} />
          </div>
          <Grid data-testid="MobileHeader-content" container className={styles.dropdownMenu} direction="column">
            <ul className={styles.menuList}>
              {remoteConfig?.ENABLE_AUTH && <access-button mobile listItem />}
              {dropDownContent()}
            </ul>
          </Grid>
        </div>
      </FocusTrap>
    </div>
  );
};

export default MobileHeaderModalContent;
