/* eslint-disable react/prop-types */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Select from '../../../components/Select/Select';
import NewSelect from '../../../components/NewSelect/Select';
import FormErrors from '../formErrors';
import { callFunction, isMobileDevice } from '../../../utils/util';

class SelectFormItemNotFast extends Component {
  value = () => {
    const { mode } = this.props;
    if (mode === 'multiple') {
      return this.valueMultiple();
    }
    return this.valueOne();
  };

  valueOne = () => {
    const { form, name, options } = this.props;
    if (typeof form.values[name] === 'object' && 'codigo' in form.values[name]) {
      const formValue = form.values[name].codigo;
      return options.find(option => option.value === formValue).value;
    }

    if (form.values[name]) {
      return options.find(option => option.value === form.values[name])?.value;
    }

    return '';
  };

  selectedValueLabel = () => {
    const { form, name, options } = this.props;

    if (typeof form.values[name] === 'object' && 'codigo' in form.values[name]) {
      const formValue = form.values[name].codigo;
      const selectedOption = options.find(option => option.value === formValue);
      const _label = selectedOption.ariaLabel || selectedOption.label;
      return _label;
    }

    if (form.values[name]) {
      const selectedOption = options.find(option => option.value === form.values[name]);
      const _label = selectedOption?.ariaLabel || selectedOption?.label;
      return _label;
    }

    return '';
  };

  handleSelectOne = (data, onChange) => {
    const { form, name } = this.props;

    if (!data) {
      form.setFieldValue(name, undefined);
      return;
    }
    let tempData;
    if (data.target) {
      tempData = data.target.value;
    } else {
      tempData = data;
    }

    form.setFieldValue(name, tempData);
    if (onChange) {
      onChange(data, name);
    }
  };

  ariaLabel = () => {
    const { ariaLabel, label } = this.props;
    const _ariaLabel = ariaLabel || label;
    const selectedValue = this.selectedValueLabel() ? `. Opção selecionada: ${this.selectedValueLabel()}` : '';

    return `${_ariaLabel}${selectedValue}`;
  };

  render() {
    const {
      label,
      name,
      form,
      hint,
      options,
      errorMessage,
      required,
      placeholder,
      onChange,
      onBlur,
      field,
      defaultSelect,
      className,
      labelLayout,
      id,
      disabled,
      product,
      ..._props
    } = this.props;

    if (product === 'odonto') {
      return (
        <NewSelect
          onBlur={event => {
            if (disabled) return;

            if (onBlur) callFunction(onBlur, event);
            if (field.onBlur) callFunction(field.onBlur, event);
          }}
          label={label}
          name={name}
          value={this.value()}
          handleChange={data => this.handleSelectOne(data, onChange)}
          ariaLabel={this.ariaLabel()}
          error={!!FormErrors.displayableError(form, name, errorMessage)}
          helperText={FormErrors.displayableError(form, name, errorMessage) || hint}
          placeholder={placeholder}
          options={options}
          required={required}
          enableMobileOptions={defaultSelect && !disabled ? isMobileDevice() : false}
          mobileOptionsTitle={label}
          className={className}
          labelLayout={labelLayout}
          id={id}
          disabled={disabled}
          {..._props}
        />
      );
    }

    return (
      <Select
        onBlur={event => {
          if (disabled) return;

          if (onBlur) callFunction(onBlur, event);
          if (field.onBlur) callFunction(field.onBlur, event);
        }}
        label={label}
        name={name}
        value={this.value()}
        handleChange={data => this.handleSelectOne(data, onChange)}
        ariaLabel={this.ariaLabel()}
        error={!!FormErrors.displayableError(form, name, errorMessage)}
        helperText={FormErrors.displayableError(form, name, errorMessage) || hint}
        placeholder={placeholder}
        options={options}
        required={required}
        enableMobileOptions={defaultSelect && !disabled ? isMobileDevice() : false}
        mobileOptionsTitle={label}
        className={className}
        labelLayout={labelLayout}
        id={id}
        disabled={disabled}
        {..._props}
      />
    );
  }
}

SelectFormItemNotFast.defaultProps = {
  required: false,
};

SelectFormItemNotFast.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

function SelectFormItem(props) {
  const { name } = props;
  return (
    <Field name={name}>{({ form, field }) => <SelectFormItemNotFast {...props} field={field} form={form} />}</Field>
  );
}

export default SelectFormItem;
