import { getSessionStorage } from './util';
import config from '../config';
import { ALL, PRODUCT_AUTO, CHANNELS } from '../shared/channel';

const { DISTRIBUTION_KEY, DEFAULT_ID_PATH_VALUE } = config;

export const getChannel = (from = ALL) => {
  let channelToReturn = DEFAULT_ID_PATH_VALUE;
  const isAuto = from.match(PRODUCT_AUTO);

  const source = getSessionStorage(DISTRIBUTION_KEY) || DEFAULT_ID_PATH_VALUE;
  const originMapped = CHANNELS.filter(channel => channel.name === source);
  if (originMapped[0]) {
    if (
      isAuto &&
      !CHANNELS.filter(channel => channel.name === source && channel.lead.includes(PRODUCT_AUTO))[0]?.name
    ) {
      return channelToReturn;
    }

    channelToReturn = source;
    return channelToReturn;
  }

  return DEFAULT_ID_PATH_VALUE;
};

export const getPureChannel = () => getSessionStorage(DISTRIBUTION_KEY) || DEFAULT_ID_PATH_VALUE;

export const getChannelIfIsBlokedForLead = () => {
  const source = getSessionStorage(DISTRIBUTION_KEY) || DEFAULT_ID_PATH_VALUE;
  const originMapped = CHANNELS.filter(channel => channel.name === source);
  if (originMapped[0] && originMapped[0].blockChannelLead) {
    return DEFAULT_ID_PATH_VALUE;
  }
};
