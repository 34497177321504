import { paths, getStoredMockModules } from 'mocks/config.mock';

/** Put here all mock modules description according to each path name defined at config.mock.js */
const DESCRIPTIONS = {
  odonto: 'API de serviço para o fluxo de contratação de **Odonto**.',
  resid: 'API de serviço para o fluxo de contratação de **Resid**.',
  auto: 'API de serviço para o fluxo de contratação de **Auto**.',
  realiza: 'API de serviço para o fluxo de contratação de produtos Realiza: **Pet e Bem-estar**.',
  cancellation: 'API de serviço para o fluxo de **cancelamento** de serviços.',
  shared:
    'APIs compartilhadas para vários serviços, como: **autenticação, ofertas (Odonto, Auto e Resid), busca CEP, elegibilidade, FAQ, estados e cidades.**',
};

const itemsList = paths.map(path => ({ name: path.name, description: DESCRIPTIONS[path.name] }));

const storedMockModules = getStoredMockModules(process.env.REACT_APP_ENVIRONMENT);

export default itemsList?.map(module => ({ ...module, enabled: storedMockModules?.includes(module.name) }));
