const ROUTES = {
  HOME: '/',
  LANDING_ODONTO: '/',
  LANDING_RESID: '/',
  LANDING_AUTO: '/v2/seguro-automovel',
  LANDING_AUTO_V1: '/seguro-auto',
  LANDING_WELFARE: '/saude-bem-estar',
  LANDING_CARGLASS: '/carglass',
  LANDING_BLLU: '/bllu',
  LANDING_CONECTCAR: '/conectcar',
  LANDING_CONSORCIO: '/consorcio',
  WELFARE_HIRING_PAGE: '/saude-bem-estar/contratacao',
  WELFARE_SUCCESS_PAGE: '/saude-bem-estar/contratacao/comprovante',
  LANDING_PET: '/pet-saude',
  PET_HIRING_PAGE: '/pet-saude/contratacao',
  PET_SUCCESS_PAGE: '/pet-saude/contratacao/comprovante',
  FAQ_PAGE: '/ajuda',
  ERROR_PAGE: '/error',
  ICONS: '/icons',
  ODONTO_HIRING_PAGE: '/',
  ODONTO_HIRING_SUCCESS_PAGE: '/',
  AUTH: '/autenticacao',
  REDIRECT_TO_CONTINUE_ASYNC_QUOTATION: '/prosseguir_contratacao',
  ASYNC_QUOTATION_ERROR: '/error-quotacao-assincrona',
  RESID_HIRING_PAGE: '/',
  RESID_HIRING_SUCCESS_PAGE: '/',
  CANCEL: '/cancelamento',
  CANCEL_DETAIL: '/cancelamento/proposta/:proposalId/',
  CANCEL_RECEIPT: '/cancelamento/comprovante',
  CANCEL_TOKEN_CONFIRMATION: '/cancelamento/confirmar-token',
  DOWNLOAD_RECEIPT: '/comprovante:hash',
  CREDICARDRESID: '/credicardresidencial',
  ITAUCARDRESID: '/itaucardresidencial',
  ADVISORY_RESEARCH: '/assessoria',
  REALIZA_PET_TERMS: '/pet-saude/manual',
  REALIZA_WELFARE_TERMS: '/saude-bem-estar/manual',
  HIRING_PAGE: '/seguro-auto/contratacao',
  INSPECTION_PAGE: '/seguro-auto/contratacao/vistoria',
  SUCCESS_LEAD_SIMULATION: '/seguro-auto/cotacao/sucesso',
  AUTO_SUCCESS_PAGE: '/seguro-auto/contratacao/comprovante',
  INSPECTION_APP: '/seguro-auto/contratacao/vistoria/aplicativo',
  INSPECTION_DISPENSED: '/seguro-auto/contratacao/vistoria/dispensado',
  INSPECTION_SCHEDULED: '/seguro-auto/contratacao/vistoria/agendado',
  INSPECTION_SUCCESS: '/seguro-auto/contratacao/vistoria/sucesso',
  NOT_FOUND_CAR_MODEL: '/seguro-auto/contratacao/erro',
  REDIRECT_TO_APP_OR_WEBSITE: '/redirecionamento',
  AUTO: '/v2/seguro-automovel',
  AUTO_V2_HOME: '/v2/seguro-automovel',
  AUTO_V2_HIRING: '/v2/seguro-automovel/contratacao',
  AUTO_V2_SUCCESS: '/v2/seguro-automovel/sucesso',
  FAQ: '/v2/ajuda/:id',
  AUTH_V2: '/v2/autenticacao',
  MY_ACCOUNT: '/v2/minha-conta',
  HOSPITAU: '/v2/detalhes/produto/hospitau',
  HOSPITAU_PAGE: '/v2/detalhes/produto/hospitau/:id',
};

export default ROUTES;
