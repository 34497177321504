export const Types = {
  SET_RESIDENTIAL: 'residencial/SET_RESIDENTIAL',
  FINISH_HIRING: 'residencial/FINISH_RESIDENCIAL',
  ADD_QUESTIONS: 'residencial/ADD_QUESTIONS',
  SET_PROPONENT: 'residencial/SET_PROPONENT',
  ON_TOGGLE_COVERAGES: 'residencial/ON_TOGGLE_COVERAGES',
  TOGGLE_PRE_SELECTED_COVERAGES: 'residencial/TOGGLE_PRE_SELECTED_COVERAGES',
  SET_PRE_SELECTED_COVERAGES: 'residencial/SET_PRE_SELECTED_COVERAGES',
  RESET_PRE_SELECTED_COVERAGES: 'residencial/RESET_PRE_SELECTED_COVERAGES',
  HANDLE_SELECTED_COVERAGE: 'residencial/HANDLE_SELECTED_COVERAGE',
  SET_RESID_OFFER: 'residencial/SET_RESID_OFFER',
  SET_ADDRESS: 'residencial/SET_ADDRESS',
  SET_REAL_ESTATE_TYPE: 'residencial/SET_REAL_ESTATE_TYPE',
  SET_ADDITIONAL_INFORMATIONS: 'residencial/SET_ADDITIONAL_INFORMATIONS',
  LOADING_SIMULATION_REQUEST: 'residencial/ LOADING_SIMULATION_REQUEST',
  LOAD_SIMULATION: 'residencial/LOAD_SIMULATION',
  LOAD_SIMULATION_SUCCESS: 'residencial/LOAD_SIMULATION_SUCCESS',
  LOAD_SIMULATION_ERROR: 'residencial/LOAD_SIMULATION_ERROR',
  LOAD_ASSISTANCE_SIMULATION: 'residencial/LOAD_ASSISTANCE_SIMULATION',
  LOAD_ASSISTANCE_SIMULATION_SUCCESS: 'residencial/LOAD_ASSISTANCE_SIMULATION_SUCCESS',
  LOAD_SIMULATION_RESET: 'residencial/LOAD_SIMULATION_RESET',
  LOAD_ASSISTANCE_SIMULATION_ERROR: 'residencial/LOAD_ASSISTANCE_SIMULATION_ERROR',
  LOAD_HIRING: 'residencial/LOAD_HIRING',
  LOAD_HIRING_SUCCESS: 'residencial/LOAD_HIRING_SUCCESS',
  LOAD_HIRING_ERROR: 'residencial/LOAD_HIRING_ERROR',
  SET_COVERAGES: 'residencial/SET_COVERAGES',
  UPDATE_COVERAGE_PRICE_COVERED: 'residencial/UPDATE_COVERAGE_PRICE_COVERED',
  SET_PROPONENT_CONTRACTS: 'residencial/SET_PROPONENT_CONTRACTS',
  SET_PROPONENT_CONTACTS: 'residencial/SET_PROPONENT_CONTACTS',
  SET_SELECTED_ASSISTANCE: 'residencial/SET_SELECTED_ASSISTANCE',
  SET_PAYMENT_DATA: 'residencial/SET_PAYMENT_DATA',
  SET_OCCUPATION_TYPE: 'residencial/SET_OCCUPATION_TYPE',
  SET_HOUSE_TYPE_TEXT: 'residencial/SET_HOUSE_TYPE_TEXT',
  SET_INSTALLMENT_OPTION: 'residencial/SET_INSTALLMENT_TYPE',
  RESET_INSTALLMENT_OPTION: 'residencial/RESET_INSTALLMENT_OPTION',
  UPDATE_PRICE_QUOTATION: 'residencial/UPDATE_PRICE_QUOTATION',
  RESET_DATA: 'residencial/RESET_DATA',
  SET_INSTALLMENT_PAYMENT_TYPE: 'residencial/SET_INSTALLMENT_PAYMENT_TYPE',
  SET_FINANCIAL_RESPONSIBLE: 'residencial/SET_FINANCIAL_RESPONSIBLE',
  RELOAD_QUOTATION_PAGE: 'residencial/RELOAD_QUOTATION_PAGE',
  PROCESS_ASYNC_SIMULATION_SAGA: 'residencial/PROCESS_ASYNC_SIMULATION_SAGA',
  VALIDATE_USE_DATA_TO_ASYNC_QUOTATION_FLOW_SAGA: 'residencial/VALIDATE_USE_DATA_TO_ASYNC_QUOTATION_FLOW_SAGA',
  SET_DIVERGENT_DATA_QUOTATION_HOLDER: 'residencial/SET_DIVERGENT_DATA_QUOTATION_HOLDER',
  SET_DIVERGENT_SECOND_SIMULATION: 'residencial/SET_DIVERGENT_SECOND_SIMULATION',
  SET_ERROR_VALIDATION_AUTH: 'residencial/SET_ERROR_VALIDATION_AUTH',
  RESET_ERROR_VALIDATION_AUTH: 'residencial/RESET_ERROR_VALIDATION_AUTH',
};

const initialState = {
  simulacao: {
    recarregado: false,
    carregando: false,
    erro: false,
    requisicao: {
      item_risco: {
        imovel: {},
      },
      oferta: {},
      proponente: {
        contatos: [],
        cpf: '',
        data_nascimento: '',
        nome: '',
      },
      questoes: [],
    },
    resposta: {},
    autenticacao_validation_erro: false
  },
  contratacao: {
    requisicao: {
      dados_opcao_pagamento: {
        cartao_credito: undefined,
        conta_bancaria: undefined,
        endereco: undefined,
        id_opcao_pagamento: undefined,
      },
    },
    resposta: {},
    carregando: false,
    sucesso: false,
    erro: false,
  },
  coberturas: {
    fixas: [],
    preSelecionadas: {},
    selecionadas: [],
    naoSelecionadas: [],
    excluidas: [],
  },
  assistencias: [],
  valorCotacao: {},
  assistenciaSelecionada: {},
  opcaoParcelaSelecionada: { parcelas: 6, juros: 0 },
  responsavelFinanceiro: 'Titular',
  dadosDivergentesQuotacaoTitular: false,
  segundaSimulacaoDivergenteQuotacao: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.RESET_DATA: {
      return initialState;
    }
    case Types.SET_RESIDENTIAL: {
      const { data } = action;
      return { ...data };
    }
    case Types.FINISH_HIRING: {
      return {
        ...initialState,
        contratacao: {
          ...initialState.contratacao,
          resposta: { ...state.contratacao.resposta },
        },
      };
    }
    case Types.ADD_QUESTIONS: {
      const { data } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          requisicao: {
            ...state.simulacao.requisicao,
            questoes: data,
          },
        },
      };
    }
    case Types.TOGGLE_PRE_SELECTED_COVERAGES: {
      const { data } = action;
      return {
        ...state,
        coberturas: {
          ...state.coberturas,
          preSelecionadas: {
            ...state.coberturas.preSelecionadas,
            [data]: !state.coberturas.preSelecionadas[data],
          },
        },
      };
    }
    case Types.SET_PRE_SELECTED_COVERAGES: {
      const { data } = action;
      return {
        ...state,
        coberturas: {
          ...state.coberturas,
          preSelecionadas: {
            ...state.coberturas.preSelecionadas,
            [data]: true,
          },
        },
      };
    }
    case Types.RESET_PRE_SELECTED_COVERAGES: {
      return {
        ...state,
        coberturas: {
          ...state.coberturas,
          preSelecionadas: {},
        },
      };
    }
    case Types.SET_RESID_OFFER: {
      const { data } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          requisicao: {
            ...state.simulacao.requisicao,
            oferta: { ...data },
          },
        },
      };
    }
    case Types.HANDLE_SELECTED_COVERAGE: {
      const { selecionadas, naoSelecionadas, excluidas } = action;
      return {
        ...state,
        coberturas: {
          ...state.coberturas,
          selecionadas,
          naoSelecionadas,
          excluidas,
        },
      };
    }
    case Types.SET_PROPONENT: {
      const { proponente } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          requisicao: {
            ...state.simulacao.requisicao,
            proponente,
          },
        },
      };
    }
    case Types.SET_ADDRESS: {
      const { endereco } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          requisicao: {
            ...state.simulacao.requisicao,
            item_risco: {
              ...state.simulacao.requisicao.item_risco,
              imovel: {
                ...state.simulacao.requisicao.item_risco.imovel,
                endereco,
              },
            },
          },
        },
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            item_risco: {
              ...state.contratacao.requisicao.item_risco,
              imovel: {
                ...state.contratacao.requisicao.item_risco?.imovel,
                endereco,
              },
            },
          },
        },
      };
    }
    case Types.SET_COVERAGES: {
      const { data } = action;
      return {
        ...state,
        coberturas: { ...data },
      };
    }
    case Types.SET_SELECTED_ASSISTANCE: {
      const { data } = action;
      return {
        ...state,
        assistenciaSelecionada: data,
      };
    }
    case Types.SET_ERROR_VALIDATION_AUTH: {
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          autenticacao_validation_erro: true,
        },
      };
    }
    case Types.RESET_ERROR_VALIDATION_AUTH: {
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          autenticacao_validation_erro: false,
        },
      };
    }
    case Types.LOAD_SIMULATION: {
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          carregando: true,
          erro: false,
        },
      };
    }
    case Types.LOADING_SIMULATION_REQUEST: {
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          carregando: true,
          erro: false,
        },
      };
    }
    case Types.LOAD_SIMULATION_RESET: {
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          carregando: false,
          erro: false,
          recarregado: false,
        },
      };
    }
    case Types.LOAD_SIMULATION_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          resposta: { ...data },
          carregando: false,
          erro: false,
          recarregado: false,
        },
      };
    }
    case Types.LOAD_SIMULATION_ERROR: {
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          carregando: false,
          erro: true,
          recarregado: false,
        },
      };
    }
    case Types.LOAD_ASSISTANCE_SIMULATION: {
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          carregando: true,
          erro: false,
          recarregado: false,
        },
      };
    }
    case Types.LOAD_ASSISTANCE_SIMULATION_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          resposta: { ...data },
          carregando: false,
          erro: false,
          recarregado: false,
        },
        assistencias: data.produtos[0].assistencias,
      };
    }
    case Types.LOAD_ASSISTANCE_SIMULATION_ERROR: {
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          carregando: false,
          erro: true,
          recarregado: false,
        },
      };
    }
    case Types.LOAD_HIRING: {
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          carregando: true,
          erro: false,
        },
      };
    }
    case Types.LOAD_HIRING_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          resposta: { ...data },
          carregando: false,
          sucesso: true,
          erro: false,
        },
      };
    }
    case Types.LOAD_HIRING_ERROR: {
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          carregando: false,
          erro: true,
        },
      };
    }
    case Types.SET_PROPONENT_CONTRACTS: {
      const { data } = action;
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            proponente: {
              ...data,
            },
          },
        },
      };
    }
    case Types.SET_PROPONENT_CONTACTS: {
      const { data } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          requisicao: {
            ...state.simulacao.requisicao,
            proponente: {
              ...state.simulacao.requisicao.proponente,
              contatos: data,
            },
          },
        },
      };
    }
    case Types.SET_REAL_ESTATE_TYPE: {
      const { data } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          tipoImovel: data,
        },
      };
    }
    case Types.UPDATE_COVERAGE_PRICE_COVERED: {
      const { id, value } = action.data;
      const newCoverages = state.coberturas.selecionadas.map((c) => {
        if (c.id_cobertura === id) {
          return { ...c, importancia_segurada: value };
        }
        return c;
      });
      return {
        ...state,
        coberturas: {
          ...state.coberturas,
          selecionadas: newCoverages,
        },
      };
    }
    case Types.SET_OCCUPATION_TYPE: {
      const { data } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          tipoOcupacao: data,
        },
      };
    }
    case Types.SET_HOUSE_TYPE_TEXT: {
      const { data } = action;
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          tipoImovelTexto: data,
        },
      };
    }
    case Types.SET_PAYMENT_DATA: {
      const { data } = action;
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            dados_opcao_pagamento: {
              ...state.contratacao.requisicao.dados_opcao_pagamento,
              ...data,
              conta_bancaria: action.data.cartao_credito ? undefined : data.conta_bancaria,
              cartao_credito: action.data.conta_bancaria ? undefined : data.cartao_credito,
            },
          },
        },
      };
    }
    case Types.SET_INSTALLMENT_OPTION: {
      const { data } = action;
      return {
        ...state,
        opcaoParcelaSelecionada: data,
      };
    }
    case Types.RESET_INSTALLMENT_OPTION: {
      return {
        ...state,
        opcaoParcelaSelecionada: initialState.opcaoParcelaSelecionada,
      };
    }
    case Types.UPDATE_PRICE_QUOTATION: {
      const { data } = action;
      return {
        ...state,
        valorCotacao: {
          ...state.valorCotacao,
          ...data,
        },
      };
    }

    case Types.RELOAD_QUOTATION_PAGE: {
      return {
        ...state,
        simulacao: {
          ...state.simulacao,
          carregando: true,
          erro: false,
          recarregado: true,
        },
      };
    }

    case Types.SET_FINANCIAL_RESPONSIBLE: {
      const { data } = action;
      return {
        ...state,
        responsavelFinanceiro: data,
      };
    }

    case Types.SET_DIVERGENT_DATA_QUOTATION_HOLDER: {
      return {
        ...state,
        dadosDivergentesQuotacaoTitular: action.data,
      };


    }case Types.SET_DIVERGENT_SECOND_SIMULATION: {
      return {
        ...state,
        segundaSimulacaoDivergenteQuotacao: action.data,
      };
    }

    default: {
      return state;
    }
  }
}

export function setResidential(data) {
  return {
    type: Types.SET_RESIDENTIAL,
    data,
  };
}

export function finishHiring() {
  return {
    type: Types.FINISH_HIRING,
  };
}

export function setInstallmentOption(data) {
  return {
    type: Types.SET_INSTALLMENT_OPTION,
    data,
  };
}

export function resetInstallmentOption() {
  return {
    type: Types.RESET_INSTALLMENT_OPTION,
  };
}

export function setSelectedAssistance(data) {
  return {
    type: Types.SET_SELECTED_ASSISTANCE,
    data,
  };
}

export function togglePreSelectedCoverage(data) {
  return {
    type: Types.TOGGLE_PRE_SELECTED_COVERAGES,
    data,
  };
}

export function setPreSelectedCoverage(data) {
  return {
    type: Types.SET_PRE_SELECTED_COVERAGES,
    data,
  };
}

export function onToggleCoverages(data) {
  return { type: Types.ON_TOGGLE_COVERAGES, data };
}

export function resetPreSelectedCoverage() {
  return {
    type: Types.RESET_PRE_SELECTED_COVERAGES,
  };
}

export function handleSelectedCoverages(selecionadas, naoSelecionadas, excluidas) {
  return {
    type: Types.HANDLE_SELECTED_COVERAGE,
    selecionadas,
    naoSelecionadas,
    excluidas,
  };
}

export function addQuestions(data) {
  return {
    type: Types.ADD_QUESTIONS,
    data,
  };
}

export function setProponent(proponente) {
  return {
    type: Types.SET_PROPONENT,
    proponente,
  };
}

export function setResidOffer(data) {
  return {
    type: Types.SET_RESID_OFFER,
    data,
  };
}

export function setAddress(endereco) {
  return {
    type: Types.SET_ADDRESS,
    endereco,
  };
}

export function loadAssistanceSimulation(data) {
  return {
    type: Types.LOAD_ASSISTANCE_SIMULATION,
    data,
  };
}

export function loadHiring(data) {
  return {
    type: Types.LOAD_HIRING,
    data,
  };
}

export function loadSimulation(data) {
  return {
    type: Types.LOAD_SIMULATION,
    data,
  };
}

export function loadSimulationRequest(data) {
  return {
    type: Types.LOADING_SIMULATION_REQUEST,
    data,
  };
}

export function setAdditionalInformation(data) {
  return {
    type: Types.SET_ADDITIONAL_INFORMATIONS,
    data,
  };
}

export function setProponentContracts(data) {
  return {
    type: Types.SET_PROPONENT_CONTRACTS,
    data,
  };
}

export function updateCoveragePriceCovered(data) {
  return {
    type: Types.UPDATE_COVERAGE_PRICE_COVERED,
    data,
  };
}

export function setProponentContacts(data) {
  return {
    type: Types.SET_PROPONENT_CONTACTS,
    data,
  };
}

export function setPaymentData(data) {
  return {
    type: Types.SET_PAYMENT_DATA,
    data,
  };
}

export function setCoverages(data) {
  return {
    type: Types.SET_COVERAGES,
    data,
  };
}

export function updatePriceQuotation(data) {
  return {
    type: Types.UPDATE_PRICE_QUOTATION,
    data,
  };
}

export function resetData() {
  return {
    type: Types.RESET_DATA,
  };
}

export function reloadQuotationPage() {
  return {
    type: Types.RELOAD_QUOTATION_PAGE,
  };
}

export function setInstallmentPaymentType(data) {
  return {
    type: Types.SET_INSTALLMENT_PAYMENT_TYPE,
    data,
  };
}

export function processAsyncSimulationSaga(data) {
  return {
    type: Types.PROCESS_ASYNC_SIMULATION_SAGA,
    data,
  };
}

export function validateUserDataToAsyncQuotationFlowSaga(data) {
  return {
    type: Types.VALIDATE_USE_DATA_TO_ASYNC_QUOTATION_FLOW_SAGA,
    data,
  };
}

export function setFinancialResponsible(data) {
  return {
    type: Types.SET_FINANCIAL_RESPONSIBLE,
    data,
  };
}

export function setDivergentDataQuotationHolder(data) {
  return {
    type: Types.SET_DIVERGENT_DATA_QUOTATION_HOLDER,
    data,
  };
}
