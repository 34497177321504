export const ANALYTICS_EVENT_ACTIONS = {
  HOME: 'Home',
  ODONTO: 'Odonto',
  RESID: 'Resid',
  AUTO: 'Auto',
  ASSESSORIA: 'Assessoria',
  BEM_ESTAR: 'Bem-Estar',
  PET: 'Pet',
  RESIDENCIAL: 'Residencial',
  CANCELLATION: 'Cancelamento',
};
