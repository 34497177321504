import { useState } from 'react';
import PropTypes from 'prop-types';
import { TabsGroup, Tab } from './Tabs.styles.js';

const tabA11yProps = index => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});

function TabPanelWrapper({ children, value, label, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      data-testid={`${label}-tab-content`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index ? children : null}
    </div>
  );
}

export default function Tabs({ tabIndex, setTabIndex, tabs }) {
  const [uncontrolledTabIndex, setUncontrolledTabIndex] = useState(0);

  const properlyTabIndex = tabIndex !== undefined ? tabIndex : uncontrolledTabIndex;
  const properlySetTabIndex = setTabIndex !== undefined ? setTabIndex : setUncontrolledTabIndex;

  return (
    <>
      <TabsGroup
        value={properlyTabIndex}
        onChange={(_, newIndex) => {
          properlySetTabIndex(newIndex);
        }}
        indicatorColor="primary"
        aria-label="Escolha uma aba"
        scrollButtons="auto"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            data-testid={`${tab.label}-tab-label`}
            label={tab.label}
            aria-label={tab.ariaLabel || tab.label}
            {...tabA11yProps(index)}
            disabled={!!tab.disabled}
          />
        ))}
      </TabsGroup>
      {tabs.map((tab, index) => (
        <TabPanelWrapper key={index} index={index} value={properlyTabIndex} label={tab.label}>
          {tab.element ? tab.element : null}
        </TabPanelWrapper>
      ))}
    </>
  );
}

Tabs.propTypes = {
  tabIndex: PropTypes.number,
  setTabIndex: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      ariaLabel: PropTypes.string,
      element: PropTypes.node,
      disabled: PropTypes.bool,
    }),
  ),
};
