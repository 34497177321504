import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import { makeId } from 'utils/util';

export default function Loading({ size = 40, ariaLabel = 'Carregando', color = 'primary', page = 'default' }) {
  const onTop = useRef();
  const loadingId = `circularprogress-${makeId(8)}`;

  useEffect(() => {
    if (page !== 'default') {
      if (onTop.current) onTop.current.scrollIntoView();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      ref={onTop}
      role="alert"
      display="flex"
      justifyContent="center"
      aria-live="polite"
      aria-atomic="true"
      aria-label={ariaLabel}
      id={loadingId}
    >
      <CircularProgress color={color} size={size} aria-labelledby={loadingId} />
    </Box>
  );
}

Loading.propTypes = {
  size: PropTypes.number,
  ariaLabel: PropTypes.string,
  color: PropTypes.string,
};
