import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, constants }) => ({
  root: {
    width: '100%',
    minHeight: constants.MENU_HEIGHT,
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 999,
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 50%)',

    [breakpoints.up('lg')]: {
      boxShadow: 'none',
      position: 'static',
    },
  },
  container: {
    height: constants.MENU_HEIGHT,
    padding: `1rem ${constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN}`,

    [breakpoints.up('xl')]: {
      padding: `1rem ${constants.DEFAULT_DESKTOP_HORIZONTAL_MARGIN}`,
    },
  },
}));
