import ANALITYCS_CATEGORY from '../category.json';

export default {
  ASSESSORIA_BEGIN_FORM: {
    action: 'assessoria_begin_form',
    label: 'BTN:Assessoria:Formulario:Inicio',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_BACK: {
    action: 'assessoria_form_back',
    label: 'BTN:Assessoria:Formulario:Voltar',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_LETS_GO: {
    action: 'assessoria_form_lets_go',
    label: 'BTN:Assessoria:Formulario',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_MAYBE_LATER: {
    action: 'assessoria_form_maybe_later',
    label: 'BTN:Assessoria:Formulario',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_NAME: {
    action: 'assessoria_form_name',
    label: 'BTN:Assessoria:Formulario:Nome',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_AGE: {
    action: 'assessoria_form_age',
    label: 'BTN:Assessoria:Formulario:Idade',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_MOMENT: {
    action: 'assessoria_form_moment',
    label: 'BTN:Assessoria:Formulario:MomentoDeVida:{1}:{0}',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_HOUSE: {
    action: 'assessoria_form_house',
    label: 'BTN:Assessoria:Formulario:Casa:{1}:{0}',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_CAR: {
    action: 'assessoria_form_car',
    label: 'BTN:Assessoria:Formulario:{1}:Carro:{0}',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_CEP: {
    action: 'assessoria_form_cep',
    label: 'BTN:Assessoria:Formulario:{0}:Cep',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_EMAIL: {
    action: 'assessoria_form_email',
    label: 'BTN:Assessoria:Formulario:{1}:Email:{0}',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_FORM_END: {
    action: 'assessoria_form_end',
    label: 'BTN:Assessoria:Formulario:{0}:Resultado:Ver',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_LANDING_RESULT_MOMENT: {
    action: 'assessoria_landing_result_moment',
    label: 'BTN:Assessoria:Resultado:{0}:MomentoDeVida',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_LANDING_RESULT_PRODUCT_SIMULATE: {
    action: 'assessoria_landing_result_product_simulate',
    label: 'BTN:Assessoria:Resultado:{1}:SimuleAgora:{0}',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_LANDING_RESULT_PRODUCT_WARNING: {
    action: 'assessoria_landing_result_product_warning',
    label: 'BTN:Assessoria:Resultado:{1}:QueroSerAvisado:{0}',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_LANDING_RESULT_PRODUCT_SEEMORE: {
    action: 'assessoria_landing_result_product_seemore',
    label: 'BTN:Assessoria:Resultado:{1}:VerMais:{0}',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSESSORIA_LANDING_RESULT_SCROLL: {
    action: 'ASSESSORIA_LANDING_RESULT_SCROLL',
    label: 'BTN:Assessoria:Resultado:{0}:Scroll',
    category: ANALITYCS_CATEGORY.SCROLL,
  },
};
