import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    direction: 'row',
    [breakpoints.down('sm')]: {
      direction: 'column',
    },
  },
  field: {
    paddingRight: '1rem',
    [breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  button: {
    top: '2.5rem',
    position: 'relative',
    '& button': {
      fontSize: '1rem',
      width: '100%',
      padding: '0',
      display: 'block',
    },
    [breakpoints.down('sm')]: {},
  },
}));
