import config from 'config';
import ProductsResearch, { LIFE_INSURANCE } from 'pages/ProductsResearch';
import ROUTES from 'routes/routes';
import { QAToolsContent } from 'sections/MocksPanel';

export default function GetMenuItems(channels) {
  const { FAKEDOOR, QA_TOOLS } = config;

  const home = {
    title: '**home**',
    onClick: { path: '/' }
  };

  const insurances = {
    title: 'seguro',
    defaultOption: {
      label: 'seguros',
      ariaLabel: 'Escolha um seguro'
    },
    items: [
      {
        availability: channels?.isAutoE2EAllowed,
        iconDefault: 'icon-itaufonts_seguro_auto',
        iconHover: 'icon-itaufonts_full_seguro_auto',
        label: 'seguro automóvel',
        onClick: { path: ROUTES.LANDING_AUTO }
      }
    ]
  };

  const assistances = {
    title: 'assistência',
    defaultOption: {
      label: 'assistências',
      ariaLabel: 'Escolha uma assistência'
    },
    items: [
      {
        availability: channels?.isPetE2EAllowed || false,
        iconDefault: 'icon-itaufonts_outline_seguro_pet',
        iconHover: 'icon-itaufonts_full_seguro_pet',
        label: 'assistência pet',
        onClick: { path: ROUTES.LANDING_PET }
      },
      {
        availability: channels?.isWelfareE2EAllowed || false,
        iconDefault: 'icon-itaufonts_seguro_vida',
        iconHover: 'icon-itaufonts_full_seguro_vida',
        label: 'assistência bem-estar',
        onClick: { path: ROUTES.LANDING_WELFARE }
      },
      {
        availability: channels?.isCarglassAllowed,
        iconDefault: 'icon-itaufonts_seguro_auto',
        iconHover: 'icon-itaufonts_full_seguro_auto',
        label: 'serviços automotivos',
        onClick: {
          path: ROUTES.LANDING_CARGLASS
        }
      }
    ]
  };

  const help = {
    title: 'ajuda',
    defaultOption: {
      label: 'ajuda',
      ariaLabel: 'Lista de ajuda'
    },
    items: [
      {
        availability: true,
        label: 'dúvidas frequentes',
        onClick: { path: '/v2/ajuda/produtos' }
      },

      {
        availability: true,
        label: 'contatos',
        onClick: { action: 'focusContacts' }
      },

    ]
  };

  const fakedoor = {
    title: 'seguro de vida',
    children: <ProductsResearch research={LIFE_INSURANCE} />
  };

  const qaTools = {
    title: 'QA Tools',
    ariaLabel: 'QA Tools. Ferramentas para testes.',
    children: <QAToolsContent style={{ marginTop: '2rem' }} />,
    hiddenOnDesktop: true
  };

  /** Remove menu items subitems wich have channelAvailability key with false */
  const filterItemsByAvailability = items =>
    items.filter(item => item.availability === true || item.availability === undefined);

  /** First, mount product menu items */
  let DropdownMenuItems = [insurances, assistances];

  /** Filter submenu items by availability */
  DropdownMenuItems = DropdownMenuItems.map(menuItem => ({
    ...menuItem,
    items: filterItemsByAvailability(menuItem.items)
  }));

  /** Remove menu items wich submenu items array were empty */
  DropdownMenuItems = DropdownMenuItems.filter(menuItem => menuItem.items?.length > 0);

  /** Initiate final object mounting in correct order */
  const MenuItems = [home, ...DropdownMenuItems];

  /** Push fakedoor item if available */
  if (FAKEDOOR.enabled) MenuItems.push(fakedoor);

  // MenuItems.push(blog);

  /** Filter help items by availability, than push */
  help.items = filterItemsByAvailability(help.items);
  MenuItems.push(help);

  /** Push QA Tools item if available */
  if (QA_TOOLS) MenuItems.push(qaTools);

  return MenuItems;
}

