const MAX_CONTENT_WIDTH = '1300px';
const DEFAULT_DESKTOP_HORIZONTAL_MARGIN = `calc((100vw - ${MAX_CONTENT_WIDTH}) / 2)`;
const DEFAULT_MOBILE_HORIZONTAL_MARGIN = '1.5rem';
const MENU_HEIGHT = '77px';

export default {
  MAX_CONTENT_WIDTH,
  DEFAULT_DESKTOP_HORIZONTAL_MARGIN,
  DEFAULT_MOBILE_HORIZONTAL_MARGIN,
  MENU_HEIGHT,
};
