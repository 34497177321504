import React from 'react';
import { setFeatureToggle } from 'utils/featureToggle';
import GenericToggleTab from '../GenericToggleTab';
import ItemsList from './itemsList';

export default function MockToggleTab() {
  const handleClickApply = values => {
    values.map(item => setFeatureToggle(item.name, item.enabled));
  };

  return (
    <GenericToggleTab
      items={ItemsList}
      handleClickApply={handleClickApply}
      colNames={['Feature', 'Descrição', 'Ativado?']}
    />
  );
}
