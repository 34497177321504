import { all, call, put, select } from 'redux-saga/effects';
import assistanceQuotation from '../../../api/E2E/Realiza/assistanceQuotation';
import { setErrorSagaAction } from '../../ducks/httpErrors';
import { authenticationV2 } from '../../../api/E2E/Authentication/authV2';
import scopes from '../../../auth/scopes';

export default function genericAuthDataSubmitSagaAction(
  creator,
  selector,
  simulationToken,
  offerType,
  authToken,
  stateName
) {
  return function* authDataSubmitSagaAction(action) {
    const { data } = action;
    const { enablePetLogin, getToken } = data;
    const captcha = yield call(getToken, simulationToken);

    if (enablePetLogin) {
      try {
        const QuotationRequest = yield select(selector.QuotationRequestSelector);
        const { accessToken, identifier } = JSON.parse(sessionStorage.getItem('auth:safe'));
        const response = yield call(
          assistanceQuotation,
          offerType,
          { ...QuotationRequest, captcha },
          { 'token-sts': accessToken, identifier }
        );
        yield put(creator.createResponseQuotation(response));
      } catch (err) {
        console.error(err);
        yield put(setErrorSagaAction(action));
      }
    } else {
      const { data } = action;
      const { getToken } = data;

      const proponent = {
        email: data.email,
        cellphone: data.cellphone && data.cellphone.replace(/\D/g, ''),
      };
      const contatos = [
        {
          valor: data.email,
          tipo: 'email',
        },
        {
          tipo: 'telefone',
          valor: data.cellphone && data.cellphone.replace(/\D/g, ''),
        },
      ];

      try {
        yield all([
          put(creator.authLoad()),
          put(creator.addFlowAuthData(proponent)),
          put(creator.createHiringRequestProponentContact(contatos)),
          put(creator.createQuotationRequestProponentContact(contatos)),
        ]);
        const QuotationRequest = yield select(selector.QuotationRequestSelector);

        const response = yield call(assistanceQuotation, offerType, { ...QuotationRequest, captcha });
        yield put(creator.createResponseQuotation(response));
        const nome = yield select(selector.QuotationProponentNameSelector);
        const cpf = yield select(selector.QuotationProponentCpfSelector);
        const email = yield select(selector.EmailSelector);
        const telefone = yield select(selector.CellphoneSelector);
        const state = yield select((_state) => _state[stateName]);
        const authCaptcha = yield call(getToken, authToken);
        const dadosParaAutenticacao = {
          id_simulacao: response.id_simulacao,
          usuario: {
            nome,
            cpf,
            email,
            telefone,
          },
          captcha: {
            ...authCaptcha,
          },
          sessao: {
            [stateName]: state,
          },
        };
        yield call(authenticationV2, dadosParaAutenticacao, scopes[stateName.toUpperCase()]);
        yield put(creator.authSuccess());
      } catch (err) {
        console.error(err);
        yield put(creator.authError(err));
        yield put(setErrorSagaAction(action));
      }
    }
  };
}
