import ROUTES from 'routes/routes';

export const CREDICARD = 'credicard';
export const ITAUCARD = 'itaucard';
export const SMS = 'sms';
export const MALADIGITAL1 = 'maladigital1';
export const MALADIGITAL2 = 'maladigital2';
export const CONNECTCAR = 'connectcar';
export const ABASTECEAI = 'abasteceai';
export const NLCARTOESSEGUROS = 'NLcartoesseguros';
export const CONSORCIO = 'consorcio';
export const IMPRENSA = 'imprensa';

const BLOCK_REDIRECT_ROUTES = [ROUTES.AUTH, '/comprovantes'];

export const redirectURL = ({ base, routes }) => {
  let url = null;
  if (base.path !== '/') {
    return base.path;
  }

  if (BLOCK_REDIRECT_ROUTES.includes(base.path)) {
    return base.path;
  }

  switch (base.distributionkey) {
    case SMS:
      url = routes.LANDING_ODONTO;
      break;
    case MALADIGITAL1:
      url = routes.LANDING_ODONTO;
      break;
    case MALADIGITAL2:
      url = routes.LANDING_ODONTO;
      break;
    case CONNECTCAR:
      url = routes.LANDING_AUTO;
      break;
    case ABASTECEAI:
      url = routes.LANDING_AUTO;
      break;
    case IMPRENSA:
      url = routes.LANDING_ODONTO;
      break;
    case NLCARTOESSEGUROS:
      url = hasProduct(NLCARTOESSEGUROS, base.product, routes);
      break;
    case CONSORCIO:
      url = hasProduct(CONSORCIO, base.product, routes);
      break;
    default:
      url = base.path;
  }
  return url;
};
