import { getPartners } from './Partners/partners';
import { getOffersByLanding } from './Offers/offers';
import { getFaq } from './Faq/faq';
import { getToken } from './Authentication/authentication';
import { getHome } from './Home/home';
import { getProducts } from './Products/products';
import { getCep } from './Cep/cep';
import { createLeads } from './Leads/leads';
import { getStates } from './E2E/Odonto/States/getStates';
import { getCities } from './E2E/Odonto/Cities/getCities';
import { hasAvailablePlans } from './E2E/Odonto/odonto';
import { setPayment } from './E2E/Odonto/Payments/setPayment';
import { getCoverages } from './E2E/Resid/Coverages/getCoverages';
import { getPersonalFields } from './PersonalFields/getPersonalFields';
import { getE2EAllowedChannels } from './Channels/getE2EAllowedChannels';
import { createCarts } from './Cart/createCart';
import { deleteCarts } from './Cart/deleteCart';
import { createResidCart } from './Cart/createCart';
import { deleteResidCart } from './Cart/deleteCart';

const resid = {
  getCoverages,
  getPersonalFields,
};

export {
  getPartners,
  getFaq,
  getToken,
  getHome,
  getProducts,
  getCep,
  createLeads,
  getStates,
  getCities,
  hasAvailablePlans,
  setPayment,
  getOffersByLanding,
  resid,
  getE2EAllowedChannels,
  createCarts,
  deleteCarts,
  createResidCart,
  deleteResidCart,
};
