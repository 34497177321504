import axios from 'axios';

import { performance } from '../../config';

export const createLeads = async lead => {
  const trace = performance.trace('createLeads');
  trace.start();
  const response = axios.post('v1/mktseguros/leads', lead);
  trace.stop();
  return response;
};
