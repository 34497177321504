import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'store/ducks/modal';
import { modalSelector } from 'store/sagas/selectors/modal';
import Box from '@material-ui/core/Box';
import Tabs from 'components/Tabs';
import MockToggleTab from './components/MockToggleTab';
import FeatureToggleTab from './components/FeatureToggleTab';
import { VirtualEnvironmentsTab } from './components/VirtualEnvironmentsTab';
import { useSharedStyles } from './styles';
import { MwsToggleTab } from './components/MswToggleTab';

export function QAToolsContent({ ...props }) {
  const sharedStyles = useSharedStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      label: 'Mock toggle',
      ariaLabel: 'Configurações de mock de A P I',
      element: <MockToggleTab />,
    },
    {
      label: 'Feature toggle',
      ariaLabel: 'Configurações de disponibilidade de recursos',
      element: <FeatureToggleTab />,
    },
    // {
    //   label: 'State snapshots',
    //   ariaLabel: 'Estado da aplicação',
    //   disabled: true
    // },
    {
      label: 'Virtualizador',
      ariaLabel: '',
      element: <VirtualEnvironmentsTab />,
    },
    {
      label: 'MSW',
      ariaLabel: '',
      element: <MwsToggleTab />,
    },
  ];

  return (
    <Box className={sharedStyles.root} display="flex" flexDirection="column" justifyContent="space-between" {...props}>
      <div>
        <h4 className={sharedStyles.modalTitle} aria-label="QA Tools. Ferramentas para testes.">
          🛠️ QA Tools
        </h4>
        <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} />
      </div>
      <p className={sharedStyles.modalFooterText}>
        * Essas configurações são locais, ou seja, elas refletem somente na sua máquina, não serão alteradas para outros
        usuários deste ambiente.
      </p>
    </Box>
  );
}

export default function QATools() {
  const dispatch = useDispatch();
  const modalState = useSelector(modalSelector);
  const oldModalToggleHandler = useRef();

  const handleToggleModal = (e) => {
    if (e.ctrlKey && e.code === 'F12') dispatch(showModal(!modalState.showModal, <QAToolsContent />));
  };

  useEffect(() => {
    window.removeEventListener('keydown', oldModalToggleHandler.current);
    window.addEventListener('keydown', handleToggleModal);
    oldModalToggleHandler.current = handleToggleModal;
  }, [modalState.showModal]);

  return null;
}
