import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Box from '@material-ui/core/Box';
import { useSchemerContext } from 'shared/form/FormikAutomaticSchema';
import StringField from '../fields/stringField';
import MaskedField from '../fields/maskedField';
import EnumeratorField from '../fields/enumeratorField';
import ArrayField from '../fields/arrayField';
import SelectFormItem from './items/SelectFormItem';
import BooleanField from '../fields/booleanField';
import CheckboxFormItem from './items/CheckboxFormItem';
import RadioFormItem from './items/RadioFormItem/RadioFormItem';
import FormikRadioCardField from './items/FormikRadioCardField/FormikRadioCardField';
import FormikCheckboxListField from './items/FormikCheckboxListField/FormikCheckboxListField';
import FormikNumericalSliderField from './items/FormikNumericalSliderField/FormikNumericalSliderField';
import FormikTextFieldWithToolTip from './items/FormikTextFieldWithToolTip/FormikTextFieldWithToolTip';

import TextInputField from './items/TextInputField/TextInputField';
import FormikItemWrapper from './items/FormikItemWrapper';
import MaskInputField from '../../components/MaskInputField/MaskInputField';
import InputMaskWrapper from '../../components/InputField/InputField';

export default function FormItem({
  field,
  autofocus,
  options,
  onBlur,
  onChange,
  value,
  disabled,
  className,
  checkBoxCustom,
  iconInfo,
  labelLayout,
  optionsLayout,
  helperField,
  classNameWrapper,
  inputRef,
  onClick,
  ...props
}) {
  const context = useSchemerContext();
  useEffect(() => {
    if (context) context.addField(field);

    return () => {
      if (context) context.removeField(field.name);
    };
  }, []);

  if (field instanceof StringField) {
    if (field.tooltipProps) {
      return (
        <FormikTextFieldWithToolTip
          inputRef={inputRef}
          name={field.name}
          label={field.label}
          inputProps={{
            'aria-label': `${field.ariaLabel || field.label}${
              field.required ? `. ${i18next.t('WORDS.REQUIRED')}.` : ''
            }`,
            minLength: field.min ? field.min.length : null,
            maxLength: field.max ? field.max.length : null,
          }}
          type={field.type || 'text'}
          required={field.required}
          autoFocus={autofocus}
          onBlur={onBlur}
          hint={field.helperText || ''}
          placeholder={field.placeholder}
          onChange={onChange}
          disabled={disabled || field.disabled}
          id={field.id}
          disableUnderline={field.disableUnderline}
          tooltipProps={field.tooltipProps}
        />
      );
    }
    return (
      <FormikItemWrapper
        component={_props => <TextInputField {..._props} />}
        inputRef={inputRef}
        name={field.name}
        label={field.label}
        inputProps={{
          'aria-label': field.ariaLabel,
          minLength: field.min ? field.min.length : null,
          maxLength: field.max ? field.max.length : null,
        }}
        type={field.type || 'text'}
        required={field.required}
        autoFocus={autofocus}
        onBlur={onBlur}
        hint={field.helperText || ''}
        placeholder={field.placeholder}
        onChange={onChange}
        disabled={disabled || field.disabled}
        id={field.id}
        disableUnderline={field.disableUnderline}
        {...props}
      />
    );
  }
  if (field instanceof MaskedField) {
    if (helperField) {
      return (
        <FormikItemWrapper
          component={InputMaskWrapper}
          inputRef={inputRef}
          name={field.name}
          label={field.label}
          type={field.type}
          inputProps={{
            minLength: field.min ? field.min.length : null,
            maxLength: field.max ? field.max.length : null,
          }}
          ariaLabel={field.ariaLabel}
          required={field.required}
          mask={field.mask}
          formatChars={field.formatChars}
          onBlur={onBlur}
          placeholder={field.placeholder}
          onChange={onChange}
          hint={field.helperText || ''}
          disabled={disabled || field.disabled}
          helperField={helperField}
        />
      );
    }

    return (
      <FormikItemWrapper
        component={MaskInputField}
        inputRef={inputRef}
        name={field.name}
        label={field.label}
        type={field.type}
        inputProps={{
          minLength: field.min ? field.min.length : null,
          maxLength: field.max ? field.max.length : null,
        }}
        ariaLabel={field.ariaLabel}
        required={field.required}
        mask={field.mask}
        formatChars={field.formatChars}
        onBlur={onBlur}
        placeholder={field.placeholder}
        onChange={onChange}
        hint={field.helperText || ''}
        disabled={disabled || field.disabled}
        className={className}
        labelLayout={labelLayout}
        disableUnderline={field.disableUnderline}
      />
    );
  }
  if (field instanceof ArrayField) {
    return (
      <FormikCheckboxListField
        options={field.options}
        name={field.name}
        label={field.label}
        classNameLabel={className}
        labelLayout={labelLayout}
        classNameTitle={className}
        required={field.required}
      />
    );
  }
  if (field instanceof EnumeratorField) {
    if (field.type === 'radio') {
      return (
        <RadioFormItem
          name={field.name}
          label={field.label}
          ariaLabel={field.ariaLabel}
          options={options || field.options}
          required={field.required}
          onBlur={onBlur}
          onChange={onChange}
          className={className}
          classNameLabel={className}
          classNameWrapper={classNameWrapper}
          labelLayout={labelLayout}
          horizontalOrientation={field.horizontalOrientation}
          columnContainer={field.columnContainer}
          optionsLayout={optionsLayout}
          id={field.id}
          nestedQuestionSpacing={field.nestedQuestionSpacing}
        />
      );
    }
    if (field.type === 'numericalSlider') {
      return (
        <FormikNumericalSliderField
          name={field.name}
          label={field.label}
          describeCurrentValue={field.describeCurrentValue}
          tooltipProps={field.tooltipProps}
          ariaLabel={field.ariaLabel}
          options={field.options}
          required={field.required}
        />
      );
    }
    if (field.type === 'radioCard') {
      return (
        <FormikRadioCardField
          options={field.options}
          name={field.name}
          label={field.label}
          className={className}
          labelLayout={labelLayout}
          required={field.required}
        />
      );
    }
    if (field.type === 'checkboxList') {
      return (
        <FormikCheckboxListField
          options={field.options}
          name={field.name}
          label={field.label}
          classNameLabel={className}
          labelLayout={labelLayout}
          classNameTitle={className}
          required={field.required}
        />
      );
    }
    if (field.type === 'selectRegular') {
      return (
        <SelectFormItem
          name={field.name}
          label={field.label}
          ariaLabel={`${field.ariaLabel || field.label}${field.required ? `. ${i18next.t('WORDS.REQUIRED')}.` : ''}`}
          options={options || field.options}
          required={field.required}
          onBlur={onBlur}
          onChange={onChange}
          defaultSelect={false}
          id={field.id}
          disabled={disabled}
        />
      );
    }
    return (
      <SelectFormItem
        name={field.name}
        label={field.label}
        ariaLabel={`${field.ariaLabel || field.label}${field.required ? ` ${i18next.t('WORDS.REQUIRED')}`: ''}`}
        options={options || field.options}
        required={field.required}
        onBlur={onBlur}
        onChange={onChange}
        className={className}
        labelLayout={labelLayout}
        defaultSelect
        product={field.product}
        id={field.id}
        disabled={disabled}
        {...props}
      />
    );
  }
  if (field instanceof BooleanField) {
    return (
      <CheckboxFormItem
        name={field.name}
        label={field.label}
        linkLabel={field.linkLabel}
        linkUrl={field.linkUrl}
        fontSize={field.fontSize}
        onBlur={onBlur}
        value={value}
        onClick={onClick}
        onChange={onChange}
        className={className}
        checkBoxCustom={checkBoxCustom}
        iconInfo={iconInfo}
        disabled={disabled}
        ariaLabel={`${field.ariaLabel || field.label}${field.required ? `. ${i18next.t('WORDS.REQUIRED')}.` : ''}`}
        required={field.required}
        inputRef={inputRef}
      />
    );
  }
  return (
    <Box>
      <p>Não há implementação para esse tipo de campo ainda</p>
    </Box>
  );
}

FormItem.propTypes = {
  field: PropTypes.any,
  autofocus: PropTypes.bool,
  options: PropTypes.array,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  checkBoxCustom: PropTypes.any,
  iconInfo: PropTypes.any,
  labelLayout: PropTypes.object,
  optionsLayout: PropTypes.object,
  helperField: PropTypes.func,
};
