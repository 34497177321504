import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SelectableChip } from '../index.js';

export default function SelectableChipGroup({ options, onChange, value = [], onClickChipInfo }) {
  const [selection, setSelection] = useState(value);

  const handleChange = (e, checked) => {
    const selectionValue = e?.target?.getAttribute('value');
    let newSelection = selection;

    if (checked) newSelection = [...newSelection, selectionValue];
    else newSelection = newSelection.filter(item => item !== selectionValue);

    setSelection(newSelection);
    onChange(newSelection);
  };

  return options.map(option => (
    <SelectableChip
      key={option.id}
      onChange={handleChange}
      checked={!!value?.find(item => item === option.value)}
      onClickChipInfo={onClickChipInfo}
      {...option}
    />
  ));
}

SelectableChipGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      info: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  value: PropTypes.array,
  onClickChipInfo: PropTypes.func,
};
