import { getFeatureToggle } from 'utils/featureToggle';

const NORMALIZE_LABEL = 'REACT_APP_';
const FEATURE_TOGGLE_PREFIX = '%featureToggle';

/**
 * Remove React standard env vars prefixes (needed to inject env vars to React environment)
 * @param {String} value String to be normalized
 * @returns {String} Normalized string
 */
function normalizeAppKey(value) {
  return value.replace(NORMALIZE_LABEL, '');
}

/**
 * Parse some string to JSON data
 * @param {String} element String to be parsed to JSON
 * @returns {JSON} Parsed string to JSON
 */
function tryToParse(element) {
  try {
    return JSON.parse(element);
  } catch (e) {
    return element;
  }
}

/**
 * Use feature toggle behavior
 * @param {String} element A string containing a complete data about a feature toggle ($featureToggle:<KEY>:<true|false>)
 * @returns {JSON} { "enabled": true|false, "metadata": { "dataType": "featureToggle" } }
 */
function handleFeatureToggle(element) {
  const elementParts = element.split(':');
  return getFeatureToggle(elementParts[1], JSON.parse(elementParts[2]));
}

/**
 * Determines what to do with a given env var, end return some JSON data
 * @param {String} element The element to deal with
 * @returns {JSON}
 */
function transformEnvVar(element) {
  /** Check if the env var has the feature toggle string pattern */
  if (typeof element === 'string' && element?.includes(FEATURE_TOGGLE_PREFIX)) return handleFeatureToggle(element);
  /** The default treatment is to try to parse to JSON data */
  return tryToParse(element);
}

export function mapParsedEnvs(envs) {
  const envKeys = Object.keys(envs);
  return envKeys.reduce((acc, envKey) => {
    const normalizedKey = normalizeAppKey(envKey);
    const value = envs[envKey];
    acc[normalizedKey] = transformEnvVar(value);
    return acc;
  }, {});
}
