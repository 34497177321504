import { call } from 'redux-saga/effects';
import { createCarts, deleteCarts, createResidCart as createCartResid, deleteResidCart as deleteCartResid} from '../../../api';
import {prepareDeleteCartData} from "../../../utils/utils-abandoned-cart";

export function* createCart(action) {
  try {
    const { payload } = action;
    yield call(createCarts, { ...payload });
  } catch (error) {
    console.error(`create cart error` , error)
  }
}

export function* deleteCart(action) {
  try {
    const { payload } = action;
    yield call(deleteCarts, { ...payload });
  } catch (error) {
    console.error(`delete cart error` , error)
  }
}

export function* createResidCart(action) {
  try {
    const { payload } = action;
    yield call(createCartResid, { ...payload });
  } catch (error) {
    console.error(`create resid cart error` , error)
  }
}

export function* deleteResidCart(action) {
  try {
    const { payload } = action;
    yield call(deleteCartResid, prepareDeleteCartData(payload?.cpf, 'RESID'));
  } catch (error) {
    console.error(`delete resid cart error` , error)
  }
}
