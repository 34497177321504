const receipt = {
  RECEIPT: {
    INFO: {
      LOADED: 'Página de proposta carregada',
      BANNER_SUCCESS: 'proposta enviada com sucesso!',
      PROTOCOL: 'o número da sua cotação é: ',
      EMAIL_PAYMENT:
        'ah, a seguradora irá analisar sua proposta e se aceita, te enviará um e-mail com sua apólice quando o pagamento for aprovado.',
      PROPOSE:
        'para cancelamento em até 7 dias da data de compra, você receberá o estorno do valor total do seguro, conforme o Código de Defesa do Consumidor',
      PROPOSE_STATUS: 'status da proposta',
    },
  },
};

export default receipt;
