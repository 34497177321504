import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import NumericalSlider from 'components/NumericalSlider';
import { Grid } from '@material-ui/core';
import { useStyles } from './FormikNumericalSliderField.styles';
import { callFunction } from '../../../../utils/util';

function FormikNumericalSliderField({ ariaLabel, options, name, tooltipProps, describeCurrentValue, required }) {
  const styles = useStyles();
  const { helperProps } = tooltipProps;
  return (
    <Field
      name={name}
      render={({ form, field }) => {
        const onChange = event => {
          form.setFieldValue(name, event);
          if (field.onChange) callFunction(field.onChange, event);
        };
        const sliderInitialValue = form.values.classStore;
        return (
          <>
            <NumericalSlider
              ariaLabel={ariaLabel}
              describeCurrentValue={describeCurrentValue}
              options={options}
              onChange={onChange}
              tooltipProps={tooltipProps}
              required={required}
              sliderInitialValue={sliderInitialValue}
            />
            <Grid container className={styles.containerInfo}>
              <Grid item sm={12} lg={12}>
                {helperProps.map(prop => (
                  <>
                    <p className={styles.titleInfo}>{prop.title}</p>
                    <p className={styles.description}>{prop.description}</p>
                  </>
                ))}
              </Grid>
            </Grid>
          </>
        );
      }}
    />
  );
}

FormikNumericalSliderField.propTypes = {
  ariaLabel: PropTypes.any,
  options: PropTypes.any,
  name: PropTypes.string,
};

export default FormikNumericalSliderField;
