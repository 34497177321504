import i18next from 'i18n';

export default {
  PRODUCTS: {
    ODONTO: {
      SCHEDULE_CONTACT: {
        TITLE: i18next.t('ODONTO.SCHEDULE_CONTACT.TITLE'),
      },
    },
  },
};
