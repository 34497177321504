import axios from 'axios';

import config, { performance } from 'config';

const { ITAU_GATEWAY_HOST, SEGUROS_APIS_PATH } = config;

async function loadAsyncQuotation(payload) {
  const baseURL = ITAU_GATEWAY_HOST;
  const trace = performance.trace('postLoadAsyncQuotation');
  trace.start();
  const { data } = await axios.post(
    `${SEGUROS_APIS_PATH}/cotacoes/recuperar`,
    payload,
    {
      baseURL
    }
  );
  trace.stop();
  return data.data;
}

export default loadAsyncQuotation;
