export const MFNames = {
  LOJA_EVOLUCAO: 'LojaEvolucao',
  LOJA_CDPFMA: 'LojaCdpfma',
  LOJA_PCPFL: 'LojaPcpfl',
  LOJA_CSEMA: 'LojaCsema',
  HOSPITAU: 'Hospitau',
};

export const mfConfigs = [
  {
    name: MFNames.LOJA_EVOLUCAO,
    configs: [
      {
        env: 'localhost',
        path: 'http://localhost:4200',
        scripts: ['/main.js', '/runtime.js'],
      },
      {
        env: 'development',
        path: 'https://lojadeseguros-evolucao.dev.cloud.itau.com.br',
        scripts: ['/main.js', '/runtime.js'],
      },
      {
        env: 'qa',
        path: 'https://lojadeseguros-evolucao.hom.cloud.itau.com.br',
        scripts: ['/main.esm.js', '/runtime.esm.js', '/vendor.esm.js'],
      },
      {
        env: 'production',
        path: 'https://lojadeseguros-evolucao.cloud.itau.com.br',
        scripts: ['/main.esm.js', '/runtime.esm.js', '/vendor.esm.js'],
      },
    ],
  },
  {
    name: MFNames.LOJA_CDPFMA,
    configs: [
      {
        env: 'localhost',
        path: 'http://localhost:4200',
        scripts: ['/main.js', '/runtime.js'],
      },
      {
        env: 'development',
        path: 'https://evolucao-cdpfma-dev.cloud.itau.com.br',
        scripts: ['/main.js', '/runtime.js'],
      },
      {
        env: 'qa',
        path: 'https://evolucao-cdpfma-dev.cloud.itau.com.br',
        scripts: ['/main.js', '/runtime.js'],
      },
    ],
  },
  {
    name: MFNames.LOJA_PCPFL,
    configs: [
      {
        env: 'localhost',
        path: 'http://localhost:4200',
        scripts: ['/main.js', '/runtime.js'],
      },
      {
        env: 'development',
        path: 'https://evolucao-pcpfl-dev.cloud.itau.com.br',
        scripts: ['/main.js', '/runtime.js'],
      },
      {
        env: 'qa',
        path: 'https://evolucao-pcpfl-dev.cloud.itau.com.br',
        scripts: ['/main.esm.js', '/runtime.esm.js', '/vendor.esm.js'],
      },
    ],
  },
  {
    name: MFNames.LOJA_CSEMA,
    configs: [
      {
        env: 'localhost',
        path: 'http://localhost:4200',
        scripts: ['/main.js', '/runtime.js'],
      },
      {
        env: 'development',
        path: 'https://evolucao-csema-dev.cloud.itau.com.br',
        scripts: ['/main.js', '/runtime.js'],
      },
      {
        env: 'qa',
        path: 'https://evolucao-csema-dev.cloud.itau.com.br',
        scripts: ['/main.js', '/runtime.js'],
      },
    ],
  },
  {
    name: MFNames.HOSPITAU,
    configs: [
      {
        env: 'localhost',
        path: '',
        scripts: [
          '/cdn/assets/scripts/core.min.js',
          '/cdn/assets/scripts/zone.min.js',
          '/cdn/assets/scripts/document-register-element.min.js',
          '/cdn/assets/scripts/custom-elements-es5-adapter.min.js',
          '/main.js',
        ],
      },
      {
        env: 'development',
        path: 'https://microfrontend.dev.cloud.itau.com.br',
        scripts: [
          '/cdn/assets/scripts/core.min.js',
          '/cdn/assets/scripts/zone.min.js',
          '/cdn/assets/scripts/document-register-element.min.js',
          '/cdn/assets/scripts/custom-elements-es5-adapter.min.js',
          '/seguros/mf-portalsegurado-hospitau/main.js',
        ],
      },
      {
        env: 'qa',
        path: 'https://microfrontend.hom.cloud.itau.com.br',
        scripts: [
          '/cdn/assets/scripts/core.min.js',
          '/cdn/assets/scripts/zone.min.js',
          '/cdn/assets/scripts/document-register-element.min.js',
          '/cdn/assets/scripts/custom-elements-es5-adapter.min.js',
          '/seguros/mf-portalsegurado-hospitau/main.js',
        ],
      },
      {
        env: 'production',
        path: 'https://microfrontend.cloud.itau.com.br',
        scripts: [
          '/cdn/assets/scripts/core.min.js',
          '/cdn/assets/scripts/zone.min.js',
          '/cdn/assets/scripts/document-register-element.min.js',
          '/cdn/assets/scripts/custom-elements-es5-adapter.min.js',
          '/seguros/mf-portalsegurado-hospitau/main.js',
        ],
      },
    ],
  },
];
