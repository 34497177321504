import axios from 'axios';

import config, { performance } from '../../../../config';

const { E2E_PATH } = config;

const setHiring = async (payload, headers) => {
  const trace = performance.trace('setHiring');
  trace.start();
  const response = await axios.post(`${E2E_PATH}/contratacoes`, payload, {
    headers,
  });
  trace.stop();
  const location = await response.headers.location.split('/');
  const id = location[location.length - 1];
  return `${E2E_PATH}/contratacoes/id/${id}`;
};

export default setHiring;
