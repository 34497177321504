const hiring = {
  DYNAMIC_FORM: {
    ERROR_MODAL: {
      TITLE: 'cotação do meu seguro residencial',
      CANT_COVER: 'infelizmente ainda não conseguimos cobrir seu imóvel',
      MOBILE_DESCRIPTION: {
        VALID_FOR: {
          START: 'o seguro é',
          MIDDLE: ' válido para ',
          END: 'imóveis',
        },
        BRICKS: {
          TITLE: 'de alvenaria ',
          DESCRIPTION: '(alvenaria é o local construído com tijolos, cimento, pedras ou blocos de concreto',
        },
        OCCUPIED: 'ocupado',
        FARM_HOUSE: 'não localizado em chácaras, sítios ou fazendas',
      },
      DESCRIPTION:
        'poxa! este seguro residencial não é válido para imóveis de madeira ou local desocupado. mas você pode contratar o seguro para um imóvel de alvenaria, sendo casa, apartamento ou chácara que esteja ocupado.',
      BUTTONS_TEXT: {
        HOME: 'voltar a página inicial',
        NEW_QUOTATION: 'cotar outro tipo de imóvel',
      },
    },
  },
  PERSONAL_FORM: {
    FIELDS: {
      MARTIAL_STATUS: {
        LABEL: 'estado civil',
        REQUIRED_MESSAGE: 'você precisa informar o estado civil',
      },
      NATIONALITY: {
        LABEL: 'nacionalidade',
        REQUIRED_MESSAGE: 'você precisa informar a nacionalidade',
      },
      RESIDENTIAL_COUNTRY: {
        LABEL: 'país de residência',
        REQUIRED_MESSAGE: 'você precisa informar se reside no Brasil',
      },
      INCOME_BRACKET: {
        LABEL: 'faixa de renda',
        REQUIRED_MESSAGE: 'você precisa informar a faixa de renda',
      },
      NAME: {
        LABEL: 'nome completo',
        REQUIRED_MESSAGE: 'você precisa informar o nome completo',
        MESSAGE: 'nome do titular deve ter nome e sobrenome',
        GENERIC_MESSAGE: 'o campo precisa conter nome e sobrenome sem abreviações',
      },
      BIRTHDATE: {
        LABEL: 'data de nascimento',
        REQUIRED_MESSAGE: 'você precisa informar a data de nascimento',
        MESSAGE: {
          DATE: 'data de nascimento inválida',
          AGE: 'para contratar o seguro, você precisa ter mais de 18 anos',
        },
      },
      CPF: {
        LABEL: 'CPF',
        REQUIRED_MESSAGE: 'você precisa informar o CPF',
        MESSAGE: 'CPF inválido',
      },
      DOCUMENT: {
        LABEL: 'documento',
        REQUIRED_MESSAGE: 'você precisa informar o documento',
      },
      DOCUMENT_NUMBER: {
        LABEL: 'número do documento',
        REQUIRED_MESSAGE: 'você precisa informar o número do documento',
      },
      DOCUMENT_TYPE: {
        RG: 'RG',
        CNH: 'CNH',
        PASSPORT: 'Passaporte',
        RNE: 'RNE',
      },
      NATIONALITY_OPTIONS: {
        BRAZILIAN: 'brasileira',
        FOREIGN: 'estrangeira',
      },
      ISSUING_ORGAN: {
        LABEL: 'órgão emissor',
        REQUIRED_MESSAGE: 'você precisa informar o órgão emissor',
        INVALID_ORGAN_MESSAGE: 'informe um órgão emissor válido',
        MESSAGE: 'órgão emissor inválido',
      },
      SHIPPING_DATE: {
        LABEL: 'data de expedição',
        REQUIRED_MESSAGE: 'você precisa informar a data de expedição',
        MESSAGE: 'data de expedição inválida',
      },
      CEP: {
        LABEL: 'CEP',
        REQUIRED_MESSAGE: 'você precisa informar o CEP',
        MESSAGE: 'você precisa informar um CEP válido',
      },
      ADDRESS: {
        LABEL: 'endereço',
        REQUIRED_MESSAGE: 'você precisa informar o endereço',
        SAME_ADDRESS: 'endereço de cobrança é o mesmo que o de correspondência do titular',
        TYPE_ADDRESS: {
          BILLING_ADDRESS: 'endereço **de cobrança**',
        },
      },
      ADDRESS_NUMBER: {
        LABEL: 'número',
        REQUIRED_MESSAGE: 'você precisa informar o número',
        MESSAGE:
          'Este campo só aceita números. Se a residência não tem número, digite SN. Se possui letra, informe-a no complemento.',
      },
      COMPLEMENT: {
        LABEL: 'complemento',
        MESSAGE: 'você atingiu a quantidade máxima de 40 caracteres',
      },
      NEIGHBORHOOD: {
        LABEL: 'bairro',
        REQUIRED_MESSAGE: 'você precisa informar o bairro',
      },
      CITY: {
        LABEL: 'cidade',
        REQUIRED_MESSAGE: 'você precisa informar a cidade',
      },
      STATE: {
        LABEL: 'estado',
        REQUIRED_MESSAGE: 'você precisa informar o estado',
      },
      OWNER_ADDRESS: {
        LABEL: 'preencher com os mesmos dados do imóvel',
      },
      ACCEPT_TERMS: {
        LABEL: 'Declaro que li e estou de acordo com os termos de uso e com as ',
        ARIA_LABEL: 'declaro que li e concordo com as condições gerais',
        REQUIRED_MESSAGE: 'você precisa aceitar as condições gerais e os termos de uso',
      },
    },
  },
  PERSONALIZED_COVERAGE: {
    TITLE: '**personalize suas coberturas**',
    SUBTITLE: 'caso você altere os valores das coberturas, o preço do seu seguro também será alterado',
  },
  QUOTATION: {
    LOADING: [
      'hora de analisar suas informações pessoais, aguarde...',
      'estamos encontrando as melhores coberturas para sua residência...',
      'estamos finalizando o cálculo do valor para você...',
    ],
    PAGE_LOADED: 'página coberturas carregada',
    TITLE: '**coberturas**',
    SUBTITLE: 'os valores das coberturas já estão simulados, mas você pode deixar do seu jeito',
    TITLE_1: 'coberturas escolhidas',
    TITLE_2: 'agora que já viu os valores, quer incluir mais coberturas?',
    SUBTITLE_3: 'não esqueça, se quiser você pode',
    TITLE_4: 'escolher o valor das minhas coberturas',
    SUBTITLE_4: 'quer deixar do seu jeito?',
    INCLUDED: '(incluso)',
  },
  BIKE_CARD: {
    TITLE: 'subtração de bicicleta',
    SUBTITLE:
      'Essa cobertura não está disponível, mas estamos trabalhando a possibilidade para inclui-la. Ficamos felizes em ver seu interesse :)',
  },
  ASSISTS: {
    PAGE_TITLE: 'assistências',
    PAGE_SUBTITLE: [
      {
        id: 0,
        content:
          'as assistências são os serviços que você usa no seu dia a dia. Pode ser usado naquelas horas em que mais precisa. Ex. encanador, vidraceiro, eletricista.',
      },
      { id: 1, content: 'escolha o pacote de assistências que mais combina com as suas necessidades.' },
    ],
    CARD: {
      ALREADY_INCLUDED: '(já incluso)',
      SERVICE_LOCATIONS: 'locais de atendimento',
      USAGE_LIMIT: 'limite de uso',
      INCLUDED_ITEMS_LIST: 'serviços inclusos nesse plano para sua região',
      SHOW: 'ver',
      HIDE: 'ocultar',
      NO_ADDITIONAL_SERVICES: 'sem serviços adicionais',
    },
    TIP: {
      TITLE:
        'todos esses serviços estão inclusos no pacote selecionado e somente poderão ser acionados em caso de sinistro',
      FOOTER: {
        LABEL: 'limite de uso',
        CONTENT: 'até 2 vezes durante a vigência*',
      },
    },
    GENERAL_CONDITIONS: {
      PREFIX: '*você pode consultar os detalhes de utilização nas ',
      SUFFIX: '.',
      URL_TEXT: 'condições gerais',
    },
  },
  RESID: {
    FAQ_CARD: {
      HOME: 'ver condições gerais do seguro residencial habitual',
      SUMMER: 'ver condições gerais do seguro residencial veraneio',
    },
  },
};

export default hiring;
