const quotationAsync = {
  QUOTATION_ASYNC: {
    SUCCESS: {
      TITLE: 'agora é só esperar',
      DESCRIPTION:
        'assim que terminarmos o cálculo, você receberá um email com o resultado da sua cotação. não se esqueça de verificar na pasta Spam :)',
    },
    EMAIL: {
      TITLE: 'não foi possível carregar o resultado da sua cotação',
      DESCRIPTION: 'mas fique tranquilo que vamos te enviar o resultado da cotação via e-mail o mais breve possível.',
      TEXT_BUTTON: 'Receber cotação por email',
    },
  },
};

export default quotationAsync;
