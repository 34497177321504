import React from 'react';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
import { useStyles } from './MaskInputField.styles';

export default function MaskInputField({
  id,
  mask,
  formatChars,
  label,
  name,
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  onKeyPress,
  onKeyDown,
  autoFocus,
  required,
  error,
  ariaLabel,
  type,
  placeholder,
  autoComplete,
  value,
  disabled,
  helperText,
  disableUnderline,
  inputRef,
  inputProps,
}) {
  const styles = useStyles();
  const _generateElementId = () => id || name;

  const _inputProps = {
    autoFocus,
    disableUnderline,
    role: 'none',
    inputProps: {
      ...inputProps,
      'aria-required': required,
      'aria-label': ariaLabel || label,
      className: `${disabled ? styles.disabled : ''} ${styles.placeholder}`,
    },
  };

  const inputTypeBasedInMask = () => {
    if (type) return type;
    if (!/[a-zA-z/*]/g.test(mask)) return 'tel';
    return 'text';
  };

  return (
    <InputMask
      maskChar=""
      value={value}
      disabled={disabled}
      mask={mask}
      formatChars={formatChars}
      onChange={!disabled && onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {() => (
        <TextField
          id={_generateElementId()}
          type={inputTypeBasedInMask()}
          name={name}
          value={value}
          required={required}
          margin="normal"
          fullWidth
          disabled={disabled}
          placeholder={placeholder || undefined}
          autoFocus={autoFocus || undefined}
          autoComplete={autoComplete || undefined}
          InputLabelProps={{
            shrink: true,
            'aria-hidden': true,
            className: disabled && styles.disabled,
          }}
          error={error}
          InputProps={{ ..._inputProps }}
          helperText={helperText}
          inputRef={inputRef}
          label={label}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          data-testid={`${name}-data-testid`}
        />
      )}
    </InputMask>
  );
}
