/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './styles/_structure.scss';
import './styles/_fonts.scss';
import './styles/_customIcons.scss';
import './utils/axios.request.interceptor';
import './utils/axios.response.interceptor';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import queryString from 'query-string';
import { AnalyticsV2Provider } from 'providers/AnalyticsV2Provider';
import AnalyticsProvider from 'providers/AnalyticsProvider';
import { RemoteConfigProvider } from 'providers/RemoteConfigProvider';
import { CMSProvider } from 'providers/CMSProvider';
import { CashbackProvider } from 'providers/CashbackProvider';
import autoContext from 'store/auto/store/autoContext';
import autoInitStore from 'store/auto/store';
import App from 'app/App';
import ScrollToTop from 'providers/ScrollToTop';
import { Loading } from 'components';
import configure from 'configure';
import i18n from 'i18n';
import config from 'config';
import { getPathnameWithoutTrailingSlash, getSessionStorage, isAuthenticationMode } from 'utils/util';
import { persistor, store } from 'utils/storeElements';
import { chooseHeadTags } from 'utils/headTags';
import { OdontoSelectedPlanSettingsProvider } from './pages/Landings/Odonto/Campaign/Providers/OdontoSelectedPlanSettingsProvider';

export const { autoStore, autoPersistor } = autoInitStore();

const { DISTRIBUTION_KEY, ID_PATH_VALUES, IS_SOURCE_LOCK, LINK_REDIRECT_SOURCE_LOCK, DYNAMICS_OBSERVABILITY_KEY } = config;

(async () => {
  const source = queryString.parse(location.search);
  const { distribution_key, utmsource, utm_source } = source;
  const pathnameWithoutTrailingSlash = getPathnameWithoutTrailingSlash();
  const origin = distribution_key || utm_source || utmsource;
  const currentStore = store.getState();
  const { authScopes } = currentStore;
  const authenticationServiceMode = isAuthenticationMode(authScopes);

  if (IS_SOURCE_LOCK && !authenticationServiceMode) {
    const localSource = getSessionStorage(DISTRIBUTION_KEY) || origin;
    if (!localSource || !ID_PATH_VALUES.includes(localSource)) {
      window.location.href = LINK_REDIRECT_SOURCE_LOCK;
    }
  }

  IntersectionObserver.prototype.POLL_INTERVAL = 100;

  await configure(DYNAMICS_OBSERVABILITY_KEY);

  const rootElement = document.getElementById('root');

  const content = (
    <BrowserRouter>
      <Provider store={store}>
        <Provider store={autoStore} context={autoContext}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <PersistGate loading={<span />} persistor={autoPersistor}>
              {chooseHeadTags(pathnameWithoutTrailingSlash)}
              <RemoteConfigProvider>
                <CMSProvider>
                  <CashbackProvider>
                    <AnalyticsV2Provider>
                      <AnalyticsProvider>
                        <ScrollToTop>
                          <I18nextProvider i18n={i18n}>
                            <OdontoSelectedPlanSettingsProvider>
                              <App translator={i18n} />
                            </OdontoSelectedPlanSettingsProvider>
                          </I18nextProvider>
                        </ScrollToTop>
                      </AnalyticsProvider>
                    </AnalyticsV2Provider>
                  </CashbackProvider>
                </CMSProvider>
              </RemoteConfigProvider>
            </PersistGate>
          </PersistGate>
        </Provider>
      </Provider>
    </BrowserRouter>
  );

  if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(content, rootElement);
  } else {
    ReactDOM.render(content, rootElement);
  }

  window.snapSaveState = () => {
    const AppElement = document.querySelector('.App');

    Array.from(document.querySelectorAll('style')).forEach(
      (ell) => ell && ell.setAttribute('data-server-rendered', 'true')
    );

    if (AppElement) {
      AppElement.setAttribute('data-server-rendered', 'true');
    }
  };

  if (navigator.userAgent !== 'ReactSnap') {
    Array.from(document.querySelectorAll('style')).forEach((ell) => {
      if (ell && ell.getAttribute('data-server-rendered')) {
        ell.parentElement.removeChild(ell);
      }
    });
  }
})();
