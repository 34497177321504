import axios from 'axios';

import config, { performance } from '../../config';

const { SEGUROS_GATEWAY_APP_KEY, GATEWAY_PATH } = config;

export const getProducts = async () => {
  const trace = performance.trace('getProducts');
  trace.start();
  const response = await axios.get(
    `${GATEWAY_PATH}/producttypes?gw-app-key=${SEGUROS_GATEWAY_APP_KEY}&status=APPROVED`,
  );
  trace.stop();
  return response.data;
};
