/* eslint-disable no-plusplus,camelcase */
export const Types = {
  SET_RESID_QUOTATION: 'residQuotation/SET_RESID_QUOTATION',
  ADD_PROPONENT: 'residQuotation/ADD_PROPONENT',
  UPDATE_SELECTED_COVERAGES: 'residQuotation/UPDATE_SELECTED_COVERAGES',
  START_SELECTED_COVERAGES: 'residQuotation/START_SELECTED_COVERAGES',
  SET_OWNER_INFO: 'residQuotation/SET_OWNER_INFO',
  SET_REAL_ESTATE_INFO: 'residQuotation/SET_REAL_ESTATE_INFO',
  SET_PAYMENT_METHOD: 'residQuotation/SET_PAYMENT_METHOD',
  SET_PAYMENT_INSTALLMENTS: 'residQuotation/SET_PAYMENT_INSTALLMENTS',
  UPDATE_SELECTED_ASSIST: 'residQuotation/UPDATE_SELECTED_ASSIST',
  START_SELECTED_ASSIST: 'residQuotation/START_SELECTED_ASSIST',
  RESET: 'residQuotation/RESET',
  ADD_PAYMENT_FORM: 'residQuotation/ADD_PAYMENT_FORM',
  ADD_IDS_QUESTIONS: 'residQuotation/ADD_IDS_QUESTIONS',
};

export const initialState = {
  selectedInstallment: {
    valor_premio: 50.64,
    id_opcao_pagamento: '6',
    recorrencia: false,
    metodo: 'credit_card',
    parcelas: 6,
    valor_parcela: 50.64,
    valor_total: 303.79,
    variante: 'padrao',
  },
  proponente: {
    endereco: {},
    documento: {},
    aceita_contato_por_telefone: true
  },
  dados_opcao_pagamento: {},
  coberturas: [],
  assistencia: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESID_QUOTATION: {
      return {
        ...action.data,
      };
    }
    case Types.ADD_PROPONENT: {
      return {
        ...state,
        proponente: {
          ...state.proponente,
          ...action.data,
        },
      };
    }
    case Types.ADD_PAYMENT_FORM: {
      return {
        ...state,
        dados_opcao_pagamento: {
          ...state.dados_opcao_pagamento,
          ...action.data,
          conta_bancaria: action.data.cartao_credito ? undefined : action.data.conta_bancaria,
          cartao_credito: action.data.conta_bancaria ? undefined : action.data.cartao_credito,
        },
      };
    }
    case Types.SET_PAYMENT_INSTALLMENTS: {
      return {
        ...state,
        selectedInstallment: action.data,
        valor_premio: action.data.valor_premio,
      };
    }
    case Types.START_SELECTED_COVERAGES: {
      const totalPrice = action.data.reduce((acc, item) => acc + item.valor, 0);
      return {
        ...state,
        valor_premio: totalPrice,
        coberturas: action.data,
      };
    }
    case Types.SET_OWNER_INFO: {
      return {
        ...state,
        dados_imovel: action.data.address,
        proponente: {
          ...state.proponente,
          cpf: action.data.owner.cpf,
          data_nascimento: action.data.owner.birthdate,
          nome: action.data.owner.name,
          aceita_contato_por_telefone: action.data.owner.receiveContactByPhone,
        },
      };
    }
    case Types.SET_REAL_ESTATE_INFO: {
      return {
        ...state,
        ocupado_imovel: parseInt(action.data[0]),
        material_imovel: parseInt(action.data[1]),
        condominio_imovel: parseInt(action.data[2]),
        tipo_imovel: parseInt(action.data[3]),
        tipo_ocupacao_imovel: action.data[4],
        tipo_imovel_texto: action.data[5],
      };
    }
    case Types.UPDATE_SELECTED_COVERAGES: {
      const { id_cobertura } = action.data;
      let newCoverages = [];
      const index = state.coberturas.findIndex(item => item.id_cobertura === id_cobertura);
      if (index === -1) {
        newCoverages.push(...state.coberturas, action.data);
      } else {
        newCoverages = state.coberturas.filter((_, i) => i !== index);
      }
      const totalPrice = state.coberturas.reduce((acc, item) => acc + item.valor, 0);
      return {
        ...state,
        valor_premio: totalPrice,
        coberturas: newCoverages,
      };
    }
    case Types.UPDATE_SELECTED_ASSIST: {
      const { id } = action.data;
      let newAssist = {};
      const selected = state.assistencia && state.assistencia.id === id;
      if (selected) {
        newAssist = {};
      } else {
        newAssist = action.data;
      }
      return {
        ...state,
        assistencia: newAssist,
      };
    }
    case Types.START_SELECTED_ASSIST: {
      return {
        ...state,
        assistencia: action.data,
      };
    }
    case Types.SET_PAYMENT_METHOD: {
      return {
        ...state,
        opcoes_pagamento: action.data,
      };
    }
    case Types.RESET: {
      return initialState;
    }
    case Types.ADD_IDS_QUESTIONS: {
      const questions = [
        { id_questions: action.data[0][0], resposta: action.data[1][0] },
        { id_questions: action.data[0][1], resposta: action.data[1][1] },
        { id_questions: action.data[0][2], resposta: action.data[1][2] },
        { id_questions: action.data[0][3], resposta: action.data[1][3] },
        { id_questions: action.data[0][4], resposta: action.data[1][4] },
      ];
      return {
        ...state,
        questions_ids: questions,
      };
    }
    default:
      return state;
  }
}

export function setResidQuotation(data) {
  return {
    type: Types.SET_RESID_QUOTATION,
    data,
  };
}

export function addProponent(data) {
  return { type: Types.ADD_PROPONENT, data };
}

export function addPaymentForm(data) {
  return { type: Types.ADD_PAYMENT_FORM, data };
}

export function updateSelectCoverages(data) {
  return { type: Types.UPDATE_SELECTED_COVERAGES, data };
}

export function updateSelectedAssists(data) {
  return { type: Types.UPDATE_SELECTED_ASSIST, data };
}

export function startSelectedAssist(data) {
  return { type: Types.START_SELECTED_ASSIST, data };
}

export function addLandInfo(data) {
  return { type: Types.SET_OWNER_INFO, data };
}

export function addLandRealEstateInfo(data) {
  return { type: Types.SET_REAL_ESTATE_INFO, data };
}

export function startSelectCoverages(data) {
  return { type: Types.START_SELECTED_COVERAGES, data };
}

export function setPaymentMethod(data) {
  return { type: Types.SET_PAYMENT_METHOD, data };
}

export function updatePaymentInstallments(data) {
  return { type: Types.SET_PAYMENT_INSTALLMENTS, data };
}

export function resetResidQuotation() {
  return { type: Types.RESET };
}

export function addIdsQuestions(data) {
  return { type: Types.ADD_IDS_QUESTIONS, data };
}
