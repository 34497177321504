import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_ENVIRONMENTS,
} from 'trackingV2/constants';

export const ANALYTICS_ODONTO_HOME_FLOW = {
  T1: {
    nome: 'LS:NL:NCC:Home:SeguroOdontologico',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:Destaque:{0}:{1}',
    noInteraction: false,
  },
  A2: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:Cotacao:{0}:{1}DetalhesDoPlano',
    noInteraction: false,
  },
  A3: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:Cotacao:{0}:QueroEssePlano',
    noInteraction: false,
  },
  A4: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:SiteMetLife',
    noInteraction: false,
  },
  A5: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:RedeCredenciada',
    noInteraction: false,
  },
  A6: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:ResumoDosPlanos:{0}:{1}',
    noInteraction: false,
  },
  A7: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:ExpandirPerguntaFrequente:{0}',
    noInteraction: false,
  },
  A8: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:VerMaisPerguntasFrequentes',
    noInteraction: false,
  },
  A9: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:Cotacao:{0}:TrocarPorOutro',
    noInteraction: false,
  },
  A10: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:Cotacao:{0}:Buscar:{1}',
    noInteraction: false,
  },
  A11: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'Alerta:Home:Odonto:Cotacao:{0}:Buscar:Sucesso',
    noInteraction: false,
  },
  A12: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'Alerta:Home:Odonto:Cotacao:{0}:Buscar:NaoAtendemosEssaRegiao',
    noInteraction: false,
  },
  A13: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:Cotacao:{0}:Buscar:TentarOutroCep',
    noInteraction: false,
  },
  A14: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Odonto:Cotacao:{0}:VerMaisCoberturas',
    noInteraction: false,
  },
};
