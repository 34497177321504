import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export default createBreakpoints({
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 1024,
    xl: 1400,
  },
});
