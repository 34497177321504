import { call, put } from 'redux-saga/effects';
import { getSurveyDate } from 'api/E2E/Auto/getSurveyDate';
import { Types } from 'store/auto/store/ducks/autoFlow';
import { createHouseholdCustomService } from 'store/auto/store/ducks/inspection';
import { setError, setErrorSagaAction } from 'store/ducks/httpErrors';

export default function* getInspectionDateAction(values) {
  const { cep, id_parceiro, hashIn } = values.data;
  try {
    const response = yield call(getSurveyDate, cep, id_parceiro, hashIn);
    yield put(createHouseholdCustomService(response));
  } catch (err) {
    yield put({ type: Types.ERROR_INSPECTION });
    yield put(setError());
    yield put(setErrorSagaAction(values));
  }
}
