/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { AnalyticsContext } from 'providers/AnalyticsProvider';
import './_App.scss';
import { createTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import smoothscroll from 'smoothscroll-polyfill';
import { connect } from 'react-redux';
import Content from 'content/Content';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AllowedChannelsProvider } from 'providers/AllowedChannelsProvider';
import Header from 'layout/Header';
import { Creators } from 'store/ducks/products';
import { getSessionStorage, setSessionStorage, getPathnameWithoutTrailingSlash } from 'utils/util';
import { redirectURL } from 'utils/url';
import { generateDynamicConfigBySource } from 'pages/Welcome/routes/routes';
import { ProjectContextProvider } from 'pages/Welcome/providers/ProjectContextProvider';
import theme from 'theme';
import resources from 'store/resources';
import withZupNext from 'hoc/withZupNext';
import ModalsWrapper from 'components/ModalsWrapper/ModalsWrapper';
import { Message } from 'components';
import Footer from 'layout/Footer/Footer';
import Welcome from 'pages/Welcome';
import MocksPanel from 'sections/MocksPanel';
import config from 'config';
import ROUTES from 'routes/routes';
import { ANALYTICS_APP_FLOW } from 'tracking';
import { useCMSProviderConsumer } from 'providers/CMSProvider';
import { useCashbackProviderConsumer } from 'providers/CashbackProvider';
import { chooseHeadTags } from '../utils/headTags';
import AcceptCookies from '../layout/AcceptCookies';

// Scroll IE11 polyfill
smoothscroll.polyfill();

const {
  DISTRIBUTION_KEY,
  SOURCE_KEY,
  MEDIUM_KEY,
  CAMPAIGN_KEY,
  DEFAULT_ID_PATH_VALUE,
  DEFAULT_SOURCE_VALUE,
  DEFAULT_MEDIUM_VALUE,
  DEFAULT_CAMPAIGN_VALUE,
  ENABLE_GOOGLE_ANALYTICS,
  TEMPORARY_CHANNELS,
} = config;

const currentTheme = createTheme({
  ...theme,
});

const Body = React.memo(({ partner, quotation, allowedChannels, origin, loading }) => (
  // TODO FORNECE A INFORMAÇÃO DE QUAL CANAL ESTÁ ATIVADO
  <AllowedChannelsProvider allowedChannels={allowedChannels} origin={origin}>
    <div className="content-wrapper">
      <Header />
      <div className="content" role="main">
        <Content partner={partner} userHasQuotation={quotation.id_produto != null} loading={loading} />
        <AcceptCookies />
      </div>
      <Message />
      <Footer />
      {process.env.REACT_APP_ENVIRONMENT !== 'production' && <MocksPanel />}
    </div>
  </AllowedChannelsProvider>
));

Body.propTypes = {
  partner: PropTypes.shape({
    create: PropTypes.shape({
      error: PropTypes.any,
      status: PropTypes.string,
    }),
    data: PropTypes.shape({
      auto: PropTypes.any,
      odonto: PropTypes.any,
      residencial: PropTypes.any,
    }),
    load: PropTypes.shape({
      error: PropTypes.any,
      status: PropTypes.string,
    }),
    remove: PropTypes.shape({
      error: PropTypes.any,
      status: PropTypes.string,
    }),
    update: PropTypes.shape({
      error: PropTypes.any,
      status: PropTypes.string,
    }),
  }),
  quotation: PropTypes.any,
};

function prepareRedirectParams(search) {
  const urlParams = new URLSearchParams(search);
  urlParams.delete('distribution_key')
  urlParams.delete('utmsource')
  urlParams.delete('utm_source')
  return urlParams.toString();
}

function App({ partner, quotation, httpErrors, modal, allowedChannels, loadProductsLeadsAction }) {
  const { REACT_APP_ENVIRONMENT } = process.env;
  const location = useLocation();
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const source = queryString.parse(history.location.search);
  const { distribution_key, utmsource, utm_source, utm_medium, utm_campaign } = source;

  const distKey = distribution_key || utm_source || utmsource;
  const hasDistKey = distKey !== undefined;

  const { pathname } = history.location;
  const { getPageContent, loading } = useCMSProviderConsumer();
  const productPathname = `/${pathname.split('/')[1]}`;
  const { getCashbackConfig } = useCashbackProviderConsumer();

  useEffect(() => {
    getCashbackConfig();
  }, []);

  useEffect(() => {
    getPageContent(productPathname);
  }, [productPathname]);

  useEffect(() => {
    analytics.sendWebVitals();
  }, []);

  let allowedChannelsList = [];

  if (REACT_APP_ENVIRONMENT !== 'testenv') {
    allowedChannelsList = Object.values(allowedChannels?.data || {}).reduce((acc, item) => {
      item.map(i => {
        if (acc.indexOf(i) === -1) acc.push(i);
        return undefined;
      });
      return acc;
    });
  }

  let temporaryAllowedChannelsList;

  if (TEMPORARY_CHANNELS) {
    temporaryAllowedChannelsList = Object.values(TEMPORARY_CHANNELS).reduce((acc, item) => {
      item.map(i => {
        if (acc.indexOf(i) === -1) acc.push(i);
        return undefined;
      });
      return acc;
    });
  }

  const origin =
    (temporaryAllowedChannelsList && temporaryAllowedChannelsList.includes(distKey) && distKey) ||
    (allowedChannelsList.includes(distKey) && distKey) ||
    getSessionStorage(DISTRIBUTION_KEY) ||
    DEFAULT_ID_PATH_VALUE;

  const sourceUtm = utm_source || utmsource || getSessionStorage(SOURCE_KEY) || DEFAULT_SOURCE_VALUE;
  const medium = utm_medium || getSessionStorage(MEDIUM_KEY) || DEFAULT_MEDIUM_VALUE;
  const campaign = utm_campaign || getSessionStorage(CAMPAIGN_KEY) || DEFAULT_CAMPAIGN_VALUE;

  setSessionStorage(DISTRIBUTION_KEY, origin);
  setSessionStorage(SOURCE_KEY, sourceUtm);
  setSessionStorage(MEDIUM_KEY, medium);
  setSessionStorage(CAMPAIGN_KEY, campaign);

  const params = prepareRedirectParams(history.location.search);

  useEffect(() => {
    if (ENABLE_GOOGLE_ANALYTICS && !hasDistKey) {
      analytics.logEvent({ ...ANALYTICS_APP_FLOW.ORIGIN, value: [origin] });
    }
    loadProductsLeadsAction();
  }, []);

  if (hasDistKey) {
    if (ENABLE_GOOGLE_ANALYTICS) {
      analytics.logEvent({ ...ANALYTICS_APP_FLOW.ORIGIN, value: [origin] });
    }

    const pathToRedirect = redirectURL({
      base: {
        path: pathname || ROUTES.HOME,
        distributionkey: distKey,
        parameters: source,
        product: utm_medium,
      },
      routes: ROUTES,
    });
    history.push({ pathname: pathToRedirect, search: params });
  }

  const welcomeConfig = generateDynamicConfigBySource(origin);
  const isWelcomeRoute =
    welcomeConfig && window.location.pathname.includes(welcomeConfig.route) && location.pathname !== ROUTES.CANCEL;
  if (isWelcomeRoute) {
    return <Welcome currentProject={origin} />;
  }

  const pathnameWithoutTrailingSlash = getPathnameWithoutTrailingSlash();

  return (
    <div className="App">
      {chooseHeadTags(pathnameWithoutTrailingSlash, productPathname)}
      <ProjectContextProvider currentProject={origin}>
        <MuiThemeProvider theme={currentTheme}>
          <CssBaseline />
          <Body
            partner={partner}
            quotation={quotation}
            hasError={httpErrors.hasError}
            disableGlobalError={httpErrors.disableGlobalError}
            origin={origin}
            allowedChannels={allowedChannels} // TODO ESSA LISTA VEM DO CMS
            loading={loading}
          />
          <ModalsWrapper modal={modal} />
        </MuiThemeProvider>
      </ProjectContextProvider>
    </div>
  );
}

const mapStateToProps = ({ partner, quotation, httpErrors, allowedChannels, modal }) => ({
  partner,
  quotation,
  httpErrors,
  allowedChannels,
  modal,
});

const actions = {
  loadPartners: resources.partner.actions.load,
  loadAllowedChannels: resources.allowedChannels.actions.load,
  loadProductsLeadsAction: Creators.loadProductsLeads,
};

const enhance = compose(connect(mapStateToProps, actions), withZupNext);

App.propTypes = {
  partner: PropTypes.shape({
    create: PropTypes.shape({
      error: PropTypes.any,
      status: PropTypes.string,
    }),
    data: PropTypes.shape({
      auto: PropTypes.any,
      odonto: PropTypes.any,
      residencial: PropTypes.any,
    }),
    load: PropTypes.shape({
      error: PropTypes.any,
      status: PropTypes.string,
    }),
    remove: PropTypes.shape({
      error: PropTypes.any,
      status: PropTypes.string,
    }),
    update: PropTypes.shape({
      error: PropTypes.any,
      status: PropTypes.string,
    }),
  }),
  quotation: PropTypes.any,
};

export default enhance(App);
