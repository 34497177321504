const common = {
  ALERT_PEP_PLD: {
    TEXT1:
      'Informamos que por razão dos critérios de análise desta corretora e de acordo com termos da legislação vigente,\n' +
      '  não podemos seguir com a proposta de contratação do produto que você solicitou na Loja de Seguros Itaú.',
    TEXT2: 'Se houver qualquer dúvida, entre em contato pelos telefones localizados no rodapé.',
    TEXT3: 'Atenciosamente,',
    TEXT4: 'Equipe Loja de Seguros Itaú',
  },
  FAQ_CARD: 'Carregado',
};

export default common;
