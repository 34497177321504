const promotionInDoorModule = {
  PROMOTIONINDOOR: {
    BANNER: {
      TITLE: 'Porto Seguro Residência',
      SUBTITLE: 'cuide do seu lar e conte com várias assistências',
      DEADLINE: 'Corre que esse preço levinho é de 10 a 11/03!',
      CTA: 'faça sua cotação',
    },
  },
};

export default promotionInDoorModule;
