const authModule = {
  AUTH: {
    SECOND_STEP: {
      TITLE: '**validando** seu número de celular',
      FIRST_STEP_TITLE: '**passo 1 concluido**',
      FIRST_STEP_SUBTITLE: 'e-mail autenticado',
      SECOND_STEP_TITLE: '**passo 2**',
      SECOND_STEP_SUBTITLE: 'validando seu número de celular',
      INFORMATION_TEXT:
        'enviamos um SMS para o número de celular informado.  \nagora é só preencher o código recebido no campo abaixo.',
      DIDNT_RECEIVE_TEXT: 'não recebeu ?',
      DIDNT_RECEIVE_ACTION: 'reenviar código',
      DIDNT_RECEIVE_ACTION_WAIT: 'reenviar código em {{seconds}}',
      SMS_RESEND_SUCCESS: 'o SMS foi reenviado com sucesso',
      GENERIC_ERROR: 'ocorreu um erro, tente novamente mais tarde',
      VALIDATE_SMS_ERROR: 'o código digitado é inválido',
      CONTINUE: 'continuar',
      LOAD_PAGE_ERROR_TITLE: 'ocorreu um erro',
      LOAD_PAGE_ERROR_SUBTITLE: 'o link que você está tentando usar é inválido ou expirou',
      LOAD_PAGE_ERROR_INFO: 'por favor, refaça a operação',
      SMS_RETRIES_ERROR_TITLE: 'não foi possivel autenticar',
      SMS_RETRIES_ERROR_SUBTITLE: 'você precisará se autenticar novamente por email',
    },
  },
};

export default authModule;
