import React, { useState } from 'react';
import { Grid, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import DarkToolTip from 'components/DarkTooltip';
import { useStyles, Slider } from './numericalSlider.styles';
import './index.scss';

function NumericalSlider({
  ariaLabel,
  options,
  onChange,
  id,
  tooltipProps,
  describeCurrentValue,
  required,
  sliderInitialValue,
}) {
  const styles = useStyles();
  const defaultInitialValue = 1;
  const defaultValue = sliderInitialValue || defaultInitialValue;
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const minValue = options[0].value;
  const maxValue = options.length;

  function setInputCurrentValue(e) {
    const { value } = e.target;
    setCurrentValue(value);
    onChange(value);
  }

  return (
    <>
      <Grid item className={styles.containerText}>
        <FormLabel className={styles.text} required={required}>
          {ariaLabel}
        </FormLabel>
        <DarkToolTip {...tooltipProps} placement="top" darkBackground darkIcon />
      </Grid>

      <Grid item lg={6} sm={12} className="NumericalSlider">
        <input
          type="range"
          aria-label={ariaLabel}
          className={styles.input}
          aria-describedby="numerical-slider-range-value"
          aria-valuetext={`${describeCurrentValue} ${currentValue.toString()} `}
          aria-valuemin={options[0].value}
          aria-valuemax={maxValue}
          onChange={setInputCurrentValue}
          min={options[0].value}
          step={1}
          max={maxValue}
          value={currentValue}
        />
        <span id="numerical-slider-range-value" hidden>
          <span id="numerical-slider-min-value">mínimo: {minValue}</span>
          <span id="numerical-slider-max-value">máximo: {maxValue}</span>
        </span>
        <div aria-hidden>
          <Slider
            step={1}
            id={id}
            value={currentValue}
            marks={options}
            min={minValue}
            max={maxValue}
            valueLabelDisplay="off"
          />
        </div>
      </Grid>
    </>
  );
}

NumericalSlider.propTypes = {
  ariaLabel: PropTypes.any,
  options: PropTypes.any,
};

export default NumericalSlider;
