const productsResearch = {
  LIFE_INSURANCE_RESEARCH: {
    LOAD_ALERT: 'Janela de seguro de vida carregada.',
    MAIN: {
      TITLE: 'Qual é o Seguro de Vida ideal para você?',
      SUBTITLE: 'Escolha a melhor opção de seguro de vida de acordo com suas necessidades.',
      RESEARCHS: {
        AWARDABLE: {
          TITLE: 'vida resgatável + premiável',
          DESCRIPTION:
            'A opção para de amparar e com as vantagens de você resgatar seu dinheiro investido e ainda concorrer a premiações.',
          START_BUTTON_TEXT: 'saiba mais',
        },
        CAPITALIZATION: {
          TITLE: 'vida + capitalização',
          DESCRIPTION: 'Nessa opção seguro de vida é  possível a realização de um resgate do valor investido.',
          START_BUTTON_TEXT: 'saiba mais',
        },
        COVERAGES: {
          TITLE: 'vida simples + coberturas',
          DESCRIPTION:
            'A opção de seguro de vida para você adaptar conforme suas necessidades e com um valor que cabe no seu bolso.',
          START_BUTTON_TEXT: 'saiba mais',
        },
      },
    },
    RESEARCHS: {
      AWARDABLE: {
        TITLE: 'Seguro de Vida Resgatável + Premiável',
        PAGES: [
          {
            TITLE: 'Para começar, nos diga qual é sua faixa etária:',
            DESCRIPTION:
              'Um seguro de Vida resgatável é aquele que te deixa protegido de um imprevisto e ainda permite que você consiga resgatar, após um período determinado, o valor investido parcialmente no seguro, **caso não haja abertura de sinistros.**',
            CONTENT: {
              OPTIONS: ['18 a 35 anos', '36 a 45 anos', '46 a 55 anos', '56 a 65 anos'],
            },
          },
          {
            TITLE: 'Baseado na sua idade, esta é a opção de sorteio disponível e o valor de investimento mensal.',
            BACK_BUTTON: 'alterar faixa etária',
            CONTENT: {
              ITEMS: [
                { TITLE: 'Valor do seguro', INFORMATION: 'R$ {{insuranceValue}} em caso de sinistro' },
                { TITLE: 'Sorteios', INFORMATION: 'R$ {{drawValue}} semestrais + sorteios mensais' },
                { TITLE: 'Investimento mensal', INFORMATION: 'R$ {{monthlyInvestment}}' },
              ],
            },
          },
          {
            TITLE: 'Agora vamos calcular o valor do resgate do seu Seguro. Em quanto tempo deseja resgatar?',
            CONTENT: {
              OPTIONS: [
                {
                  LABEL: '60 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '48 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '36 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '24 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '12 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
              ],
            },
          },
          {
            TITLE: 'Qual das opções abaixo de resgate dos valores investidos mais atende sua expectativa?',
            CONTENT: {
              OPTIONS: [
                { LABEL: 'capitalização', DESCRIPTION: 'Sorteios semanais e resgate 100% do valor após período' },
                {
                  LABEL: 'fundo investimento',
                  DESCRIPTION: 'Resgate os lucros de um fundo de investimentos após período.',
                },
                { LABEL: 'IPCA', DESCRIPTION: 'Seu dinheiro é corrigido de acordo com o índice IPCA' },
                { LABEL: 'Outro', DESCRIPTION: 'A opção que você procura não está aqui' },
              ],
            },
          },
          {
            TITLE: 'Deseja auxiliar na construção deste produto?',
            SUBTITLE:
              'Deixe seu email e ou telefone abaixo para que possamos entrar em contato para apresentarmos e discutirmos as opções que melhor atendem a você.',
            DESCRIPTION:
              'No momento, a opção de **Seguro de Vida Resgatável + Premiável** não está disponível. Contudo, sua opção neste formulário nos ajudará a criar um seguro de vida que se adeque à sua necessidade.',
          },
        ],
      },
      CAPITALIZATION: {
        TITLE: 'Seguro de Vida + Capitalização',
        PAGES: [
          {
            TITLE: 'Qual foi o motivo de você escolher um Seguro de Vida com Capitalização?',
            CONTENT: {
              OPTIONS: [
                'Porque Capitalização me dá a chance de ganhar dinheiro semanalmente, e ainda fico protegido.',
                'Porque é uma forma simples de guardar dinheiro durante a vigência do contrato do Seguro',
                'Porque Capitalização sempre é indicada pelo gerente do meu banco.',
                'Outro motivo que não está na lista.',
              ],
            },
          },
          {
            TITLE: 'Para começar, nos diga qual é sua faixa etária:',
            DESCRIPTION:
              'Um seguro de Vida resgatável é aquele que te deixa protegido de um imprevisto e ainda permite que você consiga resgatar, após um período determinado, o valor investido parcialmente no seguro, **caso não haja abertura de sinistros**',
            CONTENT: {
              OPTIONS: ['18 a 35 anos', '36 a 45 anos', '46 a 55 anos', '56 a 65 anos'],
            },
          },
          {
            TITLE: 'Baseado na sua idade, esta é a opção de sorteio disponível e o valor de investimento mensal.',
            BACK_BUTTON: 'alterar faixa etária',
            CONTENT: {
              ITEMS: [
                { TITLE: 'Valor do seguro', INFORMATION: 'R$ {{insuranceValue}} em caso de sinistro' },
                { TITLE: 'Sorteios', INFORMATION: 'R$ {{drawValue}} semestrais + sorteios mensais ' },
                { TITLE: 'Investimento mensal', INFORMATION: 'R$ {{monthlyInvestment}}' },
              ],
            },
          },
          {
            TITLE: 'Agora vamos calcular o valor do seu resgate. Em quanto tempo deseja resgatar?',
            CONTENT: {
              OPTIONS: [
                {
                  LABEL: '60 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '48 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '36 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '24 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '12 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
              ],
            },
          },
          {
            TITLE: 'O que você preferiria ganhar em sorteios vinculados ao seu Seguro de Vida?',
            CONTENT: {
              OPTIONS: [
                { LABEL: 'dinheiro', DESCRIPTION: 'Sorteios semanais em dinheiro enquanto estiver segurado.' },
                { LABEL: 'descontos', DESCRIPTION: 'Descontos periódicos em mensalidades e benefícios' },
                { LABEL: 'prêmios', DESCRIPTION: 'Prêmios de bens de consumo, viagens, etc.' },
                { LABEL: 'Outro', DESCRIPTION: 'Se você não se interessou por nenhum, deixe-nos saber' },
              ],
            },
          },
          {
            TITLE: 'Deseja auxiliar na construção deste produto?',
            SUBTITLE:
              'Deixe seu email e ou telefone abaixo para que possamos entrar em contato para apresentarmos e discutirmos as opções que melhor atendem a você.',
            DESCRIPTION:
              'No momento, a opção de **Seguro de Vida + Capitalização** não está disponível. Contudo, sua opção neste formulário nos ajudará a criar um seguro de vida que se adeque à sua necessidade.',
          },
        ],
      },
      COVERAGES: {
        TITLE: 'Seguro Simples + Coberturas',
        PAGES: [
          {
            TITLE: 'Selecione até 8 coberturas ou assistências adicionais que você deseja no seu Seguro de Vida:',
            DESCRIPTION:
              'É um Seguro de Vida Modular, que permite que você monte um plano acessível, conforme suas necessidades.',
            CONTENT: {
              OPTIONS: [
                {
                  LABEL: 'diária por incapacidade temporária',
                  INFO: 'você recebe valor por dia caso fique incapaz de exercer suas atividades habituais',
                },
                {
                  LABEL: 'Indenização especial para morte acidental',
                  INFO: 'caso você venha a falecer por motivo de acidente, o valor da indenização será dobrado',
                },
                {
                  LABEL: 'cônjuge e filhos',
                  INFO: 'cobertura que extende os benefícios do seguro para cônjuge e filhos',
                },
                { LABEL: 'dias de internação', INFO: 'caso você fique internado, você recebe valor por dia' },
                {
                  LABEL: 'check up médico',
                  INFO: 'assistência que dá direito a fazer um check up médico por ano, com direito a exames básicos',
                },
                {
                  LABEL: 'invalidez por acidente',
                  INFO: 'recebimento de indenização caso você venha a ficar inválido em decorrência de acidente',
                },
                {
                  LABEL: 'invalidez por doença',
                  INFO: 'recebimento de indenização caso você venha a ficar inválido em decorrência de alguma doença',
                },
                {
                  LABEL: 'viagem',
                  INFO:
                    'serviços para viagem como: despesas médicas, com medicamentos no exterior, atraso de bagagem, etc',
                },
                { LABEL: 'sorteios', INFO: 'concorrer a sorteios todo mês, com diversos prêmios' },
                { LABEL: 'desconto farmácia', INFO: 'programa que prevê descontos nas principais redes de farmácia' },
                {
                  LABEL: 'segunda opção médica',
                  INFO:
                    'relativa a doença grave, emitido por médicos especialistas de renomados hospitais internacionais',
                },
                {
                  LABEL: 'doenças graves',
                  INFO:
                    'essa cobertura dá direito a indenização em caso de diagnóstico de alguma doença grave (ex: câncer)',
                },
                {
                  LABEL: 'orientação nutricional',
                  INFO: 'assistência que dá direito a uma teleconsulta para orientações nutricionais',
                },
                {
                  LABEL: 'assistência funeral',
                  INFO:
                    'serviços como contratar funerária, escolher o caixão, reservar velório e sepultamento, transporte do corpo',
                },
                {
                  LABEL: 'assistência pet',
                  INFO:
                    'serviços como consultas emergenciais, cirurgias, consultas com especialistas, vacinação, exames',
                },
                {
                  LABEL: 'assistência veicular',
                  INFO:
                    'serviços como reboque, troca de pneus, pane seca, transporte alternativo, autosocorro após pane',
                },
                {
                  LABEL: 'assistência bike',
                  INFO:
                    'serviços como montagem de bike, troca e conserto de pneus, troca de correntes, falta de freios',
                },
                {
                  LABEL: 'assistência domiciliar',
                  INFO: 'serviços como chaveiro, encanador, eletrecista, vidraceiro, etc',
                },
                { LABEL: 'Spotify', INFO: 'direito a desconto na mensalidade da plataforma' },
              ],
            },
          },
          {
            TITLE: 'O que você preferiria ganhar em sorteios vinculados ao seu Seguro de Vida?',
            CONTENT: {
              OPTIONS: [
                { LABEL: 'dinheiro', DESCRIPTION: 'Sorteios semanais em dinheiro enquanto estiver segurado.' },
                { LABEL: 'descontos', DESCRIPTION: 'Descontos periódicos em mensalidades e benefícios' },
                { LABEL: 'prêmios', DESCRIPTION: 'Prêmios de bens de consumo, viagens, etc.' },
                { LABEL: 'Outro', DESCRIPTION: 'Se você não se interessou por nenhum, deixe-nos saber' },
              ],
            },
          },
          {
            TITLE: 'Para começar, nos diga qual é sua faixa etária:',
            DESCRIPTION:
              'Um seguro de Vida resgatável é aquele que te deixa protegido de um imprevisto e ainda permite que você consiga resgatar, após um período determinado, o valor investido parcialmente no seguro, **caso não haja abertura de sinistros**',
            CONTENT: {
              OPTIONS: ['18 a 35 anos', '36 a 45 anos', '46 a 55 anos', '56 a 65 anos'],
            },
          },
          {
            TITLE: 'Baseado na sua idade, esta é a opção de sorteio disponível e o valor de investimento mensal.',
            BACK_BUTTON: 'alterar faixa etária',
            CONTENT: {
              ITEMS: [
                { TITLE: 'Valor do seguro', INFORMATION: 'R$ {{insuranceValue}} em caso de sinistro' },
                { TITLE: 'Sorteios', INFORMATION: 'R$ {{drawValue}} semestrais + sorteios mensais ' },
                { TITLE: 'Investimento mensal', INFORMATION: 'R$ {{monthlyInvestment}}' },
              ],
            },
          },
          {
            TITLE: 'Agora vamos calcular o valor do seu resgate. Em quanto tempo deseja resgatar?',
            CONTENT: {
              OPTIONS: [
                {
                  LABEL: '60 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '48 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '36 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '24 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
                {
                  LABEL: '12 meses',
                  INFORMATION: [
                    { TITLE: 'Porcentagem de resgate', INFORMATION: '{{rescuePercentage}}% do valor investido' },
                    { TITLE: 'Valor do resgate', INFORMATION: 'R$ {{rescueValue}} ao final do período' },
                  ],
                },
              ],
            },
          },
          {
            TITLE: 'Deseja auxiliar na construção deste produto?',
            SUBTITLE:
              'Deixe seu email e ou telefone abaixo para que possamos entrar em contato para apresentarmos e discutirmos as opções que melhor atendem a você.',
            DESCRIPTION:
              'No momento, a opção de **Seguro de Vida Simples + Coberturas** não está disponível. Contudo, sua opção neste formulário nos ajudará a criar um seguro de vida que se adeque à sua necessidade.',
          },
        ],
      },
    },
  },
};

export default productsResearch;
