import homeModule from './home/pt-BR';
import autoModule from './auto/pt-BR';
import residModule from './resid/pt-BR';
import odontoModule from './odonto/pt-BR';
import blackFridayModule from './blackFriday/pt-BR';
import promotionInDoorModule from './promotionInDoor/pt-BR';
import faqModule from './faq/pt-BR';
import common from './common/pt-BR';
import componentsModule from './components/pt-BR';
import sections from './sections/pt-BR';
import welcomeModule from './welcome/landpage/pt-BR';
import cancellationModule from './cancellation/pt-BR';
import receipt from './receipt/pt-BR';
import productsResearch from './productsResearch/pt-BR';
import authModule from './auth/pt-BR';
import realizaModule from './realiza/pt-BR';
import comparative from './auto/comparativeQuotationMobile/pt-BR';
import carglassModule from './carglass/pt-BR';
import asyncQuotationModule from './asyncQuotation/pt-BR';

const ptBR = {
  ...common,
  ...componentsModule,
  ...sections,
  ...autoModule,
  ...residModule,
  ...odontoModule,
  ...faqModule,
  ...welcomeModule,
  ...cancellationModule,
  ...receipt,
  ...productsResearch,
  ...authModule,
  ...realizaModule,
  ...comparative,
  ...blackFridayModule,
  ...promotionInDoorModule,
  ...homeModule,
  ...carglassModule,
  ...asyncQuotationModule,
};

export default ptBR;
