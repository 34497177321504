import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { Markdown } from 'components';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import { clean } from 'utils/string';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 0 0 0',
  },
  firstLine: {
    width: '100%',
    padding: '.5rem 0',

    borderBottom: props => (props.haveChildren ? 'solid #efe9e5 1px' : 'none'),
    textDecoration: 'none',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    paddingLeft: '1.5rem',
    margin: 0,

    fontSize: '1rem',
    fontFamily: 'ItauText-Bold',
    color: palette.primary.black,

    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1.5rem',
    marginRight: '0.5rem',
    color: palette.primary.main,
  },
  contentContainer: {
    margin: '0.5rem 0 0 0',
  },
}));

const OptionDropdown = ({ parentLabel, content, closeMenu, testId }) => {
  const { iconDefault, defaultOption, title, ariaLabel, label, onClick, items, children } = content;
  const haveChildren = children || items;
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();

  const history = useHistory();
  const styles = useStyles({ haveChildren });
  const TITLE = defaultOption?.label || label || title;
  const RAW_TITLE = clean(TITLE);
  const ARIA_LABEL = defaultOption?.ariaLabel || ariaLabel || defaultOption?.label || label || title;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (parentLabel) dispatchAnalyticsEvent('HOME', 'A3', [parentLabel, RAW_TITLE]);
    else if (!open) dispatchAnalyticsEvent('HOME', 'A2', [RAW_TITLE]);

    if (haveChildren) return setOpen(!open);
    if (onClick.path) {
      closeMenu();
      if (typeof onClick.path === 'string') {
        if (onClick.openInANewTab) window.open(onClick.path, '_blank');
        else window.location.href = onClick.path;
      }
    } else if (onClick.action) {
      closeMenu();
      switch (onClick.action) {
        case 'focusContacts': {
          document.querySelector('.content-wrapper').scrollTo({
            top: document.querySelector('.content-wrapper').scrollHeight,
            left: 0,
            behavior: 'smooth',
          });
          document.querySelector('#btnfooter_screen_home_sale_0').focus();
          break;
        }
        case 'focusCancellation': {
          document.querySelector('.content-wrapper').scrollTo({
            top: document.querySelector('.content-wrapper').scrollHeight,
            left: 0,
            behavior: 'smooth',
          });
          document.querySelector('#btnfooter_screen_home_cancel_0').focus();
          break;
        }
        default:
          break;
      }
    }
  };

  return (
    <li className={styles.root} data-testid={testId}>
      <button
        type="button"
        role={haveChildren ? 'button' : 'link'}
        className={styles.firstLine}
        aria-expanded={haveChildren ? open : undefined}
        aria-live="polite"
        aria-label={ARIA_LABEL}
        onClick={() => handleClick()}
      >
        <p className={styles.title}>
          {iconDefault && <i aria-hidden className={`${iconDefault} ${styles.icon}`} />}
          <Markdown source={TITLE} />
        </p>
        {(items?.length > 0 || children) && (
          <i
            className={`${haveChildren && open ? 'icon-itaufonts_seta_up' : 'icon-itaufonts_seta_down'} ${styles.icon}`}
          />
        )}
      </button>
      {open && haveChildren && (
        <>
          {items ? (
            <ul className={styles.contentContainer}>
              {items.map((item, index) => (
                <OptionDropdown key={index} parentLabel={TITLE} content={item} closeMenu={closeMenu} />
              ))}
            </ul>
          ) : (
            children
          )}
        </>
      )}
    </li>
  );
};

export default OptionDropdown;
