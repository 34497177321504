import { toRem } from '../functions';

export default {
  formControl: {
    transform: `translate(0, ${toRem(-3)}) scale(1)`,
    marginBottom: '0 !important',
  },
  shrink: {
    transform: `translate(0, ${toRem(-3)}) scale(1)`,
  },
};
