import ANALYTICS_CATEGORY from '../category.json';

export default {
  VIDA_SAIBAMAIS: {
    action: 'vida_saibamais',
    label: 'BTN:Vida:Teste:SaibaMais',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_SELECT_AGE: {
    action: 'vida_select_age',
    label: 'BTN:Vida:Teste:Idade',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_PRICE_CONTINUE: {
    action: 'vida_price_continue',
    label: 'BTN:Vida:Teste:Preco',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_PRICE_CHANGE_AGE: {
    action: 'VIDA_PRICE_CHANGE_AGE',
    label: 'BTN:Vida:Teste:Preco:AlterarFaixaEtaria',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_REDEMPTION_TIME_OPTIONS: {
    action: 'vida_redemption_time_options',
    label: 'BTN:Vida:Teste:Resgate',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_REDEMPTION_TYPE_OPTIONS: {
    action: 'vida_redemption_type_options',
    label: 'BTN:Vida:Teste:Resgate',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_CLIENT_INFORMATION: {
    action: 'vida_client_information',
    label: 'BTN:Vida:Teste:Finalizacao:Informacoes',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_WHY_CAPITALIZATION: {
    action: 'vida_why_capitalization',
    label: 'BTN:Vida:Teste:Capitalizacao:Motivo',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_CAPITALIZATION_PRIZE: {
    action: 'vida_capitalization_prize',
    label: 'BTN:Vida:Teste:Capitalizacao:Sorteios',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_MODULAR_COVERAGE_CHOICE: {
    action: 'vida_modular_coverage_choice',
    label: 'BTN:Vida:Teste:Modular:Coberturas',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_MODULAR_COVERAGE_DETAILS: {
    action: 'vida_modular_coverage_details',
    label: 'BTN:Vida:Teste:Modular:Coberturas:Detalhes',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_VOLTAR: {
    action: 'vida_voltar',
    label: 'BTN:Vida:Teste:Voltar',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  VIDA_FECHAR_MENU: {
    action: 'vida_fechar_menu',
    label: 'BTN:Vida:Teste:Fechar_menu',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
};
