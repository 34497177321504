const receipt = {
  RECEIPT_DOWNLOAD: {
    TITLE: 'comprovante da contratação',
    TITLE_CANCELLING: 'comprovante de cancelamento',
    DESCRIPTION:
      'para baixar um resumo com as informações do seu produto clique no botão abaixo e digite **os cinco primeiros digitos do seu CPF**',
    DESCRIPTION_CANCELLING:
      'para baixar um resumo com as informações do seu cancelamento clique no botão abaixo e digite **os cinco primeiros digitos do seu CPF**',
    BUTTON: 'baixar comprovante',
  },
  INSPECTION: {
    TITLE: 'dados do **agendamento**',
    WARNING: 'após a vistoria do carro, será feita uma avaliação dos dados no prazo de até 15 dias.',
  },
};

export default receipt;
