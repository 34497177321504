const CREDITCARD_REGEX = {
  Mastercard: /^5[1-5]/,
  Visa: /^4/,
  Elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/,
  Amex: /^3[47][0-9]{13}/,
  Aura: /^((?!504175))^((?!5067))(^50[0-9])/,
  Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
};
Object.freeze(CREDITCARD_REGEX);
export { CREDITCARD_REGEX };

export const DATE_REGEX = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;

export const EMAIL_REGEX = /^[a-z0-9.+%_-]{1,64}@[a-z0-9.-]{1,255}\.[a-z]{2,}$/;

export const RG_REGEX = /^[a-z0-9]+$/i;

export const ONLY_TEXT = /^[a-zA-Z ]*$/;

export const ONLY_NUMBER = /^[0-9]*$/;

export const CPF_REGEX = /^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}$/;

export const CNPJ_REGEX = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/;

export const CELLPHONE_REGEX = /^\(?[0-9]{2}\)? ?[0-9]{5}-?[0-9]{4}$/;

export const CEP_REGEX = /^[0-9]{5}-?[0-9]{3}$/;

export const NAME_REGEX = /^[^!@#$%^&*(),.?":{}|<>0-9][a-zA-Z\u00C0-\u00FF]{2,}( [a-zA-Z\u00C0-\u00FF]{2,})+$/;

export const NAME_ABREVIATED_REGEX = /^[^!@#$%^&*(),.?":{}|<>0-9][a-zA-Z\u00C0-\u00FF]{2,}( [a-zA-Z\u00C0-\u00FF]+)+$/;

export const NO_UPPER_CASE_REGEX = /^[^A-Z]+$/;

export const ADDRESS_NUMBER_REGEX = /^(([0-9]+)|SN)$/i;

export const SPECIAL_CHARACTERS_REGEX = /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\s,.'-]*$/u;
