const FAQ = {
  TITLE: 'veja a lista de perguntas frequentes',
  SUBTITLE: 'tire todas as suas dúvidas',
  SHOW_MORE: {
    TEXT: 'ver mais',
    ARIA_LABEL: 'ver mais',
  },
  HERE: {
    TEXT: 'aqui',
    ARIA_LABEL: 'aqui',
  },
};

export default FAQ;
