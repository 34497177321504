import moment from 'moment';
import { createSelector } from 'reselect';

import { formatToBRLCurrency } from '../../../utils/currencyUtils';
import { stringFormatterCEP, cellphoneFormatter, cpfFormatter } from '../../../utils/util';

const realizaSelectors = (types, i18nextBase) => {
  const Selectors = state => state[types];
  const FlowSelector = createSelector(Selectors, item => item.flow);
  const QuotationSelector = createSelector(Selectors, item => item.cotacao);
  const QuotationRequestSelector = createSelector(QuotationSelector, item => item.requisicao);
  const QuotationResponseSelector = createSelector(QuotationSelector, item => item.resposta);
  const HiringSelector = createSelector(Selectors, item => item.contratacao);
  const HiringRequestSelector = createSelector(HiringSelector, item => item.requisicao);
  const HiringResponseSelector = createSelector(HiringSelector, item => item.resposta);

  const HiringStatusSelector = createSelector(
    HiringSelector,
    ({ erro = false, carregando = false, sucesso = false }) => ({
      erro,
      carregando,
      sucesso,
    }),
  );

  const FlowAuthSelector = createSelector(FlowSelector, item => item.auth);

  const QuotationRequestProponentSelector = createSelector(QuotationRequestSelector, item => item.proponente);

  const QuotationProponentNameSelector = createSelector(QuotationRequestProponentSelector, item => item.nome);
  const QuotationProponentCpfSelector = createSelector(QuotationRequestProponentSelector, item => item.cpf);

  const IdSimulationSelector = createSelector(QuotationResponseSelector, item => item?.id_simulacao);
  const ContractNumberSelector = createSelector(HiringResponseSelector, item => item.numero_contratacao);

  const QuotationResponseAvailablePaymentMethodsSelector = createSelector(QuotationResponseSelector, item =>
    item?.metodos_pagamento?.[0].cartoes.map(i => i.tipo),
  );

  const PaymentOptionIdSelector = createSelector(
    QuotationResponseSelector,
    item => item?.opcoes_pagamento?.[0].id_opcao_pagamento,
  );

  const FlowAuthDataSelector = createSelector(FlowSelector, item => item.authData);
  const EmailSelector = createSelector(FlowAuthDataSelector, item => item.email);
  const CellphoneSelector = createSelector(FlowAuthDataSelector, item => item.cellphone);

  const InitialProfileState = createSelector(QuotationRequestProponentSelector, item => ({
    holderName: item?.nome || '',
    holderCpf: item?.cpf || '',
    holderBirthday: item?.data_nascimento ? moment(item.data_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY') : '',
    cep: item?.endereco?.cep || '',
    city: item?.endereco?.cidade || '',
    state: item?.endereco?.estado || '',
    address: item?.endereco?.logradouro || '',
    addressNumber: item?.endereco?.numero || '',
    neighborhood: item?.endereco?.bairro || '',
    complement: item?.endereco?.complemento || '',
  }));

  const AssistanceListSelector = createSelector(FlowSelector, ({ valor_mensal_minimo, assistencias_selecionadas }) => {
    let amount = valor_mensal_minimo;
    const list = assistencias_selecionadas?.reduce(
      (acc, assistencia) => {
        const item = {
          title: assistencia?.nome,
          key: assistencia?.id_assistencia,
        };
        if (assistencia?.assistencia_obrigatoria) {
          if (!acc[0].items.length) {
            item.value = assistencia?.premio ? formatToBRLCurrency(valor_mensal_minimo) : '';
          } else {
            item.value = '';
          }
          acc[0].items.push(item);
        } else {
          amount += assistencia?.premio || 0;
          item.value = assistencia?.premio ? formatToBRLCurrency(assistencia?.premio) : '';
          acc[1].items.push(item);
        }
        return acc;
      },
      [
        {
          key: `${i18nextBase}.SUMMARY.ASSISTANCES.PRINCIPAL_PACKAGE.KEY`,
          leftTitle: `${i18nextBase}.SUMMARY.ASSISTANCES.PRINCIPAL_PACKAGE`,
          rightTitle: `${i18nextBase}.SUMMARY.ASSISTANCES.VALUES`,
          items: [],
        },
        {
          key: `${i18nextBase}.SUMMARY.ASSISTANCES.ADDITIONAL.KEY`,
          leftTitle: `${i18nextBase}.SUMMARY.ASSISTANCES.ADDITIONAL`,
          rightTitle: `${i18nextBase}.SUMMARY.ASSISTANCES.VALUES`,
          items: [],
        },
      ],
    );

    return {
      list,
      amount: formatToBRLCurrency(amount),
    };
  });

  const FlowSelectedAssistanceSelector = createSelector(FlowSelector, item => item.assistencias_selecionadas);
  const FlowMainAssistancePriceSelector = createSelector(FlowSelector, item => item.valor_mensal_minimo);

  const SummaryProfileAndAddressList = createSelector(QuotationRequestProponentSelector, quotationProponent => {
    const contacts = quotationProponent?.contatos?.reduce((acc, contact) => {
      acc[contact.tipo] = contact.valor;
      return acc;
    }, {});

    const profileList = [
      {
        title: `${i18nextBase}.SUMMARY.PROFILE_SECTION.NAME`,
        value: quotationProponent?.nome || '',
      },
      {
        title: `${i18nextBase}.SUMMARY.PROFILE_SECTION.CPF`,
        value: cpfFormatter(quotationProponent?.cpf) || '',
      },
      {
        title: `${i18nextBase}.SUMMARY.PROFILE_SECTION.EMAIL`,
        value: contacts?.email || '',
      },
      {
        title: `${i18nextBase}.SUMMARY.PROFILE_SECTION.CELLPHONE`,
        value: cellphoneFormatter(contacts?.telefone) || '',
      },
      {
        title: `${i18nextBase}.SUMMARY.PROFILE_SECTION.BIRTHDAY`,
        value: moment(quotationProponent?.data_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY') || '',
      },
      {
        title: `${i18nextBase}.SUMMARY.PROFILE_SECTION.PAYMENT_METHOD`,
        value: 'cartão de crédito',
      },
    ];

    const addressList = [
      {
        title: `${i18nextBase}.SUMMARY.ADDRESS_SECTION.CEP`,
        value: stringFormatterCEP(quotationProponent?.endereco?.cep) || '',
      },
      {
        title: `${i18nextBase}.SUMMARY.ADDRESS_SECTION.ADDRESS`,
        value: quotationProponent?.endereco?.logradouro || '',
      },
      {
        title: `${i18nextBase}.SUMMARY.ADDRESS_SECTION.ADDRESS_NUMBER`,
        value: quotationProponent?.endereco?.numero || '',
      },
      {
        title: `${i18nextBase}.SUMMARY.ADDRESS_SECTION.COMPLEMENT`,
        value: quotationProponent?.endereco?.complemento || '',
      },
    ];

    return {
      profileList,
      addressList,
    };
  });

  return {
    BaseSelector: Selectors,
    FlowSelector,
    QuotationSelector,
    QuotationRequestSelector,
    QuotationResponseSelector,
    HiringSelector,
    HiringRequestSelector,
    HiringStatusSelector,
    FlowAuthSelector,
    QuotationRequestProponentSelector,
    QuotationProponentNameSelector,
    QuotationProponentCpfSelector,
    IdSimulationSelector,
    ContractNumberSelector,
    QuotationResponseAvailablePaymentMethodsSelector,
    PaymentOptionIdSelector,
    FlowAuthDataSelector,
    EmailSelector,
    CellphoneSelector,
    InitialProfileState,
    AssistanceListSelector,
    FlowSelectedAssistanceSelector,
    FlowMainAssistancePriceSelector,
    SummaryProfileAndAddressList,
  };
};

export default realizaSelectors;
