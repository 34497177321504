import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ toRem, palette }) => ({
  field: {
    color: palette.defaultLabel,
    fontSize: toRem(14),
    marginTop: 0,
  },
  noMargin: {
    marginBottom: 0,
  },
  label: {
    margin: `${toRem(15)} 0 0`,
  },
  disabled: {
    pointerEvents: 'none',
    color: `${palette.inputDisableColor}!important`,
  },
  placeholder: {
    '&::placeholder': {
      color: palette.inputPlaceholderColor,
    },
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '1.875rem',
  },
  helperField: {
    minInlineSize: 'max-content',
  },
}));
