import { EMAIL_REGEX, SPECIAL_CHARACTERS_REGEX } from 'utils/regex';
import { defaultVulnerabilityFieldNames } from './translator';

const EMAIL_PROFILE_LENGTH = 64;
const EMAIL_DOMAIN_LENGTH = 255;

const defaultRule = value => SPECIAL_CHARACTERS_REGEX.test(value);

const emailRule = value => {
  const validEmail = EMAIL_REGEX.test(value);
  if (validEmail) {
    const splittedEmail = value.split('@');
    const [profile, domain] = splittedEmail;
    return profile.length < EMAIL_PROFILE_LENGTH || domain.length < EMAIL_DOMAIN_LENGTH;
  }
};

const applyDefaultRules = defaultVulnerabilityFieldNames.reduce((acc, fieldName) => {
  acc[fieldName] = defaultRule;
  return acc;
}, {});

export const rules = {
  ...applyDefaultRules,
  email: emailRule,
};
