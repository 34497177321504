import MuiButtonBase from './MuiButtonBase';
import MuiCheckbox from './MuiCheckbox';
import MuiCssBaseline from './MuiCssBaseline';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiRadio from './MuiRadio';
import MuiSelect from './MuiSelect';
import MuiFormLabel from './MuiFormLabel';
import MuiCollapse from './MuiCollapse';

export default {
  MuiButtonBase,
  MuiCheckbox,
  MuiCssBaseline,
  MuiOutlinedInput,
  MuiRadio,
  MuiSelect,
  MuiFormLabel,
  MuiCollapse,
};
