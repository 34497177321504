import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18n';
import ButtonLink from 'components/Button/ButtonImpl/ButtonLink/ButtonLink';
// eslint-disable-next-line import/no-unresolved
import useStyles from './styles';

export default function BannerSubtitle({ handleClickLink }) {
  const styles = useStyles();

  return (
    <p className={styles.root}>
      <span aria-hidden>{i18next.t('ODONTO.BANNER.MALA_DIGITAL.SUBTITLE.LINK_PREFIX')}</span>
      <ButtonLink
        role="link"
        onClick={handleClickLink}
        text={i18next.t('ODONTO.BANNER.MALA_DIGITAL.SUBTITLE.LINK_TEXT')}
        className={styles.link}
        ariaLabel={i18next.t('ODONTO.BANNER.MALA_DIGITAL.SUBTITLE.LINK_ARIA_LABEL')}
      />
      <span aria-hidden>{i18next.t('ODONTO.BANNER.MALA_DIGITAL.SUBTITLE.LINK_SUFIX')}</span>
    </p>
  );
}

BannerSubtitle.propTypes = {
  handleClickLink: PropTypes.func,
};
