/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TERMS_URL } from '../../resources/Texts/Odonto/Terms';
import { useStyles } from './componentStyle';
import Button from '../../components/Button/Button';

const STORAGE_ACCEPTED_COOKIES = 'dataAssinaturaTermo';
const AcceptCookies = () => {
  const [acceptedCookies, setShowAcceptedCookies] = useState(false);
  const styles = useStyles();

  const valideCookiesConfirmation = () => {
    const cookiesValidation = localStorage.getItem(STORAGE_ACCEPTED_COOKIES);
    if (cookiesValidation) {
      setShowAcceptedCookies(true);
    }
  };

  const onAcceptCookies = () => {
    localStorage.setItem(STORAGE_ACCEPTED_COOKIES, moment().format('DD/MM/YYYY HH:mm'));
    setShowAcceptedCookies(true);
  };

  useEffect(() => {
    valideCookiesConfirmation();
  }, []);

  return (
    <div id="acceptedCookies" className={`${acceptedCookies ? styles.acceptedCookies : ''} ${styles.acceptCookies}`}>
      <div className={styles.fitContainer}>
        <div>
          <p className={styles.text}>
            {'Este site usa cookies e dados pessoais de acordo com os nossos '}
            <a
              href={TERMS_URL}
              aria-label="Termos de Uso e Política de Privacidade"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.termsLink}
            >
              Termos de Uso e Política de Privacidade
            </a>
            {' e, ao continuar navegando, você declara estar ciente dessas condições.'}
          </p>
        </div>
        <div className={styles.acceptButton}>
          <Button
            text="aceitar"
            aria-label="aceitar"
            className={styles.confirmButton}
            classNameText={styles.confirmButtonText}
            onClick={onAcceptCookies}
            context="cookies"
            page="header"
          />
        </div>
      </div>
    </div>
  );
};

export default AcceptCookies;
