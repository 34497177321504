/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import config from '../../../../config';
import { useStyles } from './pageStyles';
import { getSessionStorage } from '../../../../utils/util';
import { getTextByOrigin } from '../../utils/util';
import CreditCard from '../../../../components/CreditCard/CreditCard';
import creditcardlogo from '../../../../assets/images/itaucard.svg';
import creditcard from '../../../../assets/images/itaucard-card.png';
import { ITAUCARD } from '../../../../utils/url';

const { DISTRIBUTION_KEY } = config;

const CredicardPayment = ({ form, fields, paymentType, onChangePaymentType, theme }) => {
  const styles = useStyles();

  const showContainerSelected = () =>
    paymentType === ITAUCARD ? `${styles.itaucard} ${styles.itaucardSelected}` : styles.itaucard;

  const buttonPressed = () => paymentType === ITAUCARD;

  const showRadioSelected = () =>
    paymentType === ITAUCARD
      ? `${styles.itaucardCheckBox} ${styles.itaucardCheckBoxSelected}`
      : styles.itaucardCheckBox;

  return (
    // TODO REGRA DE NEGÓCIO 
    getSessionStorage(DISTRIBUTION_KEY) === ITAUCARD && (
      <div className={styles.itaucardBox}>
        <h2 className={styles.header}>
          {i18next.t(getTextByOrigin(theme, 'ITAUCARD_PAYMENT.OPTION'))}&nbsp;
          <span className={styles.keyword}>{i18next.t(getTextByOrigin(theme, 'ITAUCARD_PAYMENT.NAME'))}</span>
        </h2>
        <div
          className={showContainerSelected()}
          tabIndex={0}
          role="checkbox"
          aria-checked={buttonPressed()}
          onClick={onChangePaymentType}
          onKeyDown={onChangePaymentType}
        >
          <img className={styles.itaucardImg} src={creditcard} alt="Cartões Itaucard" aria-hidden />
          <div className={styles.itaucardWrapper}>
            <div className={styles.itaucardInfo}>
              <div className={styles.iconCard}>
                <div>
                  <img src={creditcardlogo} alt="Itaucard" className={styles.itaucardSpacing} aria-hidden />
                </div>
                <div className={showRadioSelected()} />
              </div>
              <p>
                <Trans i18nKey={getTextByOrigin(theme, 'ITAUCARD_PAYMENT.ADVERTISEMENT')} />
              </p>
            </div>
          </div>
        </div>
        {paymentType === ITAUCARD && (
          <div>
            <CreditCard fields={fields} form={form} />
            <p aria-label={i18next.t(getTextByOrigin(theme, 'ITAUCARD_PAYMENT.WARNING_ACCESSIBILITY'))}>
              {i18next.t(getTextByOrigin(theme, 'ITAUCARD_PAYMENT.WARNING'))}
            </p>
          </div>
        )}
      </div>
    )
  );
};

CredicardPayment.propTypes = {
  fields: PropTypes.object,
  form: PropTypes.object,
  paymentType: PropTypes.object,
  onChangePaymentType: PropTypes.func,
  theme: PropTypes.string,
};

export default CredicardPayment;
