/* eslint-disable no-plusplus */
export const Types = {
  START_QUOTATION: 'quotation/START_QUOTATION',
  ADD_OWNER: 'quotation/ADD_OWNER',
  ADD_BENEFICIARY: 'quotation/ADD_BENEFICIARY',
  UPDATE_BENEFICIARY: 'quotation/UPDATE_BENEFICIARY',
  DELETE_BENEFICIARY: 'quotation/DELETE_BENEFICIARY',
  ADD_PAYER: 'quotation/ADD_PAYER',
  RESET_QUOTATION: 'quotation/RESET_QUOTATION',
  UPDATE_ADDRESS_BY_CEP: 'quotation/UPDATE_ADDRESS_BY_CEP',
  ADD_PAYMENT: 'quotation/ADD_PAYMNENT',
  ADD_PAYMENT_ADDRESS: 'quotation/ADD_PAYMENT_ADDRESS',
  VALIDATE_QUOTATION: 'quotation/VALIDATE_QUOTATION',
  ADD_COVERAGES: 'quotation/ADD_COVERAGES',
  ADD_FORMALIZATION_METHOD: 'quotation/ADD_FORMALIZATION_METHOD',
  ADD_ONLY_PAYER: 'quotation/ADD_ONLY_PAYER',
  ADD_DEPENDENT_PRICES: 'quotation/ADD_DEPENDENT_PRICES',
};

export const initialState = {
  aceite_termos: false,
  id_produto: null,
  id_produto_externo: null,
  dia_pagamento: null,
  data_contratacao: null,
  metodo_formalizacao: null,
  nome_produto: null,
  segmento: 'SSEG0',
  beneficiarios: [
    {
      titular: true,
      sequencia: 0,
      nome_completo: '',
      cpf_beneficiario: '',
      data_nascimento: '',
      nome_completo_mae: '',
      enderecos: [
        {
          tipo: 'A',
          logradouro: '',
          numero: '',
          complemento: '',
          bairro: '',
          uf: '',
          cidade: '',
          cep: '',
        },
      ],
      sexo: '',
      email: '',
      telefones: [
        {
          tipo: 'celular',
          ddd: '',
          numero: '',
        },
      ],
    },
  ],
  valor_premio: null,
  valor_premio_total: null,
  codigo_campanha: null,
  efetivada: false,
  pagador: null,
  coberturas: [],
  link_condicoes_gerais: null,
  link_resumo_plano: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.START_QUOTATION: {
      return {
        ...state,
        ...action.data,
      };
    }
    case Types.ADD_FORMALIZATION_METHOD: {
      return {
        ...state,
        metodo_formalizacao: action.data,
      };
    }
    case Types.VALIDATE_QUOTATION: {
      const { idProposta, situacao, diaPagamento, dataContratacao } = action.data;
      return {
        ...state,
        id_proposta: idProposta,
        situacao,
        dia_pagamento: diaPagamento,
        data_contratacao: dataContratacao,
      };
    }

    case Types.ADD_OWNER: {
      return {
        ...state,
        beneficiarios: state.beneficiarios.map(beneficiario => {
          if (!beneficiario.titular) {
            return beneficiario;
          }
          return {
            ...beneficiario,
            ...action.data,
          };
        }),
      };
    }

    case Types.ADD_ONLY_PAYER: {
      return {
        ...state,
        pagador: action.data,
      };
    }

    case Types.UPDATE_ADDRESS_BY_CEP: {
      return {
        ...state,
        beneficiarios: state.beneficiarios.map(beneficiario => {
          if (!beneficiario.titular) {
            return beneficiario;
          }
          return {
            ...beneficiario,
            enderecos: beneficiario.enderecos.map((endereco, index) => {
              if (index !== 0) {
                return endereco;
              }
              return {
                tipo: 'A',
                logradouro: action.data.logradouro,
                numero: action.data.numero,
                complemento: action.data.complemento,
                bairro: action.data.bairro,
                uf: action.data.uf,
                cidade: action.data.cidade,
                cep: action.data.cep,
              };
            }),
          };
        }),
      };
    }
    case Types.ADD_BENEFICIARY: {
      const newBeneficiary = {
        ...action.data,
        sequencia: parseInt(state.beneficiarios.slice(-1)[0].sequencia) + 1,
      };

      return {
        ...state,
        beneficiarios: [...state.beneficiarios, newBeneficiary],
        valor_premio_total: (state.valor_premio * (state.beneficiarios.length + 1)).toFixed(2),
      };
    }
    case Types.ADD_PAYMENT: {
      if (!action.data)
        return {
          ...state,
          pagador: undefined,
        };
      return {
        ...state,
        pagador: action.data,
      };
    }
    case Types.ADD_PAYMENT_ADDRESS: {
      return {
        ...state,
        pagador: {
          ...state.pagador,
          ...action.data,
        },
      };
    }
    case Types.UPDATE_BENEFICIARY: {
      return {
        ...state,
        beneficiarios: state.beneficiarios.map(beneficiario => {
          if (beneficiario.sequencia !== action.data.sequencia) {
            return beneficiario;
          }

          return {
            ...beneficiario,
            ...action.data,
          };
        }),
      };
    }
    case Types.ADD_COVERAGES: {
      return {
        ...state,
        coberturas: action.data,
      };
    }
    case Types.DELETE_BENEFICIARY: {
      const newBeneficiaries = state.beneficiarios.filter(beneficiario => beneficiario.sequencia !== action.data);

      if (action.data !== state.beneficiarios.slice(-1)[0].sequencia) {
        for (let i = action.data; i < newBeneficiaries.length; i++) {
          newBeneficiaries[i].sequencia--;
        }
      }

      return {
        ...state,
        beneficiarios: newBeneficiaries,
        valor_premio_total: state.valor_premio * newBeneficiaries.length,
      };
    }
    case Types.ADD_PAYER: {
      return {
        ...state,
        quotation: action.quotation,
      };
    }
    case Types.RESET_QUOTATION: {
      return {
        ...initialState,
      };
    }
    case Types.ADD_DEPENDENT_PRICES: {
      return {
        ...state,
        valor_premio_unitario_liquido: action.valor_premio_unitario_liquido,
        valor_premio_total_liquido: action.valor_premio_total_liquido,
        valor_premio: action.valor_premio,
        valor_premio_total: action.valor_premio_total,
      };
    }
    default:
      return state;
  }
}

export function startQuotation(data) {
  return {
    type: Types.START_QUOTATION,
    data,
  };
}

export function addQuotationCoverages(data) {
  return {
    type: Types.ADD_COVERAGES,
    data,
  };
}

export function validateQuotation(data) {
  return {
    type: Types.VALIDATE_QUOTATION,
    data,
  };
}

export function addOwner(data) {
  return {
    type: Types.ADD_OWNER,
    data,
  };
}

export function updateAddressByCep(data) {
  return {
    type: Types.UPDATE_ADDRESS_BY_CEP,
    data,
  };
}

export function addBefeficiary(data) {
  return {
    type: Types.ADD_BENEFICIARY,
    data,
  };
}

export function updateBefeficiary(data) {
  return {
    type: Types.UPDATE_BENEFICIARY,
    data,
  };
}

export function deleteBefeficiary(data) {
  return {
    type: Types.DELETE_BENEFICIARY,
    data,
  };
}

export function addPayer(data) {
  return {
    type: Types.ADD_PAYER,
    data,
  };
}

export function addOnlyPayer(data) {
  return {
    type: Types.ADD_ONLY_PAYER,
    data,
  };
}

export function resetQuotation(data) {
  return {
    type: Types.RESET_QUOTATION,
    data,
  };
}

export function addPayment(data) {
  return {
    type: Types.ADD_PAYMENT,
    data,
  };
}

export function addPaymentAddress(data) {
  return {
    type: Types.ADD_PAYMENT_ADDRESS,
    data,
  };
}

export function addFormalizationMethod(data) {
  return {
    type: Types.ADD_FORMALIZATION_METHOD,
    data,
  };
}
