/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import { Field } from 'formik';
import FormErrors from '../formErrors';
import { callFunction } from '../../../utils/util';

export default function FormikItemWrapper({
  component,
  name,
  onChange,
  onBlur,
  errorMessage,
  hint,
  inputProps,
  ...props
}) {
  const [focused, setFocus] = useState(false);

  return (
    <Field name={name}>
      {({ form, field }) => {
        const handleChange = event => {
          if (onChange) callFunction(onChange, event);
          if (field.onChange) callFunction(field.onChange, event);
        };

        const handleBlur = event => {
          setFocus(false);

          if (onBlur) callFunction(onBlur, event);
          if (field.onBlur) callFunction(field.onBlur, event);
        };

        const handleFocus = () => {
          setFocus(true);
        };

        const { helperText, hasError } = FormErrors.validate(form, name, errorMessage, hint, focused);

        const newProps = {
          value: field.value,
          name,
          onBlur: handleBlur,
          onChange: handleChange,
          onFocus: handleFocus,
          inputProps: {
            ...inputProps,
            'aria-invalid': hasError,
          },
          helperText,
          error: hasError,
          ...props,
        };
        return <>{component(newProps)}</>;
      }}
    </Field>
  );
}
