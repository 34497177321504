import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  item: {
    marginRight: '80px',
    '&:last-child': {
      marginRight: '0',
    },
  },
  radioBoxFix: {
    [theme.breakpoints.down('xs')]: {
      '& input[type=radio]': {
        position: 'relative',
        width: '26px',
        height: '25px',
        marginRight: '-23px',
      },
    },
  },
}));

export default function RadioButton({ option, checked, className, onClick, ariaLabel, showLabel, id }) {
  const classes = useStyles();

  const getAriaLabel = () => {
    const optLabel = option && option.label ? option.label : undefined;

    let concatLabel = '';
    if (ariaLabel !== undefined) {
      concatLabel = `${ariaLabel}`;
    }

    if (optLabel !== undefined) {
      concatLabel += concatLabel.length > 0 ? ` ${optLabel}` : `${optLabel}`;
    }
    return concatLabel;
  };

  return (
    <FormControlLabel
      className={className || clsx(classes.item, classes.radioBoxFix)}
      label={showLabel ? option.label : null}
      id={id}
      aria-label={getAriaLabel()}
      labelPlacement="end"
      value={option.value}
      checked={checked}
      onClick={onClick}
      control={<Radio id={option.id} color="primary" data-testid={id} />}
    />
  );
}

RadioButton.defaultProps = {
  showLabel: true,
};

RadioButton.propTypes = {
  option: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  showLabel: PropTypes.bool,
};
