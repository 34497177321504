import ROUTES from 'routes/routes';

const HELP = {
  ITEMS: [
    {
      TEXT: 'dúvidas sobre seguros',
      LINK: ROUTES.FAQ_PAGE,
    },
    {
      TEXT: 'central de atendimento',
      LINK: ROUTES.HOME,
    },
  ],
};

export default HELP;
