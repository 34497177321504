import palette from '../palette';
import { spacing } from '../functions';

export default {
  root: {
    '&$selected:not($disabled)': {
      fontFamily: 'ItauText-XBold',
      fontSize: spacing(4),
      backgroundColor: palette.white,
      borderLeft: `1px solid ${palette.primary.main}`,
    },
    '&:hover': {
      backgroundColor: `${palette.backgroundSelect} !important`,
    },
    '&:focused': {
      backgroundColor: `${palette.backgroundSelect} !important`,
    },
    '&$focusVisible': {
      backgroundColor: `${palette.backgroundSelect} !important`,
      outlineColor: palette.focusColor,
      outlineStyle: 'solid',
      outlineWidth: '2px',
    },
  },
};
