import config from 'config';

export const transformCoverages = (coverages, allDetailCoverages) => {
  const transformedCoverages = [];
  if (!allDetailCoverages) return transformedCoverages;
  if (!coverages) return transformedCoverages;

  for (const coverage of coverages) {
    const coverageDetailsFound = allDetailCoverages?.find(
      coverageDetails => coverageDetails.identificador === coverage.identificador,
    );

    // eslint-disable-next-line no-continue
    if (!coverageDetailsFound) continue;

    transformedCoverages.push({
      id: coverageDetailsFound.id_cobertura,
      name: coverageDetailsFound.nome,
      value: coverage.limite_servico,
    });
  }

  return transformedCoverages;
};

export const getImage = productName => {
  const { URL_BUCKET_BASE } = config;
  const PORTO_FLAG = 'PORTO SEGURO';
  const AZUL_FLAG = 'AZUL';
  if (productName.toUpperCase().includes(AZUL_FLAG)) {
    return `${URL_BUCKET_BASE}/parceiros/logo_azul.svg`;
  }
  if (productName.toUpperCase().includes(PORTO_FLAG)) {
    return `${URL_BUCKET_BASE}/auto/ilustracoes/logo_porto_seguro.svg`;
  }
};
