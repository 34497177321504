import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    height: '2.5rem',
    width: '100%',
  },
  modalNavMenu: {
    paddingTop: '1rem !important',
  },
  dropdownMenu: {
    height: '100vh',
    marginTop: '1rem',
    width: '100vw',
    position: 'absolute',
    left: 0,
    zIndex: 2,
  },
  rowContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  hamburgerIcon: {
    fontSize: '2rem',
  },
  image: {
    width: '45px',
    height: '45px',
  },
  copyPaste: {
    zIndex: 2,
    width: '100vw',
    position: 'absolute',
    transitionProperty: 'all',
    transitionDuration: '.5s',
    transitionTimingFunction: 'cubic-bezier(0,1,.5,1)',
    overflow: 'hidden',
    maxHeight: '100vh',
  },
  navbar: {
    height: '100%',
  },
}));
