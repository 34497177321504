export const Types = {
  SET_WIZARD_STEP: 'wizardStep/SET_WIZARD_STEP',
  RESET_WIZARD_STEP: 'wizardStep/RESET_WIZARD_STEP',
};

export const initialState = {
  currentStep: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_WIZARD_STEP: {
      return {
        ...state,
        currentStep: action.data,
      };
    }
    case Types.RESET_WIZARD_STEP: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}

export function setWizardStep(data) {
  return {
    type: Types.SET_WIZARD_STEP,
    data,
  };
}

export function resetWizardStep() {
  return {
    type: Types.RESET_WIZARD_STEP,
  };
}
