/* eslint-disable no-useless-escape */
import axios from 'axios';

import config, { performance } from 'config';

const { E2E_PATH } = config;

export const checkElegibility = async (name, cpf) => {
  const trace = performance.trace('checkElegibility');
  trace.start();

  const response = await axios.post(`${E2E_PATH}/elegibilidade`, {
    nome: name,
    cpf: cpf.replace(/[\.()\s-]/g, ''),
  });

  trace.stop();
  const { elegibilidade } = response.data;
  return elegibilidade.some(e => e.elegivel === false);
};
