/* eslint-disable prefer-const */
export const Types = {
  CREATE_SEARCH_VEHICLE: 'searchVehicle/CREATE_SEARCH_VEHICLE',
  LOAD_DATA_VEHICLE: 'searchVehicle/LOAD_DATA_VEHICLE',
  ERROR_DATA_VEHICLE: 'searchVehicle/ERROR_DATA_VEHICLE',
  SUCCESS_LOAD_DATA_VEHICLE: 'searchVehicle/SUCCESS_LOAD_DATA_VEHICLE',
  RESET_SEARCHVEHICLE: 'searchVehicle/RESET_SEARCHVEHICLE',
  SAVE_SESSION: 'searchvehicle/SAVE_SESSION',
};

const initialState = {
  data: null,
  loading: false,
  error: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.CREATE_SEARCH_VEHICLE:
      return {
        ...state,
        data: action.data,
      };
    case Types.RESET_SEARCHVEHICLE:
      return initialState;
    case Types.LOAD_DATA_VEHICLE:
      return {
        ...state,
        loading: true,
      };
    case Types.ERROR_DATA_VEHICLE:
      return {
        ...state,
        error: true,
      };
    case Types.SUCCESS_LOAD_DATA_VEHICLE:
      return {
        ...state,
        loading: false,
      };
    case Types.SAVE_SESSION:
      return {
        ...action.data,
      };
    default:
      return state;
  }
}

export function createSearchVehicle(data) {
  return {
    type: Types.CREATE_SEARCH_VEHICLE,
    data,
  };
}

export function setLoadingSearchVehicle() {
  return {
    type: Types.LOAD_DATA_VEHICLE,
  };
}

export function setErrorSearchVehicle() {
  return {
    type: Types.ERROR_DATA_VEHICLE,
  };
}

export function setSuccessSearchVehicle() {
  return {
    type: Types.SUCCESS_LOAD_DATA_VEHICLE,
  };
}

export function resetSearchVehicle() {
  return {
    type: Types.RESET_SEARCHVEHICLE,
  };
}

export function saveSearchVehicleSession(data) {
  return {
    type: Types.SAVE_SESSION,
    data,
  };
}
