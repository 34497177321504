/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';

const modalRoot = document.getElementById('modal-root');

const removeInert = () => {
  document.querySelector('.App').removeAttribute('inert');
  if (document.querySelector('.Header')) document.querySelector('.Header').removeAttribute('inert');
  document.querySelector('.footer').removeAttribute('inert');
};

const addInert = () => {
  document.querySelector('.App').setAttribute('inert', true);
  if (document.querySelector('.Header')) document.querySelector('.Header').setAttribute('inert', true);
  document.querySelector('.footer').setAttribute('inert', true);
};

const checkIfModalIsOpen = () => {
  let haveChildrens = false;

  modalRoot.childNodes.forEach(element => {
    if (element.childNodes.length !== 0) {
      haveChildrens = true;
    }
  });

  if (haveChildrens) {
    addInert();
  } else {
    removeInert();
  }
};

export default class ModalWrapperExtern extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentDidUpdate() {
    checkIfModalIsOpen();
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    const { opened, children } = this.props;
    if (!opened) return [];
    return ReactDOM.createPortal(<FocusTrap>{children}</FocusTrap>, this.el);
  }
}
