import { ANALYTICS_EVENT_ACTIONS, ANALYTICS_EVENT_CATEGORIES } from '../constants';

export const ANALYTICS_AUTOV2_FLOW = {
  A4: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Auto2.0:ExpandirPerguntaFrequente:{0}',
    noInteraction: false,
  },
  A5: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Auto2.0:VerMaisPerguntasFrequentes',
    noInteraction: false,
  },
};
