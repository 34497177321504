import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const whiteSmoke = '#f8fafd';
const black = '#000000';
const informationBackground = '#F3F3F3';
const inputLabelColor = '#605751';
const buttonActionColor = '#969290';
const focusBorderColor = 'rgba(0, 103, 244, 0.8)';
const inputDisableColor = 'rgba(0, 0, 0, 0.6)';
const inputPlaceholderColor = '#595959';
const wizardStepColor = '#007A47';
const wizardLineBorderColor = '#EFE9E5';
const wizardCurrentIconColor = '#008859';
const wizardStepHoverColor = 'rgba(0, 0, 0, 0.1)';
const wizardActiveStepHoverColor = 'rgba(0, 122, 71, 0.1)';
const wizardActiveStepTouchRippleColor = 'rgba(0, 122, 71, 0.3)';
const defaultTextColor = '#574f4a';
const mobileWizardTitleLabel = '#252220';
const sinisterInfoColor = '#31261E';
const focusColor = '#90BAFE';
const quotationCardHeaderBackground = '#212B3B';
const quotationCardContentBackground = '#2B374A';
const quotationCardLink = '#5FB0EA';
const backgroundSelect = '#F7F4F2';
const inputUnderlineColor = '#D9D3CF';
const bannerBackgroundColor = '#f1ae2f';
const bannerTextColor = '#2f240f';
const disabledColor = '#767676';
const juridicTextColor = '#2b374a';
const backgroundDarkBlue = '#2B374A';
const cardBackGroundFormBike = 'rgba(245, 245, 245, 0.7)';
const cardShadow = '0 0 2px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.18)';
const stripeGray = '#F3F3F3';
const backgroundBlueGradient =
  'linear-gradient(135deg, rgb(138, 179, 232) 0%, rgb(107, 149, 222) 50%, rgb(58, 96, 201) 100%)';
const backgroundOrangeGradient =
  'linear-gradient(135deg, rgb(233, 100, 13) 0%, rgba(233, 130, 8, 1) 50%, rgba(233, 155, 12, 1) 100%)';
const backgroundCredicardPaymentGradient = 'linear-gradient(100deg, #002FB4 0%, #087DB9 100%)';
const backgroundCredicardBoxShadow = '0 0 2px 0 rgba(0,0,0,0.16), 0 2px 8px 0 rgba(0,0,0,0.18)';
const closeModalButton = '#2B374A';
const darkGray = '#231d19';
const chatUserLabel = '#791b8e';
const authLinearGradient = 'linear-gradient(135deg, #E66100 0, #FEA100 100%)';
const homeBody = '#EFE9E5';
const chips = '#076EB2';
const timelineDescriptionColor = '#4A4A4A';

export default {
  black,
  white,
  whiteSmoke,
  wizardStepColor,
  informationBackground,
  wizardLineBorderColor,
  wizardCurrentIconColor,
  wizardStepHoverColor,
  wizardActiveStepHoverColor,
  wizardActiveStepTouchRippleColor,
  defaultTextColor,
  mobileWizardTitleLabel,
  sinisterInfoColor,
  quotationCardLink,
  backgroundSelect,
  focusColor,
  inputLabelColor,
  buttonActionColor,
  focusBorderColor,
  inputDisableColor,
  inputPlaceholderColor,
  inputUnderlineColor,
  bannerBackgroundColor,
  bannerTextColor,
  disabledColor,
  stripeGray,
  primary: {
    main: '#EC7000',
    light: '#EC7000',
    dark: '#DD762D',
    orange: '#EB7404',
    contrastText: white,
    black: '#252220',
    gray42: '#6B6B6B',
  },
  secondary: {
    contrastText: white,
    main: white,
    light: '#48a999',
    dark: '#004c40',
    black: '#2B2724',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: black,
    dark: '#F1AE2F',
    main: '#F1AE2F',
    light: '#F1AE2F',
  },
  error: {
    contrastText: white,
    light: '#531E6D',
    main: '#531E6D',
    dark: '#531E6D',
  },
  text: {
    primary: '#2b374a',
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: white,
    paper: white,
    light: '#EFE9E5',
    dark: '#E4DAD4',
  },
  link: {
    main: '#106EB0',
    light: '#106eb014',
  },
  productsColor: {
    odonto: {
      '0': '#8499A1',
      '1': '#00336E',
      '2': '#005F40',
      '3': '#F79000',
      '4': '#C59B47',
      '5': '#0452B7',
      '6': 'black',
    },
  },
  headerBackground: 'rgba(255, 255, 255, 0.98)',
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  quotationCardHeaderBackground,
  quotationCardContentBackground,
  juridicTextColor,
  cardBackGroundFormBike,
  cardShadow,
  backgroundBlueGradient,
  backgroundOrangeGradient,
  backgroundDarkBlue,
  backgroundCredicardPaymentGradient,
  backgroundCredicardBoxShadow,
  closeModalButton,
  darkGray,
  chatUserLabel,
  authLinearGradient,
  homeBody,
  chips,
  timelineDescriptionColor,
};
