import axios from 'axios';

import { performance } from '../../config';

export const getProductsLeads = async () => {
  const trace = performance.trace('getProductsLeads');
  trace.start();
  const response = await axios.get('v1/seguros/products/leads');
  trace.stop();
  return response.data;
};
