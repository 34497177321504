import { createSelector } from 'reselect';
import { landingDataSelector } from './landing';

export const odontoSelector = (state) => state?.odonto;

export const odontoSelectedPlanSelector = createSelector(odontoSelector, (item) => item?.selectedPlan);

export const odontoDivergentDataHolderSelector = createSelector(odontoSelector, (item) => item?.odontoDivergentDataHolder);
export const odontoDivergentDataHolderContinueButtonSelector = createSelector(odontoSelector, (item) => item?.odontoDivergentDataHolderContinueButton);

export const odontoAvailablePlansSelector = createSelector(odontoSelector, (item) => item?.availablePlans);

export const odontoProductInformationSelector = createSelector(odontoSelector, (item) => item?.productInformation);

export const odontoPaymentTypeSelector = createSelector(odontoSelector, (item) => item?.paymentType);

export const odontoGenderSelector = createSelector(odontoSelector, (item) => item?.gender);

export const odontoQuotationSelector = (state) => state.quotation;

export const odontoQuotationCitiesList = (item) => item.cities;

export const odontoQuotationStatesList = (item) => item.states;

export const odontoIsYoungPlanSelector = createSelector(odontoQuotationSelector, (item) => item?.para_menores_de_idade);

export const odontoIdProductSelector = createSelector(odontoQuotationSelector, (item) => item?.id_produto);

export const odontoInsuranceHolderSelector = createSelector(odontoQuotationSelector, (item) =>
  item?.beneficiarios.find((e) => e.titular)
);

export const odontoInsuranceBeneficiariesSelector = createSelector(
  odontoQuotationSelector,
  (item) => item?.beneficiarios
);

export const odontoInsuranceBeneficiariesCpfSelector = createSelector(odontoInsuranceBeneficiariesSelector, (item) =>
  item?.map((i) => i?.cpf_beneficiario)
);

export const odontoInsuranceDependentsSelector = createSelector(odontoQuotationSelector, (item) =>
  item?.beneficiarios.filter((e) => !e.titular)
);

export const odontoInsuranceDependentsNameSelector = createSelector(odontoInsuranceDependentsSelector, (item) =>
  item?.map((dependent) => dependent?.nome_completo)
);

export const odontoInsuranceTotalPriceSelector = createSelector(
  odontoQuotationSelector,
  (item) => item?.valor_premio_total
);

export const odontoInsuranceDiscountedUnitPriceSelector = createSelector(
  odontoQuotationSelector,
  (item) => item?.valor_premio_unitario_liquido
);

export const odontoInsuranceDiscountedTotalPriceSelector = createSelector(
  odontoQuotationSelector,
  (item) => item?.valor_premio_total_liquido
);

export const odontoJuridicTextSelector = createSelector(odontoSelector, (item) => item?.juridicText);

export const odontoKinshipSelector = createSelector(
  odontoSelector,
  (item) =>
    item?.kinship?.map((res) => ({
      label: res.descricao,
      value: res.codigo,
    })) || []
);

export const odontoPlanCampaignSelector = createSelector(
  odontoSelectedPlanSelector,
  landingDataSelector,
  (selectedPlan, landingData) => landingData?.find((i) => i.id === selectedPlan.id)?.campaign
);
