import { call, put, select } from 'redux-saga/effects';
import scopes from 'auth/scopes';
import { authenticationV2 } from 'api/E2E/Authentication/authV2';
import { Types } from 'store/auto/store/ducks/auth';
import {
  autoSelectors,
  automobileSelectedSimulationIDSelector,
  automobileHiringProponentSelector,
} from 'store/auto/store/sagas/selectors/auto';
import { searchVehicleSelector } from 'store/auto/store/sagas/selectors/searchVehicle';
import { suspenseListsSelectors } from 'store/auto/store/sagas/selectors/suspenseList';

import { autoSelectors as autoFlowSelector } from 'store/auto/store/sagas/selectors/autoFlow';

export default function* auth(action) {
  const { data } = action;
  try {
    const idSimulacao = yield select(automobileSelectedSimulationIDSelector);
    const proponente = yield select(automobileHiringProponentSelector);
    const autoSession = yield select(autoSelectors);
    const autoFlow = yield select(autoFlowSelector);
    const searchVehicle = yield select(searchVehicleSelector);
    const suspenseLists = yield select(suspenseListsSelectors);
    const email = proponente.contatos.find(c => c.tipo === 'email');
    const telefone = proponente.contatos.find(c => c.tipo === 'telefone');
    const dadosParaAutenticacao = {
      id_simulacao: idSimulacao,
      usuario: {
        nome: proponente.nome,
        cpf: proponente.cpf,
        email: email.valor,
        telefone: telefone.valor,
      },
      captcha: {
        ...data,
      },
      sessao: {
        automovel: {
          ...autoSession,
        },
        autoFlow: {
          ...autoFlow,
        },
        searchVehicle: {
          ...searchVehicle,
        },
        suspenseLists: {
          ...suspenseLists,
        },
      },
    };
    yield call(authenticationV2, dadosParaAutenticacao, scopes.AUTO);
    yield put({ type: Types.AUTH_AUTO_SUCCESS });
  } catch (err) {
    yield put({ type: Types.AUTH_AUTO_ERROR });
  }
}
