import {
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ENVIRONMENTS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
} from 'trackingV2/constants';

export const ANALYTICS_CANCELLATION = {
  T1: {
    nome: 'LS:NL:NCC:Cancelamento:Form',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'BTN:Cancelamento:Form:Continuar',
    noInteraction: false,
  },
  T2: {
    nome: 'LS:NL:NCC:Cancelamento:Autenticacao',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A2: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'BTN:Cancelamento:Autenticacao:Passo1:Voltar',
    noInteraction: false,
  },
  A3: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'BTN:Cancelamento:Autenticacao:Passo1:Continuar',
    noInteraction: false,
  },
  A4: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'BTN:Cancelamento:Autenticacao:Passo2:Continuar',
    noInteraction: false,
  },
  A5: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'BTN:Cancelamento:Autenticacao:Passo2:ReenviarCodigo',
    noInteraction: false,
  },
  T3: {
    nome: 'LS:NL:NCC:Cancelamento:ProdutosContratados',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A6: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'CRD:Cancelamento:ProdutosContratados:{0}',
    noInteraction: true,
  },
  A7: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'BTN:Cancelamento:ProdutosContratados:Cancelar:{0}',
    // label: 'BTN:Cancelamento:ProdutosContratados:Cancelar:[NomeDoServico]',
    noInteraction: false,
  },
  T4: {
    nome: 'LS:NL:NCC:Cancelamento:Confirmacao',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A8: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'BTN:Cancelamento:Confirmacao:VerificarCondicoesDeCancelamento',
    noInteraction: false,
  },
  A9: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'BTN:Cancelamento:Confirmacao:Voltar',
    noInteraction: false,
  },
  A10: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'BTN:Cancelamento:Confirmacao:Confirmar:{0}',
    // label: 'BTN:Cancelamento:Confirmacao:Confirmar:[MotivoDoCancelamento]',
    noInteraction: false,
  },
  T5: {
    nome: 'LS:NL:NCC:Cancelamento:Sucesso',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A11: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.CANCELLATION,
    label: 'Alerta:Cancelamento:Sucesso',
    noInteraction: true,
  },
};
