import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createEffects } from '@zup-next/redux-resource';
import { composeWithDevTools as composeWithDevToolsDev } from 'redux-devtools-extension/developmentOnly';
import { composeWithDevTools as composeWithDevToolsProd } from 'redux-devtools-extension/logOnlyInProduction';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import mapValues from 'lodash/mapValues';
import resources from './resources';
import createLead from './sagas/lead';
import ducksReducers from './ducks';
import loadAvailablePlans from './sagas/landing/odonto';
import loadAssistanceSimulation from './sagas/resid/assistanceSimulation';
import loadSimulation, { setInitialPreSelectedCoverage } from './sagas/resid/simulation';
import setResidAdditionalInformations from './sagas/resid/setResidAdditionalInformations';
import auth from './sagas/auth/auth';
import loadHiring from './sagas/resid/hiring';
import { Types as ResidencialTypes } from './ducks/residencial';
import { Types as RecoveryE2ETypes } from './ducks/recoveryE2E';
import { Types as EligibilityTypes } from './ducks/eligibility';
import { Types as ProductsTypes } from './ducks/products';
import updateQuotationPrice from './sagas/resid/updateQuotationPrice';
import onToggleCoverages from './sagas/resid/toggleCoverages';
import recoveryE2E from './sagas/auth/recoveryE2EData';
import { Types as HttpErrorsTypes } from './ducks/httpErrors';
import resetAppStoreOn401 from './sagas/application/httpErrors';
import setInstallmentPaymentTypeAction from './sagas/resid/setInstallmentPaymentTypeAction';
import callEligibilityAction from './sagas/eligibility/callEligibilityAction';
import { petEffects, welfareEffects } from './sagas/realiza/effects';
import loadProductsLeads from './sagas/products/leads';
import asyncQuotationEffects from './sagas/asyncQuotation/effects';
import processAsyncSimulationSaga from './sagas/resid/processAsyncSimulationSaga';
import validateUserDataToAsyncQuotationFlow from './sagas/resid/validateUserDataToAsyncQuotationFlowSaga';
import {createCart, createResidCart, deleteCart, deleteResidCart} from './sagas/cart';
import { Types as CartTypes } from './ducks/cart';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: [
    'quotation',
    'wizardStep',
    'odonto',
    'authScopes',
    'cancellation',
    'resid',
    'residQuotation',
    'landing',
    'residCoverages',
    'channel',
    'residencial',
    'auth',
    'coberturas',
    'eligibility',
    'welfare',
    'pet',
    'products',
    'asyncQuotation',
  ],
};

const reducers = combineReducers({
  ...mapValues(resources, 'reducer'),
  ...ducksReducers,
});

const sagas = function* run() {
  yield createEffects({
    ...resources.faq.sagas,
    ...resources.partner.sagas,
    ...resources.product.sagas,
    ...resources.home.sagas,
    ...resources.landing.sagas,
    ...resources.states.sagas,
    ...resources.payments.sagas,
    ...resources.cities.sagas,
    ...resources.cep.sagas,
    ...resources.residCoverages.sagas,
    ...resources.allowedChannels.sagas,
    [ResidencialTypes.RESET_PRE_SELECTED_COVERAGES]: setInitialPreSelectedCoverage,
    'RESID_COVERAGES/LOAD_SUCCESS': setInitialPreSelectedCoverage,
    'LEAD/CREATE': createLead,
    'odonto/LOAD_AVAILABLE_PLANS': loadAvailablePlans,
    'residencial/LOAD_ASSISTANCE_SIMULATION': loadAssistanceSimulation,
    'residencial/LOAD_HIRING': loadHiring,
    'residencial/LOAD_SIMULATION': loadSimulation,
    'residencial/SET_ADDITIONAL_INFORMATIONS': setResidAdditionalInformations,
    'auth/AUTH_RESID': auth,
    [ResidencialTypes.HANDLE_SELECTED_COVERAGE]: updateQuotationPrice,
    [ResidencialTypes.SET_SELECTED_ASSISTANCE]: updateQuotationPrice,
    [ResidencialTypes.SET_INSTALLMENT_OPTION]: updateQuotationPrice,
    [ResidencialTypes.RESET_INSTALLMENT_OPTION]: updateQuotationPrice,
    [ResidencialTypes.TOGGLE_PRE_SELECTED_COVERAGES]: updateQuotationPrice,
    [ResidencialTypes.ON_TOGGLE_COVERAGES]: onToggleCoverages,
    [ResidencialTypes.SET_COVERAGES]: updateQuotationPrice,
    [RecoveryE2ETypes.RECOVERY_E2E_START]: recoveryE2E,
    [HttpErrorsTypes.SET_ERROR]: resetAppStoreOn401,
    [ResidencialTypes.SET_INSTALLMENT_PAYMENT_TYPE]: setInstallmentPaymentTypeAction,
    [EligibilityTypes.CALL_ELIGIBILITY]: callEligibilityAction,
    ...petEffects,
    ...welfareEffects,
    [ProductsTypes.LOAD_PRODUCTS_LEADS]: loadProductsLeads,
    [ResidencialTypes.PROCESS_ASYNC_SIMULATION_SAGA]: processAsyncSimulationSaga,
    [ResidencialTypes.VALIDATE_USE_DATA_TO_ASYNC_QUOTATION_FLOW_SAGA]: validateUserDataToAsyncQuotationFlow,
    ...asyncQuotationEffects,
    [CartTypes.CREATE_CART]: createCart,
    [CartTypes.DELETE_CART]: deleteCart,
    [CartTypes.CREATE_RESID_CART]: createResidCart,
    [CartTypes.DELETE_RESID_CART]: deleteResidCart,
  });
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
  let store;
  if (process?.env?.REACT_APP_ENVIRONMENT === 'development') {
    store = createStore(
      persistedReducer,
      composeWithDevToolsProd({ actionsWhitelist: '@@INIT' })(applyMiddleware(sagaMiddleware)),
    );
  } else {
    store = createStore(persistedReducer, composeWithDevToolsDev(applyMiddleware(sagaMiddleware)));
  }
  sagaMiddleware.run(sagas);
  const persistor = persistStore(store);

  return {
    store,
    persistor,
    sagaMiddleware,
    sagas,
    persistedReducer,
  };
};
