import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_ENVIRONMENTS,
} from 'trackingV2/constants';

export const ANALYTICS_PET = {
  T1: {
    nome: 'LS:NL:NCC:Pet:DadosDoTitular',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:CardDoSeguro:{0}:{1}',
    noInteraction: false,
  },
  A2: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:CardDoSeguro:CondicoesGerais',
    noInteraction: false,
  },
  A3: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:DadosTitular:Passo1:Voltar',
    noInteraction: false,
  },
  A4: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:DadosTitular:Passo1:Continuar',
    noInteraction: false,
  },
  T2: {
    nome: 'LS:NL:NCC:Pet:Validacao',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A5: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Validacao:Passo1:Voltar',
    noInteraction: false,
  },
  A6: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Validacao:Passo1:Continuar',
    noInteraction: false,
  },
  A7: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:ReenviarEmail',
    noInteraction: false,
  },
  A8: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:AlterarEmail',
    noInteraction: false,
  },
  A9: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Validacao:Passo2:Voltar',
    noInteraction: false,
  },
  A10: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:ReenviarCodigo',
    noInteraction: false,
  },
  A11: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Validacao:Passo3:Continuar',
    noInteraction: false,
  },
  T3: {
    nome: 'LS:NL:NCC:Pet:Pagamento',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A12: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:CondicoesGerais',
    noInteraction: false,
  },
  A13: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Pagamento:Voltar',
    noInteraction: false,
  },
  A14: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Pagamento:Continuar',
    noInteraction: false,
  },
  A15: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:CardDoSeguro:{0}:{1}',
    noInteraction: false,
  },
  A16: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:CardDoSeguro:CondicoesGerais',
    noInteraction: false,
  },
  T4: {
    nome: 'LS:NL:NCC:Pet:Resumo',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A17: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Resumo:Voltar',
    noInteraction: false,
  },
  A18: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Resumo:Finalizar',
    noInteraction: false,
  },
  T5: {
    nome: 'LS:NL:NCC:Pet:Sucesso',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A19: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'Alerta:Pet:Sucesso',
    noInteraction: true,
  },
  A20: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Likert:{0}',
    noInteraction: false,
  },
  A21: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Plano:PacotePrincipal:{0}',
    noInteraction: true,
  },
  A22: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Pagamento:{0}',
    noInteraction: true,
  },
};
