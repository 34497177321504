import { makeStyles } from '@material-ui/core/styles';

export const useStylesPopUp = makeStyles(({ palette, breakpoints }) => ({
  wrapper: {
    fontFamily: 'ItauText-Light',
    backgroundColor: palette.black,
    display: 'flex',
    fontSize: '24.96px',
    flexDirection: 'column',
    color: palette.white,
    alignItems: 'center',
    border: '3px solid #fff',
    height: '450px',
    margin: 'auto',
    [breakpoints.up('md')]: {
      height: 440,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    [breakpoints.up('lg')]: {
      height: 440,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  wrapperContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '25px',
    marginTop: '-20px',
    [breakpoints.up('md')]: {
      marginTop: 0,
      marginLeft: 0,
      flexDirection: 'column',
    },
    [breakpoints.up('lg')]: {
      marginTop: 0,
      marginLeft: 0,
      flexDirection: 'column',
    },
  },
  wrapperDiscount: {
    marginTop: '10px',
    [breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '40px',
    },
    [breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
    },
  },
  mainMessage: {
    width: 187,
    lineHeight: '1.5rem',
    '& p': {
      margin: 0,
    },
    [breakpoints.up('md')]: {
      fontSize: '48px',
      width: '320px',
      lineHeight: '3rem',
    },
    [breakpoints.up('lg')]: {
      fontSize: '48px',
      width: '320px',
      lineHeight: '3rem',
    },
  },
  deadline: {
    width: 187,
    '& p': {
      margin: 0,
      marginLeft: '5px',
    },
    [breakpoints.up('md')]: {
      width: '320px',
      marginTop: '10px',
      lineHeight: '1.6rem',
      marginLeft: '-10px',
    },
    [breakpoints.up('lg')]: {
      width: '300px',
      lineHeight: '1.6rem',
      marginTop: '10px',
      marginLeft: '-10px',
    },
  },
  discount: {
    fontFamily: 'ItauText-XBold',
    width: 187,
    lineHeight: '1.7rem',
    marginTop: '-10px',
    display: 'flex',
    '& p': {
      margin: 0,
    },
    [breakpoints.up('md')]: {
      marginTop: 0,
      marginLeft: '-10px',
      lineHeight: '2.6rem',
    },
    [breakpoints.up('lg')]: {
      marginTop: 0,
      marginLeft: '-10px',
      lineHeight: '2.6rem',
    },
  },
  discountValue: {
    fontSize: '86px',
    lineHeight: '5.7rem',
    color: palette.primary.main,
    [breakpoints.up('md')]: {
      fontSize: '171px',
      lineHeight: '4.7rem',
    },
    [breakpoints.up('lg')]: {
      fontSize: '171px',
      lineHeight: '4.7rem',
    },
  },
  plans: {
    fontSize: '15px',
    [breakpoints.up('md')]: {
      fontSize: '31.5px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '31.5px',
    },
  },
  time: {
    fontSize: '8px',
    [breakpoints.up('md')]: {
      fontSize: '14px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '14px',
    },
  },
  sign: {
    fontSize: '32px',
    margin: 0,
    marginTop: '13px',
    color: palette.primary.main,
    [breakpoints.up('md')]: {
      fontSize: '57px',
      marginTop: 0,
      marginBottom: '13px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '57px',
      marginTop: 0,
      marginBottom: '13px',
    },
  },
  screenReader: {
    visibility: 'hidden',
  },
  imgDesktop: {
    height: '350px',
    width: 'auto',
  },
  paragraphPorcent: {
    marginTop: '10px !important',
    [breakpoints.up('md')]: {
      marginTop: 0,
    },
    [breakpoints.up('lg')]: {
      marginTop: 0,
    },
  },
  paragraphSign: {
    marginTop: '4px',
    [breakpoints.up('md')]: {
      marginTop: '15px',
    },
    [breakpoints.up('lg')]: {
      marginTop: '15px',
    },
  },
}));
