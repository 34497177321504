import axios, { AxiosResponse } from 'axios';
import config from 'config';

export const getPageContent = async (contentType: string, environment: string) => {
  const { CONTENTSTACK_API_KEY, CONTENTSTACK_ACCESS_TOKEN }: any = config;
  const response: AxiosResponse = await axios.get(
    `https://cdn.contentstack.io/v3/content_types/${contentType}/entries?environment=${environment}`,
    {
      headers: {
        api_key: CONTENTSTACK_API_KEY,
        access_token: CONTENTSTACK_ACCESS_TOKEN,
      },
    },
  );
  return response.data;
};
