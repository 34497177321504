import produce from 'immer';

const MODULE_NAME = 'asyncQuotation';

const initialState = {
  email: {
    enviando: false,
    erro: false,
    sucesso: false,
  },
  cotacao: {
    carregando: false,
    erro: false,
    sucesso: false,
  },
};

export const Types = {
  EMAIL_SUBMIT_SAGA_ACTION: `${MODULE_NAME}/EMAIL_SUBMIT_SAGA_ACTION`,
  SENDING_EMAIL: `${MODULE_NAME}/SENDING_EMAIL`,
  EMAIL_SENT: `${MODULE_NAME}/EMAIL_SENT`,
  FAILED_TO_SEND_EMAIL: `${MODULE_NAME}/FAILED_TO_SEND_EMAIL`,
  LOADING_QUOTATION: `${MODULE_NAME}/LOADING_QUOTATION`,
  SUCCESS_QUOTATION: `${MODULE_NAME}/SUCCESS_QUOTATION`,
  FAILED_QUOTATION: `${MODULE_NAME}/FAILED_QUOTATION`,
  RESET: `${MODULE_NAME}/RESET`,
};

export default produce((state = initialState, action) => {
  switch (action.type) {
    case Types.RESET: {
      return {
        ...state,
        email: initialState.email,
        cotacao: initialState.cotacao,
      };
    }
    case Types.SENDING_EMAIL: {
      state.email.enviando = true;
      state.email.erro = false;
      state.email.sucesso = false;
      return state;
    }
    case Types.EMAIL_SENT: {
      state.email.enviando = false;
      state.email.erro = false;
      state.email.sucesso = true;
      return state;
    }
    case Types.FAILED_TO_SEND_EMAIL: {
      state.email.enviando = false;
      state.email.erro = true;
      state.email.sucesso = false;
      return state;
    }
    case Types.LOADING_QUOTATION: {
      state.cotacao.carregando = true;
      state.cotacao.erro = false;
      state.cotacao.sucesso = false;
      return state;
    }
    case Types.SUCCESS_QUOTATION: {
      state.cotacao.carregando = false;
      state.cotacao.erro = false;
      state.cotacao.sucesso = true;
      return state;
    }
    case Types.FAILED_QUOTATION: {
      state.cotacao.carregando = false;
      state.cotacao.erro = true;
      state.cotacao.sucesso = false;
      return state;
    }
    default:
      return state;
  }
});

export function emailSubmitSagaAction(data) {
  return {
    type: Types.EMAIL_SUBMIT_SAGA_ACTION,
    data,
  };
}

export function sendingEmail() {
  return {
    type: Types.SENDING_EMAIL,
  };
}

export function emailSent() {
  return {
    type: Types.EMAIL_SENT,
  };
}

export function failedToSendEmail() {
  return {
    type: Types.FAILED_TO_SEND_EMAIL,
  };
}

export function loadingQuotation() {
  return {
    type: Types.LOADING_QUOTATION,
  };
}

export function successQuotation() {
  return {
    type: Types.SUCCESS_QUOTATION,
  };
}

export function failedQuotation() {
  return {
    type: Types.FAILED_QUOTATION,
  };
}

export function reset() {
  return {
    type: Types.RESET,
  };
}
