import ANALYTICS_CATEGORY from '../category.json';

export default {
  GENERATE_LEAD: {
    action: 'generate_lead',
    label: 'BTN:Resid:GerarLead',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_PROPERTY_INFORMATION: {
    action: 'resid_property_information',
    label: 'BTN:Resid:Info',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  PROMOTIONINDOOR: {
    action: 'promotionindoor',
    label: 'BTN:Residencial:FaçaCotação',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_COVERAGE_CHOICE: {
    action: 'resid_coverage_choice',
    label: 'BTN:Resid:Cobertura',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_PROPERTY_DATA: {
    action: 'resid_property_data',
    label: 'BTN:Resid:VerPrecoSeguro',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_UNAVAILABLE_INSURANCE: {
    action: 'resid_unavailable_insurance',
    label: 'BTN:Resid:SeguroNaoDisponivel',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_COVERAGE_PERSONALIZATION: {
    action: 'resid_coverage_personalization',
    label: 'BTN:Resid:EscolherValorCobertura',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_COVERAGE_VALIDATION: {
    action: 'resid_coverage_validation',
    label: 'BTN:Resid:CoberturaValidacao',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_PERSONALIZATION_RECALCULATE: {
    action: 'resid_personalization_recalculate',
    label: 'BTN:Resid:RecalcularValorCobertura',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_PERSONALIZATION_BACK: {
    action: 'resid_personalization_back',
    label: 'BTN:Resid:RecalcularValorCobertura:Voltar',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_ASSISTANCE_CHOICE_ACCORDEON: {
    action: 'resid_assistance_choice_accordeon',
    label: 'BTN:Resid:Assistencia:DetalhesDoServico',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  RESID_ASSISTANCE_CHOICE: {
    action: 'resid_assistance_choice',
    label: 'BTN:Resid:Assistencia',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_RESID_AUTH_INFORMATION: {
    action: 'checkout_progress_resid_auth_information',
    label: 'BTN:Resid:Autenticacao',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_RESID_AUTH_EMAIL_RESEND: {
    action: 'checkout_progress_resid_auth_email_resend',
    label: 'BTN:Resid:ReenviarEmailAutenticacao',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_RESID_AUTH_EMAIL: {
    action: 'checkout_progress_resid_auth_email',
    label: 'BTN:Resid:EnviarEmailAutenticacao',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_RESID_AUTH_CELPHONE_RESEND: {
    action: 'checkout_progress_resid_auth_celphone_resend',
    label: 'BTN:Resid:ReenviarTelefoneAutenticacao',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_RESID_AUTH_CELPHONE: {
    action: 'checkout_progress_resid_auth_celphone',
    label: 'BTN:Resid:EnviarTelefoneAutenticacao',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_RESID_PERSONAL_DATA: {
    action: 'checkout_progress_resid_personal_data',
    label: 'BTN:Resid:Contratar:DadosPessoais',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_RESID_PAYMENT: {
    action: 'checkout_progress_resid_payment',
    label: 'BTN:Resid:Contratar:Pagamento',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  ADD_PAYMENT_INFO: {
    action: 'add_payment_info',
    label: 'BTN:Resid:EscolhaPagamento',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_RESID_SUMMARY: {
    action: 'checkout_progress_resid_summary',
    label: 'BTN:Resid:Contratar:Resumo',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  PURCHASE: {
    action: 'purchase',
    label: 'BTN:Resid:Contratar:Sucesso',
    category: ANALYTICS_CATEGORY.SUCESSO,
  },
};
