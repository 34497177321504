import ItaucardInsurance from '../../../../assets/images/itaucard-insurance.svg';
import OdontoItauCardIcon from '../../../../assets/images/odonto-credicard.jpg';
import logoSegurosItau from '../../../../assets/images/logo-seguros-itau-cinza.png';

function createStyleMapper({ palette }) {
  return {
    logo: {
      color: palette.default.main,
      width: 200,
      heightLG: '75px',
      widthLG: '300px',
    },
    advertisement: {
      widthMD: '247px',
      widthLG: '247px',
    },
    welcomeText: {
      color: palette.default.main,
      widthMD: '125px',
      widthLG: '125px',
    },
    welcomeIdentification: {
      image: OdontoItauCardIcon,
      backgroundPositionMD: 'top 40% right 43%',
      backgroundPositionLG: '0 60%',
    },
    visitorText: {
      color: palette.default.main,
    },
    descriptionText: {
      color: palette.default.main,
    },
    cardLogo: {
      color: palette.default.main,
      height: 23,
      width: '200px',
      image: ItaucardInsurance,
      marginTop: 30,
      marginBottom: 30,
      heightMD: 50,
      heightLG: 65,
      widthMD: '440px',
      widthLG: '565px',
    },
    cardTitle: {
      color: palette.default.main,
    },
    cardSubtitle: {
      color: palette.default.main,
    },
    cardItemCircle: {
      background: palette.default.main,
      color: palette.default.contrastText,
    },
    cardItemText: {
      color: palette.default.main,
    },
    cardActionButton: {
      color: palette.default.contrastText,
      background: palette.default.main,
    },
    cardBorder: {
      background: palette.secondary.main,
    },
    cardWrapper: {
      background: {
        dark: palette.primary.main,
        light: palette.primary.light,
      },
      color: palette.primary.contrastText,
    },
    footer: {
      image: logoSegurosItau,
      backGroundColor: palette.footer.backGroundColor,
      font: {
        colorHeading: palette.footer.colorHeading,
        colorArrow: palette.footer.colorArrow,
        color: palette.footer.color,
        weight: 'ItauText-Regular',
      },
    },
  };
}

export default createStyleMapper;
