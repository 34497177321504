import credicardImg from './credicard.png';
import itaucardImg from './itaucard.svg';
import superappImg from './superapp.svg';

export const logotype = {
  credicard: {
    image: credicardImg,
    title: 'Credicard',
  },
  credicardresidencial: {
    image: credicardImg,
    title: 'Credicard',
  },
  itaucard: {
    image: itaucardImg,
    title: 'Itaucard',
  },
  itaucardresidencial: {
    image: itaucardImg,
    title: 'Itaucard',
  },
  superapp: {
    image: superappImg,
    title: 'Itaú',
  },
};
