const authentication = {
  USER_FORM: {
    AUTHENTICATION: '**autenticação** de dados',
    DESCRIPTION:
      'nos preocupamos com a sua segurança e para garantir que os dados são seus, vamos confirmá-los em 2 passos',
  },
  PIN_PAGE: {
    FIRST_STEP: {
      TITLE: 'passo 1',
      SUBTITLE: 'o e-mail {{email}} foi autenticado',
    },
    SECOND_STEP: {
      TITLE: 'passo 2',
      SUBTITLE: 'validando seu número de celular',
      DESCRIPTION_FIRST: '• foi enviado SMS para: ',
      DESCRIPTION_SECOND: 'agora é só colocar no campo abaixo o código recebido',
      NOT_RECIEVED: 'não recebeu?',
      CELLPHONE_VALIDATION: 'Necessário informar o código de oito dígitos para continuar',
    },
  },
  EMAIL_STEP: {
    SEND_EMAIL: 'foi enviado um e-mail para:',
    TO_CONTINUE: 'para continuar clique no link de confirmação enviado para sua caixa de entrada',
  },
};

export default authentication;
