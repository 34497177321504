import httpErrors from 'store/ducks/httpErrors';
import modal from 'store/auto/store/ducks/modal';
import autoFlow from 'store/auto/store/ducks/autoFlow';
import automovel from 'store/auto/store/ducks/automovel';
import questions from 'store/auto/store/ducks/questions';
import searchVehicle from 'store/auto/store/ducks/searchVehicle';
import suspenseLists from 'store/auto/store/ducks/suspenseLists';
import franchise from 'store/auto/store/ducks/franchise';
import auth from 'store/auto/store/ducks/auth';
import inspection from 'store/auto/store/ducks/inspection';

export default {
  autoFlow,
  auth,
  modal,
  automovel,
  questions,
  searchVehicle,
  suspenseLists,
  franchise,
  inspection,
  httpErrors,
};
