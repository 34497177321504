import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { scrollTo } from '../utils/util';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/prop-types
    const { location } = this.props;
    // eslint-disable-next-line react/prop-types
    if (location !== prevProps.location) {
      scrollTo(0, 0, 'auto');
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollToTop);
