import { call, put } from 'redux-saga/effects';
import { Types as WizardStepTypes } from 'store/ducks/wizardStep';
// import { Types as QuotationTypes } from 'store/ducks/quotation';
// import { Types as OdontoTypes } from 'store/ducks/odonto';
import { Types as RevoveryE2ETypes } from 'store/ducks/recoveryE2E';
// import { detailProposal } from 'api/E2E/Odonto/Proposal/detailProposal';
// import { normalizeCEP } from 'utils/util';
// import { getCep } from 'api/Cep/cep';
// import { getCities } from 'api';
import { validateHash } from 'api/E2E/Authentication/email';
import recoveryAuthScope from '../auth/authScopes';

const odontoAuthStep = 2;

export default function* recoveryE2EOdonto(action) {
  try {
    const { hash, captcha } = action.data;

    yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_LOAD });

    const validatedScope = yield call(recoveryAuthScope, action.data);
    if (!validatedScope) {
      yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_ERROR });
      return;
    }

    yield call(validateHash, { hash, captcha });
    // const proposalDetailData = yield call(
    //   detailProposal,
    //   proposalData.data.id_contratacao,
    //   proposalData.data.cpf,
    //   hash,
    // );
    yield put({
      type: WizardStepTypes.SET_WIZARD_STEP,
      data: odontoAuthStep,
    });

    // const owner = proposalDetailData.beneficiarios.find(e => e.titular);
    // const normalizedOwnerCEP = normalizeCEP(owner.enderecos[0].cep);
    // const ownerAddress = yield call(getCep, normalizedOwnerCEP);

    /* fixing IR6 gaps */
    // const normalizedBeneficiaries = proposalDetailData.beneficiarios.map(beneficiary => {
    //   if (!beneficiary.titular) return beneficiary;
    //   return {
    //     ...beneficiary,
    //     enderecos: [
    //       {
    //         ...beneficiary.enderecos[0],
    //         cidade: ownerAddress.localidade,
    //       },
    //     ],
    //   };
    // });

    // const quotationData = {
    //   ...proposalDetailData,
    //   beneficiarios: normalizedBeneficiaries,
    //   inicio_vigencia: undefined,
    //   efetivada: false,
    //   valor_premio: proposalDetailData.valor_premio_total,
    // };
    // yield put({
    //   type: QuotationTypes.START_QUOTATION,
    //   data: quotationData,
    // });

    // const citiesByOwnerUF = yield call(getCities, ownerAddress.uf);
    // const normalizedOwnerCity = citiesByOwnerUF.find(
    //   e => e.label.toLowerCase() === ownerAddress.localidade.toLowerCase(),
    // );

    // const productInformationData = {
    //   idProduct: proposalDetailData.id_produto,
    //   formatedCity: normalizedOwnerCity.value.nomeFormatado,
    //   idCity: normalizedOwnerCity.value.id,
    //   idUF: ownerAddress.uf,
    // };
    // yield put({
    //   type: OdontoTypes.UPDATE_PRODUCT_INFORMATION,
    //   data: productInformationData,
    // });

    yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_SUCCESS });
  } catch (err) {
    yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_ERROR });
  }
}
