import useStyles from './style';

const Tag = ({ text, enabled, type }) => {
  const styles = useStyles();
  if (enabled) {
    switch (type) {
      case 'piercing':
        return (
          <div className={styles.piercing_container}>
            <p className={styles.piercing_text}>{text ?? '20% cashback'}</p>
          </div>
        );
      default:
        return (
          <div className={styles.basic_container}>
            <p className={styles.basic_text}>{text ?? '20% cashback'}</p>
          </div>
        );
    }

  }
  return <></>

};

export default Tag;
