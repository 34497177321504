import { call, put } from 'redux-saga/effects';
import { createLeads } from '../../../api';
import { analyticsTrackLoad } from '../../../utils/analytics';
import { openModalMessagedata } from '../../ducks/modal';
import { showMessage } from '../../ducks/message';
import { Types } from '../../ducks/lead';

// Adicionar regra de criação dos leads aqui e depois ver se o form foi com sucesso
export default function* createLead(action) {
  const { payload, analyticsObject, isModalMessage, successMessage, captcha } = action;
  let { errorMessage } = action;
  try {
    yield put({
      type: Types.CREATE_PENDING,
      payload,
    });
    analyticsTrackLoad(analyticsObject || window.analyticsData);
    const submitPayload = {
      ...payload,
      captcha,
    };
    yield call(createLeads, submitPayload);
    yield put({
      type: Types.CREATE_SUCCESS,
      payload,
    });
    if (isModalMessage) {
      yield put(openModalMessagedata(successMessage));
    } else {
      yield put(showMessage(successMessage));
    }
  } catch (e) {
    const { mensagem } = e;
    yield put({
      type: Types.CREATE_ERROR,
      payload,
    });
    if (mensagem) {
      errorMessage = {
        ...errorMessage,
        message: mensagem,
      };
    } else {
      errorMessage = {
        ...errorMessage,
        message: 'Ocorreu um erro tente novamente mais tarde',
      };
    }
    if (isModalMessage) {
      yield put(openModalMessagedata(errorMessage));
    } else {
      yield put(showMessage(errorMessage));
    }
  }
}
