import palette from '../palette';

export default {
  bar: {
    borderRadius: '2px',
  },
  colorPrimary: { backgroundColor: palette.wizardLineBorderColor },
  barColorPrimary: {
    backgroundColor: palette.wizardStepColor,
  },
};
