import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { scrollTo } from '../../utils/util';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    display: 'flex',
    padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    alignItems: 'center',
    backgroundColor: theme.palette.bannerBackgroundColor,
    borderRadius: 0,
  },
  text: {
    fontSize: theme.toRem(18),
    color: theme.palette.bannerTextColor,
  },
  button: {
    color: theme.palette.bannerTextColor,
    padding: 0,
    '& svg': {
      fontSize: theme.toRem(44),
    },
  },
  errorIcon: {
    fontSize: theme.toRem(36),
    marginRight: theme.spacing(2),
  },
}));

function BannerInfo({ message, onClick, styles, type, errors, submitCount, bannerRef }) {
  const classes = useStyles();
  const justifyContent = onClick ? 'space-between' : 'flex-start';

  const paperRef = useRef(null);

  const props = {};

  if (type === 'alert') {
    props.role = 'alert';
  }

  useEffect(() => {
    setTimeout(() => {
      if (paperRef && paperRef.current) {
        if (type === 'error' && submitCount >= 0) {
          paperRef.current.focus();
          paperRef.current.removeAttribute('aria-hidden');
        } else if (type === 'alert') {
          scrollTo(paperRef.current.offsetTop - 500);
        }
      }
    }, 100);
  }, [type, submitCount]);

  const handleBlur = () => {
    paperRef.current.setAttribute('aria-hidden', 'true');
  };

  return (
    <Box
      className={clsx(classes.root, styles)}
      style={{
        justifyContent,
      }}
      data-testid="BannerInfo"
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        ref={paperRef}
        aria-live="polite"
        aria-atomic="true"
        role="alert"
        onBlur={handleBlur}
        {...props}
      >
        {errors && (
          <i data-testid="error-icon" aria-hidden className={`icon-itaufonts_exclamacao ${classes.errorIcon}`} />
        )}
        <Typography className={classes.text} ref={bannerRef} tabIndex={-1}>
          {message}
        </Typography>
      </Box>
      {onClick && (
        <IconButton data-testid="close-button" onClick={onClick} className={classes.button} aria-label="fechar">
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
}

BannerInfo.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  styles: PropTypes.any,
  type: PropTypes.string,
  errors: PropTypes.number,
  submitCount: PropTypes.number,
};

export default BannerInfo;
