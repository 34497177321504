import { mfConfigs } from './mfs.config';

export function createMFScriptsURLByEnvironment(mfName, environment) {
  const mfConfigByMfName = mfConfigs.find((mfConfig) => mfConfig.name === mfName);
  if (!mfConfigByMfName) return [];
  const mfConfigPathsByEnvironment = mfConfigByMfName.configs.find((mfConfig) => mfConfig.env === environment);
  if (!mfConfigPathsByEnvironment) return [];
  const generatedScriptsURL = mfConfigPathsByEnvironment.scripts.map(
    (mfConfigScriptRoute) => `${mfConfigPathsByEnvironment.path}${mfConfigScriptRoute}`
  );
  return generatedScriptsURL;
}
