/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@material-ui/core';
import './_Button.scss';
import Loading from '../Loading';

const ButtonWrapper = ({
  text,
  context,
  className,
  elementRef,
  classNameContainer,
  classNameText,
  variant,
  page,
  index,
  onClick,
  disabled,
  role,
  link,
  type,
  autoFocus,
  startIcon,
  loading,
  style,
  describedById,
  customId,
  ariaLabel,
  labelledById,
}) => {
  const props = {};
  const id = customId || `btn${context}_screen${page}${index}`;

  if (onClick && typeof onClick === 'function') {
    props.onClick = e => {
      onClick(e, link);
    };
  }

  if (disabled) {
    props.disabled = disabled;
  }

  if (role) {
    props.role = role;
  }

  if (autoFocus) {
    props.autoFocus = autoFocus;
  }

  if (startIcon) {
    props.startIcon = startIcon;
  }

  if (describedById) {
    props['aria-describedby'] = describedById;
  }

  if (labelledById) {
    props['aria-labelledby'] = labelledById;
  }

  if (describedById) {
    props['aria-describedby'] = describedById;
  }

  if (labelledById) {
    props['aria-labelledby'] = labelledById;
  }

  return (
    <div className={`button-group ${classNameContainer}`}>
      <Button
        aria-label={ariaLabel}
        id={id}
        data-testid={id}
        ref={elementRef}
        color="primary"
        variant={variant}
        className={`button ${className}`}
        type={type}
        disableRipple
        loading={loading ? loading.toString() : null}
        disableFocusRipple
        startIcon={loading !== undefined && loading === true ? loading && <Loading color="#fff" size={25} /> : null}
        style={style}
        {...props}
      >
        <span id={`${id}-text`} className={classNameText}>
          {text}
        </span>
      </Button>
    </div>
  );
};

ButtonWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  context: PropTypes.string,
  elementRef: PropTypes.object,
  className: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameText: PropTypes.string,
  variant: PropTypes.string,
  page: PropTypes.string,
  index: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  role: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  startIcon: PropTypes.any,
  loading: PropTypes.bool,
  style: PropTypes.object,
};

ButtonWrapper.defaultProps = {
  classNameContainer: '',
  variant: 'contained',
  type: 'button',
  index: '',
};

export default ButtonWrapper;
