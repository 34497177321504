/* eslint-disable class-methods-use-this */
import * as yup from 'yup';
import { rules } from 'shared/form/vulnerabilityValidate/rules';
import { messages } from 'shared/form/vulnerabilityValidate/messages';
import { VulnerabilityValidate } from 'shared/form/vulnerabilityValidate';
import GenericField from './genericField';

export default class StringField extends GenericField {
  constructor(
    name,
    label,
    {
      required = false,
      requiredMessage = undefined,
      isEmail = undefined,
      emailErrorMessage = undefined,
      helperText = undefined,
      ariaLabel = undefined,
      min = undefined,
      max = undefined,
      matches = undefined,
      oneOf = undefined,
      test = undefined,
      placeholder = undefined,
      type = undefined,
      id = undefined,
      disabled = undefined,
      disableUnderline = undefined,
      tooltipProps = undefined,
    } = {},
  ) {
    super(name, label);

    this.placeholder = placeholder;
    this.ariaLabel = ariaLabel;
    this.required = required;
    this.requiredMessage = requiredMessage;
    this.matches = matches;
    this.min = min;
    this.max = max;
    this.oneOf = oneOf;
    this.isEmail = isEmail;
    this.emailErrorMessage = emailErrorMessage;
    this.helperText = helperText;
    this.type = !this.isEmail ? type : 'email';
    this.test = test;
    this.id = id;
    this.disabled = disabled;
    this.disableUnderline = disableUnderline;
    this.tooltipProps = tooltipProps;
  }

  forView(value) {
    return value;
  }

  forFormInitialValue(value) {
    return value;
  }

  forForm() {
    let yupChain = yup
      .string()
      .nullable(true)
      .trim()
      .label(this.label);

    if (this.required) {
      yupChain = yupChain.required(this.requiredMessage);
    }

    if (this.min && this.min.length > 0) {
      yupChain = yupChain.min(this.min.length, this.min.message);
    }

    if (this.max && this.max.length > 0) {
      yupChain = yupChain.max(this.max.length, this.max.message);
    }

    if (this.matches) {
      yupChain = yupChain.matches(this.matches.pattern, this.matches.message);
    }

    if (this.oneOf) {
      yupChain = yupChain.oneOf(this.oneOf);
    }

    if (this.test) {
      this.test.forEach(testIndex => {
        yupChain = yupChain.test(testIndex);
      });
    }
    const pureName = VulnerabilityValidate.pureName(this.name);
    if (rules[pureName]) {
      yupChain = yupChain.test({
        name: pureName,
        message: messages[pureName],
        test: rules[pureName],
      });
    }

    if (this.isEmail) {
      yupChain = yupChain.email(this.emailErrorMessage);
    }

    return yupChain;
  }
}
