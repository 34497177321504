import { retry } from 'api/E2E/Auto/retry';

import config, { performance } from '../../../../config';

const { ITAU_GATEWAY_HOST } = config;

const getHiring = async url => {
  const trace = performance.trace('setHiring');
  trace.start();
  const baseURL = ITAU_GATEWAY_HOST;
  const options = {
    method: 'get',
    baseURL,
    disableRetry: true,
    disableException: true,
  };
  const response = await retry(url, options, 6);

  trace.stop();
  return response.data;
};

export default getHiring;
