import i18n from 'i18next';
import moment from 'moment';
import ptBR from './pt-BR';

const { REACT_APP_ENVIRONMENT } = process.env;

const isDebug = REACT_APP_ENVIRONMENT === 'development' || REACT_APP_ENVIRONMENT === 'localhost';

i18n.init({
  debug: isDebug,
  lng: 'pt-BR',
  fallbackLng: 'pt-BR',
  interpolation: {
    format(value, format) {
      if (format === 'bold') return `**${value}**`;
      if (format === 'uppercase') return value.toUpperCase();
      if (value instanceof Date) return moment(value).format(format);
      return value;
    },
  },
  resources: {
    'pt-BR': {
      translation: {
        ...ptBR,
      },
    },
  },
  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
});
export default i18n;
