import React from 'react';
import { Field } from 'formik';
import RadioCardList from '../../../../components/RadioCardList/RadioCardList';
import { callFunction } from '../../../../utils/util';

const FormikRadioCardField = ({ options, name, label, className, labelLayout, required }) => (
  <Field
    name={name}
    render={({ form, field }) => {
      const onChange = event => {
        form.setFieldValue(name, event.target.value);
        if (field.onChange) callFunction(field.onChange, event);
      };
      return (
        <RadioCardList
          name={field.name}
          options={options}
          label={label}
          onChange={onChange}
          value={field.value}
          className={className}
          labelLayout={labelLayout}
          required={required}
        />
      );
    }}
  />
);

export default FormikRadioCardField;
