import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, toRem }) => ({
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    '& label': {
      position: 'inherit',
      whiteSpace: 'nowrap',
    },
  },
  errorMessage: {
    color: palette.error.main,
    width: '100%',
  },
  inputContainer: {
    width: '100%',
  },
  input: {
    marginTop: toRem(14),
  },
  tooltip: {
    padding: `0 ${toRem(4)}`,
    fontSize: toRem(16),
  },
}));

export default useStyles;
