import { put, select } from 'redux-saga/effects';
import { Types } from 'store/ducks/residencial';
import {
  residCoveragesAssistsSelector,
  residCoveragesCoverageSelector,
  residCoveragesProductSelector,
  residCoveragesOfferSelector,
} from 'store/sagas/selectors/residCoverage';
import { isNumber } from 'utils/util';

export default function* setResidAdditionalInformations(action) {
  let questionsResponse = [];
  const {
    data: { answeredQuestions, questions, realEstateType, occupationType, houseTypeText },
  } = action;

  for (const [key, value] of Object.entries(answeredQuestions)) {
    const question = questions[key];
    if (question) {
      const resposta = question.respostas.find(qr => {
        const parsedValue = parseInt(value);
        if (!isNumber(parsedValue)) {
          return qr.texto_resposta === value;
        }
        return qr.id_resposta === parsedValue;
      });
      questionsResponse = [...questionsResponse, { id_questao: question.id_questao, resposta: resposta.id_resposta }];
    }
  }
  const residOffer = yield select(residCoveragesOfferSelector);
  const residProduct = yield select(residCoveragesProductSelector);
  const residAssists = yield select(residCoveragesAssistsSelector);
  const residCoverages = yield select(residCoveragesCoverageSelector);
  const assists = residAssists.map(assist => ({ id_assistencia: assist.id_assistencia }));
  const coverages = residCoverages.map(cov => {
    const importanciaSegurada = cov.importancias_segurada[0].valor_sugerido.find(
      vs => vs.tipo_imovel === realEstateType,
    );
    return {
      id_cobertura: cov.id_cobertura,
      importancia_segurada: importanciaSegurada?.valor || 0,
    };
  });
  const valor = residProduct.importancia_base.valor_sugerido.find(vs => vs.tipo_imovel === realEstateType)?.valor || 0;
  const oferta = {
    id_oferta: residOffer.id_oferta,
    produtos: [
      {
        assistencias: assists,
        coberturas: coverages,
        id_produto: residProduct.id_produto,
        importancia_base: valor,
      },
    ],
  };
  yield put({ type: Types.SET_HOUSE_TYPE_TEXT, data: houseTypeText });
  yield put({ type: Types.SET_OCCUPATION_TYPE, data: occupationType });
  yield put({ type: Types.SET_REAL_ESTATE_TYPE, data: realEstateType });
  yield put({ type: Types.SET_RESID_OFFER, data: oferta });
  yield put({ type: Types.ADD_QUESTIONS, data: questionsResponse });
}
