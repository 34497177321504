import { call, put, select } from 'redux-saga/effects';
import { Types } from 'store/ducks/residencial';
import { residencialCoverageSelector, residencialSelector } from 'store/sagas/selectors/residencial';
import { residCoveragesCoverageSelector } from 'store/sagas/selectors/residCoverage';
import { assistanceSimulation } from 'api/E2E/Resid/Simulations';
import { BIKE_COVERAGE } from 'utils/constants';
import { setErrorSagaAction } from '../../ducks/httpErrors';

export default function* loadAssistanceSimulation(action) {
  try {
    const { data } = action;
    const { captcha } = data;
    const residencial = yield select(residencialSelector);
    const todasAsCoberturas = yield select(residCoveragesCoverageSelector);
    const { tipoOcupacao, tipoImovelTexto } = residencial.simulacao;

    const idsCoberturasParaExcluir = todasAsCoberturas
      .filter(c => c.nao_incluso.includes(tipoOcupacao) || c.nao_incluso.includes(tipoImovelTexto))
      .map(e => e.id_cobertura);

    // TODO : VERIFICAR REGRA IMPORTANCIA SEGURADA = 0 NÃO ENVIAR
    const filteredCoverages = residencial.simulacao.requisicao.oferta.produtos[0].coberturas.filter(
      c => c.importancia_segurada > 0 && !idsCoberturasParaExcluir.includes(c.id_cobertura),
    );

    const idsCoberturasFiltradas = filteredCoverages.map(e => e.id_cobertura);

    residencial.simulacao.requisicao.oferta.produtos[0].coberturas = filteredCoverages;

    const payload = {
      ...residencial.simulacao.requisicao,
      captcha,
    };

    const simulacao = yield call(assistanceSimulation, payload);

    let { excluidas, fixas, naoSelecionadas, preSelecionadas, selecionadas, todas } = yield select(
      residencialCoverageSelector,
    );

    /** Transform all assistance servicos to lower case */
    const { assistencias } = simulacao.produtos[0];
    simulacao.produtos[0].assistencias = assistencias.map(assist => ({
      ...assist,
      servicos: assist.servicos.map(servico => ({
        ...servico,
        nome: servico.nome.toLowerCase(),
      })),
    }));

    const { coberturas } = simulacao.produtos[0];

    const coberturasFundidas = todasAsCoberturas
      .filter(c => idsCoberturasFiltradas.includes(c.id_cobertura) || c.identificador === BIKE_COVERAGE.identificador)
      .map(c => ({
        ...c,
        ...coberturas.find(co => co.id_cobertura === c.id_cobertura),
      }));
    todas = coberturasFundidas;
    const dependentes = coberturasFundidas.filter(cf => cf.dependencias.length);
    excluidas = coberturasFundidas.filter(
      cf =>
        cf.identificador === BIKE_COVERAGE.identificador ||
        !cf.dependencias.every(idDependency =>
          coberturasFundidas.some(cob => cob.identificador === idDependency && preSelecionadas[cob.id_cobertura]),
        ),
    );
    const idsExcluidas = excluidas.map(e => e.id_cobertura);
    fixas = coberturasFundidas.filter(
      cf =>
        (preSelecionadas[cf.id_cobertura] === true && !idsExcluidas.includes(cf.id_cobertura)) ||
        cf.cobertura_obrigatoria === true,
    );
    selecionadas = fixas;
    const idsSelecionadas = selecionadas.map(e => e.id_cobertura);
    naoSelecionadas = coberturasFundidas.filter(cf => !idsSelecionadas.includes(cf.id_cobertura));
    naoSelecionadas = naoSelecionadas.filter(cf => !idsExcluidas.includes(cf.id_cobertura));
    const coberturasSimuladas = {
      excluidas,
      fixas,
      naoSelecionadas,
      preSelecionadas,
      selecionadas,
      dependentes,
      todas,
    };
    yield put({ type: Types.SET_COVERAGES, data: coberturasSimuladas });
    yield put({ type: Types.LOAD_ASSISTANCE_SIMULATION_SUCCESS, data: simulacao });
  } catch (err) {
    yield put({ type: Types.LOAD_ASSISTANCE_SIMULATION_ERROR, err });
    yield put(setErrorSagaAction(action));
  }
}
