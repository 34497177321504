import axios from 'axios';

import config from 'config';

const { E2E_PATH, ITAU_GATEWAY_HOST } = config;

export const getAutoOffer = async () => {
  // const trace = performance.trace('getAutoCoverages');
  // trace.start();
  const baseURL = ITAU_GATEWAY_HOST;
  const response = await axios.get(`${E2E_PATH}/ofertas/automovel`, {
    baseURL,
  });
  // trace.stop();
  return response.data;
};
