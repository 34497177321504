/* eslint-disable prefer-const */
export const Types = {
  SELECT_COVERAGE: 'resid/SELECT_COVERAGE',
  RESET_COVERAGES: 'resid/RESET_COVERAGES',
  UPDATE_COVERAGES: 'resid/UPDATE_COVERAGES',
  SET_PERSONAL_FIELDS: 'resid/SET_PERSONAL_FIELDS',
  SET_LOADING_PERSONAL_FIELDS: 'resid/SET_LOADING_PERSONAL_FIELDS',
  UPDATE_SIMULATED_COVERAGES: 'redid/UPDATE_SIMULATED_COVERAGES',
  ADD_AUTH_DATA: 'redid/ADD_AUTH_DATA',
  REMOVE_AUTH_DATA: 'redid/REMOVE_AUTH_DATA',
  SET_ADDITIONAL_INFO: 'resid/SET_ADDITIONAL_INFO',
  SET_INFO_PERSIST_FORMS: 'resid/SET_INFO_PERSIST_FORMS',
  SET_DIVERGENT_DATA_QUOTATION_HOLDER: 'resid/SET_DIVERGENT_DATA_QUOTATION_HOLDER',
};

const initialState = {
  auth: {},
  coverages: [],
  selecteds: {},
  fields: {},
  simulatedCoverages: [],
  dataPersist: {},
  divergentDataQuotationHolder: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SELECT_COVERAGE: {
      return {
        ...state,
        selecteds: {
          ...state.selecteds,
          [action.identifier]: !state.selecteds[action.identifier],
        },
      };
    }
    case Types.SET_ADDITIONAL_INFO: {
      return {
        ...state,
        payer: {
          name: action.data?.name,
          cpf: action.data?.cpf,
        },
      };
    }
    case Types.UPDATE_COVERAGES: {
      const { complement, coverages } = action.data;
      let newCoverages = [];
      complement.forEach((item) => {
        const coverage = coverages.find((e) => e.id_cobertura === item.id_cobertura);
        if (coverage) {
          newCoverages.push({ ...coverage, ...item });
        }
      });
      return {
        ...state,
        completedCoverages: newCoverages,
      };
    }
    case Types.UPDATE_SIMULATED_COVERAGES: {
      let oldSimulatedCoverages = state.simulatedCoverages;
      const alreadySelected = oldSimulatedCoverages.findIndex((simulated) => simulated.id === action.data.id);
      if (alreadySelected === -1) {
        oldSimulatedCoverages = oldSimulatedCoverages.concat(action.data);
      } else {
        oldSimulatedCoverages[alreadySelected] = undefined;
      }

      return {
        ...state,
        completedCoverages: oldSimulatedCoverages,
      };
    }
    case Types.RESET_COVERAGES: {
      return {
        ...state,
        selecteds: {},
      };
    }
    case Types.SET_PERSONAL_FIELDS: {
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.fields,
        },
      };
    }
    case Types.SET_LOADING_PERSONAL_FIELDS: {
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: action.loading,
        },
      };
    }
    case Types.ADD_AUTH_DATA: {
      return {
        ...state,
        auth: action.data,
      };
    }
    case Types.REMOVE_AUTH_DATA: {
      return {
        ...state,
        auth: {},
      };
    }
    case Types.SET_INFO_PERSIST_FORMS: {
      return {
        ...state,
        dataPersist: action.data,
      };
    }
    default:
      return state;
  }
}

export function addAuthData(data) {
  return {
    type: Types.ADD_AUTH_DATA,
    data,
  };
}

export function removeAuthData() {
  return {
    type: Types.REMOVE_AUTH_DATA,
  };
}

export function setCoverages(identifier) {
  return {
    type: Types.SELECT_COVERAGE,
    identifier,
  };
}

export function updateCoverages(complement, coverages) {
  return {
    type: Types.UPDATE_COVERAGES,
    data: {
      complement,
      coverages,
    },
  };
}

export function updateSimulatedCoverages(data) {
  return {
    type: Types.UPDATE_SIMULATED_COVERAGES,
    data,
  };
}

export function resetSelectedCoverages(identifier) {
  return {
    type: Types.RESET_COVERAGES,
    identifier,
  };
}

export function setResidPersonalFields(fields) {
  return {
    type: Types.SET_PERSONAL_FIELDS,
    fields,
  };
}

export function setLoadingPersonalFields(loading) {
  return {
    type: Types.SET_LOADING_PERSONAL_FIELDS,
    loading,
  };
}

export function setAdditionalInfo(data) {
  return {
    type: Types.SET_ADDITIONAL_INFO,
    data,
  };
}

export function setInfoPersisitForms(data) {
  return {
    type: Types.SET_INFO_PERSIST_FORMS,
    data,
  };
}
