import { put } from 'redux-saga/effects';
import { Types as ResidTypes } from 'store/ducks/residencial';
import { Types as OdontoTypes } from 'store/ducks/odonto';
import { Types as QuotationTypes } from 'store/ducks/quotation';
import { Types as CancellationTypes } from 'store/ducks/cancellation';

export default function* resetAppStoreOn401(action) {
  const ERROR_STATUS = [401, 403];

  if (!ERROR_STATUS.find(status => status === action.data?.response?.status)) return;

  try {
    yield put({ type: ResidTypes.RESET_DATA });
    yield put({ type: OdontoTypes.RESET_DATA });
    yield put({ type: QuotationTypes.RESET_QUOTATION });
    yield put({ type: CancellationTypes.RESET_QUOTATION });

    sessionStorage.clear();
  } catch (err) {
    console.log(err);
  }
}
