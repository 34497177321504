import axios from 'axios';
import config from 'config';

const { E2E_PATH, ITAU_GATEWAY_HOST } = config;

export const postAutoInspection = async ({ id_parceiro, payload, hash }) => {
  const baseURL = ITAU_GATEWAY_HOST;
  const response = await axios.post(
    `${E2E_PATH}/automovel/vistoria/agendamento?&id_parceiro=${id_parceiro}`,
    payload,
    {
      headers: {
        'x-inspection-hash': hash,
      },
      disableException: true,
      baseURL,
    },
  );

  const { status, data } = response;

  return { status, data };
};
