/* eslint-disable import/no-cycle,react/display-name */
import React from 'react';
import credicard from '../themes/credicard';
import itaucard from '../themes/itaucard';
import itaucardresidencial from '../themes/itaucardresidencial';
import credicardCreateStyleMapper from '../themes/credicard/styleMapper';
import credicardresidencialCreateStyleMapper from '../themes/credicardresidencial/styleMapper';
import itaucardCreateStyleMapper from '../themes/itaucard/styleMapper';
import itaucardresidencialCreateStyleMapper from '../themes/itaucardresidencial/styleMapper';
import CredicardPayment from '../components/CredicardPaymentType';
import ItaucardPayment from '../components/ItaucardPaymentType';

export const mapper = [
  {
    source: 'credicardresidencial',
    route: '/credicardresidencial',
    theme: credicard,
    dispatchTo: 'residencial',
    createStyleMapper: credicardresidencialCreateStyleMapper,
  },
  {
    source: 'itaucardresidencial',
    route: '/itaucardresidencial',
    theme: itaucardresidencial,
    dispatchTo: 'residencial',
    createStyleMapper: itaucardresidencialCreateStyleMapper,
  },
];

export const generateDynamicConfigBySource = currentProject => { // TODO verifica se o currentProject é igual a algum objeto do mapper, se sim retorna ele.
  const projectConfig = mapper.find(route => route.source === currentProject);
  if (projectConfig) {
    return projectConfig;
  }
};

export const getRoutes = () => mapper.reduce((newValue, iterator) => newValue.concat(iterator.route), []);
