import { makeStyles } from '@material-ui/core/styles';

export const useStylesBanner = makeStyles(({ palette, breakpoints }) => ({
  wrapper: {
    fontFamily: 'ItauText-Light',
    backgroundColor: palette.black,
    display: 'flex',
    fontSize: '24.96px',
    flexDirection: 'row',
    color: palette.white,
    height: 234,
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.up('md')]: {
      height: 440,
    },
    [breakpoints.up('lg')]: {
      height: 440,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  wrapperContent: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  wrapperDiscount: {
    [breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '40px',
    },
    [breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
    },
  },
  mainMessage: {
    width: 187,
    lineHeight: '1.5rem',
    '& p': {
      margin: 0,
    },
    [breakpoints.up('md')]: {
      fontSize: '48px',
      width: '320px',
      lineHeight: '3rem',
    },
    [breakpoints.up('lg')]: {
      fontSize: '48px',
      width: '320px',
      lineHeight: '3rem',
      marginRight: '60px',
    },
  },
  deadline: {
    width: 187,
    '& p': {
      margin: 0,
    },
    [breakpoints.up('md')]: {
      width: '320px',
      lineHeight: '1.6rem',
    },
    [breakpoints.up('lg')]: {
      width: '400px',
      lineHeight: '1.6rem',
    },
  },
  discount: {
    fontFamily: 'ItauText-XBold',
    width: 187,
    lineHeight: '1.7rem',
    marginLeft: '-5px',
    marginBottom: '-10px',
    display: 'flex',
    '& p': {
      margin: 0,
    },
    [breakpoints.up('md')]: {
      marginBottom: 0,
      lineHeight: '2.6rem',
    },
    [breakpoints.up('lg')]: {
      marginBottom: 0,
      lineHeight: '2.6rem',
    },
  },
  discountValue: {
    fontSize: '86px',
    lineHeight: '6rem',
    color: palette.primary.main,
    [breakpoints.up('md')]: {
      fontSize: '171px',
      lineHeight: '4rem',
    },
    [breakpoints.up('lg')]: {
      fontSize: '171px',
      lineHeight: '4rem',
    },
  },
  plans: {
    fontSize: '15px',
    [breakpoints.up('md')]: {
      fontSize: '31.5px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '31.5px',
    },
  },
  time: {
    fontSize: '8px',
    [breakpoints.up('md')]: {
      fontSize: '14px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '14px',
    },
  },
  sign: {
    fontSize: '32px',
    margin: 0,
    marginTop: '18px',
    color: palette.primary.main,
    [breakpoints.up('md')]: {
      fontSize: '57px',
      marginTop: 0,
      marginBottom: '13px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '57px',
      marginTop: 0,
      marginBottom: '13px',
    },
  },
  screenReader: {
    visibility: 'hidden',
  },
  paragraphSign: {
    marginTop: '5px !important',
    [breakpoints.up('md')]: {
      marginTop: '10px !important',
    },
    [breakpoints.up('lg')]: {
      marginTop: '10px !important',
    },
  },
  paragraphPorcent: {
    marginTop: '5px !important',
  },
}));
