/* eslint-disable react/prop-types */
/* eslint-disable max-classes-per-file */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import AriaReader from 'components/AriaReader/AriaReader';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormErrors from '../formErrors';

const useStyles = makeStyles(theme => ({
  checkBoxFix: {
    [theme.breakpoints.down('xs')]: {
      '& input[type=checkbox]': {
        position: 'relative',
        width: '26px',
        height: '25px',
        marginRight: '-25px',
      },
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: 'theme.palette.primary.main',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input ~ &': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconInfo: {
    position: 'relative',
    marginLeft: theme.toRem(10),
    top: theme.toRem(2),
    color: theme.palette.white,
    fontSize: theme.toRem(18),
  },
}));

function IconDynamic({ checkBoxCustom, styles, fontSize }) {
  if (checkBoxCustom) {
    return <span className={styles.icon} />;
  }
  return <CheckBoxIcon style={{ fontSize }} />;
}

function CheckedIconDynamic({ checkBoxCustom, styles, fontSize }) {
  if (checkBoxCustom) {
    return <span className={clsx(styles.icon, styles.checkedIcon)} />;
  }
  return <CheckBoxOutlineBlankIcon style={{ fontSize }} />;
}

export const CheckboxFormItemNotFast = props => {
  const styles = useStyles();
  const {
    label,
    name,
    form,
    hint,
    inputProps,
    errorMessage,
    linkLabel,
    linkUrl,
    fontSize,
    onChange,
    onClick,
    value,
    className,
    ariaLabel,
    required,
    checkBoxCustom,
    iconInfo,
    disabled,
    inputRef,
  } = props;

  const formHelperText = FormErrors.displayableError(form, name, errorMessage) || hint;
  const params = { checkBoxCustom, styles, fontSize };

  return (
    <div style={{ display: 'block' }}>
      <FormControlLabel
        style={{ marginTop: '16px' }}
        className={clsx(className, styles.checkBoxFix)}
        control={
          <Checkbox
            id={name}
            data-testid={name}
            name={name}
            style={{ fontSize: '24px' }}
            color="primary"
            disabled={disabled}
            icon={checkBoxCustom && <IconDynamic {...params} />}
            checkedIcon={checkBoxCustom && <CheckedIconDynamic {...params} />}
            checked={value || form.values[name] || false}
            inputProps={{ ref: inputRef, 'data-testid': `${name}_input` }}
            onChange={e => {
              form.setFieldTouched(name);
              form.setFieldValue(name, e.target.checked);
              if (onChange) {
                onChange(e);
              }
            }}
            {...inputProps}
          />
        }
        label={
          <>
            {label}
            {linkLabel && linkUrl && (
              <a className="checkbox-link" target="_blank" rel="noopener noreferrer" href={linkUrl} onClick={onClick}>
                {linkLabel}
              </a>
            )}
            {required && <AriaReader reader="exigido" />}
            {iconInfo && <i className={`${styles.iconInfo} icon-itaufonts_full_informacao`} aria-hidden />}
          </>
        }
        labelPlacement="end"
        aria-label={ariaLabel}
      />
      {formHelperText && (
        <FormHelperText error style={{ marginTop: '8px' }}>
          {formHelperText}
        </FormHelperText>
      )}
    </div>
  );
};

CheckboxFormItemNotFast.defaultProps = {
  fontSize: 24,
};

CheckboxFormItemNotFast.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  errorMessage: PropTypes.string,
  inputProps: PropTypes.object,
  linkLabel: PropTypes.string,
  linkUrl: PropTypes.string,
  fontSize: PropTypes.number,
  ariaLabel: PropTypes.string,
};

export default function CheckboxFormItem(props) {
  const { name } = props;
  return <Field name={name} render={({ form }) => <CheckboxFormItemNotFast {...props} form={form} />} />;
}

CheckboxFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
