/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import Modal from '../Modal/Modal';
import ModalWrapperExtern from '../ModalWrapperExtern/ModalWrapperExtern';

function ModalsWrapper({ modal, resetModalState, closeModalMessage }) {
  if (!modal) return [];

  return (
    <ModalWrapperExtern opened={modal && modal.showModal}>
      <div className="ModalsWrapper" aria-label="ModalsWrapper" aria-modal={modal?.showModal || false} role="dialog">
        <Modal customResetModalState={resetModalState} customModal={modal} closeModalMessage={closeModalMessage}>
          {modal.component}
        </Modal>
      </div>
    </ModalWrapperExtern>
  );
}

export default ModalsWrapper;
