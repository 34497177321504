/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { makeId } from 'utils/util';
import { useStyles, CustomSwitch } from './styles';

export default function Switch({ onChange, label, yesLabel, noLabel, inputRef, checked, name, ariaLabel, ...props }) {
  const styles = useStyles();

  const handleChange = e => {
    if (onChange && typeof onChange === 'function') onChange(e.target.checked);
  };

  const labelId = `label-switchField-${name || makeId(8)}`;
  const inputId = `input-switchField-${name || makeId(8)}`;

  const dynamicProps = {};
  if (label) dynamicProps['aria-labelledby'] = labelId;

  return (
    <>
      {label && (
        <label id={labelId} htmlFor={inputId} className={styles.label}>
          {label}
        </label>
      )}
      <div className={styles.switchWrapper}>
        <span className={styles.trueOption} aria-hidden>
          {noLabel}
        </span>
        <CustomSwitch
          id={inputId}
          onChange={handleChange}
          checked={checked}
          inputProps={{ ref: inputRef, name, 'aria-label': ariaLabel || label }}
          {...dynamicProps}
          {...props}
        />
        <span className={styles.trueOption} aria-hidden>
          {yesLabel}
        </span>
      </div>
    </>
  );
}

Switch.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  inputRef: PropTypes.object,
  checked: PropTypes.bool,
};

Switch.defaultProps = {
  yesLabel: 'sim',
  noLabel: 'não',
};
