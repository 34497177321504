import { ContentTypesInterface } from '../interfaces/ContentTypes.interface';

export const CONTENT_TYPES: ContentTypesInterface = {
  Odonto: 'seg_lojaseguros_homeodonto',
  Bllu: 'seg_lojaseguros_landings',
  Consorcio: 'seg_lojaseguros_landings',
  Conectcar: 'seg_lojaseguros_landings',
  
  Resid: 'seg_lojaseguros_homeresid_new',
  Home: 'seg_lojaseguros_home',
  Cashback: 'seg_lojaseguros_cashback',
};
