import { TextField } from '@material-ui/core';
import React from 'react';
import { isMobileDevice } from 'utils/util';

import Loading from 'components/Loading';
import useStyles from './textInputField.style';

const TextInputField = function({
  id,
  label,
  name,
  value,
  placeholder,
  autoFocus,
  autoComplete,
  inputProps,
  required = false,
  error,
  ariaLabel,
  helperText,
  onBlur,
  disabled,
  onChange,
  disableUnderline,
  inputRef,
  type,
  onFocus,
  loading,
}) {
  const styles = useStyles();
  const _inputProps = {
    role: 'none',
    className: inputProps?.className
      ? { ...inputProps?.className, [styles.customProps]: styles.customProps }
      : styles.customProps,
    disableUnderline,
    inputProps: {
      ...inputProps,
      ref: inputRef,
      'aria-required': required,
      'aria-label': ariaLabel || label,
      className: `${disabled ? styles.disabled : ''} ${styles.placeholder}`,
    },
    endAdornment: loading ? <Loading /> : undefined,
  };

  const _generateElementId = () => id || name;

  const isMultiline = disabled && isMobileDevice() ? { multiline: true } : {};
  return (
    <TextField
      id={_generateElementId()}
      type={type || 'text'}
      required={required}
      value={value}
      margin="normal"
      fullWidth
      placeholder={placeholder || undefined}
      autoFocus={autoFocus || undefined}
      autoComplete={autoComplete || undefined}
      InputLabelProps={{
        shrink: true,
        'aria-hidden': true,
        className: disabled && styles.disabled,
      }}
      onBlur={onBlur}
      InputProps={_inputProps}
      className={disabled && 'InputDisabled'}
      error={error}
      helperText={helperText}
      {...isMultiline}
      onChange={onChange}
      disabled={disabled}
      label={label}
      data-testid={`${name}-data-testid`}
      onFocus={onFocus}
    />
  );
};

export default TextInputField;
