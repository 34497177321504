const blackFridayModule = {
  BLACKFRIDAY: {
    BANNER: {
      TITLE: 'Abra o sorriso.',
      NAME: '<strong>Black Friday Odonto.<strong>',
      NAMEACCESSIBILITY: 'Black Friday Odonto.',
      PLANS: 'Em todos os planos <strong>Odonto.<strong>',
      PLANSACCESSIBILITY: 'Em todos os planos Odonto.',
      WARNING: '*promoção válida do dia 16/11 até dia 31/12',
      WARNINGACCESSIBILITY: 'importante: promoção válida do dia 16/11 até dia 31/12',
      ACCESSIBILITY:
        'Abra o sorriso. Black Friday Odonto vinte porcento off em todos os planos odonto, promoção válida do dia 16 de novembro até dia 31 de dezembro',
    },
    DISCOUNT: {
      VALUE: '20',
      VALUEACCESSIBILITY: '20 porcento off',
      PORCENT: '%',
      DISCOUNT: 'off',
    },
  },
};

export default blackFridayModule;
