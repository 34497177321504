import { makeStyles, withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: theme.toRem(11),
    margin: 0,
    padding: 0,
  },
  tooltipIcon: {
    fontSize: theme.toRem(15),
    color: props => (props.darkIcon ? theme.palette.black : theme.palette.white),
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.toRem(13),
    },
  },
}));

export const DarkbackgroundTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: props => (props.darkBackground ? theme.palette.black : theme.palette.white),
    color: props => (props.darkBackground ? theme.palette.white : theme.palette.black),
    fontSize: theme.toRem(11),
  },
  popper: {
    opacity: 0.9,
  },
}))(Tooltip);

export default useStyles;
