import { createContext, useContext, useState } from 'react';
import { getSingleContent } from 'cms';
import { CONTENT_TYPES } from 'cms/constants/contentTypes';
import { PAGE_TYPE } from 'cms/constants/pageType';

export interface CashbackProviderProps {
  children: JSX.Element;
}

export const CashbackContext = createContext({});

const CashbackProvider = ({ children }: CashbackProviderProps) => {
  const [cashbackConfig, setCashbackConfig] = useState<any>();
  const [, setLoading] = useState(false);

  const getCashbackConfig = async () => {
    setLoading(true);
    const response = await getSingleContent({
      PAGE_TYPE: PAGE_TYPE.SINGLE,
      CONTENT_TYPE: CONTENT_TYPES.Cashback,
      CMS_ENABLED: { enabled: true },
    });
    setCashbackConfig(response);
    setLoading(false);
  };

  const cashbackIsEnabled = (product: string) =>
    cashbackConfig?.enabled && cashbackConfig?.products?.some((p: string) => product.toLowerCase().indexOf(p) > -1);

  return (
    <CashbackContext.Provider value={{ getCashbackConfig, cashbackIsEnabled, cashbackConfig }}>
      {children}
    </CashbackContext.Provider>
  );
};

const useCashbackProviderConsumer = () => useContext(CashbackContext);

export { CashbackProvider, useCashbackProviderConsumer };
