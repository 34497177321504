import { toRem } from '../functions';
import breakpoints from '../breakpoints/breakpoints';

export default {
  gutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  regular: {
    minHeight: toRem(65),
    [breakpoints.up('md')]: {
      minHeight: toRem(80),
    },
  },
};
