import { createMFScriptsURLByEnvironment } from 'mfs/generateConfig';
import { MFNames } from 'mfs/mfs.config';
import config from './config';
import { loadDynamics } from './utils/observability';

const { REACT_APP_ENVIRONMENT } = process.env;

let mfsScriptURLs = [];

const head = document.getElementsByTagName('head')[0];
const body = document.getElementsByTagName('body')[0];

const enablingMicrofrontendScriptsInLocalhostAndProduction = (REACT_APP_ENVIRONMENT) => {

  if(REACT_APP_ENVIRONMENT === 'localhost' || REACT_APP_ENVIRONMENT === 'development' || REACT_APP_ENVIRONMENT === 'production' || REACT_APP_ENVIRONMENT === 'qa') {
    return (window.location.href.includes('hospitau')) ? [
      ...createMFScriptsURLByEnvironment(MFNames.LOJA_EVOLUCAO, REACT_APP_ENVIRONMENT),
      ...createMFScriptsURLByEnvironment(MFNames.HOSPITAU, REACT_APP_ENVIRONMENT),
    ] : createMFScriptsURLByEnvironment(MFNames.LOJA_EVOLUCAO, REACT_APP_ENVIRONMENT);
  }

  return createMFScriptsURLByEnvironment(MFNames.LOJA_EVOLUCAO, REACT_APP_ENVIRONMENT);
};

const activeEvolucaoCloudfrontEndpoint = () => {
  if (window.location.href.includes('cdpfma')) {
    mfsScriptURLs = createMFScriptsURLByEnvironment(MFNames.LOJA_CDPFMA, REACT_APP_ENVIRONMENT);

    if (window.location.href.includes('hospitau')) {
      mfsScriptURLs.push(...createMFScriptsURLByEnvironment(MFNames.HOSPITAU, REACT_APP_ENVIRONMENT));
    }
  } else if (window.location.href.includes('pcpfl')) {
    mfsScriptURLs = createMFScriptsURLByEnvironment(MFNames.LOJA_PCPFL, REACT_APP_ENVIRONMENT);

    if (window.location.href.includes('hospitau')) {
      mfsScriptURLs.push(...createMFScriptsURLByEnvironment(MFNames.HOSPITAU, REACT_APP_ENVIRONMENT));
    }
  } else if (window.location.href.includes('csema')) {
    mfsScriptURLs = createMFScriptsURLByEnvironment(MFNames.LOJA_CSEMA, REACT_APP_ENVIRONMENT);
  } else {
    mfsScriptURLs = enablingMicrofrontendScriptsInLocalhostAndProduction(REACT_APP_ENVIRONMENT);
  }

  mfsScriptURLs.forEach((mfsScriptURL) => {
    const scriptMFInject = document.createElement('script');
    scriptMFInject.src = mfsScriptURL;
    body.appendChild(scriptMFInject);
  });
};

const activeAnalitics = async () => {
  const { LIB_FRAMEWORK_DA, FRAMEWORK_DA, OPTIMIZE, ENABLE_GOOGLE_ANALYTICS } = config;
  const JsType = 'text/javascript';
  if (ENABLE_GOOGLE_ANALYTICS) {
    const scriptLibFrameworkDA = document.createElement('script');
    scriptLibFrameworkDA.type = JsType;
    scriptLibFrameworkDA.setAttribute('id', 'importLibJSFrameworkDA');
    scriptLibFrameworkDA.src = LIB_FRAMEWORK_DA;
    await head.appendChild(scriptLibFrameworkDA);

    const scriptFrameworkDA = document.createElement('script');
    scriptFrameworkDA.type = JsType;
    scriptFrameworkDA.setAttribute('id', 'frameworkDA');
    scriptFrameworkDA.src = FRAMEWORK_DA;
    await head.appendChild(scriptFrameworkDA);

    const scriptOptimize = document.createElement('script');
    scriptOptimize.type = JsType;
    scriptOptimize.setAttribute('id', 'optimize');
    scriptOptimize.src = OPTIMIZE;
    await head.appendChild(scriptOptimize);
  }
};

const configure = async (DYNAMICS_OBSERVABILITY_KEY) => {
  await activeAnalitics();
  if (navigator.userAgent === 'ReactSnap') return;
  loadDynamics(DYNAMICS_OBSERVABILITY_KEY);
  activeEvolucaoCloudfrontEndpoint();
};

export default configure;
