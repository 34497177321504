export const PRODUCT_ODONTO = 'odonto';
export const PRODUCT_RESID = 'residencial';
export const PRODUCT_AUTO = 'auto';
export const ALL = 'todos';

export const VIP_ROOM = [];

export const CHANNELS = [
  {
    name: 'cartaonaologado',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'itaunaologado',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'hipercard',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'itauuniclass',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'itaupersonnalite',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'veiculos',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'consorcio',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'loja',
    lead: [ALL],
  },
  {
    name: 'autenticado',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'itaucard',
    description: 'parceria com itaucard odonto e resid',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'credicard',
    description: 'parceria com credicard odonto e resid',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'sms',
    description: 'ação de promoção do produto odonto',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'maladigital',
    description: 'ação de promoção do produto odonto',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'maladigital1',
    description: 'ação de promoção do produto odonto considerando massa de sms gerado',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'maladigital2',
    description: 'ação de promoção do produto odonto considerando massa de sms gerado',
    lead: [PRODUCT_ODONTO, PRODUCT_RESID],
  },
  {
    name: 'carrinhoabandonado',
    description: 'geração de lead com base em dados criados pelo usuário e compra não finalizada',
    lead: [PRODUCT_ODONTO],
  },
  {
    name: 'connectcar',
    description: 'parceria de auto',
    initDate: '04/01/2021',
    lead: [PRODUCT_AUTO, PRODUCT_RESID, PRODUCT_ODONTO],
  },
  {
    name: 'abasteceai',
    description: 'parceria de auto',
    initDate: '04/01/2021',
    lead: [PRODUCT_AUTO, PRODUCT_RESID, PRODUCT_ODONTO],
  },
  {
    name: 'realizapiloto',
    description: 'Realiza Piloto',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'nlsegurosflutuante',
    description: 'Origem do botão flutuante na área não logada do Itaú',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'itaucardparcerias',
    description: 'Origem para comunicações com as parcerias do itaucard',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'bllupiloto',
    description: 'Consorcio',
    blockChannelLead: false,
    lead: [ALL],
  },
  {
    name: 'bllu',
    description: 'Bllu',
    blockChannelLead: false,
    lead: [ALL],
  },
  {
    name: 'campanhaconsorcio',
    description: 'Consorcio',
    blockChannelLead: false,
    lead: [ALL],
  },
  {
    name: 'creditoimobiliario',
    description: 'Origem da area de credito imobiliario',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'assai',
    description: 'Assai',
    blockChannelLead: true,
    lead: [PRODUCT_AUTO, PRODUCT_RESID],
  },
  {
    name: 'campanhas_lps',
    description: 'campanhas_lps',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'rede',
    description: 'rede',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'iti',
    description: 'iti',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'instagram',
    description: 'instagram',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'google',
    description: 'google',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'facebook',
    description: 'facebook',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'sms',
    description: 'sms',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'emkt',
    description: 'emkt',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'superapp',
    description: 'superapp',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'nlhomeitau',
    description: 'nlhomeitau',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'credicardon',
    description: 'credicardon',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'avi',
    description: 'avi',
    blockChannelLead: true,
    lead: [ALL],
  },
  {
    name: 'whatsapp',
    description: 'whatsapp',
    blockChannelLead: true,
    lead: [ALL],
  },
];
