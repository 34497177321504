/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import { closeMessage } from '../../store/ducks/message';
import { setFocusVisible } from '../../utils/util';

const Message = ({ message: { action, message, subtitle, open, type, refFocus, onCloseCallback }, close }) => {
  const onClose = () => {
    close();

    if (onCloseCallback && typeof onCloseCallback === 'function') {
      onCloseCallback();
    }

    if (refFocus) {
      setFocusVisible(document.querySelector(`${refFocus}`));
    }
  };

  const onEntered = () => {
    document.querySelector('.SnackbarContent').firstElementChild.setAttribute('tabindex', '-1');
  };
  return (
    <Snackbar
      action={action}
      message={message}
      subtitle={subtitle}
      open={open}
      type={type}
      onClose={onClose}
      onEntered={onEntered}
    />
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    action: PropTypes.func,
    onCloseCallback: PropTypes.func,
    refFocus: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
    open: PropTypes.bool,
  }),
};

const mapStateToProps = ({ message }) => ({
  message,
});

const actions = {
  close: () => closeMessage(),
};

export default connect(mapStateToProps, actions)(Message);
