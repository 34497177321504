import { useState, useEffect, createContext, useContext } from 'react';
import config, { remoteConfig } from 'config';
import { mapParsedEnvs } from 'config/parser';

export const RemoteConfigContext = createContext();

function RemoteConfigProvider({ children }) {
  const [mergedConfig, setMergedConfig] = useState(config);
  const [loaded, setLoaded] = useState(false);
  const { REMOTE_CONFIG } = config;

  useEffect(() => {
    const asyncEffect = async () => {
      if (!REMOTE_CONFIG) {
        setMergedConfig(config);
        return setLoaded(true);
      }
      let remoteConfigResponse = await remoteConfig.fetchAndActivate();
      remoteConfigResponse = remoteConfig.getAll();
      remoteConfigResponse = Object.keys(remoteConfigResponse).reduce(
        (acc, cur) => ({ ...acc, [cur]: remoteConfigResponse[cur]._value }),
        {},
      );
      remoteConfigResponse = mapParsedEnvs(remoteConfigResponse);
      setMergedConfig({ ...config, ...remoteConfigResponse });
      setLoaded(true);
    };
    asyncEffect();
  }, []);

  return (
    <RemoteConfigContext.Provider value={{ ...mergedConfig, loaded }}>
      {loaded && children}
    </RemoteConfigContext.Provider>
  );
}

function useRemoteConfigConsumer() {
  return useContext(RemoteConfigContext);
}

export { RemoteConfigProvider, useRemoteConfigConsumer };
