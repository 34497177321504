import sharedModule from '../../shared/pt-BR';

const petLanding = {
  ...sharedModule.LANDING,
  HOME: {
    TOTAL_PRICE_TEXT: 'Total',
    TOTAL_PRICE: '{{priceValue}}/mês',
    SUBMIT_BUTTON_TEXT: 'contrate online agora',
    BANNER: {
      TITLE: 'assistência **pet**',
      SUBTITLE: 'seu **amor de quatro patas** merece os **melhores cuidados**',
      CALL_TO_ACTION: 'monte a assistência ideal pro seu pet, é rápido e fácil',
      IMAGE: '{{- urlBucket}}/pet/otimizadas/ilustracoes/banner_home.png',
    },
    MAIN_ASSISTANCE_CARD: {
      ACCORDION_EXPANDED_TEXT: 'recolher detalhes',
      ACCORDION_CLOSED_TEXT: 'expandir detalhes',
      DESK_FOOTER_DESCRIPTION: 'já incluso por apenas:',
      FOOTER_DESCRIPTION: 'pacote principal já vem incluso por apenas:',
      PRICE_TEXT: '{{priceValue}}/mês',
      GENERAL_CONDITIONS_TEXT: 'ver condições gerais',
      IMAGE: '{{- urlBucket}}/pet/otimizadas/ilustracoes/{{id}}.png',
      MAIN_ASSISTANCE: {
        TITLE: 'Pacote principal',
        DESCRIPTION:
          'nesse pacote principal, trouxemos duas assistências essenciais para o seu PET (somente para cães e gatos)',
      },
    },
    ADDITIONAL_ASSISTANCE_SUBHEADER: 'adicione mais assistências',
    ADDITIONAL_ASSISTANCE_CARD: {
      ACCORDION_EXPANDED_TEXT: 'recolher detalhes',
      ACCORDION_CLOSED_TEXT: 'expandir detalhes',
      PRICE_TEXT: '{{priceValue}} /mês',
      GENERAL_CONDITIONS_TEXT: 'ver condições gerais',
      IMAGE: '{{- urlBucket}}/pet/otimizadas/ilustracoes/{{id}}.png',
    },
    BENEFITS_TITLE: 'conheça as **vantagens de contratar uma assistência para o seu pet**',
    BENEFITS_ICON_DESCRIPTION: [
      {
        TITLE: 'Abrangência',
        DESCRIPTION: 'ampla rede com presença nacional, 24h, 365 dias por ano',
        ICON: 'icon-itaufonts_todos_pelo_cliente',
      },
      {
        TITLE: 'Aceitação',
        DESCRIPTION: 'Não temos restrição de idade ou raça para o seu pet usar a assistência',
        ICON: 'icon-itaufonts_brilhos_nos_olhos',
      },
      {
        TITLE: 'Acessível',
        DESCRIPTION: 'Garantia da saúde do seu pet por um preço acessível',
        ICON: 'icon-itaufonts_sifrao',
      },
      {
        TITLE: 'Proteção completa',
        DESCRIPTION: 'Opções de hospedagem, vacinas e castração',
        ICON: 'icon-itaufonts_sorriso',
      },
      {
        TITLE: '100% digital',
        DESCRIPTION: 'Contrate a assistência de forma rápida e online',
        ICON: 'icon-itaufonts_computador_internet',
      },
    ],
    GENERAL_CONDITIONS_URL: '{{- urlBucket}}/termos/pet/cg_realiza_pet.pdf',
  },

  PARTNER_TITLE: '**conheça nosso parceiro**',
  PARTNER_DESCRIPTION:
    'a **Realiza** é uma marca da empresa Ikê feita para você, estamos no mercado brasileiro, com presença nacional, 24h por dia, nos 365 dias do ano.',
  PARTNER_LOGO: 'logo Realiza',
  HOLDER_DATA_LOADED: 'Página de dados do titular carregada',
  LGPD_MESSAGE:
    'Os dados inseridos por você poderão ser compartilhados com empresas parceiras para cotação e contratação dos produtos escolhidos e poderão ser tratados de acordo com a nossa Política de Privacidade disponível em nossos sites e aplicativos.',
  SUMMARY: {
    TITLE: 'Assistência Realiza Pet',
    ...sharedModule.LANDING.SUMMARY,
  },
  PAYMENT: {
    TITLE: 'dados do **cartão de crédito**',
    SUBTITLE: 'chegamos no último passo para você contratar seu pacote de assistências :)',
    GENERAL_CONDITIONS_URL: '{{- urlBucket}}/termos/pet/cg_realiza_pet.pdf',
  },
  GENERAL_CONDITIONS_URL: '{{- urlBucket}}/termos/pet/cg_realiza_pet.pdf',
  RECEIPT: {
    ...sharedModule.LANDING.RECEIPT,
    CSAT_PRODUCT_NAME: 'Assistência Pet',
  },
};

export default petLanding;
