import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { createPaymentData } from '../../ducks/payment';
import { obtainCreditCardBanner } from '../../../api/E2E/Realiza/obtainCreditCardBanner';

export default function genericCreatePaymentAction(creator, selector) {
  return function* createPaymentAction({ data }) {
    const id_opcao_pagamento = yield select(selector.PaymentOptionIdSelector);
    const nome = yield select(selector.QuotationProponentNameSelector);
    const cpf = yield select(selector.QuotationProponentCpfSelector);
    const bin = data.cardNumber.replace(/ /g, '').slice(0, 6);
    const { bandeira } = yield call(obtainCreditCardBanner, bin);
    const { enablePetLogin } = data;

    const dados_opcao_pagamento = {
      id_opcao_pagamento,
      cartao_credito: {
        numero_cartao: data.cardNumber?.replace(/\s/g, ''),
        data_validade: moment(data.validationDate, 'MM/YYYY').format('MM/YY'),
        bandeira,
        cvv: data.cvv,
      },
    };

    if (!enablePetLogin) {
      dados_opcao_pagamento.cartao_credito.pagador = {
        cpf,
        nome
      };
    }
  
    yield put(createPaymentData(dados_opcao_pagamento));
    data.onSuccess();
  };
}
