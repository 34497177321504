/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import config from '../../../config';

const { VIP_ROOM } = config;

const vipRoomText = {};

VIP_ROOM.forEach(room => {
  const file = `./${room}.json`;
  vipRoomText[room] = require(`${file}`);
});

export default vipRoomText;
