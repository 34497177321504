import axios from 'axios';

import config from 'config';

const { E2E_PATH, ITAU_GATEWAY_HOST } = config;

export const getAutoInspection = async hash => {
  const baseURL = ITAU_GATEWAY_HOST;
  const response = await axios.get(`${E2E_PATH}/automovel/vistoria`, {
    headers: {
      'x-inspection-hash': hash,
    },
    baseURL,
  });
  return response.data;
};
