import { createSelector } from 'reselect';

export const suspenseListsSelectors = state => state.suspenseLists;

export const incomeSelector = createSelector(suspenseListsSelectors, item => item.renda);

export const colocarCarSelector = createSelector(suspenseListsSelectors, item => item.cor_carro);

export const NationalitySelector = createSelector(suspenseListsSelectors, item => item.nacionalidade);

export const ProfessionsSelector = createSelector(suspenseListsSelectors, item => item.profissoes);

export const StateSelector = createSelector(suspenseListsSelectors, item => item.estados);

export const GenderSelector = createSelector(suspenseListsSelectors, item => item.sexo);

export const MartialSelector = createSelector(suspenseListsSelectors, item => item.estado_civil);
