import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip/index.js';
import { useStyles, CustomTooltip } from './styles';

export default function Chip({ label, info, variant, className, onClickChipInfo, ...props }) {
  const styles = useStyles({ variant, info });

  return (
    <div className={styles.root} data-testid="Chip">
      <div className={className} aria-label={label} {...props}>
        {label}
      </div>
      {info && (
        <Tooltip
          iconClass={styles.icon}
          title={`Ver informações sobre: ${label}`}
          content={info}
          placement="top"
          TooltipComponent={CustomTooltip}
          onClickChipInfo={() => onClickChipInfo(label)}
        />
      )}
    </div>
  );
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  onClickChipInfo: PropTypes.func,
};
