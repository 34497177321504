/* eslint-disable no-useless-escape */
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import config from 'config';

const { E2E_PATH, ITAU_GATEWAY_HOST } = config;

export const fetchVehicle = async ({ placa, ano_modelo, marca, modelo }) => {
  let queryParams = '';
  if (placa) {
    queryParams += `&placa=${placa}`;
  }
  if (ano_modelo) {
    queryParams += `&ano_modelo=${ano_modelo}`;
  }
  if (marca) {
    queryParams += `&marca=${marca}`;
  }
  if (modelo) {
    queryParams += `&modelo=${modelo}`;
  }

  const baseURL = ITAU_GATEWAY_HOST;
  const response = await axios.get(`${E2E_PATH}/automovel?${queryParams}`, {
    baseURL,
    disableException: true,
  });

  return response.data;
};
