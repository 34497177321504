export default {
  default: {
    main: '#fff',
    contrastText: '#EC7000',
  },
  primary: {
    light: '#087DB9',
    main: '#002FB4',
    contrastText: '#fff',
  },
  secondary: {
    main: '#EC7000',
    contrastText: '#000',
  },
  tertiary: {
    main: '#d62185',
    contrastText: '#fff',
  },
  footer: {
    colorHeading: '#5E5E5E',
    color: '#5E5E5E',
    backgroundColor: '#fff',
    colorArrow: '#F1AE2F',
  },
};
