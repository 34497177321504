import axios from 'axios';
import config from 'config';

const { E2E_PATH, ITAU_GATEWAY_HOST } = config;

export const getCheckBond = async cpf => {
  const baseURL = ITAU_GATEWAY_HOST;
  const response = await axios.post(
    `${E2E_PATH}/automovel/vinculo`,
    {
      cpf: cpf.replace(/[.()\s-]/g, ''),
    },
    {
      baseURL,
    },
  );
  return response.data;
};
