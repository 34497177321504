import Hidden from '@material-ui/core/Hidden';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';
import { useStyles } from './styles';

export default function Header() {
  const styles = useStyles();

  return (
    <header className={styles.root}>
      <div className={styles.container}>
        <Hidden lgUp>
          <MobileHeader />
        </Hidden>
        <Hidden mdDown>
          <DesktopHeader />
        </Hidden>
      </div>
    </header>
  );
}
