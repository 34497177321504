import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../theme/palette';
import { hasStyleMapper } from '../../../pages/Welcome/utils/util';

export const useStyles = (styleMapper, location) =>
  makeStyles(theme => {
    const footer = {
      colorHeading: hasStyleMapper(styleMapper, location) ? styleMapper.footer.font.colorHeading : '#F1AE2F',
      colorArrow: hasStyleMapper(styleMapper, location) ? styleMapper.footer.font.colorArrow : '#F1AE2F',
      fontColor: hasStyleMapper(styleMapper, location) ? styleMapper.footer.font.color : palette.white,
      fontWeight: hasStyleMapper(styleMapper, location) ? styleMapper.footer.font.weight : 'ItauText-XBold',
      backGroundColor: hasStyleMapper(styleMapper, location)
        ? styleMapper.footer.backgroundColor
        : palette.backgroundDarkBlue,
    };

    return {
      footer: {
        color: footer.fontColor,
        backgroundColor: footer.backGroundColor,
        fontFamily: 'ItauText-Regular',
        position: 'relative',
        '& a': {
          color: footer.fontColor,
          fontSize: '14px',
          fontFamily: 'ItauText-Regular',
          '& :visited': {
            color: footer.fontColor,
          },
        },
        [theme.breakpoints.down('md')]: {
          paddingBottom: '100px',
        },
      },
      logoLink: {
        backgroundRepeat: 'no-repeat',
        margin: 'auto',
        [theme.breakpoints.up('lg')]: {
          height: '32px',
          width: '102px',
          margin: '30px 0',
        },
      },
      wrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          logoLink: {
            margin: 'auto',
          },
        },
      },
      info: {
        padding: '35px 0 45px',
        textAlign: 'center',
        '& p': {
          fontSize: '12px',
          '& a': {
            fontSize: '16px',
            fontFamily: 'ItauText-Bold',
            textDecoration: 'underline',
          },
        },
        [theme.breakpoints.down('md')]: {
          padding: '24px 0 16px',
        },
      },
      location: {
        fontSize: '12px',
        margin: '10px 0 0',
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      registry: {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      mobileLocation: {
        margin: '10px 0 0',
        display: 'none',
        lineHeight: '14px',
        fontSize: '13px',
        color: palette.secondary.main,
        [theme.breakpoints.down('md')]: {
          display: 'block',
        },
      },
      mobileRegistry: {
        fontsize: '16px',
        display: 'none',
        marginTop: '20px',
        [theme.breakpoints.down('md')]: {
          display: 'block',
        },
      },
      dropDown: {
        '& .collapse-button .button-left-content-wrapper .button-label .button-title': {
          color: footer.colorHeading,
          fontSize: '22px',
          fontFamily: footer.fontWeight,
        },
        '& .collapse-button': {
          color: footer.colorArrow,
          width: '90%',
          margin: '15px auto',
          borderBottom: `1px solid ${palette.buttonActionColor}`,
          paddingBottom: '20px',
        },
        '& h3': {
          fontSize: '16px',
          margin: '12px 0 0 0',
          color: footer.fontColor,
        },
        '& p': {
          fontSize: '16px',
          margin: '0px',
        },
      },
    };
  })();
