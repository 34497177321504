/* eslint-disable prefer-const */
export const Types = {
  CREATE_QUESTIONS: 'questions/CREATE_QUESTIONS',
  CREATE_QUESTIONS_PARSED: 'questions/CREATE_QUESTIONS_PARSED',
  LOAD_QUESTIONS: 'questions/LOAD_QUESTIONS',
  ERROR_QUESTIONS: 'questions/ERROR_QUESTIONS',
  SUCCESS_LOAD_QUESTIONS: 'questions/SUCCESS_LOAD_QUESTIONS',
};

const initialState = {
  questionsResponse: [],
  questionsParsed: [],
  loading: false,
  error: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.CREATE_QUESTIONS:
      return {
        ...state,
        questionsResponse: action.data,
      };
    case Types.CREATE_QUESTIONS_PARSED:
      return {
        ...state,
        questionsParsed: action.data,
      };
    case Types.LOAD_QUESTIONS:
      return {
        ...state,
        loading: true,
      };
    case Types.ERROR_QUESTIONS:
      return {
        ...state,
        error: true,
      };
    case Types.SUCCESS_LOAD_QUESTIONS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function createQuestions(data) {
  return {
    type: Types.CREATE_QUESTIONS,
    data,
  };
}

export function createQuestionsParsed(data) {
  return {
    type: Types.CREATE_QUESTIONS_PARSED,
    data,
  };
}

export function setLoadingQuestions() {
  return {
    type: Types.LOAD_QUESTIONS,
  };
}

export function setErrorQuestions() {
  return {
    type: Types.ERROR_QUESTIONS,
  };
}

export function setSuccessQuestions() {
  return {
    type: Types.SUCCESS_LOAD_QUESTIONS,
  };
}
