import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_ENVIRONMENTS,
} from 'trackingV2/constants';

export const ANALYTICS_RESID_HOME_FLOW = {
  T1: {
    nome: 'LS:NL:NCC:Residencial:Cotacao:Cotacao',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T4: {
    nome: 'LS:NL:NCC:Home:SeguroResidencial',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A36: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Resid:FacaSuaCotacao',
    noInteraction: false,
  },
  A37: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Resid:FormCotacao:Passo1:ContinuarCotacao:{0}',
    noInteraction: false,
  },
  A38: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Resid:DetalhesCoberturas:{0}',
    noInteraction: false,
  },
  A39: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Resid:FormCotacao:Passo2:ContinuarCotacao:{0}',
    noInteraction: false,
  },
  A40: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Resid:SitePortoSeguro',
    noInteraction: false,
  },
  A41: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Resid:FormCotacao:Passo3:VerPrecoDoSeguro',
    noInteraction: false,
  },
  A42: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Resid:ExpandirPerguntaFrequente:{0}',
    noInteraction: false,
  },
  A43: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Resid:VerMaisPerguntasFrequentes',
    noInteraction: false,
  },
};
