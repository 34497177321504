import ROUTES from 'routes/routes';
import { PagesConfigInterface } from '../interfaces/PagesConfig.interface';
import { PAGE_TYPE } from '../constants/pageType';
import { CONTENT_TYPES } from '../constants/contentTypes';

export const pagesConfig = (pathname: string, remoteConfig: any) => {
  const { ODONTO_HOME_CAMPAIGN } = remoteConfig;

  const PAGES_CONFIG: PagesConfigInterface = {
    [ROUTES.LANDING_ODONTO]: {
      PAGE_TYPE: PAGE_TYPE.SINGLE,
      CONTENT_TYPE: CONTENT_TYPES.Odonto,
      CMS_ENABLED: ODONTO_HOME_CAMPAIGN,
    },
    [ROUTES.LANDING_BLLU]: {
      PAGE_TYPE: PAGE_TYPE.MULTIPLE,
      CONTENT_TYPE: CONTENT_TYPES.Bllu,
      CMS_ENABLED: { enabled: true },
    },
    [ROUTES.LANDING_CONSORCIO]: {
      PAGE_TYPE: PAGE_TYPE.MULTIPLE,
      CONTENT_TYPE: CONTENT_TYPES.Consorcio,
      CMS_ENABLED: { enabled: true },
    },
    [ROUTES.LANDING_CONECTCAR]: {
      PAGE_TYPE: PAGE_TYPE.MULTIPLE,
      CONTENT_TYPE: CONTENT_TYPES.Conectcar,
      CMS_ENABLED: { enabled: true },
    },
    [ROUTES.LANDING_RESID]: {
      PAGE_TYPE: PAGE_TYPE.SINGLE,
      CONTENT_TYPE: CONTENT_TYPES.Resid,
      CMS_ENABLED: { enabled: true },
      BYPASS_ERROR_WRAPPER: true,
    },
    [ROUTES.HOME]: {
      PAGE_TYPE: PAGE_TYPE.SINGLE,
      CONTENT_TYPE: CONTENT_TYPES.Home,
      CMS_ENABLED: { enabled: true },
    },
  };

  return PAGES_CONFIG[pathname];
};
