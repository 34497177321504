/* eslint-disable no-unused-vars */
import { call, put, select } from 'redux-saga/effects';
import { Types as ResidTypes } from 'store/ducks/residencial';
import { Types as AuthTypes } from 'store/ducks/auth';
import {
  residencialHiringRequestSelector,
  residencialSimulationResponseIdSelector,
} from 'store/sagas/selectors/residencial';
import { getHiring, setHiring } from 'api/E2E/Resid/Hiring';
import { cancelRetry, disableGlobalError, setError, setErrorSagaAction } from '../../ducks/httpErrors';
import scopes from 'auth/scopes';
import moment from "moment";

const hashSelector = (scope) => (state) => state.authScopes.data.find((aS) => aS.scope === scope);

export default function* loadHiring(action) {
  const { data } = action;
  const { captcha, enableResidLogin, context } = data;
  try {
    const request = yield select(residencialHiringRequestSelector);
    const simulationId = yield select(residencialSimulationResponseIdSelector);
    request.id_simulacao = simulationId;

    // eslint-disable-next-line camelcase
    const { item_risco, ...newRequest } = request;
    const newRequestModified = {
      ...newRequest,
      proponente: {
        ...newRequest.proponente,
        data_nascimento: newRequest?.proponente?.data_nascimento,
      },
    };

    const payload = {
      ...newRequestModified,
      captcha,
    };
    yield put(disableGlobalError(false));

    let headers = {};
    if (enableResidLogin) {
      delete payload?.proponente?.contatos;
      delete payload?.proponente?.cpf;
      delete payload?.proponente?.nome;
      const authOnStorageSession = JSON.parse(sessionStorage.getItem('auth:safe'));
      headers = {
        'token-sts': authOnStorageSession.accessToken,
        identifier: authOnStorageSession.identifier,
      };
    } else {
      const { hash } = yield select(hashSelector(scopes.RESID));
      headers = {
        hash,
      };
    }

    delete payload.dados_opcao_pagamento?.cartao_credito?.porto;
    const url = yield call(setHiring, payload, headers);
    yield put(cancelRetry());

    const contratacao = yield call(getHiring, url);

    yield put({ type: ResidTypes.LOAD_HIRING_SUCCESS, data: contratacao });

    yield put({ type: AuthTypes.AUTH_REMOVE });
  } catch (err) {
    yield put(setError());
    yield put({ type: ResidTypes.LOAD_HIRING_ERROR, err });
    yield put(setErrorSagaAction(action));
  }
}
