/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { merge } from 'lodash';

const ODONTO = 'odonto';

// TODO RETORNA A CAMPANHA ATIVA DE ACORDO COM ORIGIN
const getCustomRoomContent = (origin, location, remoteConfig) => {
  const { BLACKFRIDAY, PROMOTIONINDOOR } = remoteConfig;
  const room = {
    HOME: {
      BANNERS: {},
    },
    PRODUCTS: {
      ODONTO: {},
      RESID: {},
      AUTO: {},
    },
  };

  const defaultRoom = require('./campaigns/default/index.js').default;

  try {
    const customRoom = require(`./campaigns/${origin}/index.js`).default;
    merge(room, [defaultRoom, customRoom]);
  } catch (e) {
    merge(room, defaultRoom);
  }

  // restriction
  const isOdonto = location.pathname.match(ODONTO);

  if (PROMOTIONINDOOR && !isOdonto) {
    merge(room, require('./campaigns/promotionInDoor/index.js').default);
  }

  if (BLACKFRIDAY) {
    merge(room, require('./campaigns/blackFriday/index.js').default);
  }

  return room;
};

export default getCustomRoomContent;
