import { makeStyles } from '@material-ui/core';
import Tag from '../../assets/images/svg/tagSvg.svg';

const useStyles = makeStyles(() => ({
  basic_container: {
    background: '#F1AE2F',
    borderRadius: '0px',
    color: '#000',
    height: '32px',
    padding: '5px',
    width: '100%',
  },
  basic_text: {
    fontFamily: 'ItauText-Regular',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0em',
    lineHeight: '24px',
    margin: '0',
    padding: '0',
  },
  piercing_container: {
    alignItems: 'center',
    backgroundImage: `url(${Tag})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    bottom: '0',
    color: 'white',
    display: 'flex',
    height: '44px',
    justifyContent: 'flex-end',
    margin: '0',
    padding: '0',
    position: 'absolute',
    right: '-78px',
    top: '71px',
    transform: 'rotate(80deg)',
    width: '200px',
  },
  piercing_text: {
    fontFamily: 'ItauText-XBold',
    fontSize: '17px',
    height: 'max-content',
    left: '25%',
    margin: '0',
    position: 'absolute',
    textAlign: 'center',
    top: '25%',
    width: 'max-content',
  },
}));

export default useStyles;
