import { call, put } from 'redux-saga/effects';
import { setJuridicalText, setKinship, Types } from '../../ducks/odonto';
import { hasAvailablePlans } from '../../../api';
import { getProductInfo } from '../../../api/E2E/Odonto/Kinship/getProductInfo';

export default function* loadAvailablePlans(action) {
  const { idCity, idUF, formatedCity, idProduct } = action.data;
  try {
    yield put({
      type: Types.SET_PRODUCT_INFORMATION,
      data: action.data,
    });
    yield put({ type: Types.LOAD_PENDING_AVAILABLE_PLANS });
    const resultAvailablePlans = yield call(hasAvailablePlans, idCity, idUF, formatedCity);
    const resultProductsInfo = yield call(getProductInfo, idProduct, idCity, idUF, formatedCity);
    yield put(setJuridicalText(resultProductsInfo.texto_juridico));
    yield put(setKinship(resultProductsInfo.parentesco));
    yield put({
      type: Types.LOAD_SUCCESS_AVAILABLE_PLANS,
      data: resultAvailablePlans,
    });
  } catch (e) {
    yield put({
      type: Types.LOAD_ERROR_AVAILABLE_PLANS,
      error: {
        message: 'Ocorreu um erro tente novamente mais tarde',
        stack: e.message,
      },
    });
  }
}
