import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_ENVIRONMENTS,
} from 'trackingV2/constants';

export const ANALYTICS_AUTO_FLOW = {
  T2: {
    nome: 'LS:NL:NCC:Home:SeguroAuto',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A21: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Auto:Jumper:{0}:{1}',
    noInteraction: false,
  },
  A22: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Auto:{0}:ContratarAgora',
    noInteraction: false,
  },
  A23: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Auto:DetalhesCoberturasEAssistencias',
    noInteraction: false,
  },
  A24: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Auto:ContratarAgoraCorpo',
    noInteraction: false,
  },
  A25: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Auto:ExpandirPerguntaFrequente:{0}',
    noInteraction: false,
  },
  A26: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Auto:VerMaisPerguntasFrequentes',
    noInteraction: false,
  },
  A27: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Auto:CondicoesGerais:{0}',
    noInteraction: false,
  },
};
