import config from '../../../config';
import { logotype } from '../themes/assets/configuration';

// Products
const RESID = 'residencial';
const AUTO_VARIATION_1 = 'auto';
const AUTO_VARIATION_2 = 'automotivo';
const ODONTO = 'odonto';

export const hasStyleMapper = (styleMapper, location) => {
  const { VIP_ROOM } = config;
  const CUSTOM_CHANNELS = VIP_ROOM;
  const CHANNELS = `/${CUSTOM_CHANNELS.join('|/')}`;
  const vipPage = location.pathname.match(new RegExp(CHANNELS, 'gi'));
  return Object.keys(styleMapper).length > 0 && vipPage;
};

export const getTextByOrigin = (theme, token) => `${theme}.${token}`;

export const getLogoByOrigin = theme => logotype[theme].image;

export const getLogoTitleByOrigin = theme => logotype[theme].title;

export const hasProduct = (source, product, routes) => {
  let url = null;

  switch (product) {
    case RESID:
      url = routes[`${source.toUpperCase()}RESID`];
      break;
    case AUTO_VARIATION_1:
      url = routes.LANDING_AUTO;
      break;
    case AUTO_VARIATION_2:
      url = routes.LANDING_AUTO;
      break;
      case ODONTO:
      url = routes.LANDING_ODONTO;
      break;
    default:
      url = routes[source.toUpperCase()];
  }
  return url;
};

export const getOriginsByVipRoom = vipRoom =>
  vipRoom.reduce(
    (channels, current) =>
      !current.match(/residencial/gi) && !current.match(/odonto/gi) && !current.match(/auto/gi)
        ? channels.concat(current)
        : channels,
    [],
  );
