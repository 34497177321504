import { makeStyles } from '@material-ui/core/styles';

export const useSharedStyles = makeStyles(() => ({
  root: {
    height: '700px',

    '& .MuiAppBar-colorDefault': {
      backgroundColor: 'transparent',
    },
    '& .MuiPaper-elevation4': {
      boxShadow: 'none',
    },
  },
  mocksTable: {
    width: '100%',
    borderCollapse: 'collapse',

    '& tr:nth-child(even)': {
      backgroundColor: '#eee',
    },
    '& td, th': {
      border: '1px solid #ddd',
      textAlign: 'left',
      padding: '0 8px',
    },
    '& th': {
      textAlign: 'start',
      padding: '8px',
    },
  },
  modalFooterText: {
    color: 'gray',
    padding: '1rem 0',
    borderTop: '1px solid #ddd',
  },
  modalTitle: {
    margin: 0,
    marginBottom: '1rem',
  },
  applyButton: {
    fontSize: '1rem',
    padding: '.5rem 2rem',
    height: 'fit-content',
    marginTop: '1rem',
    marginLeft: 'auto',
  },
  toggleCell: {
    width: '100px',
    '&> div': {
      justifyContent: 'center',
    },
  },
  versionChip: {
    fontSize: '10px',
  },
}));
