import config from 'config';
import { retry } from './retry';

const { ITAU_GATEWAY_HOST } = config;

export const getAutoSimulation = async url => {
  const baseURL = ITAU_GATEWAY_HOST;
  const options = {
    disableRetry: true,
    disableException: true,
    baseURL,
    method: 'get',
  };
  const response = await retry(url, options, 6);
  return response?.data.simulacoes;
};
