import produce from 'immer';

import { createActions } from 'reduxsauce';

const initialState = {
  cotacao: {
    requisicao: {
      proponente: {},
    },
    resposta: {},
  },
  contratacao: {
    requisicao: {
      proponente: {},
    },
    resposta: {},
  },
  flow: {
    auth: {},
  },
};

export default types =>
  produce((state = initialState, action) => {
    switch (action.type) {
      case types.RESET: {
        return initialState;
      }
      case types.SAVE_SESSION:
        return action.data;
      case types.CREATE_QUOTATION_REQUEST_OFFER:
        state.cotacao.requisicao.oferta = action.data;
        return state;
      case types.ADD_SELECTED_ASSISTANCES:
        state.flow = { ...state.flow, ...action.data };
        return state;
      case types.CREATE_QUOTATION_REQUEST_PROPONENT:
        state.cotacao.requisicao.proponente = { ...state.cotacao.requisicao.proponente, ...action.data };
        return state;
      case types.CREATE_QUOTATION_REQUEST_PROPONENT_CONTACT:
        state.cotacao.requisicao.proponente.contatos = action.data;
        return state;
      case types.CREATE_HIRING_REQUEST_PROPONENT_CONTACT:
        state.contratacao.requisicao.proponente.contatos = action.data;
        return state;
      case types.CREATE_HIRING_REQUEST_PROPONENT:
        state.contratacao.requisicao.proponente = action.data;
        return state;
      case types.LOAD_QUOTATION: {
        state.cotacao.carregando = true;
        return state;
      }
      case types.RESET_RESPONSE: {
        state.cotacao.resposta = initialState.cotacao.resposta;
        return state;
      }

      case types.LOAD_HIRING: {
        state.contratacao.carregando = true;
        state.contratacao.erro = false;
        state.contratacao.sucesso = false;
        return state;
      }
      case types.LOAD_HIRING_SUCCESS: {
        const { data } = action;
        state.contratacao.resposta = data;
        state.contratacao.carregando = false;
        state.contratacao.sucesso = true;
        state.contratacao.erro = false;
        return state;
      }
      case types.LOAD_HIRING_ERROR: {
        state.contratacao.carregando = false;
        state.contratacao.sucesso = false;
        state.contratacao.error = true;
        return state;
      }
      case types.RESET_HIRING_STATUS: {
        state.contratacao.carregando = false;
        state.contratacao.sucesso = false;
        state.contratacao.erro = false;
        return state;
      }

      case types.AUTH_LOAD: {
        state.flow.auth.isLoading = true;
        state.flow.auth.isError = false;
        state.flow.auth.isSuccess = false;
        return state;
      }

      case types.AUTH_SUCCESS: {
        state.flow.auth.isLoading = false;
        state.flow.auth.isError = false;
        state.flow.auth.isSuccess = true;
        return state;
      }
      case types.AUTH_ERROR: {
        state.flow.auth.isLoading = false;
        state.flow.auth.isError = true;
        state.flow.auth.isSuccess = false;
        return state;
      }
      case types.AUTH_REMOVE: {
        state.flow.auth = {};
        return state;
      }
      case types.ADD_FLOW_AUTH_DATA: {
        state.flow.authData = action.data;
        return state;
      }
      case types.CREATE_RESPONSE_QUOTATION: {
        state.cotacao.resposta = action.data;
        state.cotacao.carregando = false;
        return state;
      }
      case types.CREATE_HIRING_RESPONSE: {
        state.contratacao.resposta = action.data;
        state.contratacao.carregando = false;
        state.contratacao.sucesso = true;
        state.contratacao.erro = false;
        return state;
      }
      default:
        return state;
    }
  });

export const realizaActions = type =>
  createActions(
    {
      saveSession: ['data'],
      resetResponse: ['data'],
      reset: ['data'],

      triggerOfferSagaAction: ['data'],
      createQuotationRequestOffer: ['data'],

      triggerProfileSubmitSagaAction: ['data'],
      createQuotationRequestProponent: ['data'],
      createQuotationRequestProponentContact: ['data'],
      createHiringRequestProponent: ['data'],
      createHiringRequestProponentContact: ['data'],

      triggerAuthDataSubmitSagaAction: ['data'],
      addFlowAuthData: ['data'],
      createResponseQuotation: ['data'],
      authSuccess: ['data'],
      authError: ['data'],
      authLoad: ['data'],
      authRemove: ['data'],

      triggerPaymentSagaAction: ['data'],

      triggerHiringRequestSubmitAction: ['data'],
      createHiringResponse: ['data'],

      loadHiring: ['data'],
      loadHiringError: ['data'],
      loadHiringSuccess: ['data'],
      resetHiringStatus: ['data'],
      addSelectedAssistances: ['data'],
    },
    {
      prefix: type,
    },
  );
