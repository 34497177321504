import { emailModel, cellphoneModel } from 'shared/form/commonModels';
import FormSchema from 'shared/form/formSchema';

const createfields = () => ({
  email: emailModel({ ariaLabel: '' }),
  telephone: cellphoneModel({ ariaLabel: '' }),
});

const createSchema = fields => new FormSchema(undefined, [fields.email, fields.telephone]);

export default {
  createfields,
  createSchema,
};
