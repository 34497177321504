export const parseApi = payload => {
  const returnObj = [];

  payload.forEach((key, index) => {
    returnObj.push({});
    Object.entries(key).forEach(([element]) => {
      switch (element) {
        case 'id_questao': {
          returnObj[index].id = key[element];
          break;
        }
        case 'texto_questao': {
          returnObj[index].label = key[element];
          break;
        }
        case 'respostas': {
          returnObj[index].options = Array.from(
            key[element].map(item => ({ value: item.id_resposta, label: item.texto_resposta })),
          );
          break;
        }
        default:
          break;
      }
    });
  });
  return returnObj;
};
