/* eslint-disable no-plusplus */
import { CREDITCARD_REGEX, EMAIL_REGEX } from './regex';

export const validarCPF = value => {
  let cpf = value;
  if (cpf === undefined) return true;

  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return true;
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
};

export const checkCreditCardFlag = string => {
  if (typeof string !== 'string') return null;
  if (string.match(CREDITCARD_REGEX.Mastercard)) return 'MasterCard';
  if (string.match(CREDITCARD_REGEX.Visa)) return 'Visa';
  if (string.match(CREDITCARD_REGEX.Amex)) return 'Amex';
  if (string.match(CREDITCARD_REGEX.Diners)) return 'Diners';
  if (string.match(CREDITCARD_REGEX.Aura)) return 'Aura';
  if (string.match(CREDITCARD_REGEX.Elo)) return 'Elo';
  return null;
};

export const isValidEmail = email => email && typeof email === 'string' && EMAIL_REGEX.test(email);

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
