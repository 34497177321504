export const Types = {
  CREATE: 'LEAD/CREATE',
  CREATE_PENDING: 'LEAD/CREATE_PENDING',
  CREATE_SUCCESS: 'LEAD/CREATE_SUCCESS',
  CREATE_ERROR: 'LEAD/CREATE_ERROR',
  RESET_CREATE_STATUS: 'LEAD/RESET_CREATE_STATUS',
};

const initialState = {
  isCreatePristine: true,
  isCreating: false,
  hasCreateSuccess: false,
  hasCreateError: false,
  payload: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.CREATE_ERROR:
      return {
        ...state,
        isCreatePristine: false,
        isCreating: false,
        hasCreateError: true,
        payload: action.payload,
      };
    case Types.CREATE_PENDING:
      return {
        ...state,
        isCreatePristine: false,
        isCreating: true,
        payload: action.payload,
      };
    case Types.CREATE_SUCCESS:
      return {
        ...state,
        isCreatePristine: false,
        isCreating: false,
        hasCreateSuccess: true,
        payload: action.payload,
      };
    case Types.RESET_CREATE_STATUS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export function create(payload, analyticsObject, isModalMessage = false, successMessage, errorMessage, captcha) {
  return {
    type: Types.CREATE,
    payload,
    analyticsObject,
    isModalMessage,
    successMessage,
    errorMessage,
    captcha,
  };
}

export function resetCreateStatus() {
  return {
    type: Types.RESET_CREATE_STATUS,
  };
}
