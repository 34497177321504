import axios from 'axios';

import config, { performance } from '../../../../config';

const { E2E_ODONTO_PATH } = config;

export const setPayment = async (data) => {
  const { payload, headerContent } = data;
  const trace = performance.trace('setPayment');
  trace.start();
  const response = await axios.patch(`${E2E_ODONTO_PATH}/propostas/${payload.id_proposta}`, payload, {
    headers: {
      ...headerContent,
    },
    disableException: true,
  });
  trace.stop();
  return response.data;
};
