import { Grid } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '../Button';
import { useStyles } from './NavigateFormButtons.styles';

export default function NavigateFormButtons({
  onClickContinue,
  onClickBack,
  hideBackButton,
  isValid,
  id,
  stepName,
  backText = 'voltar',
  forwardText = 'continuar',
  className,
  classes = {},
}) {
  const styles = useStyles();
  return (
    <Grid
      container
      spacing={2}
      className={clsx(styles.actionWrapper, className, classes.root)}
      data-testid="NavigateFormButtons"
    >
      <Grid item xs={6}>
        {!hideBackButton && (
          <Button
            variant="outlined"
            onClick={onClickBack}
            className={clsx('button--small button--blue button--outlined', classes.button)}
            classNameContainer={clsx('button-group--mr-16', classes.buttonContainer)}
            text={backText}
            context="BackButton"
            page={stepName}
            id={id}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Button
          color="primary"
          type="submit"
          className={clsx('button--small', classes.button)}
          text={forwardText}
          context="ContinueButton"
          page={stepName}
          onClick={onClickContinue}
          id={id}
          disabled={isValid === false}
          classNameContainer={classes.buttonContainer}
        />
      </Grid>
    </Grid>
  );
}

NavigateFormButtons.propTypes = {
  onClickContinue: PropTypes.func,
  onClickBack: PropTypes.func,
  hideBackButton: PropTypes.bool,
  isValid: PropTypes.bool,
  id: PropTypes.number,
  stepName: PropTypes.string,
  backText: PropTypes.string,
  forwardText: PropTypes.string,
};
