import * as yup from 'yup';
import GenericField from './genericField';

export default class BooleanField extends GenericField {
  constructor(
    name,
    label,
    {
      required = false,
      requiredMessage = undefined,
      fontSize = undefined,
      yesLabel = undefined,
      noLabel = undefined,
      ariaLabel = undefined,
      linkLabel = undefined,
      linkUrl = undefined,
      style = undefined,
    } = {},
  ) {
    super(name, label);

    this.required = required;
    this.requiredMessage = requiredMessage;
    this.yesLabel = yesLabel || 'Sim';
    this.noLabel = noLabel || 'Não';
    this.ariaLabel = ariaLabel;
    this.linkLabel = linkLabel;
    this.linkUrl = linkUrl;
    this.fontSize = fontSize;
    this.style = style;
  }

  forView(value) {
    return value ? this.yesLabel : this.noLabel;
  }

  forFormInitialValue = value => value;

  forForm() {
    let yupChain = yup
      .bool()
      .default(false)
      .label(this.label);

    if (this.required) {
      yupChain = yupChain.test(this.name, this.requiredMessage, value => {
        if (value === undefined) return false;
        return value;
      });
    }
    return yupChain;
  }
}
