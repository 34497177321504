import { makeStyles, withStyles, Container } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dropDownWrapper: {
    height: '22px',
    position: 'relative',
    display: 'flex',
    zIndex: '2',
  },
  dropDownListContainer: {
    marginTop: props => (props.hideSelectedBorder ? '-16px' : '-21px'),
    marginLeft: '-21px',
    width: 0,
  },
  dropDownFixedOption: {
    margin: '5px 5px 0 5px',
    marginLeft: props => (props.expansionSide === 'right' ? '5px' : 'auto'),
    cursor: 'pointer',
    fontWeight: 'bold',
    width: 'fit-content',
    boxShadow: '0 0 5px rgba(0,0,0,.3)',
    backgroundColor: theme.palette.white,
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    color: theme.palette.primary.main,
    '& > span': {
      marginRight: '10px',
    },
    '& > span > i': {
      marginRight: '10px',
    },
  },
  dropDownFixedOptionContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 1rem 10px 1rem',
  },
  dropDownFixedOptionText: {
    margin: '0 10px',
  },
  dropDownFixedOptionShadowHidder: {
    width: '100%',
    height: '5px',
    background: 'white',
    position: 'relative',
  },
  dropDownInit: {
    listStyle: 'none',
  },
  dropContainerBoxShadowRight: {
    padding: '0 5px 5px 5px',
  },
  dropContainerBoxShadowBottom: {
    backgroundColor: 'white',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
  },
  dropDownContainer: {
    listStyle: 'none',
    margin: 0,
    marginLeft: props => (props.expansionSide === 'right' ? 0 : '-132px'),
    maxHeight: 0,
    opacity: 0,
    padding: 0,
    borderRadius: '5px',
    overflow: 'hidden',
    transition: 'opacity .1s cubic-bezier(0, 0, 0.38, 0.9), max-height .5s cubic-bezier(0, 0, 0.38, 0.9)',
    width: 'fit-content',
  },
  dropDownItemSelected: {
    paddingBottom: props => (props.hideSelectedBorder ? 0 : '5px'),
    borderBottom: props => (props.hideSelectedBorder ? 'none' : `1px solid rgba(0, 0, 0, 0.42)`),
    opacity: props => (props.disabled ? 0.7 : 1),
    cursor: props => (props.disabled ? 'not-allowed' : 'pointer'),

    '& span.selected-option-label': {
      margin: '0 10px',
    },
    '& span.selected-option-description': {
      marginLeft: '-5px',
      marginRight: '10px',
    },
  },
  dropDowItem: {
    cursor: 'pointer',
    fontFamily: 'ItauText-Regular',
    listStylePosition: 'inside',
    overflow: 'hidden',
    padding: '10px 1rem 10px 1rem',
    transition: 'background-color .1s linear, color .1s linear',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.white,

    '&:first-child': {
      borderTopRightRadius: props => (props.expansionSide === 'right' ? '5px' : 0),
      borderTopLeftRadius: props => (props.expansionSide === 'right' ? 0 : '5px'),
    },
    '&:last-child': {
      borderBottomRightRadius: '5px',
      borderBottomLeftRadius: '5px',
    },

    '& > span > span': {
      margin: '0 5px 0 10px',
    },
    '&:focus, &:hover': {
      fontFamily: 'ItauText-Bold',
      fontWeight: 'bold',
      '& > span > i': {
        fontWeight: 'bold',
      },
      '& > span': {
        textDecoration: 'underline',
      },
    },
  },
  dropDownItemDescription: {
    color: 'gray',
    fontSize: '.8rem',
  },
  open: {
    opacity: '1',
    overflow: 'hidden',
    maxHeight: '20rem',
    visibility: 'visible',
  },
  hide: {
    opacity: 0,
    maxHeight: 0,
    visibility: 'hidden',
  },
}));

export const DropdownListContainer = withStyles(({ toRem }) => ({
  root: props =>
    props.opened
      ? {
          opacity: '1',
          overflow: 'hidden',
          maxHeight: toRem(props.maxHeight),
          visibility: 'visible',
        }
      : {
          opacity: 0,
          maxHeight: 0,
          visibility: 'hidden',
        },
}))(Container);
