/* eslint-disable no-unused-vars */
import { call, put, select } from 'redux-saga/effects';
import { loadHiringError, loadHiringSuccess } from 'store/auto/store/ducks/automovel';
import { Types as AuthTypes } from 'store/ducks/auth';
import moment from 'moment';
import postHiring from 'api/E2E/Auto/postHiring';
import { setError, setErrorSagaAction } from '../../ducks/httpErrors';
import {
  automobileHiringRequestSelector,
  automobileSelectedSimulationIDSelector,
} from '../../auto/store/sagas/selectors/auto';

export default function* loadHiring(action) {
  const { data } = action;
  const { captcha, hash } = data;
  try {
    const request = yield select(automobileHiringRequestSelector);
    request.id_simulacao = yield select(automobileSelectedSimulationIDSelector);
    request.item_risco.veiculo.chassi = request.item_risco.veiculo.chassi.replace(/\s/g, '');

    if (request?.dados_renovacao?.data_inicio && request?.dados_renovacao?.data_fim) {
      const oldDateStart = request.dados_renovacao.data_inicio;
      const oldDateEnd = request.dados_renovacao.data_inicio;
      const formattedStartDate = moment(oldDateStart, 'DD/MM/YYYY');
      const formattedEndDate = moment(oldDateEnd, 'DD/MM/YYYY');
      if (!formattedStartDate.isValid() || !formattedEndDate.isValid()) {
        request.dados_renovacao.data_inicio = oldDateStart;
        request.dados_renovacao.data_fim = oldDateEnd;
      } else {
        request.dados_renovacao.data_inicio = formattedStartDate.format('YYYY-MM-DD');
        request.dados_renovacao.data_fim = formattedEndDate.format('YYYY-MM-DD');
      }
    }

    const payload = {
      ...request,
      captcha,
    };

    delete payload.dados_opcao_pagamento?.cartao_credito?.porto;
    delete payload.proponente?.politicamente_exposto?.situacao;

    const contratacao = yield call(postHiring, payload, hash);
    yield put(loadHiringSuccess(contratacao));
    yield put({ type: AuthTypes.AUTH_REMOVE });
  } catch (err) {
    yield put(setError());
    yield put(loadHiringError(err));
    yield put(setErrorSagaAction(action));
  }
}
