import { put, select } from 'redux-saga/effects';

import { setInstallmentOption } from 'store/auto/store/ducks/autoFlow';
import { automobileQuotationResponseSelectedSimulationPaymentOptionsListSelector } from 'store/auto/store/sagas/selectors/auto';
import { autoFlowSelectedPaymentOptionSelector } from 'store/auto/store/sagas/selectors/autoFlow';

export default function* setInstallmentPaymentTypeAction({ data }) {
  const hiringResponsePaymentOptions = yield select(
    automobileQuotationResponseSelectedSimulationPaymentOptionsListSelector,
  );
  const hiringResponseInstallment = yield select(autoFlowSelectedPaymentOptionSelector);
  const filterInstallmentsByMethod = (method, paymentOptions) =>
    paymentOptions.filter(option => option?.metodo === method);

  const installment = filterInstallmentsByMethod(data, hiringResponsePaymentOptions)?.filter(
    item => item?.parcelas === hiringResponseInstallment?.parcelas,
  );

  yield put(
    setInstallmentOption({
      valor_premio: installment[0].valor_parcela,
      ...installment[0],
    }),
  );
}
