import { ANALYTICS_HOME_FLOW } from 'trackingV2/flows/home';
import { ANALYTICS_AUTO_FLOW } from 'trackingV2/flows/auto';
import { ANALYTICS_ODONTO_HOME_FLOW } from 'trackingV2/flows/odontoHome';
import { ANALYTICS_ODONTO_HIRING_FLOW } from 'trackingV2/flows/odontoHiring';
import { ANALYTICS_RESID_HOME_FLOW } from 'trackingV2/flows/residHome';
import { ANALYTICS_RESID_HIRING_FLOW } from 'trackingV2/flows/residHiring';
import { ANALYTICS_CARGLASS_FLOW } from 'trackingV2/flows/carglass';
import { ANALYTICS_BLLU_FLOW } from 'trackingV2/flows/bllu';
import { ANALYTICS_WELFARE } from 'trackingV2/flows/welfare';
import { ANALYTICS_CONSORCIO_FLOW } from 'trackingV2/flows/consorcio';
import { ANALYTICS_PET } from 'trackingV2/flows/pet';
import { ANALYTICS_RESID_ASYNC_QUOTATION_FLOW } from 'trackingV2/flows/residAsyncQuotation';
import { ANALYTICS_CONNECT_CAR } from 'trackingV2/flows/connectCar';
import { ANALYTICS_AUTOV2_FLOW } from './autoV2';
import {
  ANALYTICS_AUTO_HIRING_OFFER_FLOW,
  ANALYTICS_ODONTO_HIRING_OFFER_FLOW,
  ANALYTICS_PET_HIRING_OFFER_FLOW,
  ANALYTICS_RESID_HIRING_OFFER_FLOW,
  ANALYTICS_WELFARE_HIRING_OFFER_FLOW,
} from './products-offer';
import { ANALYTICS_CANCELLATION } from './cancellation';
import { ANALYTICS_PET_HOME } from './petHome';
import { ANALYTICS_WELFARE_HOME } from './welfareHome';

interface AnalyticsFlowsInterface {
  [flow: string]: {
    [eventCode: string]: object;
  };
}

const ANALYTICS_FLOWS: AnalyticsFlowsInterface = {
  HOME: ANALYTICS_HOME_FLOW,
  AUTO: ANALYTICS_AUTO_FLOW,
  ODONTO_HOME: ANALYTICS_ODONTO_HOME_FLOW,
  ODONTO_HIRING: ANALYTICS_ODONTO_HIRING_FLOW,
  RESID_HOME: ANALYTICS_RESID_HOME_FLOW,
  RESID_ASYNC_QUOTATION: ANALYTICS_RESID_ASYNC_QUOTATION_FLOW,
  RESID_HIRING: ANALYTICS_RESID_HIRING_FLOW,
  CARGLASS: ANALYTICS_CARGLASS_FLOW,
  BLLU: ANALYTICS_BLLU_FLOW,
  CONSORCIO: ANALYTICS_CONSORCIO_FLOW,
  CONNECT_CAR: ANALYTICS_CONNECT_CAR,
  WELFARE: ANALYTICS_WELFARE,
  WELFARE_HOME: ANALYTICS_WELFARE_HOME,
  PET: ANALYTICS_PET,
  PET_HOME: ANALYTICS_PET_HOME,
  AUTOV2: ANALYTICS_AUTOV2_FLOW,
  RESID_HIRING_OFFER_PRODUCTS: ANALYTICS_RESID_HIRING_OFFER_FLOW,
  ODONTO_HIRING_OFFER_PRODUCTS: ANALYTICS_ODONTO_HIRING_OFFER_FLOW,
  AUTO_HIRING_OFFER_PRODUCTS: ANALYTICS_AUTO_HIRING_OFFER_FLOW,
  WELFARE_HIRING_OFFER_PRODUCTS: ANALYTICS_WELFARE_HIRING_OFFER_FLOW,
  PET_HIRING_OFFER_PRODUCTS: ANALYTICS_PET_HIRING_OFFER_FLOW,
  CANCELLATION: ANALYTICS_CANCELLATION,
};

export default ANALYTICS_FLOWS;
