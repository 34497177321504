import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { odontoSelectedPlanSelector } from '../../../../../store/sagas/selectors/odonto';
import { OdontoSelectedPlanSettingsModel } from '../Types/OdontoSelectedPlanSettingsModel';

const odontoSelectedPlanSettingsContextDefaultValue: OdontoSelectedPlanSettingsModel = {
  isActive: false,
  globals: null,
  components: null,
};

export const OdontoSelectedPlanSettingsContext = createContext<OdontoSelectedPlanSettingsModel>(
  odontoSelectedPlanSettingsContextDefaultValue
);

export type OdontoSelectedPlanSettingsProviderProps = {
  children: JSX.Element;
};

const OdontoSelectedPlanSettingsProvider = ({ children }: OdontoSelectedPlanSettingsProviderProps): JSX.Element => {
  const selectedPlan = useSelector(odontoSelectedPlanSelector);

  const campainContextValue: OdontoSelectedPlanSettingsModel = odontoSelectedPlanSettingsContextDefaultValue;

  if (selectedPlan?.settings) {
    campainContextValue.isActive = true;
    campainContextValue.globals = selectedPlan.settings.globals;
    campainContextValue.components = selectedPlan.settings.components;
  }

  return (
    <OdontoSelectedPlanSettingsContext.Provider value={campainContextValue}>
      {children}
    </OdontoSelectedPlanSettingsContext.Provider>
  );
};

const useOdontoSelectedPlanCampaignConsumer = () => useContext(OdontoSelectedPlanSettingsContext);

export { OdontoSelectedPlanSettingsProvider, useOdontoSelectedPlanCampaignConsumer };
