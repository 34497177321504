import axios from 'axios';

import config from 'config';

const { E2E_PATH, ITAU_GATEWAY_HOST } = config;

export const postAutoSimulation = async (franchise = 50, requestData) => {
  const baseURL = ITAU_GATEWAY_HOST;
  const response = await axios.post(`${E2E_PATH}/simulacoes/automovel?franquia=${franchise}`, requestData, {
    timeout: 60 * 1000 * 4,
    disableRetry: true,
    disableException: true,
    baseURL,
  });
  const location = await response.headers.location.split('/');
  const id = location[location.length - 1];
  return `${E2E_PATH}/simulacoes/automovel/${id}`;
};
