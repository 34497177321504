import axios from 'axios';
import config from 'config';
import { getBaseURL } from 'utils';

const { E2E_PATH } = config;
const MOCK_NAME = 'RESID_GET_QUESTIONS_MOCK';

export const getOffersQuestions = async id => {
  const baseURL = await getBaseURL(MOCK_NAME);

  const getOffersQuestionsUrl = offerId =>
    `${E2E_PATH}/ofertas/${offerId}/questoes`;

  const response = await axios.get(getOffersQuestionsUrl(id), { baseURL });

  return response.data;
};
