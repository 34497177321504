const odontoModule = {
  ODONTO: {
    BANNER: {
      TITLE: '<b>momentos felizes merecem um belo sorriso,</b> temos os melhores planos pra você',
      MALA_DIGITAL: {
        TITLE: '<b>chegou a sua hora de sorrir para a vida.</b> faça já um plano odontológico.',
        SUBTITLE: {
          LINK_PREFIX: 'ou ',
          LINK_TEXT: ' clique aqui ',
          LINK_SUFIX: ' e agende a ligação de um especialista.',
          LINK_ARIA_LABEL: 'ou clique aqui e agende a ligação de um especialista',
        },
      },
      PROMOTIONINDOOR: {
        TITLE: 'Aproveite a <br/>oportunidade!',
        SUBTITLE: 'Planos odontológicos MetLife com <br/>carência reduzida até o dia 30/04!',
        BUTTON_TEXT: 'ver planos',
      },
    },
    SCHEDULE_CONTACT: {
      TITLE: 'oi, tudo bem?',
      MALA_DIGITAL: {
        SUBTITLE: 'vamos agendar a ligação do consultor.',
        SMALLER_SUBTITLE: 'se preferir, ligue no **4020 2031** (2ª a 6ª das 9h às 18h)',
      },
    },
    PRODUCT_SELECTION: {
      PLAN_CARD: {
        FEATURED_COVERAGES: 'Mais de {{total}} outras coberturas',
        PRICE_SECTION: {
          MONTH: '/mês',
          PER_PERSON: 'por pessoa',
        },
        DROPDOWN: {
          OPENED: 'esconder os detalhes do plano',
          CLOSED: 'ver os detalhes do plano',
        },
        BUTTON: 'quero esse plano',
      },
      LOCATION_SECTION: {
        TITLE: 'Você se interessou pelo plano ',
        DESCRIPTION: 'Diz pra gente onde você mora que vamos consultar se a MetLife atende sua região',
        SEARCH_BUTTON: 'buscar',
        SWITCH_PRODUCT: 'trocar por outro',
      },
    },
  },
};

export default odontoModule;
