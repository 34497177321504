/* eslint-disable import/no-unresolved */
import React from 'react';
import useWindowWidth from 'hooks/useWindowWidth';
import PropTypes from 'prop-types';
import i18next from 'i18n';
import { Trans } from 'react-i18next';
import { useStylesBanner } from './useStylesBanner';
import { useStylesPopUp } from './useStylesPopUp';
import desktop from './assets/blackFriday.png';
import mobile from './assets/blackFridayMobile.png';
import OneTimeAriaAlert from '../../../../../../components/OneTimeAriaAlert';

const Banner = ({ type }) => {
  const styles = {
    banner: useStylesBanner(),
    popup: useStylesPopUp(),
  };
  const width = useWindowWidth();

  return (
    <>
      <OneTimeAriaAlert alertMessage={i18next.t('BLACKFRIDAY.BANNER.ACCESSIBILITY')} type="polite" />
      <div className={styles[type].wrapper}>
        {width > 768 && (
          <div>
            <img className={styles[type].imgDesktop} src={desktop} alt="black friday" aria-hidden />
          </div>
        )}
        {width < 768 && (
          <div>
            <img src={mobile} alt="black friday" aria-hidden />
          </div>
        )}
        <div className={styles[type].wrapperContent}>
          <div className={styles[type].mainMessage}>
            <p role="heading" aria-level="1">
              {i18next.t('BLACKFRIDAY.BANNER.TITLE')}
            </p>
            <p role="heading" aria-level="1" arial-label={i18next.t('BLACKFRIDAY.BANNER.NAMEACCESSIBILITY')}>
              <Trans i18nKey={i18next.t('BLACKFRIDAY.BANNER.NAME')} />
            </p>
          </div>
          <div className={styles[type].wrapperDiscount}>
            <div className={styles[type].discount}>
              <p
                className={styles[type].discountValue}
                role="heading"
                aria-level="1"
                aria-label={i18next.t('BLACKFRIDAY.DISCOUNT.VALUEACCESSIBILITY')}
              >
                {i18next.t('BLACKFRIDAY.DISCOUNT.VALUE')}
              </p>
              <div className={styles[type].sign} aria-hidden>
                <p className={styles[type].paragraphPorcent}>{i18next.t('BLACKFRIDAY.DISCOUNT.PORCENT')}</p>
                <p className={styles[type].paragraphSign}>{i18next.t('BLACKFRIDAY.DISCOUNT.DISCOUNT')}</p>
              </div>
            </div>
            <div className={styles[type].deadline}>
              <p
                role="heading"
                aria-level="1"
                aria-label={i18next.t('BLACKFRIDAY.BANNER.PLANSACCESSIBILITY')}
                className={styles[type].plans}
              >
                <Trans i18nKey={i18next.t('BLACKFRIDAY.BANNER.PLANS')} />
              </p>
              <p
                role="heading"
                aria-level="2"
                aria-label={i18next.t('BLACKFRIDAY.BANNER.WARNINGACCESSIBILITY')}
                className={styles[type].time}
              >
                {i18next.t('BLACKFRIDAY.BANNER.WARNING')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;

Banner.defaultProps = {
  type: 'banner',
};

Banner.propTypes = {
  type: PropTypes.string,
};
