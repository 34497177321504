import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_ENVIRONMENTS,
} from 'trackingV2/constants';

export const ANALYTICS_RESID_ASYNC_QUOTATION_FLOW = {
  T1: {
    nome: 'LS:NL:NCC:Residencial:Cotacao:CotacaoIndisponivel',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T2: {
    nome: 'LS:NL:NCC:Residencial:Cotacao:CotacaoIndisponivelSucesso',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESIDENCIAL,
    label: 'BTN:Residencial:Cotacao:CotacaoIndisponivel:ReceberCotacaoViaEmail',
    noInteraction: false,
  },
};
