/* eslint-disable prefer-const */
export const Types = {
  SAVE_SESSION: 'automovel/SAVE_SESSION',
  ADD_CPF: 'automovel/AddCPF',
  CREATE_OFFER: 'automovel/CREATE_OFFER',
  UPDATE_VEHICLE: 'automovel/UPDATE_VEHICLE',
  CREATE_QUESTIONS: 'automovel/CREATE_QUESTIONS',
  CREATE_DRIVER: 'automovel/CREATE_DRIVER',
  CREATE_PROPONENT: 'automovel/CREATE_PROPONENT',
  ADD_LICENSE_PLATE: 'automovel/ADD_LICENSE_PLATE',
  CREATE_PROFILE: 'automovel/CREATE_PROFILE',
  CREATE_AUTO_INFORMATION: 'automovel/CREATE_AUTO_INFORMATION',
  CREATE_RENOVATION_DATA: 'automovel/CREATE_RENOVATION_DATA',
  SET_SELECTED_SIMULATION: 'automovel/SET_SELECTED_SIMULATION',
  LOAD_QUOTATION: 'automovel/LOAD_QUOTATION',
  FINISH_QUOTATION: 'automovel/FINISH_QUOTATION',
  RESET_AUTOMOBILE: 'automovel/RESET_AUTOMOBILE',
  SET_INITIAL_HIRING_DATA: 'automovel/SET_INITIAL_HIRING_DATA',
  RESET_RESPONSE: 'automovel/RESET_RESPONSE',
  SET_UPDATE_INITIAL_HIRING_DATA: 'automovel/SET_UPDATE_INITIAL_HIRING_DATA',
  CREATE_ADITIONAL_DATA: 'automovel/CREATE_ADITIONAL_DATA',
  PREPARE_HIRING_PAYLOAD_DATA: 'automovel/PREPARE_HIRING_PAYLOAD_DATA',
  CREATE_HIRING_PROPONENT: 'automovel/CREATE_HIRING_PROPONENT',
  CREATE_MAIN_DRIVER: 'automovel/CREATE_MAIN_DRIVER',
  CREATE_HIRING_POLITICAL: 'automovel/CREATE_HIRING_POLITICAL',
  CREATE_HIRING_VEHICLE: 'automovel/CREATE_HIRING_VEHICLE',
  CREATE_HIRING_RENEWING_DATA: 'automovel/CREATE_HIRING_RENEWING_DATA',
  CREATE_HIRING_PAYMENT: 'automovel/CREATE_HIRING_PAYMENT',
  CREATE_HIRING_PAYMENT_ACTION: 'automovel/CREATE_HIRING_PAYMENT_ACTION',
  LOAD_HIRING: 'automovel/LOAD_HIRING',
  LOAD_HIRING_SUCCESS: 'automovel/LOAD_HIRING_SUCCESS',
  LOAD_HIRING_ERROR: 'automovel/LOAD_HIRING_ERROR',
  RESET_HIRING: 'automovel/RESET_HIRING',
  SET_INSTALLMENT_PAYMENT_TYPE: 'automovel/SET_INSTALLMENT_PAYMENT_TYPE',
  SET_COVERAGE_SELECTED: 'automovel/SET_COVERAGE_SELECTED',
};

const initialState = {
  cotacao: {
    requisicao: {
      oferta: {},
      item_risco: {
        veiculo: {},
      },
      condutores: [],
      dados_renovacao: {},
      proponente: {},
      questoes: [],
    },
    resposta: [],
    pristino: true,
    coverageSelected: '',
    coverageSelectedTotalValue: '',
  },
  contratacao: {
    requisicao: {
      id_simulacao: {},
      link_comprovante: {},
      dados_opcao_pagamento: {},
      proponente: {},
      item_risco: { veiculo: {} },
      condutor: {},
      dados_renovacao: {},
    },
    resposta: {},
  },
  initQuotation: {
    pristine: true,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.RESET_AUTOMOBILE: {
      return {
        ...initialState,
        cotacao: {
          ...initialState.cotacao,
          requisicao: {
            ...initialState.cotacao.requisicao,
            oferta: state.cotacao.requisicao.oferta,
          },
        },
      };
    }
    case Types.SAVE_SESSION:
      return {
        ...action.data,
      };
    case Types.LOAD_INIT_QUOTATION:
      return {
        ...state,
        initQuotation: {
          loading: true,
          error: false,
        },
      };
    case Types.ERROR_INIT_QUOTATION:
      return {
        ...state,
        initQuotation: {
          error: true,
          loading: false,
        },
      };
    case Types.SUCCESS_INIT_QUOTATION:
      return {
        ...state,
        checkBond: action.data,
        initQuotation: {
          loading: false,
          error: false,
        },
      };

    case Types.CREATE_OFFER:
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          requisicao: {
            ...state.cotacao.requisicao,
            oferta: {
              ...state.cotacao.requisicao.oferta,
              ...action.data,
            },
          },
        },
      };

    case Types.CREATE_PROPONENT:
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          requisicao: {
            ...state.cotacao.requisicao,
            proponente: {
              ...state.cotacao.requisicao.proponente,
              ...action.data,
            },
          },
        },
      };
    case Types.CREATE_HIRING_PROPONENT:
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            proponente: {
              ...state.cotacao.requisicao.proponente,
              ...state.contratacao.requisicao.proponente,
              ...action.data,
            },
          },
        },
      };
    case Types.CREATE_HIRING_POLITICAL:
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            proponente: {
              ...state.contratacao.requisicao.proponente,
              politicamente_exposto: {
                ...state.contratacao.requisicao.proponente.politicamente_exposto,
                ...action.data,
              },
            },
          },
        },
      };
    case Types.CREATE_HIRING_RENEWING_DATA: {
      let renewingData = {
        dados_renovacao: undefined,
      };
      if (action.data) {
        renewingData = {
          dados_renovacao: {
            ...state.contratacao.requisicao.dados_renovacao,
            ...action.data,
          },
        };
      }

      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            ...renewingData,
          },
        },
      };
    }

    case Types.CREATE_MAIN_DRIVER: {
      const quotationMainDriver = state.cotacao.requisicao.condutores.find(driver => driver[0]?.principal);
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            condutor: {
              ...state.contratacao.requisicao.condutor,
              cpf: quotationMainDriver[0]?.cpf,
              ...action.data,
            },
          },
        },
      };
    }
    case Types.CREATE_HIRING_VEHICLE:
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            item_risco: {
              veiculo: {
                ...state.contratacao.requisicao.item_risco?.veiculo,
                placa: state.cotacao.requisicao.item_risco.veiculo.placa,
                ...action.data,
              },
            },
          },
        },
      };

    case Types.CREATE_HIRING_PAYMENT:
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            ...action.data,
          },
        },
      };

    case Types.UPDATE_VEHICLE:
      state.cotacao.requisicao.item_risco.veiculo = {
        ...state.cotacao.requisicao.item_risco.veiculo,
        ...action.data,
      };
      return state;

    case Types.CREATE_RENOVATION_DATA:
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          requisicao: {
            ...state.cotacao.requisicao,
            dados_renovacao: {
              ...state.cotacao.requisicao.dados_renovacao,
              ...action.data,
            },
          },
        },
      };

    case Types.CREATE_DRIVER:
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          requisicao: {
            ...state.cotacao.requisicao,
            condutores: [
              {
                ...action.data,
              },
            ],
          },
        },
      };

    case Types.ADD_LICENSE_PLATE:
      state.cotacao.requisicao.item_risco.veiculo.placa = action.data;
      return state;

    case Types.CREATE_QUESTIONS:
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          requisicao: {
            ...state.cotacao.requisicao,
            questoes: action.data,
          },
        },
      };

    case Types.ADD_CPF: {
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          requisicao: {
            ...state.cotacao.requisicao,
            proponente: {
              ...state.cotacao.requisicao.proponente,
              cpf: action.data,
            },
          },
        },
      };
    }
    case Types.SET_SELECTED_SIMULATION:
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          requisicao: {
            ...state.contratacao.requisicao,
            id_simulacao: action.data,
          },
        },
      };

    case Types.LOAD_QUOTATION: {
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          carregando: true,
        },
      };
    }

    case Types.FINISH_QUOTATION: {
      const { franquia } = action.data;
      let newQuotationResponse = state.cotacao?.resposta?.filter(resp => resp.franquia !== franquia);
      newQuotationResponse.push(action.data);
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          resposta: newQuotationResponse,
          carregando: false,
          pristino: false,
        },
      };
    }

    case Types.RESET_RESPONSE: {
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          resposta: initialState.cotacao.resposta,
        },
      };
    }

    case Types.LOAD_HIRING: {
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          carregando: true,
          erro: false,
        },
      };
    }

    case Types.LOAD_HIRING_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          resposta: { ...data },
          carregando: false,
          sucesso: true,
          erro: false,
        },
      };
    }

    case Types.LOAD_HIRING_ERROR: {
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          carregando: false,
          sucesso: false,
          erro: true,
        },
      };
    }

    case Types.RESET_HIRING: {
      return {
        ...state,
        contratacao: {
          ...state.contratacao,
          carregando: false,
          sucesso: false,
          erro: false,
        },
      };
    }

    case Types.SET_COVERAGE_SELECTED: {
      const { selectedCoverageName, selectedCoverageTotalValue } = action.data;
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          coverageSelected: selectedCoverageName,
          coverageSelectedTotalValue: selectedCoverageTotalValue,
        },
      };
    }

    default:
      return state;
  }
}

export function saveAutoSession(data) {
  return {
    type: Types.SAVE_SESSION,
    data,
  };
}

export function setSelectedSimulation(data) {
  return {
    type: Types.SET_SELECTED_SIMULATION,
    data,
  };
}

export function addCPFData(data) {
  return {
    type: Types.ADD_CPF,
    data,
  };
}

export function createOffer(data) {
  return {
    type: Types.CREATE_OFFER,
    data,
  };
}

export function createQuestions(data) {
  return {
    type: Types.CREATE_QUESTIONS,
    data,
  };
}

export function addLicensePlate(data) {
  return {
    type: Types.ADD_LICENSE_PLATE,
    data,
  };
}

export function updateVehicle(data) {
  return {
    type: Types.UPDATE_VEHICLE,
    data,
  };
}

export function createRenovationData(data) {
  return {
    type: Types.CREATE_RENOVATION_DATA,
    data,
  };
}

export function createDriver(data) {
  return {
    type: Types.CREATE_DRIVER,
    data,
  };
}

export function createProponent(data) {
  return {
    type: Types.CREATE_PROPONENT,
    data,
  };
}

export function createHiringProponent(data) {
  return {
    type: Types.CREATE_HIRING_PROPONENT,
    data,
  };
}

export function createHiringMainDriver(data) {
  return {
    type: Types.CREATE_MAIN_DRIVER,
    data,
  };
}

export function createHiringPolitical(data) {
  return {
    type: Types.CREATE_HIRING_POLITICAL,
    data,
  };
}

export function createHiringVehicle(data) {
  return {
    type: Types.CREATE_HIRING_VEHICLE,
    data,
  };
}

export function createRenewingData(data) {
  return {
    type: Types.CREATE_HIRING_RENEWING_DATA,
    data,
  };
}

export function createProfile(data) {
  return {
    type: Types.CREATE_PROFILE,
    data,
  };
}

export function createAditionalData(data) {
  return {
    type: Types.CREATE_ADITIONAL_DATA,
    data,
  };
}

export function createPaymentData(data) {
  return {
    type: Types.CREATE_HIRING_PAYMENT,
    data,
  };
}

export function createPaymentAction(data) {
  return {
    type: Types.CREATE_HIRING_PAYMENT_ACTION,
    data,
  };
}

export function createAutoInformation(data) {
  return {
    type: Types.CREATE_AUTO_INFORMATION,
    data,
  };
}

export function loadQuotation(data) {
  return {
    type: Types.LOAD_QUOTATION,
    data,
  };
}

export function finishQuotation(data) {
  return {
    type: Types.FINISH_QUOTATION,
    data,
  };
}

export function resetAutomobile() {
  return {
    type: Types.RESET_AUTOMOBILE,
  };
}

export function setInitialHiringData(data) {
  return {
    type: Types.SET_INITIAL_HIRING_DATA,
    data,
  };
}

export function resetResponse(data) {
  return {
    type: Types.RESET_RESPONSE,
    data,
  };
}

export function setUpdateInitialHiringData(data) {
  return {
    type: Types.SET_UPDATE_INITIAL_HIRING_DATA,
    data,
  };
}

export function prepareHiringPayload(data) {
  return {
    type: Types.PREPARE_HIRING_PAYLOAD_DATA,
    data,
  };
}

export function loadHiringError(data) {
  return {
    type: Types.LOAD_HIRING_ERROR,
    data,
  };
}

export function loadHiringSuccess(data) {
  return {
    type: Types.LOAD_HIRING_SUCCESS,
    data,
  };
}

export function loadHiring(data) {
  return {
    type: Types.LOAD_HIRING,
    data,
  };
}

export function setInstallmentPaymentType(data) {
  return {
    type: Types.SET_INSTALLMENT_PAYMENT_TYPE,
    data,
  };
}

export function setCoverageSelected(data) {
  return {
    type: Types.SET_COVERAGE_SELECTED,
    data,
  };
}
