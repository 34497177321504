import axios from 'axios';
import ROUTES from 'routes/routes';
import SCOPES from 'auth/scopes';
import envConfig from '../config';
import { axios500ExceptNoRedirect } from './axios.500ExceptNoRedirect';
import { getSessionStorage } from './util';

const { REACT_APP_ENVIRONMENT } = process.env;
const {
  DISTRIBUTION_KEY,
  MEDIUM_KEY,
  CAMPAIGN_KEY,
  DEFAULT_ID_PATH_VALUE,
  DEFAULT_MEDIUM_VALUE,
  DEFAULT_CAMPAIGN_VALUE,
  GATEWAY_HOST,
  ITAU_GATEWAY_HOST,
} = envConfig;

axios.defaults.baseURL = GATEWAY_HOST;
axios500ExceptNoRedirect.defaults.baseURL = GATEWAY_HOST;

const paths = [
  'v1/assessoria/',
  'v1/codigo-enderecamento-postal/endereco',
  'v1/seguros/faq/Odonto',
  'v1/seguros/faq/Auto',
  'v1/seguros/faq/Residencial',
  'v1/seguros/faq/Pet',
  'v1/seguros/faq/BemEstar',
  'v1/seguros/channels/search',
  'v1/seguros/offers/search',
  'v1/seguros/products/leads',
  'v1/cart/',
  'v1/mktseguros/leads',
  'v1/mkt-seguros-e2e',
  'v1/seguros/listas-suspensas',
  'v1/mkt-seguros-autenticacao',
  'v1/mkt-seguros-odonto',
];

const productNames = {
  [ROUTES.LANDING_ODONTO]: SCOPES.ODONTO,
  [ROUTES.LANDING_RESID]: SCOPES.RESID,
  [ROUTES.LANDING_AUTO]: SCOPES.AUTO,
  [ROUTES.CANCEL]: SCOPES.CANCELLATION,
  [ROUTES.LANDING_PET]: SCOPES.PET,
  [ROUTES.LANDING_WELFARE]: SCOPES.WELFARE,
};

const defaultRequestInterceptor = config => {
  const productPathname = `/${window.location.pathname.split('/')[1]}`;
  if (config.baseURL === GATEWAY_HOST && config.url) {
    if (paths.some(path => config.url.indexOf(path) !== -1)) {
      config.baseURL = ITAU_GATEWAY_HOST;
    } else {
      config.baseURL = GATEWAY_HOST;
    }
  }

  if (getSessionStorage(MEDIUM_KEY) !== DEFAULT_MEDIUM_VALUE) {
    config.headers.common['x-utm-medium'] = getSessionStorage(MEDIUM_KEY);
  }

  if (getSessionStorage(CAMPAIGN_KEY) !== DEFAULT_CAMPAIGN_VALUE) {
    config.headers.common['x-utm-campaign'] = getSessionStorage(CAMPAIGN_KEY);
  }

  config.headers.common['x-utm-source'] = getSessionStorage(DISTRIBUTION_KEY) || DEFAULT_ID_PATH_VALUE;
  config.headers.common['Content-Type'] = 'application/json';
  config.headers.common['Cache-Control'] = 'no-cache';
  config.headers.common['x-environment'] = window.sessionStorage.getItem('x-environment') || REACT_APP_ENVIRONMENT;
  config.headers.common['x-product-name'] = productNames[productPathname] || '';
  return config;
};

axios500ExceptNoRedirect.interceptors.request.use(defaultRequestInterceptor, function(error) {
  return Promise.reject(error);
});
axios.interceptors.request.use(defaultRequestInterceptor, function(error) {
  return Promise.reject(error);
});
