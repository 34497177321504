import React, { useState } from 'react';
import { Field } from 'formik';
import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { callFunction, isMobileDevice } from '../../../../utils/util';
import FormErrors from '../../formErrors';
import useStyles from './FormikTextFieldWithToolTip.styles';
import DarkToolTip from '../../../../components/DarkTooltip/index';

const FormikTextFieldWithToolTipNotFast = ({
  label,
  name,
  form,
  hint,
  type,
  placeholder,
  autoFocus,
  autoComplete,
  inputProps,
  errorMessage,
  required,
  onBlur,
  disabled,
  field,
  onChange,
  id,
  disableUnderline,
  inputRef,
  tooltipProps,
}) => {
  const [focused, setFocus] = useState(false);
  const styles = useStyles();

  const readOnly = disabled ? { readOnly: true } : null;
  const identifier = id || name;
  const { helperText, hasError } = FormErrors.validate(form, name, errorMessage, hint, focused);
  const isMultiline = disabled && isMobileDevice() ? { multiline: true } : {};

  function handleBlur(e) {
    setFocus(false);
    if (onBlur) onBlur(e);
  }

  function handleFocus() {
    setFocus(true);
  }

  const _inputProps = {
    ...inputProps,
    'aria-invalid': hasError,
    'aria-describedby': `${identifier}-helper-text`,
    readOnly,
    className: inputProps.className
      ? { ...inputProps.className, [styles.customProps]: styles.customProps }
      : styles.customProps,
    disableUnderline,
    onBlur: handleBlur,
    onFocus: handleFocus,
  };

  return (
    <FormControl className={styles.inputContainer} disabled={disabled} margin="normal">
      <div className={styles.labelWrapper}>
        <InputLabel shrink required={required} htmlFor={identifier}>
          {label}
        </InputLabel>
        {tooltipProps && (
          <DarkToolTip {...tooltipProps} placement="top" darkBackground darkIcon className={styles.tooltip} />
        )}
      </div>
      <Input
        id={identifier}
        type={type}
        required={required}
        inputRef={inputRef}
        title={disabled && form.values[name] ? form.values[name] : ''}
        fullWidth
        autoFocus={autoFocus || undefined}
        autoComplete={autoComplete || undefined}
        placeholder={placeholder || undefined}
        value={form.values[name] || ''}
        onChange={event => {
          form.setFieldValue(name, event.target.value);
          if (onChange) callFunction(onChange, event);
          if (field.onChange) callFunction(field.onChange, event);
        }}
        error={hasError}
        role="none"
        inputProps={_inputProps}
        className={styles.input}
        {...isMultiline}
        {...field}
      />
      {helperText && (
        <FormHelperText
          className={hasError ? styles.errorMessage : ''}
          id={`${identifier}-helper-text`}
          data-testid={`${identifier}-helper-text`}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default function InputFormItem(props) {
  const { name } = props;
  return (
    <Field
      name={name}
      render={({ form, field }) => <FormikTextFieldWithToolTipNotFast {...props} field={field} form={form} />}
    />
  );
}
