const bannerResult = {
  auto: {
    title:
      '<b>seguradoras parceiras:</b> referências no mercado, nossas parceiras para o seguro automóvel são Porto Seguro e Azul Seguros',
    partners: [
      {
        nome: '"porto seguro"',
        imagem:
          '"https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/parceiros/otimizadas/logoportoseguro.svg"',
      },
    ],
    name: 'Auto',
  },
  residencial: {
    title: 'toda a comodidade e praticidade do Porto Seguro Residência no seu dia a dia',
    partners: [
      {
        nome: '"Porto Seguro"',
        imagem: '"https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/parceiros/otimizadas/logoportoseguro.svg"',
      },
    ],
    name: 'Residencial',
  },
  odonto: {
    title:
      '<b>empresa parceira:</b> a MetLife é uma das principais empresas de serviços financeiros do mundo. Com operação em quase 50 países, chegou no Brasil em 1999 e hoje atende mais de 5 milhões de clientes',
    partners: [
      {
        nome: '"metlife"',
        imagem:
          '"https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/home/parceiros/metlife_logotipo.png"',
      },
    ],
    name: 'Odonto',
  },
};

export const getPartners = () => bannerResult;
