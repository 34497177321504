import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    width: '100%',
    '& [class*="MuiInput-formControl"]': {
      marginTop: 0,
    },
    '& [class*="MuiInput-input"]': {
      marginTop: 0,
      paddingBottom: '6px',
      textTransform: 'uppercase',
    },
    '& label + [class*="MuiInput-formControl"]': {
      marginTop: '16px',
    },
    [breakpoints.up('sm')]: {
      width: spacing(90),
    },
  },
  card: {
    display: 'grid',
    gridGap: spacing(1),
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridTemplateRows: `auto repeat(2, ${spacing(20)})`,
    gridTemplateAreas: `
        'cardNumber cardNumber cardNumber cardNumber flag'
        'cardName cardName cardName cardName cardName'
        'validationDate validationDate validationDate cvv cvv'
    `,
    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateAreas: `
        'cardNumber cardNumber cardNumber flag'
        'cardName cardName cardName cardName'
        'validationDate validationDate . cvv'
    `,
    },
    padding: spacing(4),
  },
  cardNumber: {
    gridArea: 'cardNumber',
  },
  flag: {
    gridArea: 'flag',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 70,
    height: 58,
  },
  imagePorto: {
    width: 80,
    height: 100,
  },
  cardName: {
    gridArea: 'cardName',
  },
  validationDate: {
    gridArea: 'validationDate',
  },
  cvv: {
    gridArea: 'cvv',
  },
}));
