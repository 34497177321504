import sharedModule from '../../shared/pt-BR';

const welfareLanding = {
  ...sharedModule.LANDING,
  HOME: {
    TOTAL_PRICE_TEXT: 'Total',
    TOTAL_PRICE: '{{priceValue}}/mês',
    SUBMIT_BUTTON_TEXT: 'contrate online agora',
  },
  MAIN_ASSISTANCE_CARD: {
    ACCORDION_EXPANDED_TEXT: 'recolher detalhes',
    ACCORDION_CLOSED_TEXT: 'expandir detalhes',
    DESK_FOOTER_DESCRIPTION: 'já incluso por apenas:',
    FOOTER_DESCRIPTION: 'pacote principal já vem incluso por apenas:',
    PRICE_TEXT: '{{priceValue}}/mês',
    GENERAL_CONDITIONS_TEXT: 'ver condições gerais',
    MAIN_ASSISTANCE: {
      TITLE: 'Pacote principal',
      DESCRIPTION:
        'juntamos duas assistências essenciais que utilizamos em nossas vidas para sua comodidade e praticidade.',
    },
  },
  ADDITIONAL_ASSISTANCE_SUBHEADER: 'adicione mais assistências',
  ADDITIONAL_ASSISTANCE_CARD: {
    ACCORDION_EXPANDED_TEXT: 'recolher detalhes',
    ACCORDION_CLOSED_TEXT: 'expandir detalhes',
    PRICE_TEXT: '{{priceValue}} /mês',
    GENERAL_CONDITIONS_TEXT: 'ver condições gerais',
  },
  BENEFITS_TITLE: 'conheça as **vantagens de contratar uma assistência para sua família**',
  BENEFITS_ICON_DESCRIPTION: [
    {
      TITLE: 'Abrangência',
      DESCRIPTION: 'ampla rede com presença nacional, 24h, 365 dias por ano',
      ICON: 'icon-itaufonts_todos_pelo_cliente',
    },
    {
      TITLE: 'Simplicidade',
      DESCRIPTION: 'Com apenas um telefonema você aciona qualquer uma das suas assistências',
      ICON: 'icon-itaufonts_telefone',
    },
    {
      TITLE: 'Acessível',
      DESCRIPTION: 'Garantia da sua saúde por um preço acessível',
      ICON: 'icon-itaufonts_sifrao',
    },
    {
      TITLE: 'Proteção completa',
      DESCRIPTION: 'Opções para mamães, residencial e até escola 24h',
      ICON: 'icon-itaufonts_sorriso',
    },
    {
      TITLE: '100% digital',
      DESCRIPTION: 'Contrate a assistência de forma rápida e online',
      ICON: 'icon-itaufonts_computador_internet',
    },
  ],
  PARTNER_TITLE: '**conheça nosso parceiro**',
  PARTNER_DESCRIPTION:
    'a **Realiza** é uma marca da empresa Ikê que atende diversas áreas como saúde, educação, casa e funeral. Lembramos que não somos um seguro e sim um serviço de assistências. Tem presença nacional, 24h por dia, nos 365 dias do ano.',
  PARTNER_LOGO: 'logo Realiza',
  HOLDER_DATA_LOADED: 'Página de dados do titular carregada',
  LGPD_MESSAGE:
    'Os dados inseridos por você poderão ser compartilhados com empresas parceiras para cotação e contratação dos produtos escolhidos e poderão ser tratados de acordo com a nossa Política de Privacidade disponível em nossos sites e aplicativos.',
  SUMMARY: {
    TITLE: 'Assistência Realiza Bem-Estar',
    ...sharedModule.LANDING.SUMMARY,
  },
  PAYMENT: {
    TITLE: 'dados do **cartão de crédito**',
    SUBTITLE: 'chegamos no último passo para você contratar seu pacote de assistências :)',
  },
  RECEIPT: {
    ...sharedModule.LANDING.RECEIPT,
    CSAT_PRODUCT_NAME: 'Assistência Bem-Estar',
  },
};

export default welfareLanding;
