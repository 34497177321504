import { createSelector } from 'reselect';
import { PAYMENT_METHODS } from 'utils/constants';
import { transformCoverages } from 'utils/transformCoverages';
import { allCoveragesSelector } from 'store/auto/store/sagas/selectors/coverages';

export const autoSelectors = state => state.automovel;

// automovel.cotacao
export const automobileQuotationSelector = createSelector(autoSelectors, item => item?.cotacao);

// automovel.contratacao
export const automobileHiringSelector = createSelector(autoSelectors, item => item.contratacao);

// automovel.contratacao.requisicao
export const automobileHiringRequestSelector = createSelector(automobileHiringSelector, item => item.requisicao);

// automovel.contratacao.resposta
export const automobileHiringResponseSelector = createSelector(automobileHiringSelector, item => item.resposta);

// automovel.cotacao.requisicao
export const automobileRequestSelector = createSelector(automobileQuotationSelector, item => item.requisicao);

// automovel.cotacao.resposta
export const automobileResponseSelector = createSelector(automobileQuotationSelector, item => item.resposta);

// automovel.contratacao.resposta.numero_contratacao
export const automobileContractNumberSelector = createSelector(
  automobileHiringResponseSelector,
  item => item.numero_contratacao,
);

// automovel.cotacao.coverageSelected
export const automobileCoverageSelected = createSelector(automobileQuotationSelector, item => item.coverageSelected);

// automovel.cotacao.coverageSelectedTotalValue
export const automobileCoverageSelectedTotalValue = createSelector(
  automobileQuotationSelector,
  item => item.coverageSelectedTotalValue,
);

// automovel.questoes
export const automobileQuestionsSelector = createSelector(autoSelectors, item => item.questoes);

// automovel.questoes.resposta
export const automobileQuotationResponseSelector = createSelector(automobileQuotationSelector, item => item.resposta);

// automovel.questoes.resposta[0].data
export const automobileQuotationResponseDataSelector = createSelector(
  automobileQuotationResponseSelector,
  item => item[0].data,
);

export const automobileQuotationResponseSelectedSimulationSelector = createSelector(autoSelectors, item => {
  const reduceQuotationResponse = item.cotacao.resposta.reduce((acc, quotationResponse) => {
    const currentFranchiseQuotations = quotationResponse.data;
    acc.push(...currentFranchiseQuotations);
    return acc;
  }, []);
  return reduceQuotationResponse.find(
    simulation => simulation.id_simulacao == item.contratacao.requisicao.id_simulacao,
  );
});

export const automobileQuotationResponseSelectedSimulationPaymentOptionsListSelector = createSelector(
  automobileQuotationResponseSelectedSimulationSelector,
  item => item?.opcoes_pagamento,
);

export const automobileQuotationResponseSelectedSimulationPaymentOptionByTypeAndInstallmentNumberSelector = createSelector(
  automobileQuotationResponseSelectedSimulationPaymentOptionsListSelector,
  item => (paymentMethod, installmentNumber) =>
    item?.find(option => option.metodo === paymentMethod && option.parcelas === installmentNumber),
);

export const automobileSimulationResponsePaymentMethodSelector = createSelector(
  automobileQuotationResponseSelectedSimulationSelector,
  item => item?.metodos_pagamento,
);

export const automobilePaymentCardBadgesByTypeSelector = createSelector(
  automobileSimulationResponsePaymentMethodSelector,
  item => item?.find(i => i.metodo === PAYMENT_METHODS.creditCard)?.cartoes?.map(i => i.tipo),
);

export const automobilePaymentBanksOptions = createSelector(automobileSimulationResponsePaymentMethodSelector, item =>
  item
    ?.find(i => i.metodo === PAYMENT_METHODS.directDebit)
    ?.bancos?.map(i => ({
      value: i.codigo,
      label: i.nome,
    })),
);

export const automobileQuotationResponseSelectedSimulationTaxSelector = createSelector(
  automobileQuotationResponseSelectedSimulationSelector,
  item => item?.corretora?.taxa_corretagem,
);

// profile step
export const automobileQuotationConductorSelector = createSelector(
  automobileRequestSelector,
  item => item.condutores[0],
);

export const automobileQuotationProponentSelector = createSelector(automobileRequestSelector, item => item.proponente);

export const automobileQuotationRenewingDataSelector = createSelector(
  automobileRequestSelector,
  item => item.dados_renovacao,
);
export const automobileQuotationQuestionsSelector = createSelector(automobileRequestSelector, item => item.questoes);

// vehicle step
export const automobileItemRiskSelector = createSelector(automobileRequestSelector, item => item.item_risco);

export const automobileRenovationDataSelector = createSelector(automobileRequestSelector, item => item.dados_renovacao);

export const automobileVehicleSelector = createSelector(automobileItemRiskSelector, item => item.veiculo);

export const automobileSimulationSelector = createSelector(automobileResponseSelector, item => item);

export const automobileSelectedSimulationIDSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.id_simulacao,
);

// quotation step
export const automobileQuotationLoadingSelector = createSelector(automobileQuotationSelector, item => item.carregando);

export const automobileQuotationPristineSelector = createSelector(automobileQuotationSelector, item => item.pristino);

export const automobileQuotationResponseAvailableSelector = createSelector(
  automobileQuotationSelector,
  item => item.resposta?.length > 0,
);

// aditional data
export const automobileMainDriver = createSelector(automobileRequestSelector, item =>
  item.condutores.find(driver => driver[0].principal),
);

export const automobileRenewingData = createSelector(automobileRequestSelector, item => item.dados_renovacao);

export const automobileProductSelector = createSelector(automobileRequestSelector, item => item.produtos[0]);

export const automobileHiringProponentSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.proponente,
);

export const automobileHiringMainDriverSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.condutor,
);

export const automobileHiringVehicleSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.item_risco.veiculo,
);

export const automobilePoliticalSelector = createSelector(
  automobileHiringProponentSelector,
  item => item.politicamente_exposto,
);

export const automobileRenewingDataSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.dados_renovacao,
);

export const automobileCreditPaymentDataSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.dados_opcao_pagamento?.cartao_credito,
);

export const automobileDebitPaymentDataSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.dados_opcao_pagamento?.conta_bancaria,
);

export const automobileAddressPaymentDataSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.dados_opcao_pagamento?.endereco,
);

export const automobileIDPaymentDataSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.dados_opcao_pagamento?.id_opcao_pagamento,
);

export const automobileHiringResponsePaymentOptionsSelector = createSelector(
  automobileHiringRequestSelector,
  item => item.dados_opcao_pagamento,
);

export const automobileHiringResponsePaymentSelectedSelector = paymentId =>
  createSelector(automobileHiringResponsePaymentOptionsSelector, paymentOptions =>
    paymentOptions.find(po => po.id_opcao_pagamento === paymentId),
  );

export const automobileAllSimulationsSelector = createSelector(automobileQuotationResponseSelector, item =>
  item.reduce((current, option) => [...current, ...option.data], []),
);

export const automobileSelectedSimulationSelector = createSelector(
  automobileAllSimulationsSelector,
  automobileSelectedSimulationIDSelector,
  (allInsurances, selectedSimulationId) =>
    allInsurances?.length && allInsurances.find(simulation => simulation.id_simulacao === selectedSimulationId),
);

export const automobileSelectedSimulationFirstProductSelector = createSelector(
  automobileSelectedSimulationSelector,
  item => item?.produtos?.[0],
);

export const automobileSelectedInsuranceIdSelector = createSelector(
  automobileSelectedSimulationSelector,
  item => item?.id_simulacao,
);

export const automobileAllCoveragesAndAssistanceSelector = createSelector(
  automobileSelectedSimulationFirstProductSelector,
  allCoveragesSelector,
  (selectedSimulation, allDetailCoverages) => {
    const assistance = selectedSimulation?.assistencias?.[0];
    const coverages = selectedSimulation && transformCoverages(selectedSimulation.coberturas, allDetailCoverages);
    const transformAssistance = assistance && {
      name: assistance.nome,
      value: assistance.valor_assistencia,
    };
    return coverages && coverages.concat(transformAssistance);
  },
);

export const automobileSelectedSimulationProductNameSelector = createSelector(
  automobileSelectedSimulationFirstProductSelector,
  item => item?.nome_oferta,
);

export const automobileSelectedSimulationFirstProductAssistanceSelector = createSelector(
  automobileSelectedSimulationFirstProductSelector,
  item => item?.assistencias,
);

export const automobileSelectedSimulationFranchiseSelector = createSelector(
  automobileSelectedSimulationSelector,
  item => item?.franquia,
);

export const automobileSelectedSimulationPartnerQuotationIDSelector = createSelector(
  automobileSelectedSimulationSelector,
  item => item?.id_cotacao_parceiro,
);

export const automobileSelectedSimulationDefaultPaymentOptionSelector = createSelector(
  automobileSelectedSimulationSelector,
  item => item?.opcoes_pagamento?.[5],
);

export const automobileSelectedSimulationDefaultPaymentOptionInstallmentsSelector = createSelector(
  automobileSelectedSimulationDefaultPaymentOptionSelector,
  item => item?.parcelas,
);

export const automobileSelectedSimulationDefaultPaymentOptionInstallmentsValueSelector = createSelector(
  automobileSelectedSimulationDefaultPaymentOptionSelector,
  item => item?.valor_parcela,
);

export const automobileSelectedSimulationDefaultPaymentOptionTotalValueSelector = createSelector(
  automobileSelectedSimulationDefaultPaymentOptionSelector,
  item => item?.valor_total,
);
