import { createResource } from '@zup-next/redux-resource';
import { put } from 'redux-saga/effects';

import {
  getFaq,
  getPartners,
  getProducts,
  getHome,
  getOffersByLanding,
  getStates,
  getCities,
  getCep,
  setPayment,
  resid,
  getE2EAllowedChannels,
} from '../api';

const faq = createResource('FAQ', {
  load: getFaq,
});

const partner = createResource('PARTNER', {
  load: getPartners,
});

const product = createResource('PRODUCT', {
  load: getProducts,
});

const home = createResource('HOME', {
  load: getHome,
});

const landing = createResource('LANDING', {
  load: getOffersByLanding,
});

const states = createResource('STATES', {
  load: getStates,
});

const cities = createResource('CITIES', {
  load: getCities,
});

const payments = createResource('PAYMENTS', {
  load: setPayment,
});

const residCoverages = createResource('RESID_COVERAGES', {
  load: resid.getCoverages,
});

const residQuotation = createResource('RESID_QUOTATION', {
  load: resid.residQuotation,
});

const residPersonalFields = createResource('RESID_PERSONAL_FIELDS', {
  load: resid.getPersonalFields,
});

const allowedChannels = createResource('ALLOWED_CHANNELS', {
  load: getE2EAllowedChannels,
});

function* onCepSuccess({ requestData, responseData }) {
  yield put({
    type: requestData.callbackAction,
    data: responseData,
  });
}

const cep = createResource(
  'CEP',
  {
    load: action => getCep(action.cep),
  },
  { load: action => onCepSuccess(action) },
);

export default {
  faq,
  partner,
  product,
  home,
  landing,
  states,
  cities,
  cep,
  payments,
  residCoverages,
  residQuotation,
  residPersonalFields,
  allowedChannels,
};
