/* eslint-disable no-prototype-builtins */
import React from 'react';
import './_Snackbar.scss';
import PropTypes from 'prop-types';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import SnackbarMaterial from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Parser from 'html-react-parser';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const Snackbar = ({ action, message, subtitle, open, type = 'default', onClose, onEntered }) => (
  <SnackbarMaterial
    className="Snackbar"
    anchorOrigin={anchorOrigin}
    open={open}
    onClose={onClose}
    onEntered={onEntered}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    ClickAwayListenerProps={{
      onClickAway: () => {},
    }}
  >
    <SnackbarContent
      className={`SnackbarContent ${type}`}
      open={open}
      onClose={onClose}
      message={
        <div>
          <span className="message-title" id="message-id">
            {message}
          </span>
          {subtitle && <p>{Parser(subtitle)}</p>}
        </div>
      }
      action={[
        action && action !== null && typeof action === 'object' && action.hasOwnProperty('message') && (
          <Button key="action" size="small" onClick={() => action.click()}>
            {action.message}
          </Button>
        ),
        <IconButton aria-hidden color="secondary" aria-label="Fechar" onClick={onClose}>
          <CloseIcon />
        </IconButton>,
      ]}
    />
  </SnackbarMaterial>
);

Snackbar.propTypes = {
  action: PropTypes.func,
  onClose: PropTypes.func,
  onEntered: PropTypes.func,
  message: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  open: PropTypes.bool,
};

export default Snackbar;
