import axios from 'axios';

import config, { performance } from '../../config';

const { SEGUROS_GATEWAY_APP_KEY, GATEWAY_PATH } = config;

export const getToken = async () => {
  try {
    const trace = performance.trace('getToken - Autentication');
    trace.start();
    const response = await axios.get(`${GATEWAY_PATH}/token?gw-app-key=${SEGUROS_GATEWAY_APP_KEY}`, {
      withCredentials: true,
    });
    trace.stop();

    const accessToken = response.data.access_token;
    sessionStorage.setItem('token', accessToken);
    return accessToken;
  } catch (e) {
    return null;
  }
};
