/* eslint-disable camelcase */
import axios from 'axios';

import config, { performance } from 'config';

const { E2E_AUTH_PATH, ITAU_GATEWAY_HOST } = config;

export const resendEmail = async payload => {
  const trace = performance.trace('resendEmail');
  trace.start();
  const response = await axios.post(`${E2E_AUTH_PATH}/reenvio/email`, payload, {
    baseURL: ITAU_GATEWAY_HOST,
  });

  trace.stop();
  return response;
};

export const validateHash = async ({ hash, captcha }) => {
  const trace = performance.trace('validateHash');
  trace.start();
  const response = await axios.post(`${E2E_AUTH_PATH}/autenticacao/sms`, captcha, {
    headers: {
      hash,
    },
    baseURL: ITAU_GATEWAY_HOST,
  });

  trace.stop();
  return response;
};
