export const Types = {
  SHOW_MESSAGE: 'message/SHOW_MESSAGE',
  CLOSE_MESSAGE: 'message/CLOSE_MESSAGE',
};

const initialState = {
  action: null,
  message: null,
  subtitle: null,
  refFocus: null,
  onCloseCallback: null,
  open: false,
  type: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SHOW_MESSAGE:
      return {
        ...action.data,
        open: true,
      };

    case Types.CLOSE_MESSAGE:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
}

export function showMessage(data) {
  return {
    type: Types.SHOW_MESSAGE,
    data,
  };
}

export function closeMessage() {
  return {
    type: Types.CLOSE_MESSAGE,
  };
}
