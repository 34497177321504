import headerReducer from './header';
import httpErrorsReducer from './httpErrors';
import messageReducer from './message';
import modalReducer from './modal';
import odontoReducer from './odonto';
import residReducer from './resid';
import leadReducer from './lead';
import quotationReducer from './quotation';
import residQuotationReducer from './residQuotation';
import wizardStepReducer from './wizardStep';
import authScopesReducer from './authScopes';
import beneficiaryFormReducer from './beneficiaryForm';
import cancellationDataReducer from './cancellation';
import residencialDataReducer from './residencial';
import authDataReducer from './auth';
import recoveryE2EReducer from './recoveryE2E';
import eligibilityReducer from './eligibility';
import welfareReducer from './welfare';
import paymentReducer from './payment';
import petReducer from './pet';
import productsReducer from './products';
import asyncQuotationReducer from './asyncQuotation';
import cartReducer from './cart';

export default {
  header: headerReducer,
  httpErrors: httpErrorsReducer,
  message: messageReducer,
  modal: modalReducer,
  odonto: odontoReducer,
  lead: leadReducer,
  quotation: quotationReducer,
  wizardStep: wizardStepReducer,
  beneficiaryForm: beneficiaryFormReducer,
  authScopes: authScopesReducer,
  resid: residReducer,
  cancellation: cancellationDataReducer,
  residQuotation: residQuotationReducer,
  residencial: residencialDataReducer,
  auth: authDataReducer,
  recoveryE2E: recoveryE2EReducer,
  eligibility: eligibilityReducer,
  welfare: welfareReducer,
  payment: paymentReducer,
  pet: petReducer,
  products: productsReducer,
  asyncQuotation: asyncQuotationReducer,
  cart: cartReducer,
};
