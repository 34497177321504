import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_ENVIRONMENTS,
} from 'trackingV2/constants';

export const ANALYTICS_PET_HIRING_OFFER_FLOW = {
  T1: {
    nome: 'LS:NL:NCC:Pet:Contratacao:Sucesso',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Contratacao:Sucesso:Likert:{0}',
    noInteraction: false,
  },
  A2: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Contratacao:Sucesso:Oferta:{0}:{1}',
    noInteraction: false,
  },
  A3: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.PET,
    label: 'BTN:Pet:Contratacao:Sucesso:Oferta:FazerUmaCotacao:{0}',
    noInteraction: false,
  },
};
