import axios from 'axios';
import { performance } from '../../config';

export const getPersonalFields = async () => {
  const trace = performance.trace('getPersonalFields');
  trace.start();

  const response = await axios.get(`/v1/seguros/listas-suspensas`);
  trace.stop();
  return response.data;
};
