import { call, put, select } from 'redux-saga/effects';

import hiringAssistance from '../../../api/E2E/Realiza/hiringAssistance';

import { authScopesDataByScopeSelector } from '../selectors/authScopes';
import { setError, setErrorSagaAction } from '../../ducks/httpErrors';
import { paymentOptionsSelector } from '../selectors/paymentSelector';

export default function genericHiringSubmitSagaAction(creator, selector, offerType, scope) {
  return function* hiringSubmitSagaAction(action) {
    const { data } = action;
    const { enablePetLogin } = data;
    try {
      const idSimulacao = yield select(selector.IdSimulationSelector);
      const cpf = yield select(selector.QuotationProponentCpfSelector);
      const dados_opcao_pagamento = yield select(paymentOptionsSelector);

      const request = yield select(selector.HiringRequestSelector);
      if (request?.dados_opcao_pagamento?.cartao_credito?.pagador) {
        dados_opcao_pagamento.cartao_credito.pagador.cpf = cpf;
      }
      const hiringRequest = {
        ...request,
        captcha: data.captcha,
        id_simulacao: idSimulacao,
        dados_opcao_pagamento,
      };

      let headers = {};
      if (enablePetLogin) {
        const { accessToken, identifier } = JSON.parse(sessionStorage.getItem('auth:safe'));
        headers = {
          'token-sts': accessToken,
          identifier,
        };
      } else {
        const { hash } = yield select(authScopesDataByScopeSelector(scope));
        headers = {
          hash,
        };
      }

      yield put(creator.loadHiring());

      const response = yield call(hiringAssistance, offerType, hiringRequest, headers);
      yield put(creator.createHiringResponse(response));
    } catch (err) {
      console.error(err);
      yield put(creator.loadHiringError(err));
      yield put(setErrorSagaAction(action));
      yield put(setError());
    }
  };
}
