import { getPageContent } from '../api/CMS/getHomeContent';
import { ApiResponseInterface } from './interfaces/ApiResponse.interface';
import { ENVIRONMENTS } from './constants/environments';
import { PAGE_TYPE } from './constants/pageType';
import { pagesConfig } from './PagesConfig/PagesConfig';
import { PageConfigInterface } from './interfaces/PageConfig.interface';

export const getMultipleContent = async (activePageConfig: PageConfigInterface) => {
  const { REACT_APP_ENVIRONMENT }: any = process.env;

  try {
    const contentStackEnvironment = ENVIRONMENTS[REACT_APP_ENVIRONMENT];
    const response: ApiResponseInterface = await getPageContent(activePageConfig.CONTENT_TYPE, contentStackEnvironment);

    return response.entries;
  } catch (err) {
    return [];
  }
};

export const getSingleContent = async (pageConfig: PageConfigInterface) => {
  const response = await getMultipleContent(pageConfig);

  if (!response.length) return;
  return response[0];
};

export const getContent = async (pathname: string, remoteConfig: any) => {
  const activePageConfig = pagesConfig(pathname, remoteConfig);

  if (!activePageConfig || !activePageConfig.CMS_ENABLED.enabled) return;

  if (activePageConfig.PAGE_TYPE === PAGE_TYPE.MULTIPLE) {
    return getMultipleContent(activePageConfig);
  }

  return getSingleContent(activePageConfig);
};
