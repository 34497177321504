import React from 'react';
import { useDispatch } from 'react-redux';
import { setLastDivRef, showModal, closeModal } from 'store/ducks/modal';
import { useAllowedChannelsConsumer } from 'providers/AllowedChannelsProvider';
import logo from '../../../assets/images/logo-seguros-itau.png';
import { useStyles } from './styles.js';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import MobileHeaderModalContent from './components/MobileHeaderModalContent/MobileHeaderModalContent';

export default function MobileHeader() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const allowedChannels = useAllowedChannelsConsumer();
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();
  const handleClickLogo = () => {
    dispatchAnalyticsEvent('HOME', 'A1');
  };
  const closeModalNavMenu = () => {
    dispatch(closeModal(true));
  };

  const openModalNavMenu = e => {
    dispatch(setLastDivRef(e.target.id));
    dispatch(
      showModal(
        true,
        <MobileHeaderModalContent closeButtonAction={closeModalNavMenu} allowedChannels={allowedChannels} />,
        true,
        false,
        styles.modalNavMenu,
      ),
    );
  };

  return (
    <div className={styles.root}>
      <div>
        <div className={styles.rowContainer}>
          <button
            type="button"
            id="navbar-menuButton"
            aria-label="Menu da loja de seguros"
            aria-live="polite"
            onClick={e => openModalNavMenu(e)}
          >
            <i className={`${'icon-itaufonts_menu_hamburguer'} ${styles.hamburgerIcon}`} />
          </button>
          <a href="/" onClick={handleClickLogo}>
            <img className={styles.image} alt="Logo Itaú" src={logo}/>
          </a>
        </div>
      </div>
    </div>
  );
}
