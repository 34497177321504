export default {
  CSAT_RESEARCH: {
    SATISFACTION_LEVELS: {
      BAD: {
        LABEL: 'ruim',
        COMMENT_LABEL: 'Que pena... Quer deixar um comentário?',
      },
      DIDNT_LIKE: {
        LABEL: 'não gostei',
        COMMENT_LABEL: 'Que pena... Quer deixar um comentário?',
      },
      BEATS_ME: {
        LABEL: 'sei lá',
        COMMENT_LABEL: 'Quer deixar um comentário?',
      },
      LIKED: {
        LABEL: 'gostei',
        COMMENT_LABEL: 'Que bom! Quer deixar um comentário?',
      },
      LOVED: {
        LABEL: 'adorei',
        COMMENT_LABEL: 'Fantástico! Quer deixar um comentário?',
      },
    },
    HIRING_TITLE: 'O que achou da sua experiência de contratação do seu {{productName}}?',
    GENERIC_TITLE: 'O que você achou desta experiência?',
    SEND_BUTTON: 'enviar',
    COMMENT_REMAINING_CHARACTERS: '{{charactersNumber}} caracteres restantes',
    SUBMIT: {
      TITLE: {
        PREFIX: 'obrigado por dedicar ',
        COLORED: 'o seu tempo',
        SUFIX: '!',
      },
      MESSAGE: 'vamos analisar o seu comentário para deixar a Corretora de Seguros do Itaú ainda melhor para você',
    },
  },
};
