import React from 'react';
import { PropTypes } from 'prop-types';

import { Field } from 'formik';
import CheckboxGroup from '../../../../components/CheckboxGroup/CheckboxGroup';

function FormikCheckboxListField({ options, label, name, classNameLabel, labelLayout, required }) {
  return (
    <Field>
      {({ field, form }) => (
        <CheckboxGroup
          classNameLabel={classNameLabel}
          options={options}
          checkedList={form.values[name]}
          name={name}
          label={label}
          onChange={e => field.onChange(e)}
          onBlur={e => field.onBlur(e)}
          labelLayout={labelLayout}
          error={form?.touched[name] && form.errors[name]}
          required={required}
        />
      )}
    </Field>
  );
}

FormikCheckboxListField.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  classNameLabel: PropTypes.string,
};

export default FormikCheckboxListField;
