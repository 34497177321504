import React from 'react';
import { FormGroup } from '@material-ui/core';
import { PropTypes } from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckboxAuto from '../CheckboxAuto/CheckboxAuto';
import { StyledFormLabel } from './checkboxGroup.styles';

function CheckboxGroup({
  options,
  label,
  onChange,
  onBlur,
  name,
  classNameLabel,
  checkedList,
  labelLayout,
  error,
  required,
}) {
  const renderCheckbox = options.map((option, index) => (
    <CheckboxAuto
      key={`${option.value}-${index}`}
      label={option.label}
      name={name}
      value={option.value}
      onChange={onChange}
      onBlur={onBlur}
      checked={checkedList?.includes(option.value.toString())}
      hint={option.hint}
      ariaLabel="teste"
      className={classNameLabel}
      labelLayout={labelLayout}
    />
  ));
  return (
    <FormControl
      component="fieldset"
      data-testid="CheckboxGroup"
      className={`${classNameLabel} fieldset`}
      error={Boolean(error)}
      required={required}
    >
      <StyledFormLabel component="legend">{label}</StyledFormLabel>
      <FormGroup className={classNameLabel}>{renderCheckbox}</FormGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

CheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  classNameLabel: PropTypes.string,
};

export default CheckboxGroup;
