import { call, put, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { setError, setErrorSagaAction } from 'store/ducks/httpErrors';
import {
  setHouseholdInspectionRequest,
  setFlowApp,
  setFlowDispensed,
  setFlowCaps,
  setFlowSuccess,
} from 'store/auto/store/ducks/inspection';
import { postAutoInspection } from 'api/E2E/Auto/postAutoInspection';
import { initialDataSelector } from 'store/auto/store/sagas/selectors/inspection';
import { startInspection, Types } from 'store/auto/store/ducks/autoFlow';

const STATUS_SUCCESS = {
  OK: 200,
  CREATED: 201,
  DISPENSED: 204,
};
const REDIRECT_TO_SUCCESS_HOUSEHOLD = 'DOMICILIAR';
const ERROR = 422;
const REDIRECT = {
  APP_INSTRUCTION: '2',
  CAPS: '1',
};

function* setControlInspectionFlow(response) {
  if (!isEmpty(response)) {
    if (response.status === STATUS_SUCCESS.OK || response.status === STATUS_SUCCESS.CREATED) {
      if (response.data.tipo_agendamento.toUpperCase() === REDIRECT_TO_SUCCESS_HOUSEHOLD) {
        yield put(setFlowSuccess({ caps: false, household: true }));
      } else {
        yield put(setFlowSuccess({ caps: true, household: false }));
      }
    }

    if (response.status === ERROR) {
      if (response.data.codigo === REDIRECT.CAPS) {
        yield put(setFlowCaps(true));
      }
      if (response.data.codigo === REDIRECT.APP_INSTRUCTION) {
        yield put(setFlowApp(true));
      }
    }

    if (response.status === STATUS_SUCCESS.DISPENSED) {
      yield put(setFlowDispensed(true));
    }
  }
}

export default function* createHouseholdInpectionPostAction(action) {
  const { payload: payloadIn, captcha, hash } = action.data;
  const payload = {
    ...payloadIn,
    captcha,
  };
  yield put(startInspection());
  try {
    const { id_parceiro } = yield select(initialDataSelector);
    yield put(setHouseholdInspectionRequest(payloadIn));
    const args = {
      id_parceiro,
      payload,
      hash,
    };
    const response = yield call(postAutoInspection, args);
    yield setControlInspectionFlow(response);
    yield put({ type: Types.SUCCESS_INSPECTION });
  } catch (err) {
    const { response } = err;
    if (response) {
      if (response.status === 422) {
        yield setControlInspectionFlow(response);
        yield put({ type: Types.SUCCESS_INSPECTION });
      } else {
        yield put(setError());
        yield put({ type: Types.ERROR_INSPECTION });
        yield put(setErrorSagaAction(action));
      }
    }
  }
}
