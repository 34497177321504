import config from 'config';
import { CustomHeadTags } from 'components/CustomHeadTags';
import ROUTES from 'routes/routes';
import OdontoBanner from 'assets/images/home-03.png';

export interface HeadTagPagesInterface {
  [page: string]: {
    links?: Array<object>;
    metas?: Array<object>;
    scripts?: Array<object>;
    title?: string;
    subjects?: string;
    url?: any;
  };
}

const { URL_BUCKET_BASE }: any = config;
const URL_ITAU_BASE = 'https://www.itau.com.br';
const CANONICAL_URL_BASE = 'https://www.lojadesegurositau.com.br';

const PAGES_CONTENT = {
  HOME: {
    TITLE: 'Cotação de Seguro das Melhores Empresas | Itaú Seguros',
    DESCRIPTION:
      'O Itaú Seguros possui seguro de vida, seguro auto, seguro residencial e plano odontológico selecionado das melhores empresas do mercado pra você. Cotação online.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.HOME}`,
    BANNER_IMAGE: `${URL_ITAU_BASE}/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/IMG/seg_ban_def_loj_img_760X552.png`,
  },
  ODONTO: {
    TITLE: 'Plano Odontológico | Melhores Preços | Itaú Seguros',
    DESCRIPTION:
      'Conheça os planos odontológicos da MetLife com cobertura de mais de 200 procedimentos e mais de 20 mil dentistas credenciados para cuidar da saúde bucal de sua família.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.LANDING_ODONTO}/`,
    BANNER_IMAGE: `${URL_ITAU_BASE}/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/IMG/seg_ban_def_odo_img_1516X1100.png`,
  },
  RESID: {
    TITLE: 'Seguro Residencial | Sua Casa Protegida | Itaú Seguros',
    DESCRIPTION:
      'Garanta a proteção da sua casa e conte ainda com uma variedade de serviços como chaveiro, encanador e eletricista. Veja como funciona os melhores planos e preços.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.LANDING_RESID}/`,
    BANNER_IMAGE: `${URL_BUCKET_BASE}/residencial/otimizadas/img_banner.png`,
  },
  AUTO: {
    TITLE: 'Cotação de Seguro Auto | Encontre as Melhores Proteções no Itaú',
    DESCRIPTION:
      'Com a Loja de Seguros Itaú, seu carro está muito mais protegido através das melhores coberturas e assistências do Seguro Auto Porto e Azul.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.LANDING_AUTO}/`,
    BANNER_IMAGE: `${URL_ITAU_BASE}/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/IMG/seg_con_aut_ban_img_758x550.jpg`,
  },
  AUTO_HIRING: {
    TITLE: 'Cotação de Seguro Auto | Encontre as Melhores Proteções no Itaú',
    DESCRIPTION:
      'Com a Loja de Seguros Itaú, seu carro está muito mais protegido através das melhores coberturas e assistências do Seguro Auto Porto e Azul.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.AUTO_V2_HIRING}/contratacao/`,
    BANNER_IMAGE: `${URL_ITAU_BASE}/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/IMG/seg_con_aut_ban_img_758x550.jpg`,
  },
  AUTO_SUCCESS: {
    TITLE: ' Cotação de Seguro Auto | Encontre as Melhores Proteções no Itaú',
    DESCRIPTION:
      'Com a Loja de Seguros Itaú, seu carro está muito mais protegido através das melhores coberturas e assistências do Seguro Auto Porto e Azul.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.AUTO_V2_SUCCESS}/`,
    BANNER_IMAGE: `${URL_ITAU_BASE}/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/IMG/seg_con_aut_ban_img_758x550.jpg`,
  },
  WELFARE: {
    TITLE: 'Assistência Bem-estar: Saúde e Qualidade de Vida | Itaú Seguros',
    DESCRIPTION:
      'A assistência bem-estar oferece saúde e qualidade de vida de toda a família. Check up médico, residencial, auxílio mamãe & bebê, escola 24h e serviço funeral.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.LANDING_WELFARE}/`,
    BANNER_IMAGE: `${URL_BUCKET_BASE}/bem_estar/otimizadas/ilustracoes/banner_home.png`,
  },
  PET: {
    TITLE: 'Assistência Pet Saúde a partir de R$14,90 | Itaú Seguros',
    DESCRIPTION:
      'Com assistência pet saúde você garante suporte veterinário, consulta emergencial, vacinas, hospedagem, castração e funeral a partir de apenas R$14,90.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.LANDING_PET}/`,
    BANNER_IMAGE: `${URL_BUCKET_BASE}/pet/otimizadas/ilustracoes/banner_home.png`,
  },
  CANCEL: {
    TITLE: 'Como Cancelar Seguro Online | Itaú Seguros',
    DESCRIPTION:
      'Você pode cancelar seu seguro de vida, auto ou residencial a qualquer momento através de nosso canal de atendimento online. Veja como funciona.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.CANCEL}/`,
  },
  FAQ: {
    URL: `${CANONICAL_URL_BASE}${ROUTES.FAQ_PAGE}/`,
  },
  ADVISORY: {
    TITLE: 'Melhor Seguro e Assistência para Seu Perfil | Itaú Seguros',
    DESCRIPTION:
      'Quer saber qual o melhor seguro e assistência para você e sua família? Com assessoria virtual do Itaú Seguros você encontra a opção ideal para seu perfil.',
    URL: `${CANONICAL_URL_BASE}${ROUTES.ADVISORY_RESEARCH}/`,
  }
};

const HEAD_TAG_PAGES: HeadTagPagesInterface = {
  [ROUTES.HOME]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.HOME.URL,
      },
    ],
    title: PAGES_CONTENT.HOME.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.HOME.DESCRIPTION,
      },
      {
        property: 'og:title',
        content: PAGES_CONTENT.HOME.TITLE,
      },
      {
        property: 'og:url',
        content: PAGES_CONTENT.HOME.URL,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:description',
        content: PAGES_CONTENT.HOME.DESCRIPTION,
      },
      {
        property: 'og:image',
        content: PAGES_CONTENT.HOME.BANNER_IMAGE,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:title',
        content: PAGES_CONTENT.HOME.TITLE,
      },
      {
        name: 'twitter:description',
        content: PAGES_CONTENT.HOME.DESCRIPTION,
      },
      {
        name: 'twitter:url',
        content: PAGES_CONTENT.HOME.URL,
      },
      {
        name: 'twitter:image',
        content: PAGES_CONTENT.HOME.BANNER_IMAGE,
      },
    ],
  },
  [ROUTES.LANDING_ODONTO]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.ODONTO.URL,
      },
      {
        rel: 'preload',
        as: 'image',
        href: OdontoBanner,
      },
    ],
    title: PAGES_CONTENT.ODONTO.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.ODONTO.DESCRIPTION,
      },
      {
        property: 'og:title',
        content: PAGES_CONTENT.ODONTO.TITLE,
      },
      {
        property: 'og:url',
        content: PAGES_CONTENT.ODONTO.URL,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:description',
        content: PAGES_CONTENT.ODONTO.DESCRIPTION,
      },
      {
        property: 'og:image',
        content: PAGES_CONTENT.ODONTO.BANNER_IMAGE,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:title',
        content: PAGES_CONTENT.ODONTO.TITLE,
      },
      {
        name: 'twitter:description',
        content: PAGES_CONTENT.ODONTO.DESCRIPTION,
      },
      {
        name: 'twitter:url',
        content: PAGES_CONTENT.ODONTO.URL,
      },
      {
        name: 'twitter:image',
        content: PAGES_CONTENT.ODONTO.BANNER_IMAGE,
      },
    ],
    scripts: [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'listItem',
              position: 1,
              name: 'Plano Odontológico',
              item: `${CANONICAL_URL_BASE}${ROUTES.LANDING_ODONTO}`,
            },
          ],
        },
      },
    ],
    subjects: 'Odonto',
  },
  [ROUTES.LANDING_RESID]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.RESID.URL,
      },
      {
        rel: 'preload',
        as: 'image',
        href: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/residencial/otimizadas/img_banner.png',
      },
    ],
    title: PAGES_CONTENT.RESID.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.RESID.DESCRIPTION,
      },
      {
        property: 'og:title',
        content: PAGES_CONTENT.RESID.TITLE,
      },
      {
        property: 'og:url',
        content: PAGES_CONTENT.RESID.URL,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:description',
        content: PAGES_CONTENT.RESID.DESCRIPTION,
      },
      {
        property: 'og:image',
        content: PAGES_CONTENT.RESID.BANNER_IMAGE,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:title',
        content: PAGES_CONTENT.RESID.TITLE,
      },
      {
        name: 'twitter:description',
        content: PAGES_CONTENT.RESID.DESCRIPTION,
      },
      {
        name: 'twitter:url',
        content: PAGES_CONTENT.RESID.URL,
      },
      {
        name: 'twitter:image',
        content: PAGES_CONTENT.RESID.BANNER_IMAGE,
      },
    ],
    scripts: [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'listItem',
              position: 1,
              name: 'Seguro Residencial',
              item: `${CANONICAL_URL_BASE}${ROUTES.LANDING_RESID}`,
            },
          ],
        },
      },
    ],
    subjects: 'Residencial',
  },
  [ROUTES.LANDING_AUTO]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.AUTO.URL,
      },
    ],
    title: PAGES_CONTENT.AUTO.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.AUTO.DESCRIPTION,
      },
      {
        property: 'og:title',
        content: PAGES_CONTENT.AUTO.TITLE,
      },
      {
        property: 'og:url',
        content: PAGES_CONTENT.AUTO.URL,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:description',
        content: PAGES_CONTENT.AUTO.DESCRIPTION,
      },
      {
        property: 'og:image',
        content: PAGES_CONTENT.AUTO.BANNER_IMAGE,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:title',
        content: PAGES_CONTENT.AUTO.TITLE,
      },
      {
        name: 'twitter:description',
        content: PAGES_CONTENT.AUTO.DESCRIPTION,
      },
      {
        name: 'twitter:url',
        content: PAGES_CONTENT.AUTO.URL,
      },
      {
        name: 'twitter:image',
        content: PAGES_CONTENT.AUTO.BANNER_IMAGE,
      },
    ],
    scripts: [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'listItem',
              position: 1,
              name: 'Seguro Auto',
              item: `${CANONICAL_URL_BASE}${ROUTES.LANDING_AUTO}`,
            },
          ],
        },
      },
    ],
    subjects: 'Auto',
  },
  [ROUTES.AUTO_V2_HIRING]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.AUTO_HIRING.URL,
      },
    ],
    title: PAGES_CONTENT.AUTO_HIRING.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.AUTO_HIRING.DESCRIPTION,
      },
      {
        property: 'og:title',
        content: PAGES_CONTENT.AUTO_HIRING.TITLE,
      },
      {
        property: 'og:url',
        content: PAGES_CONTENT.AUTO_HIRING.URL,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:description',
        content: PAGES_CONTENT.AUTO_HIRING.DESCRIPTION,
      },
      {
        property: 'og:image',
        content: PAGES_CONTENT.AUTO_HIRING.BANNER_IMAGE,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:title',
        content: PAGES_CONTENT.AUTO_HIRING.TITLE,
      },
      {
        name: 'twitter:description',
        content: PAGES_CONTENT.AUTO_HIRING.DESCRIPTION,
      },
      {
        name: 'twitter:url',
        content: PAGES_CONTENT.AUTO_HIRING.URL,
      },
      {
        name: 'twitter:image',
        content: PAGES_CONTENT.AUTO_HIRING.BANNER_IMAGE,
      },
    ],
    scripts: [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'listItem',
              position: 1,
              name: 'Seguro Auto',
              item: `${CANONICAL_URL_BASE}${ROUTES.LANDING_AUTO}`,
            },
            {
              '@type': 'listItem',
              position: 2,
              name: 'Contratação',
              item: `${CANONICAL_URL_BASE}${ROUTES.AUTO_V2_HIRING}`,
            },
          ],
        },
      },
    ],
    subjects: 'Auto',
  },
  [ROUTES.AUTO_V2_SUCCESS]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.AUTO_SUCCESS.URL,
      },
    ],
    title: PAGES_CONTENT.AUTO_SUCCESS.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.AUTO_SUCCESS.DESCRIPTION,
      },
      {
        property: 'og:title',
        content: PAGES_CONTENT.AUTO_SUCCESS.TITLE,
      },
      {
        property: 'og:url',
        content: PAGES_CONTENT.AUTO_SUCCESS.URL,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:description',
        content: PAGES_CONTENT.AUTO_SUCCESS.DESCRIPTION,
      },
      {
        property: 'og:image',
        content: PAGES_CONTENT.AUTO_SUCCESS.BANNER_IMAGE,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:title',
        content: PAGES_CONTENT.AUTO_SUCCESS.TITLE,
      },
      {
        name: 'twitter:description',
        content: PAGES_CONTENT.AUTO_SUCCESS.DESCRIPTION,
      },
      {
        name: 'twitter:url',
        content: PAGES_CONTENT.AUTO_SUCCESS.URL,
      },
      {
        name: 'twitter:image',
        content: PAGES_CONTENT.AUTO_SUCCESS.BANNER_IMAGE,
      },
    ],
    scripts: [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'listItem',
              position: 1,
              name: 'Seguro Auto',
              item: `${CANONICAL_URL_BASE}${ROUTES.LANDING_AUTO}`,
            },
            {
              '@type': 'listItem',
              position: 2,
              name: 'Contratação',
              item: `${CANONICAL_URL_BASE}${ROUTES.AUTO_V2_HIRING}`,
            },
            {
              '@type': 'listItem',
              position: 3,
              name: 'Tela de Sucesso',
              item: `${CANONICAL_URL_BASE}${ROUTES.AUTO_V2_SUCCESS}`,
            },
          ],
        },
      },
    ],
    subjects: 'Auto',
  },
  [ROUTES.LANDING_WELFARE]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.WELFARE.URL,
      },
    ],
    title: PAGES_CONTENT.WELFARE.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.WELFARE.DESCRIPTION,
      },
      {
        property: 'og:title',
        content: PAGES_CONTENT.WELFARE.TITLE,
      },
      {
        property: 'og:url',
        content: PAGES_CONTENT.WELFARE.URL,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:description',
        content: PAGES_CONTENT.WELFARE.DESCRIPTION,
      },
      {
        property: 'og:image',
        content: PAGES_CONTENT.WELFARE.BANNER_IMAGE,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:title',
        content: PAGES_CONTENT.WELFARE.TITLE,
      },
      {
        name: 'twitter:description',
        content: PAGES_CONTENT.WELFARE.DESCRIPTION,
      },
      {
        name: 'twitter:url',
        content: PAGES_CONTENT.WELFARE.URL,
      },
      {
        name: 'twitter:image',
        content: PAGES_CONTENT.WELFARE.BANNER_IMAGE,
      },
    ],
    scripts: [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'listItem',
              position: 1,
              name: 'Assistência Bem-estar',
              item: `${CANONICAL_URL_BASE}${ROUTES.LANDING_WELFARE}`,
            },
          ],
        },
      },
    ],
    subjects: 'BemEstar',
  },
  [ROUTES.LANDING_PET]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.PET.URL,
      },
    ],
    title: PAGES_CONTENT.PET.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.PET.DESCRIPTION,
      },
      {
        property: 'og:title',
        content: PAGES_CONTENT.PET.TITLE,
      },
      {
        property: 'og:url',
        content: PAGES_CONTENT.PET.URL,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:description',
        content: PAGES_CONTENT.PET.DESCRIPTION,
      },
      {
        property: 'og:image',
        content: PAGES_CONTENT.PET.BANNER_IMAGE,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:title',
        content: PAGES_CONTENT.PET.TITLE,
      },
      {
        name: 'twitter:description',
        content: PAGES_CONTENT.PET.DESCRIPTION,
      },
      {
        name: 'twitter:url',
        content: PAGES_CONTENT.PET.URL,
      },
      {
        name: 'twitter:image',
        content: PAGES_CONTENT.PET.BANNER_IMAGE,
      },
    ],
    scripts: [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'listItem',
              position: 1,
              name: 'Assistência Pet',
              item: `${CANONICAL_URL_BASE}${ROUTES.LANDING_PET}`,
            },
          ],
        },
      },
    ],
    subjects: 'Pet',
  },
  [ROUTES.CANCEL]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.CANCEL.URL,
      },
    ],
    title: PAGES_CONTENT.CANCEL.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.CANCEL.DESCRIPTION,
      },
    ],
    scripts: [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'listItem',
              position: 1,
              name: 'Cancelamento',
              item: `${CANONICAL_URL_BASE}${ROUTES.CANCEL}`,
            },
          ],
        },
      },
    ],
  },
  [ROUTES.FAQ_PAGE]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.FAQ.URL,
      },
    ],
  },
  [ROUTES.ADVISORY_RESEARCH]: {
    links: [
      {
        rel: 'canonical',
        href: PAGES_CONTENT.ADVISORY.URL,
      },
    ],
    title: PAGES_CONTENT.ADVISORY.TITLE,
    metas: [
      {
        name: 'description',
        content: PAGES_CONTENT.ADVISORY.DESCRIPTION,
      },
    ],
    scripts: [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'listItem',
              position: 1,
              name: 'Assessoria',
              item: `${CANONICAL_URL_BASE}${ROUTES.ADVISORY_RESEARCH}`,
            },
          ],
        },
      },
    ],
  },
};

/**
 * A function that applies some custom head tags (like title, meta and link) for a specific page.
 * @returns {JSX}
 */
export const chooseHeadTags = (pathname: string, productPathname: string) => {
  return <CustomHeadTags {
    ...(
      HEAD_TAG_PAGES[pathname] ||
      HEAD_TAG_PAGES[productPathname] ||
      {}
    )}
  />;
};
