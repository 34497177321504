export const normalizeAutoData = response => {
  const { compositions } = response;
  let coverages = compositions.filter(({ attributes }) =>
    attributes.some(({ key, value }) => key === 'tipo' && value === 'cobertura'),
  );

  coverages = coverages.map(coverage => {
    const { attributes } = coverage;
    const required = attributes.some(({ key, value }) => key === 'obrigatorio' && value === 'true');
    const image = attributes.find(att => att.key === 'icone_primario').value;
    const icon = attributes.find(att => att.key === 'icone_secundario').value;
    return {
      ...coverage,
      disabled: required,
      checked: required,
      required,
      image,
      icon,
    };
  });

  return {
    ...response,
    coverages,
  };
};

export const normalizeResidData = response => {
  const { compositions } = response;
  let coverages = compositions.filter(({ attributes }) =>
    attributes.some(({ key, value }) => key === 'tipo' && value === 'cobertura'),
  );

  coverages = coverages.map(coverage => {
    const { attributes } = coverage;
    const required = attributes.some(({ key, value }) => key === 'obrigatorio' && value === 'true');
    const image = attributes.find(att => att.key === 'icone_primario').value;
    const icon = attributes.find(att => att.key === 'icone_secundario').value;
    return {
      ...coverage,
      disabled: required,
      checked: required,
      required,
      image,
      icon,
    };
  });

  let assists = compositions.filter(({ attributes }) =>
    attributes.some(({ key, value }) => key === 'tipo' && value === 'assistencia'),
  );

  assists = assists.map(assist => {
    const { attributes } = assist;
    const image = attributes.find(att => att.key === 'icone_primario').value;
    const icon = attributes.find(att => att.key === 'icone_secundario').value;
    return {
      ...assist,
      image,
      icon,
    };
  });

  return {
    ...response,
    coverages,
    assists,
  };
};
