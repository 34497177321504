import { ANALYTICS_EVENT_ACTIONS } from './actions';
import { ANALYTICS_EVENT_CATEGORIES } from './categories';
import { ANALYTICS_EVENT_RULES } from './rules';
import { ANALYTICS_EVENT_BUSINESS } from './business';
import { ANALYTICS_EVENT_CHANNEL_TYPES } from './channelTypes';
import { ANALYTICS_EVENT_IDPF } from './idpf';
import { ANALYTICS_EVENT_SEGMENTS } from './segments';
import { ANALYTICS_EVENT_TECHNICAL_IDS } from './technicalIds';
import { ANALYTICS_EVENT_ENVIRONMENTS } from './environments';

const ANALYTICS_LABEL_VALUE_MASK: RegExp = /{[0-9]+}/g;

export {
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_RULES,
  ANALYTICS_EVENT_BUSINESS,
  ANALYTICS_LABEL_VALUE_MASK,
  ANALYTICS_EVENT_CHANNEL_TYPES,
  ANALYTICS_EVENT_IDPF,
  ANALYTICS_EVENT_SEGMENTS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_ENVIRONMENTS,
};
