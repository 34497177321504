import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import resources from 'store/resources';

interface CustomHeadTagsInterface {
  title?: string;
  links?: Array<object>;
  metas?: Array<object>;
  scripts?: Array<object>;
  subjects?: string;
}

const CustomHeadTags = ({
  title = 'Cotação de Seguro das Melhores Empresas | Itaú Seguros',
  links,
  metas,
  scripts,
  subjects = '',
}: CustomHeadTagsInterface) => {
  const [faqQuestions, setfaqQuestions] = useState({});
  const dispatch = useDispatch();
  const questions = useSelector(({ faq }: any) => faq);

  useEffect(() => {
    dispatch(resources.faq.actions.load(subjects));
  }, []);

  useEffect(() => {
    if (questions.data != null) {
      const reduceQuestions: Array<any> = questions.data.conteudo.slice(0, 3);
      const mainEntity = reduceQuestions.map((element) => ({
        '@type': 'Question',
        name: `${element.pergunta}`,
        acceptedAnswer: {
          '@type': 'Answer',
          text: `${element.resposta}`,
        },
      }));
      const json = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity,
      };
      setfaqQuestions(json);
    }
  }, [questions]);
  return (
    <>
      {/* @ts-ignore*/}
      <Helmet>
        <title>{title}</title>
        {links && links.map((link) => <link {...link} />)}
        {metas && metas.map((meta) => <meta {...meta} />)}
        {scripts &&
          scripts.map((script: any) => (
            <script {...script}>{script.children && JSON.stringify(script.children)}</script>
          ))}
        {subjects !== '' && <script type="application/ld+json">{JSON.stringify(faqQuestions)}</script>}
      </Helmet>
    </>
  );
};

export default CustomHeadTags;
