import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
import { useStyles } from './InputField.styles';

export default function InputMaskWrapper({
  mask,
  formatChars,
  label,
  name,
  onChange,
  onBlur,
  required,
  type,
  placeholder,
  autoComplete,
  inputProps,
  value,
  disabled,
  helperField,
  inputRef,
  id,
  error,
  helperText,
  ariaLabel,
  onFocus,
}) {
  const _id = id || name;

  return (
    <>
      <InputMask
        maskChar=""
        value={value}
        disabled={disabled}
        mask={mask}
        formatChars={formatChars}
        onChange={!disabled && onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {() => (
          <TextField
            margin="normal"
            InputLabelProps={{ 'aria-hidden': 'true' }}
            label={label}
            id={_id}
            name={name}
            type={type}
            fullWidth
            helperText={helperText}
            InputProps={{
              endAdornment: helperField(),
              role: 'none',
              inputProps: { ...inputProps, 'aria-label': ariaLabel, 'aria-labelledby': `${_id}-label` },
            }}
            inputRef={inputRef}
            placeholder={placeholder || undefined}
            autoComplete={autoComplete || undefined}
            required={required}
            error={error}
          />
        )}
      </InputMask>
    </>
  );
}

InputMaskWrapper.propTypes = {
  mask: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  error: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  autoComplete: PropTypes.any,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  formatChars: PropTypes.any,
  onFocus: PropTypes.func,
};

export const InputBeagle = ({
  label,
  name,
  value,
  error,
  onChange,
  autoFocus,
  required,
  ariaLabel,
  onBlur,
  helperText,
}) => {
  const styles = useStyles();

  const labelRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (required) {
        const span = document.createElement('span');
        const text = document.createTextNode(' *');
        span.appendChild(text);
        span.setAttribute('aria-hidden', 'true');
        labelRef.current.appendChild(span);
      }
    }, 100);
  }, [label, required]);

  return (
    <TextField
      label={label}
      InputLabelProps={{ ref: labelRef }}
      name={name}
      value={value}
      error={Boolean(error)}
      helperText={error || helperText}
      FormHelperTextProps={{ 'aria-hidden': 'true' }}
      fullWidth
      margin="normal"
      inputProps={{
        autoFocus,
        'aria-required': required,
        'aria-label': ariaLabel,
      }}
      className={styles.field}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

InputBeagle.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  error: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};
