import axios from 'axios';
import { performance } from 'config';
import { normalizeAutoData, normalizeResidData } from './utils';

export const getOffersByLanding = async landingName => {
  const newLandingName = landingName.charAt(0).toUpperCase() + landingName.slice(1);

  const trace = performance.trace('getOffersByLanding');
  trace.start();

  const response = await axios.get('v1/seguros/offers/search', {
    headers: {
      'product-name': newLandingName,
    },
  });
  trace.stop();
  if (newLandingName.toUpperCase() === 'AUTO') {
    return normalizeAutoData(response.data);
  }

  if (newLandingName.toUpperCase() === 'RESIDENCIAL' || newLandingName.toUpperCase() === 'RESIDENCIALIAR') {
    return normalizeResidData(response.data);
  }

  return response.data;
};
