import ANALITYCS_CATEGORY from '../category.json';

export default {
  GENERATE_LEAD: {
    action: 'generate_lead',
    label: 'BTN:Auto:GerarLead',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_AUTO_AUTH_CELPHONE_RESEND: {
    action: 'checkout_progress_auto_auth_celphone_resend',
    label: 'BTN:Auto:ReenviarAutenticaçãoCelular',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_AUTO_AUTH_CELPHONE: {
    action: 'checkout_progress_auto_auth_celphone',
    label: 'BTN:Auto:Contratar:AutenticaçãoCelular',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  START_QUOTATION: {
    action: 'auto_begin_simulation',
    label: 'BTN:Auto:PedirCotação',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  SHOW_COVERAGES_DESCRIPTIONS: {
    action: 'auto_allcoverage_detail',
    label: 'BTN:Auto:TodasCoberturas',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  SHOW_COVERAGES_DETAILS: {
    action: 'auto_coverage_detail',
    label: 'BTN:Auto:Coberturas',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  START_SCROLL_SIMULATION: {
    action: 'auto_simulation_scroll',
    label: 'BTN:Auto:BotaoScroll',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CAR_INFORMATION: {
    action: 'auto_car_info',
    label: 'BTN:Auto:InfoVeiculo',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  MAIN_DRIVER: {
    action: 'auto_personal_data',
    label: 'BTN:Auto:Cotação:DadosPessoais',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  FRANCHISE_INFO: {
    action: 'auto_franquia_info',
    label: 'BTN:Auto:Cotação:Resultado:FranquiaInfo',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  COVERAGES_INFO: {
    action: 'auto_coverage_info',
    label: 'BTN:Auto:Cotação:Resultado:Coberturas',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ASSISTS_INFO: {
    action: 'auto_assistance_info',
    label: 'BTN:Auto:Cotação:Resultado:Assistencias',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  QUOTATION_CHECKOUT: {
    action: 'auto_result_checkout',
    label: 'BTN:Auto:Cotação:Resultado',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  START_SIMULATION: {
    action: 'auto_begin_simulation_ipa',
    label: 'BTN:Auto:Modal:ItauPortoAzul',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ABORT_SIMULATION: {
    action: 'auto_begin_simulation_ipa_back',
    label: 'BTN:Auto:Modal:ItauPortoAzul:Voltar',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  RENEW_CONTRACT: {
    action: 'auto_renew_contract_ipa',
    label: 'BTN:Auto:Modal:ItauPortoAzul:Renovação',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  START_SIMULATION_IC: {
    action: 'auto_begin_simulation_ic',
    label: 'BTN:Auto:Modal:ItauCorretora',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ABORT_SIMULATION_IC: {
    action: 'auto_begin_simulation_ic_back',
    label: 'BTN:Auto:Modal:ItauCorretora:Voltar',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  RENEW_CONTRACT_IC: {
    action: 'auto_renew_contract_ic',
    label: 'BTN:Auto:Modal:ItauCorretora:Renovação',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  SIMULATE_LEAD: {
    action: 'auto_simulation_lead',
    label: 'BTN:Auto:Cotação:GerarLead',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ALERT_PEP_PLD: {
    action: 'auto_error_without_acceptance',
    label: 'BTN:Auto:Cotação:Erro:SemAceitaçãodaSeguradora',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_COMPLEMENTARY_DATA: {
    action: 'checkout_progress_auto_personal_data',
    label: 'BTN:Auto:Contratar:DadosPessoais:ResponsavelPeloSeguro',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_AUTH_FIRST_STEP: {
    action: 'checkout_progress_auto_auth_information',
    label: 'BTN:Auto:Contratar:Autenticação',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PAYMENT: {
    action: 'checkout_progress_auto_payment',
    label: 'BTN:Auto:Contratar:Pagamento',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_HOME_SCHEDULING_SUCESS: {
    action: 'auto_survey_home_scheduling',
    label: 'BTN:Auto:Vistoria:Domiciliar:AgendamentoSucesso',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  HOME_SCHEDULING_PAGE: {
    action: 'auto_survey_home',
    label: 'BTN:Auto:Vistoria:Domiciliar',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_CAPS_SCHEDULING_SUCESS: {
    action: 'auto_survey_caps_scheduling',
    label: 'BTN:Auto:Vistoria:CAPS:AgendamentoSucesso',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CAPS_SCHEDULING_PAGE: {
    action: 'auto_survey_caps',
    label: 'BTN:Auto:Vistoria:CAPS',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  MAKE_YOURSELF_THE_SURVEY: {
    action: 'auto_survey_app',
    label: 'BTN:Auto:Vistoria:APP',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  COMPARATIVE_PLANS: {
    action: 'auto_coverage_comparative',
    label: 'BTN:Auto:Cotação:Resultado:Comparativo',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  REMARKED_CHASSIS: {
    action: 'auto_error_remarked_chassis',
    label: 'BTN:Auto:Cotação:Erro:ChassiRemarcado',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  REMARKED_CHASSIS_LEAD: {
    action: 'auto_error_remarked_chassis_lead',
    label: 'BTN:Auto:Cotação:Erro:ChassiRemarcado:GerarLead',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ERROR_GENERIC: {
    action: 'auto_error_generic',
    label: 'BTN:Auto:Cotação:Erro:Generico',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ERROR_GENERIC_LEAD: {
    action: 'auto_error_generic_lead',
    label: 'BTN:Auto:Cotação:Erro:Generico:GerarLead',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  ERROR_GENERIC_TRY_AGAIN: {
    action: 'auto_error_generic_try_again',
    label: 'BTN:Auto:Cotação:Erro:Generico:TentarNovamente',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CONTINUE_QUOTATION: {
    action: 'auto_continue_quotation',
    label: 'BTN:Auto:Cotação:Continue',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_SUMMARY: {
    action: 'checkout_progress_auto_summary',
    label: 'BTN:Auto:Contratar:Resumo',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  AUTO_PURCHASE: {
    action: 'purchase',
    label: 'BTN:Auto:Contratar:Sucesso',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  AUTO_DISMISS: {
    action: 'auto_survey_dismiss',
    label: 'BTN:Auto:Vistoria:Dispensa',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_AUTO_AUTH_EMAIL: {
    action: 'checkout_progress_auto_auth_email',
    label: 'BTN:Auto:EnviarEmailAutenticacao',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
};
