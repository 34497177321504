import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ toRem, breakpoints }) => ({
  root: {
    fontSize: toRem(16),
    fontFamily: 'ItauText-Regular',
    margin: toRem(38),
  },
  pageTitle: {
    fontSize: toRem(18),
    fontFamily: 'ItauText-XBold',
    margin: 0,
    [breakpoints.down('sm')]: {
      margin: '1rem 0',
      lineHeight: '1.5rem',
    },
  },
  pageSubtitle: {
    margin: 0,
  },
  a11yAlert: {
    height: 0,
    opacity: 0,
  },
}));
