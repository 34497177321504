/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Routes from './routes';
import { ProjectContextProvider } from './providers/ProjectContextProvider';

function WelcomeGrid({ currentProject, mainTheme }) {
  return (
    <ProjectContextProvider currentProject={currentProject} mainTheme={mainTheme}>
      <Routes />
    </ProjectContextProvider>
  );
}

WelcomeGrid.propTypes = {
  currentProject: PropTypes.string,
};

export default WelcomeGrid;
