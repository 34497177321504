interface AdrumRestTiming {
  bufSize: number;
  clearResTimingOnBeaconSend: boolean;
}

interface AdrumConfig {
  appKey: string;
  adrumExtUrlHttp: string;
  adrumExtUrlHttps: string;
  beaconUrlHttp: string;
  beaconUrlHttps: string;
  resTiming: AdrumRestTiming;
  maxUrlLength: number;
  xd: { enable: boolean };
  spa: { spa2: boolean };
  fetch: boolean;
}

declare global {
  interface Window {
    'adrum-app-key': string;
    'adrum-start-time': number;
    'adrum-config': Partial<AdrumConfig>
  }
}

export const injectAppDynamics = () => {
  const dynamicsScript = document.createElement('script');
  dynamicsScript.src = 'https://cdn.appdynamics.com/adrum/adrum-22.6.0.3859.js';
  document.head.appendChild(dynamicsScript);
}

export function loadDynamics(appKey?: string) {
  window['adrum-start-time'] = new Date().getTime();
(function(config){
  config.appKey = appKey;
  config.fetch = true;
  config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
  config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
  config.beaconUrlHttp = "http:/gru-col.eum-appdynamics.com";
  config.beaconUrlHttps = "https://gru-col.eum-appdynamics.com";
  config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
  config.maxUrlLength = 512;
  config.xd = {enable: true};
  config.spa = {spa2:true};
})(window['adrum-config'] || (window['adrum-config'] = {}));
    injectAppDynamics();
}
