/* eslint-disable no-prototype-builtins */
import { createSelector } from 'reselect';
import { PAYMENT_METHODS } from 'utils/constants';

export const residencialSelector = (state) => state.residencial;

export const residencialCoverageSelector = createSelector(residencialSelector, (item) => item.coberturas);

export const residencialDivergentDataQuotationHolder = createSelector(
  residencialSelector,
  (item) => item.dadosDivergentesQuotacaoTitular
);

export const residencialDivergentDataQuotationSecondSimulation = createSelector(
  residencialSelector,
  (item) => item.segundaSimulacaoDivergenteQuotacao
);

export const residencialPreSelectedSelector = createSelector(
  residencialCoverageSelector,
  (item) => item.preSelecionadas
);

export const residencialSelectedCoverageSelector = createSelector(
  residencialCoverageSelector,
  (item) => item.selecionadas
);

export const residencialSimulationSelector = createSelector(residencialSelector, (item) => item.simulacao);

export const residencialSelectedAssistanceSelector = createSelector(
  residencialSelector,
  (item) => item.assistenciaSelecionada
);

export const residencialRealStateTypeSelector = createSelector(
  residencialSimulationSelector,
  (item) => item.tipoImovel
);

export const residencialSimulationErrorTypeSelector = createSelector(
  residencialSimulationSelector,
  (item) => item.erro
);

export const residencialAuthErrorValidationTypeSelector = createSelector(
  residencialSimulationSelector,
  (item) => item.autenticacao_validation_erro
);

export const residencialSimulationLoadingTypeSelector = createSelector(
  residencialSimulationSelector,
  (item) => item.carregando
);

export const residencialOccupationTypeSelector = createSelector(
  residencialSimulationSelector,
  (item) => item.tipoOcupacao
);

export const residencialRequestSelector = createSelector(residencialSimulationSelector, (item) => item.requisicao);
export const residencialResponseSelector = createSelector(residencialSimulationSelector, (item) => item.resposta);

export const residencialPersonalDataSelector = createSelector(residencialRequestSelector, (item) => item.proponente);

export const residencialRequestOfferSelector = createSelector(residencialRequestSelector, (item) => item.oferta);

export const residencialRequestOfferProductSelector = createSelector(
  residencialRequestOfferSelector,
  (item) => item.produtos[0]
);

export const residencialHiringSelector = createSelector(residencialSelector, (item) => item.contratacao);

export const residencialSelectedInstallmentSelector = createSelector(
  residencialSelector,
  (item) => item.opcaoParcelaSelecionada
);
export const residencialSelectedInstallmentNumberSelector = createSelector(
  residencialSelectedInstallmentSelector,
  (item) => item?.parcelas
);

export const residencialSimulationRequestSelector = createSelector(
  residencialSimulationSelector,
  (item) => item.requisicao
);

export const residencialSimulationResponseSelector = createSelector(
  residencialSimulationSelector,
  (item) => item.resposta
);

export const residencialSimulationResponsePaymentMethodSelector = createSelector(
  residencialSimulationResponseSelector,
  (item) => item.metodos_pagamento
);

export const residencialPaymentCardBadgesByTypeSelector = createSelector(
  residencialSimulationResponsePaymentMethodSelector,
  (item) => item?.find((i) => i.metodo === PAYMENT_METHODS.creditCard)?.cartoes?.map((i) => i.tipo)
);

export const residencialPaymentBanksOptions = createSelector(
  residencialSimulationResponsePaymentMethodSelector,
  (item) =>
    item
      ?.find((i) => i.metodo === PAYMENT_METHODS.directDebit)
      ?.bancos?.map((i) => ({
        value: i.codigo,
        label: i.nome,
      }))
);

export const residencialHiringResponseSelector = createSelector(residencialHiringSelector, (item) => item.resposta);

export const residencialHiringRequestSelector = createSelector(residencialHiringSelector, (item) => item.requisicao);

export const residencialHiringContractNumberSelector = createSelector(
  residencialHiringResponseSelector,
  (item) => item.numero_contratacao
);

export const residencialHiringRequestRiskItemSelector = createSelector(
  residencialHiringRequestSelector,
  (item) => item.item_risco
);

export const residencialHiringRequestRiskItemImmobileSelector = createSelector(
  residencialHiringRequestRiskItemSelector,
  (item) => item.imovel
);

export const residencialHiringRequestRiskItemImmobileAdressSelector = createSelector(
  residencialHiringRequestRiskItemImmobileSelector,
  (item) => item.endereco
);

export const residencialHiringRequestProponentSelector = createSelector(
  residencialHiringRequestSelector,
  (item) => item.proponente
);

export const residencialHiringRequestProponentAddressSelector = createSelector(
  residencialHiringRequestProponentSelector,
  (item) => item.endereco
);

export const residencialHiringRequestPaymentDataSelector = createSelector(
  residencialHiringRequestSelector,
  (item) => item.dados_opcao_pagamento
);

export const residencialHiringRequestPaymentResponsibleSelector = createSelector(
  residencialHiringRequestPaymentDataSelector,
  (item) => (item.conta_bancaria ? item.conta_bancaria.pagador : item.cartao_credito.pagador)
);

export const residencialHiringRequestPaymentTypeSelector = createSelector(
  residencialHiringRequestPaymentDataSelector,
  (item) => {
    if (item?.conta_bancaria) {
      return PAYMENT_METHODS.directDebit;
    }
    if (item?.cartao_credito?.porto) {
      return PAYMENT_METHODS.portoCreditCard;
    }
    return PAYMENT_METHODS.creditCard;
  }
);

export const residencialHiringResponsePaymentOptionsSelector = createSelector(
  residencialSimulationResponseSelector,
  (item) => item?.opcoes_pagamento
);

export const residencialHiringResponsePaymentSelectedSelector = (paymentId) =>
  createSelector(residencialHiringResponsePaymentOptionsSelector, (paymentOptions) =>
    paymentOptions.find((po) => po.id_opcao_pagamento === paymentId)
  );

export const residencialSimulationResponseProductSelector = createSelector(
  residencialSimulationResponseSelector,
  (item) => item.produtos[0]
);

export const residencialSimulationResponseIdSelector = createSelector(
  residencialSimulationResponseSelector,
  (item) => item.id_simulacao
);

export const residencialSimulationResponseBrokerTaxSelector = createSelector(
  residencialSimulationResponseSelector,
  (item) => item?.corretora?.taxa_corretagem
);

export const residencialAssistancesSelector = createSelector(residencialSelector, (item) => item.assistencias);

/**
 * Return assistences list based on selected coverages number
 */
export const residencialAssistancesFilterSelector = (coveragesQty) =>
  createSelector(residencialAssistancesSelector, residencialCoverageSelector, (assistencias, coverages) => {
    const { selecionadas: selectedCoverages } = coverages;
    if (selectedCoverages?.length < coveragesQty) {
      return assistencias.filter((assistencia) => !Object.prototype.hasOwnProperty.call(assistencia, 'id_assistencia'));
    }
    return assistencias;
  });

export const residencialQuotationSelector = createSelector(residencialSelector, (item) => item.valorCotacao);

export const residencialFinancialResponsibleSelector = createSelector(
  residencialSelector,
  (item) => item.responsavelFinanceiro
);
