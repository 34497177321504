import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ toRem, palette }) => ({
  customStyle: {
    pointerEvents: 'none',
    textOverflow: 'ellipsis',
    overflow: 'scroll',
  },
  label: {
    margin: `${toRem(15)} 0 0`,
    color: palette.inputLabelColor,
  },
  disabled: {
    pointerEvents: 'none',
    color: `${palette.inputDisableColor}!important`,
  },
  placeholder: {
    '&::placeholder': {
      color: palette.inputPlaceholderColor,
    },
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
}));
