import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from 'components/Switch';
import Box from '@material-ui/core/Box';
import { ButtonV2 } from 'components/Button/ButtonV2/ButtonV2';
import Markdown from 'components/Markdown';
import { useSharedStyles } from '../../styles';

export default function GenericToggleTab({ items, handleClickApply, colNames }) {
  const sharedStyles = useSharedStyles();
  const [applyIsLoading, setApplyIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [togglesStatus, setTogglesStatus] = useState(items);

  const handleChangeToggleStatus = ({ name, enabled }) => {
    setIsDirty(true);
    setTogglesStatus(state =>
      state.map(toggleStatus => {
        if (toggleStatus.name === name) toggleStatus.enabled = enabled;
        return toggleStatus;
      }),
    );
  };

  /**
   * This function calls handleClickApply sending by param a complete array with all new state
   * toggleStatus example: [{ name: 'Item 1', enabled: true }, { name: 'Item 2', enabled: false }]
   */
  const _handleClickApply = () => {
    setApplyIsLoading(true);
    handleClickApply(togglesStatus);
    setTimeout(() => document.location.reload(), 1000);
  };

  return (
    <>
      <table className={sharedStyles.mocksTable}>
        <thead>
          <tr>
            <th>{colNames[0]}</th>
            <th>{colNames[1]}</th>
            <th className={sharedStyles.toggleCell}>{colNames[2]}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((module, index) => (
            <tr key={index}>
              <td>
                <Markdown source={`**${module.name}**`} />
              </td>
              <td>
                <Markdown source={module.description} />
              </td>
              <td className={sharedStyles.toggleCell}>
                <Switch
                  yesLabel=""
                  noLabel=""
                  checked={togglesStatus.find(toggle => toggle.name === module.name).enabled}
                  onChange={checked => handleChangeToggleStatus({ name: module.name, enabled: checked })}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box display="flex" alignItems="end">
        <ButtonV2
          className={sharedStyles.applyButton}
          onClick={_handleClickApply}
          loading={applyIsLoading}
          disabled={!isDirty || applyIsLoading}
        >
          Aplicar
        </ButtonV2>
      </Box>
    </>
  );
}

GenericToggleTab.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      description: PropTypes.string,
    }),
  ),
  handleClickApply: PropTypes.func.isRequired,
  colNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};
