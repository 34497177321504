import palette from '../palette';

const { focusColor } = palette;

export default {
  root: {
    '&$focusVisible': {
      outlineColor: focusColor,
      outlineStyle: 'solid',
      outlineWidth: '2px',
    },
  },
};
