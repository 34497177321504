import config from '../config';

const { GATEWAY_HOST } = config;

export const getBaseURL = async () => GATEWAY_HOST;
// try {
//   await remoteConfig.fetchAndActivate();
//   const isMock = remoteConfig.getBoolean(mock);
//   return isMock ? GATEWAY_MOCK : GATEWAY_HOST;
// } catch (err) {
//   console.error('remoteConfig error', err);
//   return GATEWAY_HOST;
// }
