import RESOURCES from '../../utils/RESOURCES';

export const Types = {
  SET_HEADER: 'header/SET_HEADER',
};

const initialState = {
  text: RESOURCES.HEADER.TEXT,
  active: true,
};

export default function reducer(state = initialState, action) {
  const { header } = action;

  switch (action.type) {
    case Types.SET_HEADER:
      return {
        ...state,
        ...header,
      };
    default:
      return state;
  }
}

export function setHeader(header) {
  return {
    type: Types.SET_HEADER,
    header,
  };
}
