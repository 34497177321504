import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { useStyles } from './contactLinkStyles';

const ButtonLink = ({ onClick, text, context, page, className, ariaLabel, ...restProps }) => {
  const styles = useStyles();
  return (
    <Button
      role="link"
      onClick={onClick}
      text={text}
      variant="text"
      context={context}
      page={page}
      className={className}
      ariaLabel={ariaLabel}
      classNameContainer={styles.contactButton}
      {...restProps}
    />
  );
};

ButtonLink.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  context: PropTypes.string,
  page: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  restProps: PropTypes.any,
};

export default ButtonLink;
