import { call, put } from 'redux-saga/effects';
import config from 'config';
import { setError } from 'store/ducks/httpErrors';
import { fetchVehicle } from 'api/E2E/Auto/fetchVehicle';
import { setManualVehicleSelect, Types } from 'store/auto/store/ducks/autoFlow';
import { addLicensePlate, updateVehicle } from 'store/auto/store/ducks/automovel';
import { createSearchVehicle } from 'store/auto/store/ducks/searchVehicle';

const { SINESP_API_ERROR } = config;

function* handleIgnoreLiscensePlate(value) {
  yield put(setManualVehicleSelect(true));
  value.data.zeroKmCallback();
  yield put({ type: Types.SUCCESS_INIT_QUOTATION });
}

export default function* setUpdateInitialData(value) {
  const { licensePlate, zeroKm } = value.data.request;
  yield put({ type: Types.LOAD_INIT_QUOTATION });
  yield put(updateVehicle({ novo: !!zeroKm }));
  yield put(addLicensePlate(zeroKm ? undefined : licensePlate));

  if (!zeroKm) {
    try {
      const response = yield call(fetchVehicle, { placa: licensePlate });
      if (response.placa) {
        yield put(setManualVehicleSelect(false));
        yield put(createSearchVehicle(response));
        value.data.zeroKmCallback();
        yield put({ type: Types.SUCCESS_INIT_QUOTATION, data: { placa: response.placa } });
      } else if (SINESP_API_ERROR) {
        yield handleIgnoreLiscensePlate(value);
      } else {
        yield put({ type: Types.ERROR_INIT_QUOTATION });
        value.data.errorCallback();
      }
    } catch (err) {
      if (SINESP_API_ERROR) {
        yield handleIgnoreLiscensePlate(value);
      } else {
        yield put(setError());
      }
    }
  } else {
    value.data.zeroKmCallback();
    yield put({ type: Types.SUCCESS_INIT_QUOTATION });
  }
}
