import { makeStyles, withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

export const useStyles = makeStyles(({ toRem, palette }) => ({
  root: {
    color: props => (props.variant === 'outlined' ? palette.chips : palette.white),
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '.5rem',
    marginTop: '.5rem',

    '& > div': {
      padding: props => (props.info ? '.25rem 2.5rem .25rem .5rem' : '0 .5rem'),
      border: `1px solid ${palette.chips}`,
      borderRadius: toRem(32),
      backgroundColor: props => (props.variant === 'outlined' ? 'rgba(0, 0, 0, 0)' : palette.chips),
    },
  },
  icon: {
    fontSize: toRem(24),
    marginLeft: '-2rem',
    marginRight: '0.5rem',
    padding: 0,
    color: 'inherit',
  },
}));

export const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.chips,
  },
}))(Tooltip);
