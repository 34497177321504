/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import axios from 'axios';
import { batch } from 'react-redux';
import { store } from './storeElements';
import { increaseErrorTries, setError, setStatusCode } from '../store/ducks/httpErrors';
import { wait } from './util';
import { axios500ExceptNoRedirect } from './axios.500ExceptNoRedirect';

const MAX_RETRY = 2;

const hasMoreAttempts = () => {
  const {
    httpErrors: { tries },
  } = store.getState();
  return tries < MAX_RETRY;
};
const checkStatus500 = error => {
  const { response } = error;
  const { status } = response;
  return status >= 500;
};

const checkStatus504 = error => {
  const { response } = error;
  const { status } = response;
  return status === 504;
};

const checkStatus406 = error => error?.response?.status === 406;

const checkStatus400 = error => error?.response?.status === 400;

const checkStatus422 = error => error?.response?.status === 422;

const retry = async config => {
  store.dispatch(increaseErrorTries());
  await wait(300);
  return axios(config);
};

axios.interceptors.response.use(
  response => response,
  error => {
    const { config, response } = error;
    const maxAttemptsNotReached = hasMoreAttempts();
    const hasResponse = response !== undefined;
    console.error(error);
    
    if (maxAttemptsNotReached && !checkStatus406(error) && !config?.disableRetry) {
      if (!hasResponse) {
        return retry(config);
      }
    }

    if (config?.disableException) {
      return Promise.reject(error);
    }

    if (checkStatus400(error)) {
      batch(() => {
        store.dispatch(setStatusCode(400));
        store.dispatch(setError(config));
      });
      return Promise.reject(error);
    }

    if (checkStatus504(error)) {
      batch(() => {
        store.dispatch(setStatusCode(504));
        store.dispatch(setError(config));
      });
      return Promise.reject(error);
    }

    if (checkStatus422(error)) {
      store.dispatch(setStatusCode(422));
      store.dispatch(setError(config));
      return Promise.reject(error);
    }

    if (!checkStatus406(error)) {
      store.dispatch(setError(config));
      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);

axios500ExceptNoRedirect.interceptors.response.use(
  response => response,
  async error => {
    const { config, response } = error;
    const maxAttemptsNotReached = hasMoreAttempts();
    const hasResponse = response !== undefined;

    if (maxAttemptsNotReached) {
      if (!hasResponse) {
        return retry(config);
      }
      if (checkStatus500(error)) {
        return Promise.reject(error);
      }
    }

    store.dispatch(setError(error));
    return Promise.reject(error);
  },
);
