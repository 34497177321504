import { FormLabel, withStyles } from '@material-ui/core';

export const StyledFormLabel = withStyles(theme => ({
  root: {
    fontFamily: 'ItauText-Regular',
    fontWeight: 400,
    fontSize: theme.toRem(16),
    color: theme.palette.text.primary,
    paddingBottom: theme.toRem(16),
    paddingTop: theme.toRem(24),
    position: 'inherit',
  },
}))(FormLabel);
