import { AnalyticsCustomEventInterface } from 'interfaces/analytics/AnalyticsCustomEvent.interface';
import { ANALYTICS_LABEL_VALUE_MASK, ANALYTICS_EVENT_RULES } from 'trackingV2/constants/index';
import { removeSpecialCharactersAndAccents, phraseToUpperCamelCase } from 'utils/string';

const replaceLabelSectionTokenByValue = (labelSection: string, labelValues: string[]): string => {
  const isValueToken: RegExpMatchArray | null = labelSection.match(ANALYTICS_LABEL_VALUE_MASK);

  let labelSectionWithValue: string;

  if (isValueToken) {
    const valueTokenIndex: number = Number(isValueToken[0].replace(/[{|}]/g, ''));
    labelSectionWithValue = labelValues[valueTokenIndex];
  } else {
    labelSectionWithValue = labelSection;
  }

  return labelSectionWithValue;
};

const replaceLabelTokensByValues = (label: string, labelValues: string[]): string => {
  const splittedLabel: string[] = label.split(':');
  const splittedLabelWithValues: string[] = splittedLabel.map(labelSection =>
    replaceLabelSectionTokenByValue(labelSection, labelValues),
  );
  const joinedLabelWithValues: string = splittedLabelWithValues.join(':');

  return joinedLabelWithValues;
};

const formatLabel = (label: string, labelValues: string[]): string => {
  const upperCamelCasedLabelValues: string[] = labelValues?.map(value => phraseToUpperCamelCase(value));
  const labelWithValues: string = replaceLabelTokensByValues(label, upperCamelCasedLabelValues);
  const normalizedLabel: string = removeSpecialCharactersAndAccents(labelWithValues);

  return normalizedLabel;
};

const assembleAnalyticsCustomEvent = (
  analyticsCustomEvent: AnalyticsCustomEventInterface,
  labelValues: string[],
  options: object,
) => {
  const formatedLabel: string = formatLabel(analyticsCustomEvent.label, labelValues);
  const formatedAnalyticsCustomEvent: AnalyticsCustomEventInterface = {
    ...analyticsCustomEvent,
    ...options,
    label: formatedLabel,
  };

  return {
    events: formatedAnalyticsCustomEvent,
    rule: ANALYTICS_EVENT_RULES.CUSTOM_LINK,
  };
};

export { assembleAnalyticsCustomEvent };
