import HELP from './Help/help.resource';
import HEADER from './Header/header.resource';
import BANNER from './Banner/banner.resource';
import INFORMATION from './Information/information.resource';
import STEPTOSTEP from './Steptostep/steptostep.resource';
import SHOWCASE from './Showcase/showcase.resource';
import FAQ from './Faq/faq.resource';

const HOME = {
  BANNER,
  HELP,
  HEADER,
  INFORMATION,
  STEPTOSTEP,
  SHOWCASE,
  FAQ,
};

export default HOME;
