import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DescriptiveRadio from '../DescriptiveRadio';
import InfoCardList from '../InfoCardList';

export default function RadioWithInfoCard({ options, onChange, answers, value }) {
  const [selectedOption, setSelectedOption] = useState(value);

  const handleChange = e => {
    setSelectedOption(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <>
      <DescriptiveRadio options={options} onChange={handleChange} value={value} />
      <InfoCardList options={options?.find(item => item.value === selectedOption)?.information} answers={answers} />
    </>
  );
}

RadioWithInfoCard.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      description: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  answers: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
