const common = {
  GENDER: {
    LABEL: 'sexo biológico',
    REQUIRED_MESSAGE: 'você precisa informar o sexo biológico',
    MALE: 'masculino',
    FEMALE: 'feminino',
  },
  HEADER: {
    CAR_INSURANCE: 'seguro auto',
    RESIDENTIAL_INSURANCE: 'seguro residencial',
  },
  POLITICAL_LINK_MODEL: {
    FORM: {
      TITLE: 'dados de **pessoa politicamente exposta**',
      TOOLTIP:
        'pessoa politicamente exposta é aquela que desempenha ou tenha desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou funções públicas relevantes, no Brasil ou em outros países, territórios e dependências estrangeiros, assim como seus representantes, familiares e outras pessoas de seu relacionamento próximo.',
      WHAT_IS_A_PEP: 'O que é uma pessoa politicamente exposta?',
    },
    CPF: {
      ERROR_MESSAGE: 'CPF deve ser diferente do responsável pelo seguro',
    },
    KINSHIP: {
      TITLE: 'grau de relacionamento',
      ARIA_LABEL: 'grau de relacionamento',
      REQUIRED_MESSAGE: 'você precisa informar o grau de relacionamento com o titular',
      OPTIONS: {
        FATHER_MOTHER: 'pai ou mãe',
        SPOUSE: 'cônjuge',
        COMPANION: 'companheiro',
        SON: 'filho',
        STEPSON: 'enteado',
        OTHERS: 'outros',
      },
    },
    LINK: {
      TITLE: 'pessoa politicamente exposta',
      ARIA_LABEL: 'pessoa politicamente exposta',
      REQUIRED_MESSAGE: 'você precisa informar se é pessoa politicamente exposta ou possui vínculo',
      OPTIONS: {
        IAM: 'sou',
        IM_NOT: 'não sou e não tenho relacionamento próximo',
        HAVE_LINK: 'não sou, mas tenho relacionamento próximo',
      },
    },
  },
  COMMON_MODELS: {
    NAME: {
      TITLE: 'nome completo (sem abreviações)',
      ARIA_LABEL: 'nome completo',
      REQUIRED_MESSAGE: 'você precisa informar o nome completo (sem abreviações) ',
      MESSAGE: 'verifique seu nome e sobrenome, e se não existe mais de um espaço entre eles.',
    },
    CPF: {
      TITLE: 'CPF',
      ARIA_LABEL: 'CPF',
      REQUIRED_MESSAGE: 'você precisa informar o CPF',
      MESSAGE: 'CPF inválido',
    },
    BIRTH_DATE: {
      LABEL: 'data de nascimento',
      REQUIRED_MESSAGE: 'você precisa informar a data de nascimento',
      MESSAGE: 'data de nascimento inválida',
    },
    EMAIL: {
      TITLE: 'e-mail',
      ARIA_LABEL: 'email',
      REQUIRED_MESSAGE: 'você precisa informar o e-mail',
      MESSAGE: 'use somente letras minúsculas',
      INVALID: 'e-mail inválido',
    },
    CEP: {
      TITLE: 'cep',
      ARIA_LABEL: 'cep',
      REQUIRED_MESSAGE: 'você precisa informar um CEP válido',
      MESSAGE: 'você precisa informar um CEP válido',
    },
    CELL_PHONE: {
      TITLE: 'celular',
      ARIA_LABEL: 'celular',
      REQUIRED_MESSAGE: 'você precisa informar o celular',
      MESSAGE: 'você precisa informar um celular válido',
    },
    MOTHER_NAME: {
      LABEL: 'nome completo da mãe (sem abreviações)',
      REQUIRED_MESSAGE: 'você precisa informar o nome completo da mãe (sem abreviações)',
      MESSAGE: 'nome da mãe deve ter nome e sobrenome sem abreviações',
      HELPER: 'pode ser substituído pelo nome do pai caso não haja o nome da mãe',
    },
    MARTIAL_STATUS: {
      LABEL: 'estado civil',
      REQUIRED_MESSAGE: 'você precisa informar o estado civil',
    },
    OVER_18: 'você precisa ser maior de idade para contratar o seguro',
  },
  TERMS: {
    RESID_TYPES: {
      LABEL:
        'estou ciente que o seguro será válido apenas para imóveis de alvenaria (construidos com tijolos, pedras ou blocos de cimento), ocupados.',
      REQUIRED_MESSAGE: 'você precisa aceitar os termos de uso e a política de privacidade',
    },
  },
  RECEIPT_PAGE: {
    CONFIRMATION_SUCCESS: 'proposta foi enviada com sucesso',
    PROTOCOL: 'o número do seu protocolo é: ',
    RESID: {
      HEADER: 'próximos passos',
      SUBTITLE: 'ah! você vai receber um e-mail quando o pagamento for aprovado',
      STEP_ONE: 'resumo da compra e cópia de proposta enviados para o seu e-mail',
      STEP_TWO: {
        TITLE: 'aguardando confirmação de pagamento',
        DESCRIPTION: 'até 7 dias úteis',
      },
      STEP_THREE: {
        TITLE: 'a apólice será enviada por e-mail',
        DESCRIPTION: 'após análise da proposta e confirmação de pagamento',
      },
    },
  },
  WORDS: {
    REQUIRED: 'exigido',
  },
  PAYMENT_MODULES: {
    BANK: {
      TITLE: 'escolha o banco',
      OPTIONS: {
        ITAU: {
          VALUE: '341',
          LABEL: 'Itaú',
        },
        BRADESCO: {
          VALUE: '237',
          LABEL: 'Bradesco',
        },
        SANTANDER: {
          VALUE: '033',
          LABEL: 'Santander',
        },
      },
      REQUIRED_MESSAGE: 'você precisa informar o banco',
    },
    AGENCY: {
      TITLE: 'agência',
      NUMBER_AGENCY: 'Agência',
      REQUIRED_MESSAGE: 'você precisa informar a agência',
    },
    CC: {
      TITLE: 'conta corrente (com dígito)',
      REQUIRED_MESSAGE: 'você precisa informar a conta corrente com dígito',
      ONLY_NUMBERS_AND_LETTERS: 'apenas números e X',
      INVALID_MESSAGE: 'conta inválida, por favor, verifique sua conta',
    },
    MOUNT_INSTALLMENTS: {
      INSTALLMENTS_NUMBER: 'selecione o número de parcelas',
    },
    ACCEPT_CARD_BANNER_INFO:
      'aceitamos várias bandeiras de cartão de crédito (cartões gerados virtualmente não são aceitos).',
  },
  SHOWCASE: {
    TITLE: 'por onde você quer começar?',
    CARDS: {
      BUTTON_TEXT: 'faça sua cotação',
      RELEASE_LABEL: 'contrate online',
      AUTO: {
        TEXT: 'seguro auto',
        LINK: 'auto',
        CONTEXT_MOBILE: 'SliderAuto',
        CONTEXT: 'Auto',
      },
      RESID: {
        TEXT: 'seguro residencial',
        LINK: 'residencial',
        CONTEXT_MOBILE: 'SliderResid',
        CONTEXT: 'Resid',
      },
      ODONTO: {
        TEXT: 'plano odontológico',
        LINK: 'odonto',
        CONTEXT_MOBILE: 'SliderOdonto',
        CONTEXT: 'Odonto',
      },
    },
    INFO_TITLE: 'você sabia?',
    INFO: {
      TITLE_1: 'o seguro oferece muito mais que a proteção:',
      TEXT_1:
        'A assistência é um serviço extra, sem nenhum custo adicional, que você pode usar durante o período em que seu seguro estiver ativo.',
    },
    SHOW_MORE: 'saiba mais',
  },
  LGPD_TEXT:
    'Os dados inseridos por você poderão ser compartilhados com empresas parceiras para cotação e contratação dos produtos escolhidos e poderão ser tratados de acordo com a nossa Política de Privacidade disponível em nossos sites e aplicativos',
  BUTTON_QUOTES: {
    START_QUOTATION: 'faça sua cotação',
  },
  EXCLUSIVITY_TEXT:
    'A Itaú Corretora de Seguros S.A comercializa com exclusividade os produtos: seguro residencial e seguro automóvel do Grupo Porto Seguro S.A, na figura de corretora de seguros.',
};

export default common;
