// searchVehicle
import { createSelector } from 'reselect';

export const searchVehicleSelector = state => state.searchVehicle;

export const searchVehicleResponseSelector = createSelector(searchVehicleSelector, item => item.data);

export const searchVehicleModelsSelector = createSelector(searchVehicleResponseSelector, item => item.modelos);

export const searchVehicleBrandSelector = createSelector(searchVehicleResponseSelector, item => item.marca);
