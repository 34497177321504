import palette from '../palette';
import variables from '../variables';
import { toRem } from '../functions';

export default {
  root: {
    '&$focused': {
      outlineColor: palette.focusBorderColor,
      outlineOffset: -2,
      outlineStyle: variables.focusStyle,
      outlineWidth: variables.focusBorderWidth,
    },
  },
  formControl: {
    'label + &': {
      marginTop: toRem(30),
    },
  },
  underline: {
    '&:hover:not($disabled):before': {
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
    },
    '&:after': {
      borderBottom: `1px solid ${palette.primary.main}`,
    },
    '&$error:after': {
      borderBottomColor: `rgba(0, 0, 0, 0.42)`,
    },
  },
  input: {
    color: palette.defaultTextColor,
    fontSize: 16,
    margin: 0,
    padding: '14px 0 7px 3px !important',
  },
};
