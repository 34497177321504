const summary = {
  SUMMARY: {
    SHOW_SUMMARY: 'exibir resumo do seguro',
    HIDE_SUMMARY: 'ocultar resumo do seguro',
    LOADING: [
      'Oba! A sua proposta está sendo processada...',
      'Estamos enviando a sua proposta, só mais um instante :)',
      'Finalizando a sua proposta, falta pouco!',
    ],
    HEADER: {
      TITLE: 'serviços do seu seguro',
      SUBTITLE: 'Porto Seguro Residência',
      SELECTED_COVERAGES: '**coberturas selecionadas**',
      ASSISTS: '**pacote de assistências**',
      SHOW_ASSIST_SERVICES: 'exibir serviços desse plano',
      HIDE_ASSIST_SERVICES: 'ocultar serviços desse plano',
      VALUES: '**valores**',
      PAYMENT: {
        PAYMENT_TYPE: 'forma de pagamento',
        INSTALLMENTS: 'em {{installmentsNumber, bold}}',
        WITHOUT_INTEREST: 'sem juros de',
        WITH_INTEREST: 'com juros de',
        CC_METHOD: 'no cartão de crédito',
        DEBIT_METHOD: 'no débito bancário',
      },
      TOTAL: '**valor total do seu seguro:** {{ value }}',
    },
    DATA: {
      CEP: 'CEP',
      ADDRESS: 'endereço',
      NUMBER: 'número',
      COMPLEMENT: 'complemento',
      OCCUPATION_TYPE: 'tipo de imóvel',
      CPF: 'CPF',
      EMAIL: 'e-mail',
      CELL_PHONE: 'celular',
      CITY: 'cidade',
      STATE: 'estado',
      DISTRICT: 'bairro',
      HOLDER: {
        NAME: 'nome',
        BIRTHDAY: 'data de nascimento',
        MONTHLY_VALUE: 'valor mensal',
        GENDER: {
          TITLE: 'sexo biológico',
          MALE: 'masculino',
          FEMALE: 'feminino',
        },
      },
      PAYMENT: {
        INSURANCE_HOLDER: 'responsável pelo pagamento',
        TITLE: 'forma de pagamento',
        CREDIT: 'cartão de crédito',
        DEBIT: 'débito em conta',
      },
    },
    LIST: {
      INSURANCE_HOLDER_DATA: 'dados do titular do seguro',
      INSURED_PROPERTY_DATA: 'dados do imóvel segurado',
      MAILING_ADDRESS: 'endereço de correspondência',
      PAYMENT_DATA: 'dados de **pagamento**',
      BILLING_ADDRESS: 'endereço de **cobrança**',
    },
  },
};

export default summary;
