import axios from 'axios';
import { performance } from '../../config';

export const getFaq = async (product, callback) => {
  if (!product) return;
  const trace = performance.trace('getFaq');
  trace.start();
  const response = await axios.get(`/v1/seguros/faq/${product}`).catch(callback);
  trace.stop();

  return {
    conteudo: response.data
      .filter(faq => faq.ativo === true)
      .map(faq => ({
        pergunta: faq.pergunta,
        frequente: faq.frequente,
        resposta: faq.resposta,
        codigo_categoria: faq.uid,
        categoria: faq.categoria.titulo,
        assunto: faq.produto.titulo,
        ativo: faq.ativo,
      })),
  };
};
