import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    position: 'relative',
    left: '-20px',
    zIndex: 99999,
  },
  button: {
    height: '45px',
    minWidth: '190px',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    cursor: 'pointer',
  },
  buttonExpanded: {
    boxShadow: '0 0 5px rgb(0 0 0 / 30%)',
  },
  buttonExpandedShadowHider: {
    height: '5px',
    width: '100%',
    backgroundColor: 'white',
    position: 'absolute',
    bottom: 0,
    zIndex: 999999,
  },
  title: {
    marginRight: '10px',
  },
  titleSelected: {
    color: palette.primary.main,
    fontWeight: 'bold',
  },
  content: {
    position: 'absolute',
    left: '-600px',
    marginLeft: props => `${props.containerOverlapFix}px`,
    width: '900px',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: '0 0 5px rgb(0 0 0 / 30%)',
    zIndex: 999,
    transition: 'opacity .1s cubic-bezier(0, 0, 0.38, 0.9), max-height 1s cubic-bezier(0, 0, 0.38, 0.9)',
  },
  contentOpen: {
    opacity: '1',
    overflow: 'hidden',
    maxHeight: '100vh',
    visibility: 'visible',
  },
  contentHide: {
    opacity: 0,
    maxHeight: 0,
    visibility: 'hidden',
  },
}));
