const getLog = ({ category, action, label, value, valueOnlyNumber }) => {
  const toUpperCamelCase = str => {
    let splitted = str.split(/ |_/);
    splitted = splitted.map(word => {
      if (!word) return {};
      let _word = word;
      _word = _word.toLowerCase();
      _word = _word.replace(_word[0], _word[0].toUpperCase());
      _word = _word.replace(/,/g, '');
      return _word;
    });
    return splitted.join('');
  };

  const treatValueArray = values =>
    values.map(item => {
      if (Array.isArray(item)) return item;
      return (item && toUpperCamelCase(item)) || item;
    });

  /**
   * @param {String} eventLabel Original Analytics Event Label
   * @param {Array or string} receivedValues Value(s) received as props when function is called
   */
  const formatCollectionLabel = (eventLabel, receivedValues) => {
    let returnLabel = eventLabel;

    // if recievedValues is an arrays treats placement order in returnLabel
    if (Array.isArray(receivedValues)) {
      const treatedValuesArray = treatValueArray(receivedValues);
      // copies treated values array removing falsy entries
      const elementsLeftToReplace = [...treatedValuesArray.filter(item => !!item)];

      returnLabel = eventLabel.replace(/:{(\d+)}/g, (match, offset) => {
        const substituteValue = treatedValuesArray[offset];
        elementsLeftToReplace.splice(offset, 1);
        if (Array.isArray(substituteValue)) return `:${substituteValue.filter(item => !!item).join(':')}`;
        return substituteValue ? `:${substituteValue}` : '';
      });

      // if there are more values than subtitutions to be made concats values in the end of label
      if (elementsLeftToReplace.length > 0) {
        returnLabel += elementsLeftToReplace.join(':') ? `:${elementsLeftToReplace.join(':')}` : '';
      }
    } else if (!receivedValues) {
      // if value is falsy remove substitution notation on returnLabel
      returnLabel = eventLabel.replace(/:{(\d+)}/g, '');
    } else {
      // removes value placement indicators and concats value in the end
      returnLabel = `${eventLabel.replace(/:{(\d+)}/g, '')}:${toUpperCamelCase(receivedValues)}`;
    }

    return returnLabel;
  };

  const collection = {
    events: {
      category,
      action,
      label: formatCollectionLabel(label, value),
    },
  };

  if (valueOnlyNumber) collection.eventValue = valueOnlyNumber;

  return collection;
};

export { getLog };
