// eslint-disable-next-line import/no-unresolved
import Banner from './components/Banner/index';

export default {
  PRODUCTS: {
    ODONTO: {
      BANNER: Banner,
    },
  },
};
