import { createSelector } from 'reselect';

export const httpErrorsSelector = state => state.httpErrors;

export const httpErrorsErrorSelector = createSelector(httpErrorsSelector, item => item.hasError);

export const httpErrorsDisableGlobalErrorSelector = createSelector(httpErrorsSelector, item => item.disableGlobalError);

export const configSelector = createSelector(httpErrorsSelector, httpErrors => httpErrors.config);

export const statusCodeSelector = createSelector(httpErrorsSelector, httpErrors => httpErrors.statusCode);

export const isResidInsuranceBrokerageUnavailable = createSelector(
  configSelector,
  statusCodeSelector,
  (config, statusCode) => {
    const url = (config || {}).url || '';
    if (statusCode === 500 || statusCode === 504) {
      return url.indexOf('/v1/mkt-seguros-e2e/simulacoes/assistencias') !== -1;
    }
    return false;
  },
);

export const isInsuranceBrokerageUnavailable = createSelector(
  isResidInsuranceBrokerageUnavailable,
  residInsuranceBrokerageUnavailable => residInsuranceBrokerageUnavailable,
);
