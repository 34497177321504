import {
  AnalyticsPageLoadEventInterface,
  AnalyticsPageLoadEventPageInterface,
} from 'interfaces/analytics/AnalyticsPageLoadEvent.interface';
import {
  ANALYTICS_EVENT_RULES,
  ANALYTICS_EVENT_BUSINESS,
  ANALYTICS_EVENT_CHANNEL_TYPES,
  ANALYTICS_EVENT_IDPF,
  ANALYTICS_EVENT_SEGMENTS,
} from 'trackingV2/constants';

export const assembleAnalyticsPageLoadEvent = (
  analyticsPageLoadEventPage: AnalyticsPageLoadEventPageInterface,
): AnalyticsPageLoadEventInterface => ({
  site: {
    negocio: ANALYTICS_EVENT_BUSINESS.NATURAL_PERSON,
    tipoDeCanal: ANALYTICS_EVENT_CHANNEL_TYPES.WEB,
  },
  page: analyticsPageLoadEventPage,
  visitor: {
    iDPF: ANALYTICS_EVENT_IDPF.DYNAMIC_VALUE,
    segmento: ANALYTICS_EVENT_SEGMENTS.VUP,
  },
  rule: ANALYTICS_EVENT_RULES.PAGE_LOAD,
});
