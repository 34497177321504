/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import config from '../../../../config';
import { useStyles } from './pageStyles';
import { getSessionStorage } from '../../../../utils/util';
import { getTextByOrigin } from '../../utils/util';
import CreditCard from '../../../../components/CreditCard/CreditCard';
import creditcardlogo from '../../../../assets/images/credit-card-logo.png';
import creditcard from '../../../../assets/images/credit-card.png';
import { CREDICARD } from '../../../../utils/url';

const { DISTRIBUTION_KEY } = config;

const CredicardPayment = ({ form, fields, paymentType, onChangePaymentType, theme }) => {
  const styles = useStyles();

  const showContainerSelected = () =>
    paymentType === CREDICARD ? `${styles.credicard} ${styles.credicardSelected}` : styles.credicard;

  const buttonPressed = () => paymentType === CREDICARD;

  const showRadioSelected = () =>
    paymentType === CREDICARD
      ? `${styles.credicardCheckBox} ${styles.credicardCheckBoxSelected}`
      : styles.credicardCheckBox;

  return (
    // TODO REGRA DE NEGÓCIO 
    getSessionStorage(DISTRIBUTION_KEY) === CREDICARD && (
      <div className={styles.credicardBox}>
        <h2 className={styles.header}>
          {i18next.t(getTextByOrigin(theme, 'CREDICARD_PAYMENT.OPTION'))}&nbsp;
          <span className={styles.keyword}>{i18next.t(getTextByOrigin(theme, 'CREDICARD_PAYMENT.NAME'))}</span>
        </h2>
        <div
          className={showContainerSelected()}
          tabIndex={0}
          role="checkbox"
          aria-checked={buttonPressed()}
          onClick={onChangePaymentType}
          onKeyDown={onChangePaymentType}
        >
          <div className={styles.credicardImg}>
            <img src={creditcard} alt="Cartões Credicard" aria-hidden />
          </div>
          <div className={styles.credicardWrapper}>
            <div className={styles.credicardInfo}>
              <div className={styles.iconCard}>
                <img src={creditcardlogo} alt="Credicard" className={styles.credicardSpacing} aria-hidden />
                <div className={showRadioSelected()} />
              </div>
              <p>
                <Trans i18nKey={getTextByOrigin(theme, 'CREDICARD_PAYMENT.ADVERTISEMENT')} />
              </p>
            </div>
          </div>
        </div>
        {paymentType === CREDICARD && (
          <div>
            <CreditCard fields={fields} form={form} />
            <p aria-label={i18next.t(getTextByOrigin(theme, 'CREDICARD_PAYMENT.WARNING_ACCESSIBILITY'))}>
              {i18next.t(getTextByOrigin(theme, 'CREDICARD_PAYMENT.WARNING'))}
            </p>
          </div>
        )}
      </div>
    )
  );
};

CredicardPayment.propTypes = {
  fields: PropTypes.object,
  form: PropTypes.object,
  paymentType: PropTypes.object,
  onChangePaymentType: PropTypes.func,
  theme: PropTypes.string,
};

export default CredicardPayment;
