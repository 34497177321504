/* eslint-disable react/no-deprecated */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { makeId, markdownToString } from '../../utils/util';
import './_Dropdown.scss';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();

    this.state = {
      expanded: Boolean(this.props.expanded),
    };
  }

  componentWillUnmount() {
    this.setState({
      expanded: false,
    });
  }

  componentDidUpdate(prevProps) {
    const { content } = this.props;
    if (
      prevProps.content &&
      prevProps.content.props.children &&
      content.props.children &&
      prevProps.content.props.children.toString() !== content.props.children.toString()
    ) {
      this.setState({
        expanded: false,
      });
    }
  }

  componentWillReceiveProps() {
    try {
      if (
        this.props.expanded &&
        (this.props.render || this.props.content.props.children[0].props.children[0].props.children.length > 1)
      ) {
        this.itemRef.current.style.maxHeight = `${this.itemRef.current.scrollHeight}px`;
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  componentDidMount() {
    const { expanded } = this.props;
    if (expanded) {
      this.setHeightValue(this.itemRef.current);
    }
  }

  handleChange = () => {
    const { onClick } = this.props;
    const { expanded } = this.state;
    if (onClick && typeof onClick === 'function') {
      onClick(expanded);
    }

    this.setState({
      expanded: !this.state.expanded,
    });
    this.setHeightValue(this.itemRef.current);
  };

  setHeightValue = element => {
    if (element.style.maxHeight) {
      element.style.maxHeight = null;
    } else {
      element.style.maxHeight = `100%`;
    }
  };

  render() {
    const { expanded } = this.state;
    const {
      title,
      expandedTitle,
      content,
      cssPai,
      id,
      context,
      page,
      style,
      className,
      classNameArrow,
      classNameRoot,
      render,
      arrowInverted,
      dataTestId,
    } = this.props;
    const BUTTON_CLASS = expanded ? 'active' : 'inactive';

    const customId = context && page && id ? `btn${context}_screen_${page}_${id}` : makeId(12);

    return (
      <div className={`${classNameRoot} Dropdown`}>
        <button
          aria-expanded={!!expanded}
          onClick={this.handleChange}
          id={customId}
          aria-label={markdownToString(expanded ? expandedTitle || title : title)}
          className={`${className} collapse-button ${BUTTON_CLASS}`}
          style={style}
          data-testid={dataTestId}
        >
          {arrowInverted && (
            <i
              className={`${classNameArrow} arrow-icon ${
                !expanded ? 'icon-itaufonts_seta_achatada' : 'icon-itaufonts_seta_achatada_up'
              }`}
            />
          )}

          <div className="button-left-content-wrapper" aria-hidden="true">
            <div className="button-label">
              <span className="button-title"> {expanded ? expandedTitle || title : title}</span>
            </div>
          </div>
          {!arrowInverted && (
            <i
              className={`${classNameArrow} arrow-icon ${
                !expanded ? 'icon-itaufonts_seta_achatada' : 'icon-itaufonts_seta_achatada_up'
              }`}
            />
          )}
        </button>

        <div
          aria-hidden={!expanded}
          className={`${cssPai} collapse-content-wrapper ${BUTTON_CLASS}`}
          ref={this.itemRef}
          style={{ visibility: !expanded ? 'hidden' : 'visible' }}
        >
          {expanded && content}
          {render && render(expanded)}
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  content: PropTypes.element,
  render: PropTypes.func,
  cssPai: PropTypes.string, // remover essa prop quando refatorar o menu de ajuda em mobile (classe vinda do DropDownNavMenu evita que o Dropdown pule)
  title: PropTypes.string,
  expandedTitle: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.string,
  classNameRoot: PropTypes.string,
};

export default Dropdown;
