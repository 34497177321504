import { withStyles } from '@material-ui/core';
import NavigateFormButtons from 'components/Button/NavigateFormButtons';

export const StyledNavigationButtons = withStyles(theme => ({
  root: {
    width: '50%',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      margin: '1.5rem 0 0 0',
      float: 'none',
      width: '100%',
    },
  },
  button: {
    fontSize: '1rem !important',
    width: '100% !important',
    padding: '0 !important',
    display: 'block !important',
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
}))(NavigateFormButtons);
