import axios from 'axios';

import config, { performance } from 'config';

const { E2E_PATH } = config;

const assistanceSimulation = async payload => {
  const trace = performance.trace('postAssistanceSimulations');
  trace.start();
  const { data } = await axios.post(`${E2E_PATH}/simulacoes/assistencias`, payload);
  trace.stop();
  return data;
};

export default assistanceSimulation;
