/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import { Field } from 'formik';
import _uniqueId from 'lodash/uniqueId';
import Grid from '@material-ui/core/Grid';
import { StyledRadioGroup, useStyles } from './RadioFormItem.styles';
import FormErrors from '../../formErrors';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import { callFunction } from '../../../../utils/util';

const RadioWrapper = ({
  label,
  name,
  form,
  status,
  hint,
  options,
  errorMessage,
  value,
  error,
  required,
  ariaLabel,
  className,
  helperText,
  onChange,
  horizontalOrientation,
  nestedQuestionSpacing,
  columnContainer,
  labelLayout,
  optionsLayout,
  classNameWrapper,
}) => {
  const radioId = _uniqueId('radioId-');
  const styles = useStyles();
  const labelId = `labelId${label.replace(/ /g, '')}`;
  const handleSelectOne = (data, changeHandler) => {
    if (!data) {
      form.setFieldValue(name, undefined);
      return;
    }
    form.setFieldValue(name, data.target.value);
    if (changeHandler) {
      changeHandler(data, name);
    }
  };
  const handleStyledClassName = (haveHorizontalOrientation, spacing) => {
    const myStyles = [];
    if (haveHorizontalOrientation) {
      myStyles.push(styles.radioGroupNoWrap);
    } else {
      myStyles.push(styles.radioGroup);
    }
    if (spacing) {
      myStyles.push(styles.nestedQuestionSpacing);
    }
    myStyles.push('radioGroup');
    return myStyles;
  };

  return (
    <>
      {status && <FormHelperText className={styles.error}>{status.message}</FormHelperText>}
      <span id={labelId} hidden>
        {`${label}`}
      </span>
      <div className={`${styles.wrapper} ${className}`}>
        <FormControl component="fieldset" className={`${styles.formControl} formControl`} error={error}>
          <Grid container direction={columnContainer ? 'column' : 'row'} xs={12}>
            <Grid item md={columnContainer ? 12 : 4} alignItems="center" {...labelLayout}>
              {label && (
                <FormLabel
                  component="legend"
                  aria-label={ariaLabel}
                  id={`radioFormItemLabel_${name}`}
                  className={`${className} ${styles.inputLabel} label`}
                  required={required}
                >
                  {label}
                </FormLabel>
              )}
            </Grid>
            <Grid container md={8} alignItems="center" {...optionsLayout}>
              <StyledRadioGroup
                id={radioId}
                name={name}
                className={handleStyledClassName(horizontalOrientation, nestedQuestionSpacing)}
                aria-labelledby={label ? `radioFormItemLabel_${name}` : null}
                aria-required={required}
                onChange={data => handleSelectOne(data, onChange)}
                error={!!FormErrors.displayableError(form, name, errorMessage)}
                helperText={FormErrors.displayableError(form, name, errorMessage) || hint}
                required={required}
                row
              >
                {options.map((option, index) => (
                  <RadioButton
                    key={index}
                    className={classNameWrapper || styles.radioButton}
                    option={option}
                    checked={value() === option.value}
                    id={`radioBtn_${name}_${index}`}
                  />
                ))}
              </StyledRadioGroup>
            </Grid>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </Grid>
        </FormControl>
        {status && <i aria-hidden className={`${styles.errorIcon} icon-itaufonts_exclamacao`} />}
      </div>
    </>
  );
};

RadioWrapper.propTypes = {
  label: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  form: PropTypes.array.isRequired,
  status: PropTypes.string,
  hint: PropTypes.string,
  options: PropTypes.string,
  errorMessage: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.func,
  required: PropTypes.string,
  ariaLabel: PropTypes.bool,
  className: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.string,
  horizontalOrientation: PropTypes.bool,
  id: PropTypes.string,
  nestedQuestionSpacing: PropTypes.bool,
  columnContainer: PropTypes.bool,
  labelLayout: PropTypes.object,
};

class RadioFormItemNotFast extends Component {
  value = () => {
    const { form, name, options } = this.props;

    if (form.values[name]) {
      // eslint-disable-next-line eqeqeq
      return options.find(option => option.value == form.values[name])?.value;
    }
    return '';
  };

  handleSelectOne = (data, onChange) => {
    const { form, name, field } = this.props;

    if (!data) {
      form.setFieldValue(name, undefined);
      return;
    }
    form.setFieldValue(name, data.target.value);
    form.setFieldTouched(name);
    if (onChange) {
      callFunction(onChange, data, name);
    }
    if (field.onChange) callFunction(field.onChange, data);
  };

  render() {
    const {
      label,
      name,
      form,
      hint,
      options,
      errorMessage,
      className,
      required,
      ariaLabel,
      onChange,
      nestedQuestionSpacing,
      horizontalOrientation,
      columnContainer,
      labelLayout,
      id,
      optionsLayout,
      classNameWrapper,
    } = this.props;
    return (
      <RadioWrapper
        label={label}
        className={className}
        classNameWrapper={classNameWrapper}
        name={name}
        form={form}
        ariaLabel={ariaLabel}
        error={!!FormErrors.displayableError(form, name, errorMessage)}
        onChange={data => this.handleSelectOne(data, onChange)}
        helperText={FormErrors.displayableError(form, name, errorMessage) || hint}
        options={options}
        required={required}
        value={this.value}
        labelLayout={labelLayout}
        horizontalOrientation={horizontalOrientation}
        columnContainer={columnContainer}
        optionsLayout={optionsLayout}
        id={id}
        nestedQuestionSpacing={nestedQuestionSpacing}
      />
    );
  }
}

RadioFormItemNotFast.defaultProps = {
  required: false,
};

RadioFormItemNotFast.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  nestedQuestionSpacing: PropTypes.bool,
  columnContainer: PropTypes.bool,
  id: PropTypes.string,
  labelLayout: PropTypes.object,
  horizontalOrientation: PropTypes.bool,
};

function RadioFormItem(props) {
  const { name } = props;
  return (
    <Field name={name} render={({ form, field }) => <RadioFormItemNotFast {...props} field={field} form={form} />} />
  );
}

export default RadioFormItem;
