import ROUTES from 'routes/routes';

const HEADER = {
  PRODUCTS: [
    {
      ID: 'lnkInsuranceAuto_componentHeader-desktop',
      TEXT: 'seguro auto',
      LINK: ROUTES.LANDING_AUTO,
    },
    {
      ID: 'lnkInsuranceResid_componentHeader',
      TEXT: 'seguro residencial',
      LINK: ROUTES.LANDING_RESID,
    },
    {
      ID: 'lnkPlanOdonto_componentHeader',
      TEXT: 'plano odontológico',
      LINK: ROUTES.LANDING_ODONTO,
    },
  ],
  HELP_ITEMS: [
    {
      TITLE: 'dúvidas sobre seguros',
      CONTENT_ITEM: [
        {
          TITLE: 'seguro auto',
          LINK: 'Auto',
        },
        {
          TITLE: 'seguro residencial',
          LINK: 'Residencial',
        },
        {
          TITLE: 'plano odontológico',
          LINK: 'Odonto',
        },
      ],
    },
    {
      TITLE: 'central de atendimento',
      CLASS: 'central-atendimento',
      CONTENT_ITEM: [
        {
          TITLE: 'capitais e regiões metropolitanas',
            TEL: '4090 1125',
            TIME: '2ª a 6ª das 8h às 20h',
        },
        {
          TITLE: 'demais regiões',
          TEL: '0800 791 1125',
          TIME: '2ª a 6ª das 8h às 20h',
        },
        {
          TITLE: 'sac',
          TEL: '0800 728 0728',
          TIME: 'Todos os dias, 24 hrs por dia',
        },
        {
          TITLE: 'deficiente auditivo / fala',
          TEL: '0800 722 1722',
          TIME: '2ª a 6ª das 8h às 20h',
        },
        {
          TITLE: 'ouvidoria',
          TEL: '0800 570 0011',
          TIME: '2ª a 6ª das 9h às 18h',
        },
      ],
    },
  ],
};

export default HEADER;
