/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
import { isString } from 'lodash';
import * as yup from 'yup';
import GenericField from './genericField';

export default class EnumeratorField extends GenericField {
  constructor(
    name,
    label,
    {
      options = [],
      nestedQuestionSpacing,
      type = 'select',
      required = false,
      requiredMessage = undefined,
      oneOf = undefined,
      ariaLabel = undefined,
      test = undefined,
      horizontalOrientation = false,
      columnContainer = false,
      id = undefined,
      tooltipProps = {},
      describeCurrentValue = '',
      placeholder = undefined,
      product = undefined,
    } = {},
  ) {
    super(name, label);
    this.options = options;
    this.required = required;
    this.requiredMessage = requiredMessage;
    this.oneOf = oneOf;
    this.ariaLabel = ariaLabel;
    this.test = test;
    this.type = type;
    this.horizontalOrientation = horizontalOrientation;
    this.columnContainer = columnContainer;
    this.id = id;
    this.tooltipProps = tooltipProps;
    this.describeCurrentValue = describeCurrentValue;
    this.nestedQuestionSpacing = nestedQuestionSpacing;
    this.placeholder = placeholder;
    this.product = product;
  }

  _id(option) {
    if (!option) {
      return option;
    }

    if (isString(option)) {
      return option;
    }

    return option.value;
  }

  _label(option) {
    if (!option) {
      return option;
    }

    if (isString(option)) {
      return option;
    }

    return option.text;
  }

  forView(value) {
    const option = this.options.find(option => option.id === this._id(value));

    if (option) {
      return this._label(option);
    }

    return value;
  }

  forFormInitialValue(value) {
    return this._id(value);
  }

  forForm() {
    let yupChain = yup
      .string()
      .nullable(true)
      .label(this.label);

    if (this.required) {
      yupChain = yupChain.required(this.requiredMessage);
    }

    if (this.oneOf) {
      yupChain = yupChain.oneOf(this.oneOf);
    }

    if (this.test) {
      this.test.forEach(testIndex => {
        yupChain = yupChain.test(testIndex);
      });
    }

    return yupChain;
  }
}
