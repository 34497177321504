import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { useStyles } from './RadioCard.styles';

const RadioCard = ({ label, image, selected, onClick, value, name, id }) => {
  const styles = useStyles();

  const handleKeyPress = e => {
    const keyCode = e.which || e.keyCode;
    e.preventDefault();
    if (keyCode === 13 || keyCode === 32) onClick(value);
  };

  return (
    <Paper
      id={id}
      className={`${styles.card} ${selected ? styles.selected : ''}`}
      onClick={() => onClick(value)}
      onKeyPress={e => handleKeyPress(e)}
      tabIndex="0"
      aria-checked={selected}
      role="radio"
    >
      <Grid container justify="center" onClick={() => onClick({ target: { value } })} name={name}>
        <Grid container aria-hidden justify="center">
          <img className={styles.image} alt={label} src={image} />
        </Grid>
        <Grid container justify="center" className={styles.textContainer}>
          <span>{label}</span>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RadioCard;
