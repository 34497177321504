import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * A simple component that render a visually hidden element with a alert role, forcing the screen reader tool to say
 * something important just one time. The need suggested when we identified that the createPortal React function
 * (used to display modals) forces all alerts to play again. For accessibility porpose.
 * @param {String} alertMessage The message to be said.
 * @returns {JSX|false}
 */
function OneTimeAriaAlert({ alertMessage, type }) {
  const [oneTimeAriaAlert, setOneTimeAriaAlert] = useState(true);

  useEffect(() => {
    /** Yes, the element is destroyed right when it mounts. We just need the screen reader to alert a message. */
    setTimeout(() => setOneTimeAriaAlert(false), 1000);
  }, []);
  return (
    oneTimeAriaAlert && (
      <p className="text-visually-hidden" aria-live={type} aria-atomic="true" role="alert">
        {alertMessage}
      </p>
    )
  );
}

OneTimeAriaAlert.defaultProps = {
  type: 'assertive',
};

OneTimeAriaAlert.propTypes = {
  alertMessage: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default OneTimeAriaAlert;
