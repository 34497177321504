const OBFUSCATOR_TYPE = {
  EMAIL: 1,
  CELLPHONE: 2,
  CPF: 3,
};
Object.freeze(OBFUSCATOR_TYPE);

const PRODUCT_NAMES_ODONTO = {
  METLIFE_BASIC: 'Plano Odonto Metlife',
  METLIFE_GOLD: 'Plano Odonto Metlife Gold',
  METLIFE_DIAMOND: 'Plano Odonto MetLife Diamond',
};

Object.freeze(PRODUCT_NAMES_ODONTO);

const PRODUCT_TYPE = {
  odonto: 'odonto',
  resid: 'resid',
  auto: 'auto',
  realiza: 'realiza',
  bemEstar: 'bem-estar',
  pet: 'pet',
};

Object.freeze(PRODUCT_TYPE);

const BIKE_COVERAGE = {
  id: '00e33efe-a32b-419e-b0c3-6e719b6bf1cc',
  identificador: 'sub_bike',
};

const PAYMENT_METHODS = {
  creditCard: 'credit_card',
  portoCreditCard: 'credit_card_porto',
  directDebit: 'direct_debit',
};

const MINIMUM_SELECTED_COVERAGES_FOR_COMPLETE_ASSISTANCES = 4;

Object.freeze(BIKE_COVERAGE);

const TOTAL_COVERAGE = {
  id: '456ae936-3777-4c0b-882f-0521bbcbc757',
};

Object.freeze(TOTAL_COVERAGE);

const DEFAULT_ASSISTANCE = {
  value: 'Guincho',
};

Object.freeze(DEFAULT_ASSISTANCE);

export {
  PRODUCT_TYPE,
  PRODUCT_NAMES_ODONTO,
  OBFUSCATOR_TYPE,
  BIKE_COVERAGE,
  MINIMUM_SELECTED_COVERAGES_FOR_COMPLETE_ASSISTANCES,
  PAYMENT_METHODS,
  TOTAL_COVERAGE,
  DEFAULT_ASSISTANCE,
};
