import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiSlider from '@material-ui/core/Slider';
// eslint-disable-next-line import/no-extraneous-dependencies
import palette from 'theme/palette';

const useStyles = makeStyles(theme => ({
  input: {
    '&:[type="range"]': {
      '-webkit-appearance': 'none',
      backgroundColor: 'transparent',
      position: 'absolute',
      top: 16,
      width: '101%',
      zIndex: '1000',
    },
    '&:focus': {
      outlineColor: theme.palette.text.link,
    },
  },
  containerText: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.toRem(40),
    },
  },
  text: {
    marginBottom: 0,
    fontSize: theme.toRem(14),
    color: palette.inputLabelColor,
    lineHeight: theme.toRem(16),
    fontFamily: 'ItauText-Regular',
    fontWeight: '400',
    margin: 0,
  },
  icon: {
    color: palette.inputLabelColor,
  },
}));

const Slider = withStyles(() => ({
  root: {
    width: '100%',
    height: 6,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'white',
  },
  mark: {
    height: 6,
    backgroundColor: 'white',
    borderRadius: 0,
  },
  thumb: {
    display: 'none',
  },
  track: {
    height: 6,
  },
  rail: {
    height: 6,
  },
}))(MuiSlider);

export { useStyles, Slider };
