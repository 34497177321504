import palette from '../palette';

export default {
  root: {
    '&$focused': {
      color: palette.defaultTextColor,
    },
    '&$error': {
      color: palette.defaultTextColor,
    },
  },
  asterisk: {
    color: palette.defaultTextColor,
    fontSize: 16,
  },
};
