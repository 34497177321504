import axios from 'axios';

import config, { performance } from '../../config';

const { CEP_PATH } = config;

export const getCep = async (cep) => {
  const trace = performance.trace('getCep');
  trace.start();

  const response = await axios.get(`${CEP_PATH}?cep=${cep}`, {
    disableException: true,
  });

  trace.stop();
  return response.data;
};
