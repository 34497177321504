import axios from 'axios';

import config, { performance } from '../../../../config';

const { E2E_ODONTO_PATH } = config;

export const getStates = async () => {
  const trace = performance.trace('getStates');
  trace.start();
  const response = await axios.get(`${E2E_ODONTO_PATH}/estados`);
  trace.stop();
  return response.data.map(state => ({
    value: state.id_uf,
    label: state.nome,
  }));
};
