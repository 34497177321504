import axios from 'axios';

import config from 'config';

const { E2E_PATH, ITAU_GATEWAY_HOST } = config;

export const getSurveyDate = async (cep, parceiro, hash) => {
  const baseURL = ITAU_GATEWAY_HOST;
  const response = await axios.get(
    `${E2E_PATH}/automovel/vistoria/domiciliar?id_parceiro=${parceiro}&cep=${cep}`,
    {
      headers: {
        'x-inspection-hash': hash,
      },
      baseURL,
    },
  );
  return response.data;
};
