import axios from 'axios';
import config, { performance } from 'config';

const { E2E_AUTH_PATH } = config;

export const authenticationV2 = async (payload, scope) => {
  const trace = performance.trace('authenticationV2');
  trace.start();

  const { data } = await axios.post(`${E2E_AUTH_PATH}/autenticacao`, payload, {
    headers: {
      'x-product-name': scope,
    },
  });

  trace.stop();
  return data;
};
