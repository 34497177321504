import axios from 'axios';

import config, { performance } from '../../config';

const { GATEWAY_BFF_BASE_URL } = config;

export const deleteCarts = async data => {
  const trace = performance.trace(data?.trace);
  trace.start();
  const response = await axios.post('abandonedcart/finalizecart', data, {
    disableException: true,
    baseURL: GATEWAY_BFF_BASE_URL
  });

  trace.stop();
  return response;
};

export const deleteResidCart = async data => {
  const trace = performance.trace('deleteResidCart');
  trace.start();
  const response = await axios.post('abandonedcart/finalizecart', data, {
    disableException: true,
    baseURL: GATEWAY_BFF_BASE_URL
  });

  trace.stop();
  return response;
};
