import { call, put } from 'redux-saga/effects';
import config from 'config';
import { setError, setErrorSagaAction } from 'store/ducks/httpErrors';
import { fetchVehicle } from 'api/E2E/Auto/fetchVehicle';
import { getCheckBond } from 'api/E2E/Auto/getCheckBond';
import { resetBlockFlow, setManualVehicleSelect, Types } from 'store/auto/store/ducks/autoFlow';
import { addCPFData, addLicensePlate, updateVehicle } from 'store/auto/store/ducks/automovel';
import { createSearchVehicle } from 'store/auto/store/ducks/searchVehicle';
import { showModal } from 'store/auto/store/ducks/modal';

const { SINESP_API_ERROR } = config;

function* handleCheckBond(value, licensePlate, cpf) {
  try {
    const responseCheckBond = yield call(getCheckBond, cpf);
    const checkbondData = {
      ...responseCheckBond,
      placa: licensePlate,
    };
    const { vinculo, renovacao } = responseCheckBond;
    if (vinculo) {
      yield put(showModal(true, value.data.existentContractModalComponent(renovacao, licensePlate), true));
    } else {
      value.data.handleNotBondCallback();
    }
    yield put({ type: Types.SUCCESS_INIT_QUOTATION, data: checkbondData });
  } catch (err) {
    yield put(setErrorSagaAction(value));
    yield put(setError());
  }
}

function* handleIgnoreLiscensePlate(value, licensePlate, cpf) {
  yield put(setManualVehicleSelect(true));
  yield handleCheckBond(value, licensePlate, cpf);
}

export default function* setInitialHiringData(value) {
  const { cpf, licensePlate, zeroKm } = value.data.request;
  yield put(resetBlockFlow());
  yield put({ type: Types.LOAD_INIT_QUOTATION });
  yield put(updateVehicle({ novo: !!zeroKm }));
  yield put(addLicensePlate(zeroKm ? undefined : licensePlate));
  if (!zeroKm) {
    try {
      const response = yield call(fetchVehicle, { placa: licensePlate });
      if (response.placa) {
        yield put(setManualVehicleSelect(false));
        yield handleCheckBond(value, licensePlate, cpf);
        yield put(createSearchVehicle(response));
      } else if (SINESP_API_ERROR) {
        yield handleIgnoreLiscensePlate(value, licensePlate, cpf);
      } else {
        yield put(showModal(true, value.data.plateNotFoundModalComponent(), true));
        yield put({ type: Types.ERROR_INIT_QUOTATION });
      }
    } catch (err) {
      if (SINESP_API_ERROR) {
        yield handleIgnoreLiscensePlate(value, licensePlate, cpf);
      } else {
        yield put(setError());
      }
    }
  } else {
    yield handleCheckBond(value, licensePlate, cpf);
    yield put(setManualVehicleSelect(true));
  }
  yield put(addCPFData(cpf));

}
