export const Types = {
  CALL_ELIGIBILITY: 'eligibility/CALL_ELIGIBILITY',
  SET_ELIGIBILITY: 'eligibility/SET_ELIGIBILITY',
  RESET_ELIGIBILITY: 'eligibility/RESET_ELIGIBILITY',
  SET_ERROR: 'eligibility/SET_ERROR',
};

export const initialState = {
  isBlocked: null,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_ELIGIBILITY: {
      return {
        ...state,
        isBlocked: action.data,
        loading: false,
      };
    }
    case Types.CALL_ELIGIBILITY: {
      return {
        ...state,
        isBlocked: null,
        loading: true,
      };
    }
    case Types.RESET_ELIGIBILITY: {
      return initialState;
    }
    default:
      return state;
  }
}

export function setEligibility(data) {
  return {
    type: Types.SET_ELIGIBILITY,
    data,
  };
}

export function callEligibility(data) {
  return {
    type: Types.CALL_ELIGIBILITY,
    data,
  };
}

export function resetEligibility() {
  return {
    type: Types.RESET_ELIGIBILITY,
  };
}
