import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import InfoCard from './InfoCard';
import { useStyles } from './InfoCardList.styles';

function InfoCardList({ options = [], answers, fixedHeight = '60px', selectedIndex, linkRef, onClickCardLink }) {
  const styles = useStyles();

  const filteredOptions = options.filter(option => {
    if (!option?.derivesFromAnswer) return option;

    const derivedAnswer = answers.find(answer => answer.pageId === option.derivesFromAnswer.pageId).answer;
    return option.derivesFromAnswer.answerValue === derivedAnswer;
  });

  return (
    <Grid container direction="row" spacing={5} className={styles.container}>
      {filteredOptions?.map((item, index) => (
        <InfoCard
          key={index}
          cardNo={index + 1}
          linkRef={selectedIndex === index ? linkRef : undefined}
          title={item.title}
          information={item.information}
          details={item.details}
          nextDetails={item.nextDetails}
          fixedHeight={fixedHeight}
          onClickCardLink={onClickCardLink}
        />
      ))}
    </Grid>
  );
}

InfoCardList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      information: PropTypes.string,
      details: PropTypes.string,
      analytics: PropTypes.func,
      nextDetails: PropTypes.func,
    }),
  ),
  answers: PropTypes.array,
  fixedHeight: PropTypes.string,
  onClickCardLink: PropTypes.string,
};

export default InfoCardList;
