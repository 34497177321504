export const Types = {
  SET_BENEFICIARY_ID: 'beneficiaryForm/SET_BENEFICIARY_ID',
};

export const initialState = {
  beneficiaryId: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_BENEFICIARY_ID: {
      return {
        ...state,
        beneficiaryId: action.data,
      };
    }
    default:
      return state;
  }
}

export function setBeneficiaryId(data) {
  return {
    type: Types.SET_BENEFICIARY_ID,
    data,
  };
}
