import { makeStyles, withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tooltipIcon: {
    fontSize: theme.toRem(17),
    color: theme.palette.black,
    margin: 0,
  },
}));

export const DefaultTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    fontSize: theme.toRem(16),
  },
  popper: {
    opacity: 0.9,
  },
}))(Tooltip);

export default useStyles;
