import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import OneTimeAriaAlert from 'components/OneTimeAriaAlert';
import PropTypes from 'prop-types';
import useStyles, { DarkbackgroundTooltip } from './DarkTooltip.styles';

const DarkTooltip = ({ title, content, placement, darkIcon, darkBackground }) => {
  const styles = useStyles({ darkIcon });
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {isOpen && <OneTimeAriaAlert alertMessage={content} />}
      <DarkbackgroundTooltip
        darkBackground={darkBackground}
        className={styles.tooltip}
        enterTouchDelay={0}
        leaveDelay={1500}
        disableHoverListener
        open={isOpen}
        title={content}
        placement={placement}
      >
        <IconButton
          onClick={() => setOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-label={title}
          className={styles.tooltipIcon}
        >
          <i className="icon-itaufonts_full_informacao" />
        </IconButton>
      </DarkbackgroundTooltip>
    </>
  );
};

DarkTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  darkIcon: PropTypes.bool,
  darkBackground: PropTypes.bool,
};

DarkTooltip.defaultProps = {
  darkIcon: false,
  darkBackground: false,
};

export default DarkTooltip;
