import axios from 'axios';

import config, { performance } from '../../../../config';

const { E2E_PATH } = config;

export const getCoverages = async () => {
  const trace = performance.trace('getCoverages');
  trace.start();
  const response = await axios.get(`${E2E_PATH}/ofertas/residencial`);
  trace.stop();
  return response.data;
};
