/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
import React from 'react';
import { isPristine, isLoading } from '@zup-next/redux-resource';

export default function(WrappedComponent) {
  return class extends React.Component {
    componentDidMount() {
      const functions = Object.entries(this.props).filter(
        ([key, value]) => typeof value === 'function' && key.includes('load'),
      );
      functions.forEach(f => {
        f[1]();
      });
    }

    isObjectZupNext = obj =>
      obj.hasOwnProperty('create') &&
      obj.hasOwnProperty('data') &&
      obj.hasOwnProperty('load') &&
      obj.hasOwnProperty('remove') &&
      obj.hasOwnProperty('update');

    render() {
      const properties = Object.keys(this.props).reduce((object, key) => {
        if (key !== 'cities') {
          object[key] = this.props[key];
        }
        return object;
      }, {});
      const stateProps = Object.values(properties).filter(v => typeof v === 'object' && this.isObjectZupNext(v));
      const pristine = stateProps.some(isPristine);
      const loading = stateProps.some(isLoading);
      if (pristine) return null;
      if (loading) return null;

      return <WrappedComponent {...this.props} />;
    }
  };
}
