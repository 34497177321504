import { put, call } from 'redux-saga/effects';
import { getOffersQuestions } from 'api/E2E/Auto/questions';
import { parseApi } from 'utils/GetOfferQuestionsParser';
import { Types } from 'store/auto/store/ducks/questions';

export default function* questionsLoading() {
  const response = yield call(getOffersQuestions, 41);
  yield put({ type: Types.CREATE_QUESTIONS, data: response });
  const stateQuestiosParsed = parseApi(response);
  yield put({ type: Types.CREATE_QUESTIONS_PARSED, data: stateQuestiosParsed });
  yield put({ type: Types.SUCCESS_LOAD_QUESTIONS });
}
