import axios from 'axios';

import config, { performance } from '../../../../config';

const { E2E_ODONTO_PATH, ITAU_GATEWAY_HOST } = config;

export const getProductInfo = async (idProduct, idCity, idUF, formatedCity) => {
  const trace = performance.trace('getKinshipValues');
  trace.start();
  const response = await axios.get(
    `${E2E_ODONTO_PATH}/produtos/${idProduct}?id_municipio=${idCity}&uf=${idUF}&municipio_formatado=${formatedCity}`,
    {
      baseURL: ITAU_GATEWAY_HOST,
    },
  );
  trace.stop();
  return response.data;
};
