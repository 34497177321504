/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18n';
import config from 'config';
import ROUTES from 'routes/routes';
import Button from '../../../../../../components/Button/Button';
import { useStylesBanner } from './useStylesBanner';

const Banner = ({ onClick }) => {
  const styles = useStylesBanner();
  const { URL_BUCKET_BASE } = config;
  const residencialPath = '/images/residencial/ilustracoes';

  const onExecute = () => {
    onClick(ROUTES.LANDING_RESID);
  };

  return (
    <div className={styles.banner}>
      <div className="container" style={{ 'justify-content': 'space-between' }}>
        <div className={styles.wrapper}>
          <h1 id="lblTitle_banner_promotionInDoor" className={styles.title}>
            {i18next.t('PROMOTIONINDOOR.BANNER.TITLE')}
          </h1>
          <h2 id="lblSubTitle_banner_promotionInDoor" className={styles.subTitle}>
            {i18next.t('PROMOTIONINDOOR.BANNER.SUBTITLE')}
          </h2>
          <h3 id="lblSubTitle_banner_promotionInDoor" className={styles.deadLine}>
            {i18next.t('PROMOTIONINDOOR.BANNER.DEADLINE')}
          </h3>
          <Button
            id="btnBanner_promotionInDoor"
            text={i18next.t('PROMOTIONINDOOR.BANNER.CTA')}
            page="BannerPromotionInDoor"
            context="Banner"
            onClick={onExecute}
            className={styles.buttonLine}
            classNameContainer="button-group--mt-16"
          />
        </div>
        <div className={styles.wrapperImage}>
          <img
            src={`${URL_BUCKET_BASE}${residencialPath}/10_porcent_full.png`}
            alt="10 porcento"
            aria-hidden
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
};

Banner.prototype = {
  onClick: PropTypes.func,
};

export default Banner;
