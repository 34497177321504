import { put, select } from 'redux-saga/effects';
import { togglePreSelectedCoverage } from 'store/ducks/residencial';
import { residencialCoverageSelector } from '../selectors/residencial';
import { residCoveragesCoverageSelector } from '../selectors/residCoverage';

export default function* onToggleCoverages(action) {
  const {
    data: { coverage },
  } = action;
  try {
    const allCoverages = yield select(residCoveragesCoverageSelector);
    const dependantCoverage = allCoverages.filter(c => c.dependencias.some(d => d === coverage.identificator));
    const { preSelecionadas } = yield select(residencialCoverageSelector);
    const coveragesToUnselect = dependantCoverage.filter(c => preSelecionadas[c.id_cobertura] === true);
    for (const cov of coveragesToUnselect) {
      const { id_cobertura } = cov;
      yield put(togglePreSelectedCoverage(id_cobertura));
    }
    yield put(togglePreSelectedCoverage(coverage.id));
  } catch (err) {
    yield console.error(err);
  }
}
