export type CartCustomer = {
  fullName: string;
  email?: string;
  phone?: string;
  birthDate?: string;
  cpf: string;
};

export type CartAddress = {
  cep: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  country: string;
};

export type CartProduct = {
  title: 'seguro residencial' | 'seguro odontológico' | 'seguro automóvel' | 'assistência pet';
  coverages: {
    title: string;
    description: string;
  }[];
};

type CartContext = 'RESID' | 'ODONTO' | 'AUTO' | 'PET';

export type CreateCartPayload = {
  customer: CartCustomer;
  product: CartProduct;
  context: CartContext;
  address?: CartAddress;
};

export type DeleteCartPayload = {
  cpf: string;
  context: CartContext;
};

export const prepareCreateCartData = (
  customer: CartCustomer,
  product: CartProduct,
  context: CartContext,
  address?: CartAddress
): CreateCartPayload => ({ customer, product, context, address });

export const prepareDeleteCartData = (cpf: string, context: CartContext): DeleteCartPayload => ({
  cpf,
  context,
});
