/* eslint-disable prefer-const */
import { PAYMENT_METHODS } from 'utils/constants';

export const Types = {
  SET_AUTO_FLOW: 'autoFlow/SET_AUTO_FLOW',
  ADD_AUTH_DATA: 'autoFlow/ADD_AUTH_DATA',
  REMOVE_AUTH_DATA: 'autoFlow/REMOVE_AUTH_DATA',
  ERROR_INIT_QUOTATION: 'autoFlow/ERROR_INIT_QUOTATION',
  SUCCESS_INIT_QUOTATION: 'autoFlow/SUCCESS_INIT_QUOTATION',
  LOAD_INIT_QUOTATION: 'autoFlow/LOAD_INIT_QUOTATION',
  RESET_INIT_QUOTATION: 'autoFlow/RESET_AUTO_FLOW',
  RESET_INIT_SIMULATION: 'autoFlow/RESET_INIT_SIMULATION',
  RESET_BLOCK_INITIAL_FLOW: 'autoFlow/RESET_BLOCK_INITIAL_FLOW',
  BLOCK_INITIAL_FLOW: 'autoFlow/BLOCK_INITIAL_FLOW',
  LOAD_INIT_SIMULATE_QUOTATION: 'autoFlow/LOAD_INIT_SIMULATE_QUOTATION',
  ERROR_INIT_SIMULATE_QUOTATION: 'autoFlow/ERROR_INIT_SIMULATE_QUOTATION',
  SUCCESS_INIT_SIMULATE_QUOTATION: 'autoFlow/SUCCESS_INIT_SIMULATE_QUOTATION',
  LOAD_INSPECTION: 'autoFlow/LOAD_INSPECTION',
  ERROR_INSPECTION: 'autoFlow/ERROR_INSPECTION',
  SUCCESS_INSPECTION: 'autoFlow/SUCCESS_INSPECTION',
  SET_INSTALLMENT_OPTION: 'autoFlow/SET_INSTALLMENT_TYPE',
  RESET_INSTALLMENT_OPTION: 'autoFlow/RESET_INSTALLMENT_OPTION',
  REJECT_QUOTATION: 'autoFlow/REJECT_QUOTATION',
  RESET_BY_STEP: 'autoFlow/RESET_BY_STEP',
  RESET_INIT_SIMULATION_ERROR: 'autoFlow/RESET_INIT_SIMULATION_ERROR',
  RESET_ERROR_INIT_QUOTATION: 'autoFlow/RESET_ERROR_INIT_QUOTATION',
  ADD_PAYMENT_TYPE: 'autoFlow/ADD_PAYMENT_TYPE',
  SET_MANUAL_VEHICLE_SELECT: 'autoFlow/SET_MANUAL_VEHICLE_SELECT',
};

const initialState = {
  auth: {
    name: '',
    cpf: '',
    cellphone: '',
    email: '',
  },
  inspection: {
    error: '',
    loading: '',
  },
  manualVehicleSelect: true,
  paymentType: PAYMENT_METHODS.creditCard,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_AUTO_FLOW: {
      return {
        ...action.data,
      };
    }
    case Types.BLOCK_INITIAL_FLOW: {
      return {
        ...state,
        initQuotation: {
          ...state.initQuotation,
          blocked: true,
        },
      };
    }
    case Types.RESET_BLOCK_INITIAL_FLOW: {
      return {
        ...state,
        initQuotation: {
          ...state.initQuotation,
          blocked: false,
        },
      };
    }
    case Types.RESET_INIT_SIMULATION_ERROR: {
      return {
        ...state,
        quotation: {
          ...state.quotation,
          error: false,
        },
      };
    }
    case Types.ADD_AUTH_DATA: {
      return {
        ...state,
        auth: action.data,
      };
    }
    case Types.REMOVE_AUTH_DATA: {
      return {
        ...state,
        auth: {},
      };
    }
    case Types.LOAD_INIT_QUOTATION:
      return {
        ...state,
        initQuotation: {
          loading: true,
          error: false,
          pristine: false,
          blocked: false,
        },
      };
    case Types.ERROR_INIT_QUOTATION:
      return {
        ...state,
        initQuotation: {
          error: true,
          loading: false,
          pristine: false,
          blocked: false,
        },
      };
    case Types.SUCCESS_INIT_QUOTATION:
      return {
        ...state,
        checkBond: action.data,
        initQuotation: {
          loading: false,
          error: false,
          pristine: false,
          blocked: false,
        },
      };
    case Types.RESET_INIT_QUOTATION:
      return {
        ...state,
        initQuotation: {
          pristine: true,
          blocked: false,
        },
        quotation: {
          loading: false,
          error: false,
          rejected: false,
          pristine: true,
        },
      };
    case Types.RESET_INIT_SIMULATION:
      return {
        ...state,
        quotation: {
          error: false,
          loading: false,
          rejected: false,
          pristine: true,
        },
      };
    case Types.LOAD_INIT_SIMULATE_QUOTATION:
      return {
        ...state,
        quotation: {
          loading: true,
          error: false,
          rejected: false,
          pristine: false,
        },
      };
    case Types.ERROR_INIT_SIMULATE_QUOTATION:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          error: true,
          loading: false,
          pristine: false,
        },
      };
    case Types.SUCCESS_INIT_SIMULATE_QUOTATION:
      return {
        ...state,
        quotation: {
          loading: false,
          error: false,
          rejected: false,
          pristine: false,
        },
      };
    case Types.LOAD_INSPECTION:
      return {
        ...state,
        inspection: {
          error: false,
          loading: true,
        },
      };
    case Types.ERROR_INSPECTION:
      return {
        ...state,
        inspection: {
          error: true,
          loading: false,
        },
      };
    case Types.SUCCESS_INSPECTION:
      return {
        ...state,
        inspection: {
          error: false,
          loading: false,
        },
      };
    case Types.SET_INSTALLMENT_OPTION: {
      const { data } = action;
      return {
        ...state,
        opcaoParcelaSelecionada: data,
      };
    }
    case Types.RESET_INSTALLMENT_OPTION: {
      return {
        ...state,
        opcaoParcelaSelecionada: initialState.opcaoParcelaSelecionada,
      };
    }
    case Types.REJECT_QUOTATION: {
      return {
        ...state,
        quotation: {
          ...state.quotation,
          rejected: true,
        },
      };
    }
    case Types.RESET_ERROR_INIT_QUOTATION: {
      return {
        ...state,
        initQuotation: {
          ...state.initQuotation,
          error: initialState.error,
        },
      };
    }
    case Types.ADD_PAYMENT_TYPE: {
      return {
        ...state,
        paymentType: action.data,
      };
    }
    case Types.SET_MANUAL_VEHICLE_SELECT: {
      return {
        ...state,
        manualVehicleSelect: action.data,
      };
    }
    default:
      return state;
  }
}

export function setAutoFlow(data) {
  return {
    type: Types.SET_AUTO_FLOW,
    data,
  };
}

export function setManualVehicleSelect(data) {
  return {
    type: Types.SET_MANUAL_VEHICLE_SELECT,
    data,
  };
}

export function resetBlockFlow() {
  return {
    type: Types.RESET_BLOCK_INITIAL_FLOW,
  };
}

export function setBlockedFlow() {
  return {
    type: Types.BLOCK_INITIAL_FLOW,
  };
}

export function addAuthData(data) {
  return {
    type: Types.ADD_AUTH_DATA,
    data,
  };
}

export function removeAuthData() {
  return {
    type: Types.REMOVE_AUTH_DATA,
  };
}

export function resetAutoFlowQuotation() {
  return {
    type: Types.RESET_INIT_QUOTATION,
  };
}

export function startSimulation() {
  return {
    type: Types.LOAD_INIT_SIMULATE_QUOTATION,
  };
}

export function errorSimulation() {
  return {
    type: Types.ERROR_INIT_SIMULATE_QUOTATION,
  };
}

export function successSimulation() {
  return {
    type: Types.SUCCESS_INIT_SIMULATE_QUOTATION,
  };
}

export function resetAutoFlowSimulation() {
  return {
    type: Types.RESET_INIT_SIMULATION,
  };
}

export function resetAutoFlowSimulationError() {
  return {
    type: Types.RESET_INIT_SIMULATION_ERROR,
  };
}

export function startInspection() {
  return {
    type: Types.LOAD_INSPECTION,
  };
}

export function errorInspection() {
  return {
    types: Types.ERROR_INSPECTION,
  };
}

export function successInspection() {
  return {
    types: Types.SUCCESS_INSPECTION,
  };
}

export function setInstallmentOption(data) {
  return {
    type: Types.SET_INSTALLMENT_OPTION,
    data,
  };
}

export function resetInstallmentOption() {
  return {
    type: Types.RESET_INSTALLMENT_OPTION,
  };
}

export function rejectQuotation() {
  return {
    type: Types.REJECT_QUOTATION,
  };
}

export function resetErrorInitQuotation() {
  return {
    type: Types.RESET_ERROR_INIT_QUOTATION,
  };
}

export function addPaymentType(data) {
  return {
    type: Types.ADD_PAYMENT_TYPE,
    data,
  };
}
