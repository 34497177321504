import React from 'react';
import MaterialUiTabs from '@material-ui/core/Tabs';
import MaterialUiTab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

export const TabsGroup = withStyles(theme => ({
  root: {
    borderBottom: '1px solid #ddd',
    marginBottom: '2rem',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    height: '0.25rem',

    '& > span': {
      width: '100%',
    },
  },
  scroller: {
    overflow: 'auto !important',
    height: 'fit-content',
  },
}))(props => <MaterialUiTabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const Tab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: 'black',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),

    '& .Mui-selected': {
      color: 'inherit',
    },

    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <MaterialUiTab disableRipple {...props} />);
