import React, { useContext, useEffect, useState } from 'react';
import FormSchema from 'shared/form/formSchema';
import { Formik } from 'formik';
import { DisplayFormikState } from 'components/DisplayFormikState/DisplayFormikState';

const SchemerContext = React.createContext();
export const useSchemerContext = () => useContext(SchemerContext);
const schemaFieldsList = {};

export function FormikAutomaticSchema({ children, onSubmit, debug, initialValues = {}, ...props }) {
  const [schema, setSchema] = useState();
  const [watch, setWatch] = useState();

  const addField = name => {
    schemaFieldsList[name.name] = name;
    setWatch(!watch);
  };
  const removeField = name => {
    schemaFieldsList[name] = undefined;
    setWatch(!watch);
  };

  useEffect(() => {
    const newSchema = new FormSchema(null, Object.values(schemaFieldsList));
    const timeout = setTimeout(() => setSchema(newSchema.schema), 100);
    return () => clearTimeout(timeout);
  }, [schemaFieldsList, watch]);

  return (
    <SchemerContext.Provider value={{ addField, removeField }}>
      <Formik
        enableReinitialize
        validationSchema={schema}
        validateOnMount
        validateOnChange
        validateOnBlur
        initialValues={schema?.initialValues?.(initialValues) || initialValues}
        onSubmit={values => onSubmit(schema?.cast(values) || values)}
        {...props}
      >
        {form => (
          <>
            {debug && <DisplayFormikState form={form} />}
            {typeof children === 'function' ? children(form) : children}
          </>
        )}
      </Formik>
    </SchemerContext.Provider>
  );
}
