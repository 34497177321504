import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  modalNavMenu: {
    paddingTop: '1rem !important',
  },
  root: {
    height: '2.5rem',
    width: '100%',
  },
  rowContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuList: {
    margin: 0,
    padding: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
  },
  hamburgerIcon: {
    fontSize: '2rem',
  },
  image: {
    width: '32px',
    heigth: '32px',
  },
}));
