import ANALITYCS_CATEGORY from '../category.json';

export default {
  BEGIN_CANCELLATION: {
    action: 'begin_cancellation',
    label: 'BTN:Cancelamento:DadosdoCliente',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CANCELLATION_PROGRESS_AUTH: {
    action: 'cancellation_progress_auth',
    label: 'BTN:Cancelamento:Autenticação',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CANCELLATION_PROGRESS_SELECT_PRODUCT: {
    action: 'cancellation_progress_select_product',
    label: 'BTN:Cancelamento:SelecionarProduto',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CANCELLATION_PROGRESS_CONFIRM: {
    action: 'cancellation_progress_confirm',
    label: 'BTN:Cancelamento:Confirmar',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CANCELLATION_AUTH_CELPHONE_RESEND: {
    action: 'cancellation_auth_celphone_resend',
    label: 'BTN:Cancelamento:ReenviarTelefoneAutenticacao',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CANCELLATION_AUTH_CELPHONE: {
    action: 'cancellation_auth_celphone',
    label: 'BTN:Cancelamento:EnviarTelefoneAutenticacao',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
  CANCELLATION_AUTH_EMAIL: {
    action: 'cancellation_auth_email',
    label: 'BTN:Cancelamento:EnviarEmailAutenticacao',
    category: ANALITYCS_CATEGORY.OBJETO_CLICADO,
  },
};
