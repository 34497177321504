import { makeStyles } from '@material-ui/core/styles';

export const useStylesBanner = makeStyles(({ palette, breakpoints }) => ({
  banner: {
    backgroundColor: '#F1F1F1',
    [breakpoints.up('md')]: {
      height: '440px',
    },
    [breakpoints.up('lg')]: {
      height: '550px',
    },
  },
  wrapperImage: {
    paddingRight: '10px',
    paddingLeft: '10px',
    [breakpoints.up('md')]: {
      marginTop: '29px',
    },
    [breakpoints.up('lg')]: {
      marginTop: '138px',
    },
  },
  image: {
    position: 'absolute',
    top: '143px',
    height: 'auto',
    right: '0',
    width: '200px',
    [breakpoints.up('md')]: {
      position: 'relative',
      top: '0px',
      width: '379px',
    },
    [breakpoints.up('lg')]: {
      position: 'relative',
      top: '0px',
      width: '379px',
    },
  },
  wrapper: {
    display: 'flex',
    minHeight: '260px',
    flexDirection: 'column',
    flexWrap: 'wrap',
    color: palette.white,
    fontFamily: 'ItauText-Light',
    fontSize: '24px',
    lineHeight: '28px',
    margin: '60px 0 40px 0',
    flexBasis: '50%',
    [breakpoints.up('md')]: {
      flexBasis: 'auto',
    },
  },
  title: {
    fontSize: '1.5rem',
    fontFamily: 'ItauText-Bold',
    margin: '0',
    color: '#027CC1',
    lineHeight: '1',
    [breakpoints.up('md')]: {
      margin: '8px 0 8px 0',
      fontSize: '38px',
    },
    [breakpoints.up('lg')]: {
      margin: '8px 0 8px 0',
      fontSize: '48px',
    },
  },
  subTitle: {
    fontSize: '1.5rem',
    fontFamily: 'ItauText-Light',
    margin: '0',
    color: '#027CC1',
    lineHeight: '1',
    [breakpoints.up('md')]: {
      margin: '8px 0 8px 0',
      lineHeight: '1.3',
      fontSize: '30px',
    },
    [breakpoints.up('lg')]: {
      margin: '8px 0 8px 0',
      lineHeight: '1.3',
      fontSize: '48px',
    },
  },
  deadLine: {
    fontSize: '1rem',
    fontFamily: 'ItauText-Bold',
    color: palette.black,
    lineHeight: '1',
    [breakpoints.up('md')]: {
      fontSize: '28px',
      margin: '8px 0 8px 0',
    },
    [breakpoints.up('lg')]: {
      fontSize: '28px',
      margin: '8px 0 8px 0',
    },
  },
  button: {
    marginTop: '27px',
    '&:focus': {
      outlineColor: palette.white,
      boxShadow: '-2px 1px 15px 0px rgba(255, 255, 255, 1)',
    },
  },
  buttonLine: {
    lineHeight: '2rem',
    fontSize: '1rem !important',
    padding: '7px !important',
    [breakpoints.up('md')]: {
      fontSize: '1.5rem !important',
      padding: '7px !important',
    },
  },
}));
