/* eslint-disable prefer-const */
export const Types = {
  ADD_AUTH_DATA: 'auto/ADD_AUTH_DATA',
  SET_FRANCHISE: 'franchise/SET_FRANCHISE',
  RESET_FRANCHISE: 'franchise/RESET_FRANCHISE',
  SET_FRANCHISE_PRODUCTS: 'franchise/SET_FRANCHISE_PRODUCTS',
};

const initialState = {
  currentFranchise: '100',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_FRANCHISE: {
      return {
        ...state,
        currentFranchise: action.data,
      };
    }
    case Types.RESET_FRANCHISE: {
      return initialState;
    }

    case Types.SET_FRANCHISE_PRODUCTS: {
      return {
        ...state,
        franchiseProducts: action.data,
      };
    }
    default:
      return state;
  }
}

export function setFranchise(data) {
  return {
    type: Types.SET_FRANCHISE,
    data,
  };
}

export function resetFranchise(data) {
  return {
    type: Types.RESET_FRANCHISE,
    data,
  };
}

export function setFranchiseProducts(data) {
  return {
    type: Types.SET_FRANCHISE_PRODUCTS,
    data,
  };
}
