import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '50px',

      '@media screen and (max-width: 1260px)': {
        marginLeft: 'auto'
      }
    }
  },
  logoImg: {
    width: '45px',
    height: '45px'
  },
  menuLink: {
    color: 'black',
    textDecoration: 'none'
  },
  alignRight: {
    marginLeft: 'auto'
  }
}));
