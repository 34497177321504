import { call, put } from 'redux-saga/effects';
import { setError, setErrorSagaAction } from '../../ducks/httpErrors';
import { checkElegibility } from '../../../api/E2E/Elegibility/elegibility';
import { setEligibility } from '../../ducks/eligibility';

export default function* callEligibilityAction(action) {
  try {
    const { name, cpf } = action.data;
    const isBlocked = yield call(checkElegibility, name, cpf);
    yield put(setEligibility(isBlocked));
  } catch (err) {
    yield put(setError(err));
    yield put(setErrorSagaAction(action));
  }
}
