import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ toRem, palette }) => ({
  text: {
    margin: `${toRem(16)} 0 ${toRem(16)} ${toRem(8)}`,
    fontSize: toRem(18),
    fontFamily: 'ItauText-Light ',
    color: palette.text.primary,
  },
}));
