/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useEffect, useState } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import config from 'config';
import { Loading } from 'components';
import { enabledMock } from 'mocks/config.mock';

const { RECAPTCHA_SITE_KEY, RECAPTCHA_SRC } = config;

const DISABLED_PROVIDER_MSG = 'To enable recaptcha provider, remove this current env for the mock list';

export const RecaptchaContext = createContext();

export default function RecaptchaProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const { REACT_APP_ENVIRONMENT, NODE_ENV } = process.env;

  const injectRecaptchaDOM = () => {
    if (navigator.userAgent === 'ReactSnap') return;
    const head = document.getElementsByTagName('head')[0];
    const scriptRecaptcha = document.createElement('script');
    scriptRecaptcha.type = 'text/javascript';
    scriptRecaptcha.src = `${RECAPTCHA_SRC}?render=${RECAPTCHA_SITE_KEY}`;
    scriptRecaptcha.addEventListener('load', () => {
      setIsScriptLoaded(true);
    });
    head.appendChild(scriptRecaptcha);
  };

  const removeRecaptchaDOM = () => {
    const recaptchaSelector = 'script[src*="https://www.gstatic.com/recaptcha"]';
    const badgeSelector = '.grecaptcha-badge';
    const script = document.querySelector(recaptchaSelector);
    const badge = document.querySelector(badgeSelector);
    if (script) script.remove();
    if (badge) badge.remove();
  };

  const getToken = async action => {
    const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {
      action,
    });
    return {
      acao: action,
      token,
    };
  };

  const enabledProvider = () => {
    const enableMock = false;
    let enableProvider = !enableMock;

    if (enableMock) {
      const disabledMockByProduct = enabledMock(REACT_APP_ENVIRONMENT);
      enableProvider = !disabledMockByProduct;
    }
    return enableProvider;
  };

  const makeProviderLoader = () => {
    const enableProvider = enabledProvider();
    let initializeProvider = injectRecaptchaDOM;
    let endProvider = removeRecaptchaDOM;
    let callToken = getToken;

    if (!enableProvider || NODE_ENV === 'test') {
      initializeProvider = () => {
        setIsLoaded(true);
        setIsScriptLoaded(true);
        endProvider = () => console.log(DISABLED_PROVIDER_MSG);
      };
      endProvider = () => console.log(DISABLED_PROVIDER_MSG);
      callToken = () => ({
        acao: 'mock_enabled',
        token: '',
      });
    }

    return {
      initializeProvider,
      endProvider,
      recoveryToken: callToken,
    };
  };

  useEffect(() => {
    const { initializeProvider, endProvider } = makeProviderLoader();
    initializeProvider();
    return () => {
      endProvider();
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded && !isLoaded) {
      window.grecaptcha.ready(() => {
        setIsLoaded(true);
      });
    }
  }, [isLoaded, isScriptLoaded]);

  if (!isLoaded || !isScriptLoaded) return <Loading />;
  const { recoveryToken } = makeProviderLoader();

  return <RecaptchaContext.Provider value={{ getToken: recoveryToken }}>{children}</RecaptchaContext.Provider>;
}

RecaptchaProvider.propTypes = { children: oneOfType([arrayOf(node), node]).isRequired };
