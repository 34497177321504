import { call, put } from 'redux-saga/effects';
import { Types as RevoveryE2ETypes } from 'store/ducks/recoveryE2E';
import { validateHash } from 'api/E2E/Authentication/email';
import recoveryAuthScope from './authScopes';

export default function* genericRecoveryE2E(action) {
  const { hash, captcha } = action.data;
  try {
    yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_LOAD });
    yield call(validateHash, { hash, captcha });

    const validatedScope = yield call(recoveryAuthScope, action.data);

    if (!validatedScope) {
      yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_ERROR });
      return;
    }
    yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_SUCCESS });
  } catch (err) {
    yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_ERROR });
  }
}
