import { createContext, useContext, useState } from 'react';
import { getContent } from 'cms';
import { useRemoteConfigConsumer } from 'providers/RemoteConfigProvider';

interface CMSProviderProps {
  children: JSX.Element;
}

export const CMSContext = createContext({});

const CMSProvider = ({ children }: CMSProviderProps) => {
  const [pageContent, setPageContent] = useState<object>();
  const [loading, setLoading] = useState(true);
  const remoteConfig = useRemoteConfigConsumer();

  const getPageContent = async (pathname: string) => {
    setLoading(true);
    const contentResponse = await getContent(pathname, remoteConfig);
    setPageContent(contentResponse);
    setLoading(false);
  };

  return <CMSContext.Provider value={{ getPageContent, pageContent, loading }}>{children}</CMSContext.Provider>;
};

const useCMSProviderConsumer = () => useContext(CMSContext);

export { CMSProvider, useCMSProviderConsumer };
