import React from 'react';
import { string, bool, oneOfType, arrayOf, node, oneOf } from 'prop-types';
import { Container } from '@material-ui/core';
import useStyles from './SimpleSection.styles';

function SimpleSection({ id, children, backgroundColor, backgroundImage, fullWidth, maxWidth }) {
  const classes = useStyles();

  const renderContent = fullWidth ? (
    <section id={id} style={{ backgroundColor, backgroundImage }} className={classes.root}>
      {children}
    </section>
  ) : (
    <Container
      id={id}
      component="section"
      maxWidth={maxWidth}
      style={{ backgroundColor, backgroundImage }}
      className={classes.root}
    >
      {children}
    </Container>
  );

  return renderContent;
}

SimpleSection.defaultProps = {
  maxWidth: 'lg',
  fullWidth: false,
};

SimpleSection.propTypes = {
  id: string.isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired,
  backgroundColor: string,
  backgroundImage: string,
  fullWidth: bool,
  maxWidth: oneOf([false, 'lg', 'xs', 'sm', 'md', 'xl']),
};

export default SimpleSection;
