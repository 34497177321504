import hiring from './hiring/pt-BR';
import home from './home/pt-BR';
import payment from './payment/pt-BR';
import summary from './summary/pt-BR';
import personal from './personal/pt-BR';
import authentication from './authentication/pt-BR';
import receipt from './receipt/pt-BR';
import common from './common/pt-BR';

const residModule = {
  ...authentication,
  ...hiring,
  ...home,
  ...payment,
  ...summary,
  ...personal,
  ...receipt,
  ...common,
};

export default residModule;
