import typography from '../typography';

export default {
  '@global': {
    html: {
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
    },
    '@font-face': [
      {
        fontFamily: typography.fontFamily,
      },
    ],
  },
};
