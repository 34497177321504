import residAsyncQuotation from 'api/E2E/Resid/Simulations/asyncQuotation';
import { select, put, call } from 'redux-saga/effects';
import { residQuotationSelector } from 'store/sagas/selectors/residQuotation';
import { residencialSelector } from 'store/sagas/selectors/residencial';
import { emailSent, sendingEmail, failedToSendEmail } from 'store/ducks/asyncQuotation';
import { isResidInsuranceBrokerageUnavailable } from 'store/sagas/selectors/httpErrors';
import { getFeatureToggle } from 'utils/featureToggle';

import { residCoveragesCoverageSelector } from 'store/sagas/selectors/residCoverage';

function* makeRequest({ email, residencial }) {
  const todasAsCoberturas = yield select(residCoveragesCoverageSelector);
  const { tipoOcupacao, tipoImovelTexto } = residencial.simulacao;

  const idsCoberturasParaExcluir = todasAsCoberturas
    .filter(c => c.nao_incluso.includes(tipoOcupacao) || c.nao_incluso.includes(tipoImovelTexto))
    .map(e => e.id_cobertura);

  const filteredCoverages = residencial.simulacao.requisicao.oferta.produtos[0].coberturas.filter(
    c => c.importancia_segurada > 0 && !idsCoberturasParaExcluir.includes(c.id_cobertura),
  );

  residencial.simulacao.requisicao.oferta.produtos[0].coberturas = filteredCoverages;

  residencial.simulacao.requisicao.proponente.contatos = residencial.simulacao.requisicao.proponente.contatos.map(
    contato => {
      if (contato.tipo === 'email') {
        contato.valor = email;
      }
      return contato;
    },
  );
  return residencial.simulacao.requisicao;
}

function* residEmail(action) {
  const { email, captcha } = action.data;

  const residencial = yield select(residencialSelector);
  const residQuotation = yield select(residQuotationSelector);

  const request = yield call(makeRequest, { email, residencial });

  residencial.simulacao.carregando = false;
  residencial.simulacao.erro = false;
  residencial.simulacao.recarregado = false;

  const payload = {
    requisicao: request,
    sessao: { residencial, residQuotation },
    captcha,
  };
  yield call(residAsyncQuotation, payload);
}

export function* emailSubmitSagaAction(action) {
  try {
    const residFeatureToggle = getFeatureToggle('RESID_ASYNC_QUOTATION', false);
    const residInsuranceBrokerageUnavailable = yield select(isResidInsuranceBrokerageUnavailable);
    yield put(sendingEmail());
    if (residInsuranceBrokerageUnavailable || residFeatureToggle.enabled) {
      yield call(residEmail, action);
    }
    yield put(emailSent());
  } catch (error) {
    yield put(failedToSendEmail());
  }
}
