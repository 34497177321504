import React from 'react';
import { PropTypes } from 'prop-types';
import Grid from '@material-ui/core/Grid';

export default function FormGrid({ children, lg, xs, className, ...props }) {
  return (
    <Grid item lg={lg} xs={xs} className={className} {...props}>
      {children}
    </Grid>
  );
}

FormGrid.defaultProps = {
  lg: 6,
  xs: 12,
};

FormGrid.propTypes = {
  children: PropTypes.node.isRequired,
  lg: PropTypes.number,
  xs: PropTypes.number,
  className: PropTypes.string,
  props: PropTypes.object,
};
