import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_ENVIRONMENTS,
} from 'trackingV2/constants';

export const ANALYTICS_RESID_HIRING_FLOW = {
  T1: {
    nome: 'LS:NL:NCC:Residencial:Cotacao:Cotacao',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T2: {
    nome: 'LS:NL:NCC:Residencial:Cotacao:Loading',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T3: {
    nome: 'LS:NL:NCC:Residencial:Contratacao:Autenticacao',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T4: {
    nome: 'LS:NL:NCC:Residencial:Contratacao:DadosDoTitular',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T5: {
    nome: 'LS:NL:NCC:Residencial:Contratacao:Pagamento',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T6: {
    nome: 'LS:NL:NCC:Residencial:Contratacao:Resumo',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T7: {
    nome: 'LS:NL:NCC:Residencial:Contratacao:Sucesso',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T8: {
    nome: 'LS:NL:NCC:Residencial:FormLead',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T9: {
    nome: 'LS:NL:NCC:Residencial:Modal:SeguroNaoValidoParaTipoDeImovel',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T10: {
    nome: 'LS:NL:NCC:Residencial:Cotacao:Erro',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:FormCoberturas:Passo1:Continuar:{0}',
    noInteraction: false,
  },
  A2: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:FormCoberturas:Passo1:Voltar',
    noInteraction: false,
  },
  A3: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:EscolherValorDaCobertura',
    noInteraction: false,
  },
  A4: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:FormCoberturas:Personalizar:{0}',
    noInteraction: false,
  },
  A5: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:FormCoberturas:Personalizar:Voltar',
    noInteraction: false,
  },
  A6: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:FormAssistencias:Passo2:Continuar:{0}',
    noInteraction: false,
  },
  A7: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:FormAssistencias:Passo2:Voltar',
    noInteraction: false,
  },
  A8: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:CondicoesGerais',
    noInteraction: false,
  },
  A9: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:CardDoSeguro:{0}:{1}',
    noInteraction: false,
  },
  A10: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:VerCondicoesGeraisDoSeguroResidencialHabitual',
    noInteraction: false,
  },
  A11: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:SePreferirDeixeSeusDados',
  },
  A12: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:Coberturas:{0}',
  },
  A13: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Autenticacao:Passo1:Voltar',
    noInteraction: false,
  },
  A14: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Autenticacao:Passo1:Continuar',
    noInteraction: false,
  },
  A15: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:ReenviarEmail',
    noInteraction: false,
  },
  A16: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:AlterarEmail',
    noInteraction: false,
  },
  A17: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Autenticacao:Passo2:Voltar',
    noInteraction: false,
  },
  A18: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:ReenviarCodigo',
    noInteraction: false,
  },
  A19: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Autenticacao:Passo3:Continuar',
  },
  A20: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:DadosDoTitular:Voltar',
    noInteraction: false,
  },
  A21: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:DadosDoTitular:Continuar',
    noInteraction: false,
  },
  A22: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:DadosDoTitular:Sexo:{0}',
    noInteraction: false,
  },
  A23: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:DadosDoTitular:EstadoCivil:{0}',
    noInteraction: false,
  },
  A24: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:DadosDoTitular:FaixaDeRenda:{0}',
    noInteraction: false,
  },
  A25: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:CondicoesGerais',
    noInteraction: false,
  },
  A26: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Pagamento:Voltar',
    noInteraction: false,
  },
  A27: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Pagamento:Continuar',
    noInteraction: false,
  },
  A28: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Pagamento:FormaDePagamento:{0}',
    noInteraction: false,
  },
  A29: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Pagamento:Parcelas:{0}',
    noInteraction: false,
  },
  A30: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Pagamento:ResponsavelFinanceiro:{0}',
    noInteraction: false,
  },
  A31: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Resumo:Voltar',
    noInteraction: false,
  },
  A32: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Resumo:Finalizar',
    noInteraction: false,
  },
  A33: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'Alerta:Residencial:Contratacao:Sucesso',
    noInteraction: false,
  },
  A34: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Cotacao:Coberturas:{0}',
    noInteraction: false,
  },
  A35: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Cotacao:Assistencias:{0}',
    noInteraction: false,
  },
  A36: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:DadosDoTitular:Sexo:{0}',
    noInteraction: false,
  },
  A37: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:DadosDoTitular:EstadoCivil:{0}',
    noInteraction: false,
  },
  A38: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:DadosDoTitular:FaixaDeRenda:{0}',
    noInteraction: false,
  },
  A39: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Pagamento:FormaDePagamento:{0}',
    noInteraction: false,
  },
  A40: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Pagamento:Parcelas:{0}',
    noInteraction: false,
  },
  A41: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Contratacao:Pagamento:ResponsavelFinanceiro:{0}',
    noInteraction: false,
  },
  A42: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:FormLead:Fechar',
    noInteraction: false,
  },
  A43: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:FormLead:Enviar',
    noInteraction: false,
  },
  A44: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:VoltarParaPaginaInicial',
    noInteraction: false,
  },
  A45: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:CotarOutroTipoDeImovel',
    noInteraction: false,
  },
  A46: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.RESID,
    label: 'BTN:Residencial:Cotacao:Erro:Voltar',
    noInteraction: false,
  }
};
