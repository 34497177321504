import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  mainHeader: {
    fontSize: 24,
    fontFamily: 'ItauText-XBold',
    fontWeight: '500',
    color: theme.palette.text.primary,
    textAlign: 'left',
    lineHeight: theme.toRem(32),
    margin: 0,
  },
  header: {
    fontSize: 20,
    fontFamily: 'ItauText-Regular',
    fontWeight: '400',
    color: theme.palette.text.primary,
    textAlign: 'left',
    lineHeight: theme.toRem(29),
    margin: 0,
  },
  terms: {
    textAlign: 'justify',
  },
  subtitle: {
    color: '$textGray',
    fontFamily: 'ItauText-Light',
    fontSize: '14px',
    lineHeight: '18px',
  },
  keyword: {
    fontFamily: 'ItauText-XBold',
    fontWeight: '500',
  },
  radioButton: {
    marginTop: 15,
  },
  controls: {
    display: 'flex',
    margin: `${theme.spacing(12)} 0 `,
  },
  link: {
    marginTop: 45,
  },
  linkText: {
    fontSize: 12,
  },
  formColumnExtended: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
  form: {
    marginTop: 50,
  },
  paymentType: {
    marginTop: 30,
  },
  itaucardBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 44,
    width: '100%',
  },
  itaucard: {
    alignItems: 'flex-start',
    backgroundImage: theme.palette.backgroundCredicardPaymentGradient,
    borderRadius: 8,
    boxShadow: theme.palette.backgroundCredicardBoxShadow,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: 116,
    marginTop: 59,
    marginBottom: 60,
    padding: 4,
    '&:hover, &:focus': {
      border: '2px solid #FA6400',
      padding: 2,
    },
    [theme.breakpoints.up('sm')]: {
      width: 556,
    },
  },
  itaucardSelected: {
    border: '2px solid #FA6400',
    borderTop: '2px solid #FA6400',
    paddingTop: 0,
    padding: 2,
  },
  itaucardWrapper: {
    [theme.breakpoints.down('md')]: {
      marginLeft: -30,
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  itaucardImg: {
    marginTop: -40,
    marginLeft: -22,
    height: 185.47,
    width: 224.48,
  },
  itaucardInfo: {
    color: theme.palette.white,
    fontSize: '0.99em',
  },
  itaucardSpacing: {
    marginRight: 10,
    width: 105,
    height: 'auto',
  },
  itaucardCheckBox: {
    backgroundColor: theme.palette.white,
    width: 25,
    height: 25,
    borderRadius: 30,
    marginLeft: 'auto',
    visibility: 'visible',
    marginRight: '10px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
    },
  },
  itaucardCheckBoxSelected: {
    backgroundColor: theme.palette.primary.orange,
    border: '2px solid #FFF',
    width: 25,
    height: 25,
    borderRadius: 30,
    marginRight: '10px',
  },
  iconCard: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      marginTop: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
}));
