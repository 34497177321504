import { createSelector } from 'reselect';

export const residCoveragesSelector = state => state.residCoverages;

export const residCoveragesOfferSelector = createSelector(residCoveragesSelector, item => item.data);

export const residCoveragesOfferIdSelector = createSelector(residCoveragesOfferSelector, item => item?.id_oferta);

export const residCoveragesProductSelector = createSelector(residCoveragesOfferSelector, item => item?.produtos[0]);

export const residCoveragesCoverageSelector = createSelector(residCoveragesProductSelector, item =>
  item ? item.coberturas : [],
);

export const residCoveragesAssistsSelector = createSelector(residCoveragesProductSelector, item => item?.assistencias);
