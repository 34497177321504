import { put } from 'redux-saga/effects';
import { createPaymentData } from 'store/auto/store/ducks/automovel';
import { setErrorSagaAction } from 'store/ducks/httpErrors';
import { addPaymentType } from 'store/auto/store/ducks/autoFlow';

export default function* createProfileAction({ data: { dados_opcao_pagamento, paymentType } }) {
  try {
    yield put(createPaymentData({ dados_opcao_pagamento }));
    yield put(addPaymentType(paymentType));
  } catch (err) {
    yield put(setErrorSagaAction(err));
  }
}
