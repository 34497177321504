const home = {
  RESID_HOME: {
    TITLE: 'Seguro Residencial Porto Seguro',
    LOADED: 'página seguro residencial carregada',
    SERVICES_RESID: 'serviços',
    COVERAGE_HOME_APPLIANCES_TITLE: 'reparos de eletrodomésticos',
    COVERAGE_HOME_APPLIANCES:
      'a geladeira quebrou ou o freezer parou de funcionar? Mandamos um técnico para te ajudar.',
    COVERAGE_RESIDENTIAL_ASSISTANCE_TITLE: 'serviços de encanador',
    COVERAGE_RESIDENTIAL_ASSISTANCE: 'rolou aquele vazamento? Sem estresse, temos o serviço de encanador para você.',
    COVERAGE_KEY_TITLE: 'chaveiro',
    COVERAGE_KEY: 'perdeu suas chaves? A assistência manda um chaveiro até você.',
    SECTION_INFORMATION_TITLE: 'quer saber quanto custa seu seguro?',
    SECTION_INFORMATION_SUBTITLE: 'comece respondendo as perguntas abaixo e descubra :)',
    PROMOTIONINDOOR: {
      TITLE: 'seguro residencial com 10% de desconto!',
      SUBTITLE:
        'não perca essa oportunidade nos dias 14/07 e 15/07. proteja o seu lar e ainda conte com profissionais especializados para pequenos imprevistos do dia a dia.',
      BUTTON: 'faça sua cotação',
    },
  },
  COVERAGE: {
    LOADED: 'coberturas carregadas',
    STRIPE: {
      TITLE: 'você sabe o que é cobertura?',
      SUBTITLE:
        'é o que garante que você vai receber a indenização em caso de perdas ou danos, e o mais legal é que você pode personalizar tudo durante a contratação',
    },
    TITLE: 'agora que você já sabe o que são coberturas, que tal selecionar as suas?',
  },
  PERSONAL_DATA: {
    TITLE: {
      START: 'dados do',
      ENDING: 'titular do seguro',
    },
    SUBTITLE: 'chegou a hora de reunir algumas informações para continuar com a cotação do seguro, vamos lá?',
  },
  JURIDIC_TEXT: {
    START: 'Porto Seguro Cia. de Seguros Gerais. CNPJ 61.198.164/0001-60. Consulte as Condições Gerais em ',
    LINK: 'www.portoseguro.com.br/seguro-residencial.',
    ENDING:
      ' Processos SUSEP: Residência 15414.002288/2005-85. Responsabilidade Civil Patrimonial 15414.900937/2017-49. A aceitação da proposta de seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da Susep. O segurado poderá consultar a situação cadastral do corretor de seguros e da sociedade seguradora no sítio eletrônico www.susep.gov.br.',
  },
  CART_LGPD_TITLE: 'caso não consiga terminar a contratação do plano, quero receber contato por telefone.'
};

export default home;
