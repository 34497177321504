import { call, put } from 'redux-saga/effects';
import { Types as CancellationTypes } from 'store/ducks/cancellation';
import { Types as RevoveryE2ETypes } from 'store/ducks/recoveryE2E';
import { validateHash } from 'api/E2E/Authentication/email';
import recoveryAuthScope from '../auth/authScopes';

export default function* recoveryE2ECancellation(action) {
  try {
    const { hash, captcha } = action.data;

    yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_LOAD });
    const proposalData = yield call(validateHash, { hash, captcha });
    const validatedScope = yield call(recoveryAuthScope, action.data);
    if (!validatedScope) {
      yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_ERROR });
      return;
    }

    const cancellationData = {
      idProposta: proposalData.data.id_contratacao,
      cpf: proposalData.data.cpf,
    };

    yield put({
      type: CancellationTypes.ADD_DATA,
      data: cancellationData,
    });

    yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_SUCCESS });
  } catch (err) {
    yield put({ type: RevoveryE2ETypes.RECOVERY_E2E_ERROR });
  }
}
