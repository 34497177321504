import axios from 'axios';

import config, { performance } from 'config';

const { ITAU_GATEWAY_HOST, E2E_PATH } = config;

async function asyncQuotation(payload) {
  const baseURL = ITAU_GATEWAY_HOST;
  const trace = performance.trace('postAsyncQuotation');
  trace.start();
  const { data } = await axios.post(
    `${E2E_PATH}/simulacoes/assistencias/async`,
    payload,
    {
      baseURL
    }
  );
  trace.stop();
  return data;
}

export default asyncQuotation;
