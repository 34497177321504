import { defaultVulnerabilityFieldNames } from './translator';

const SPECIAL_CHARACTERS = 'remova os caracteres especiais';
const INVALID_EMAIL = 'email inválido';

const defaultMessages = defaultVulnerabilityFieldNames.reduce((acc, fieldName) => {
  acc[fieldName] = SPECIAL_CHARACTERS;
  return acc;
}, {});

export const messages = {
  ...defaultMessages,
  email: INVALID_EMAIL,
};
