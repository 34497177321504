import { put } from 'redux-saga/effects';
import {
  createHiringVehicle,
  createHiringProponent,
  createHiringMainDriver,
  createHiringPolitical,
  createRenewingData,
} from 'store/auto/store/ducks/automovel';

export default function* createProfileAction({
  data: { proponente, condutor, veiculo, politicamente_exposto, dados_renovacao },
}) {
  yield put(createHiringProponent(proponente));
  yield put(createHiringMainDriver(condutor));
  yield put(createHiringVehicle(veiculo));
  yield put(createHiringPolitical(politicamente_exposto));
  yield put(createRenewingData(dados_renovacao));
}
