import CredicardInsurance from '../../../../assets/images/credicard-insurance.png';
import OdontoCredicardIcon from '../../../../assets/images/odonto-credicard.jpg';
import logoSegurosItau from '../../../../assets/images/logo-seguros-itau-cinza.png';

function createStyleMapper({ palette }) {
  return {
    header: {
      width: '276px',
    },
    logo: {
      color: palette.default.main,
      width: 'auto',
      heightLG: '51px',
      widthLG: 'auto',
    },
    advertisement: {
      widthMD: '168px',
      widthLG: '247px',
    },
    welcomeText: {
      color: palette.default.main,
      widthLG: '125px',
      widthMD: '125px',
    },
    welcomeIdentification: {
      image: OdontoCredicardIcon,
      backgroundPositionMD: 'top 40% right 43%',
      backgroundPositionLG: '0 60%',
    },
    visitorText: {
      color: palette.default.main,
    },
    descriptionText: {
      color: palette.default.main,
    },
    cardLogo: {
      color: palette.default.main,
      image: CredicardInsurance,
      width: 171,
      height: 25,
      marginTop: 30,
      marginBottom: 32,
      heightMD: 32,
      heightLG: 48,
      widthMD: '222px',
      widthLG: '330px',
    },
    cardTitle: {
      color: palette.default.main,
    },
    cardSubtitle: {
      color: palette.default.main,
    },
    cardItemCircle: {
      background: palette.default.main,
      color: palette.default.contrastText,
    },
    cardItemText: {
      color: palette.default.main,
    },
    cardActionButton: {
      color: palette.tertiary.contrastText,
      background: palette.tertiary.main,
    },
    cardBorder: {
      background: palette.secondary.main,
    },
    cardWrapper: {
      background: {
        dark: palette.primary.main,
        light: palette.primary.main,
      },
      color: palette.primary.contrastText,
    },
    footer: {
      image: logoSegurosItau,
      backGroundColor: palette.footer.backGroundColor,
      font: {
        colorHeading: palette.footer.colorHeading,
        color: palette.footer.color,
        colorArrow: palette.footer.colorArrow,
        weight: 'ItauText-Regular',
      },
    },
  };
}

export default createStyleMapper;
