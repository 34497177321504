/**
 * Sets a feature toggle state, persisting on localStorage
 * @param {string} key Feature toggle name
 * @param {boolean} newValue New feature toggle state
 */
export function setFeatureToggle(key, newValue) {
  window.localStorage.setItem(`FEATURE-TOGGLE-${key}`, newValue);
}

/**
 * Gets a feature toggle state from sessionStorage if persisted
 * @param {string} key Feature toggle name
 * @param {boolean} defaultValue Default feature toggle state
 * @returns Feature toggle state
 */
export function getFeatureToggle(key, defaultValue) {
  const localStoredState = window.localStorage.getItem(`FEATURE-TOGGLE-${key}`);
  const object = { enabled: defaultValue, metadata: { dataType: 'FEATURE_TOGGLE' } };
  return localStoredState ? { ...object, enabled: JSON.parse(localStoredState) } : object;
}
