import { call, put, select } from 'redux-saga/effects';
import { Types } from 'store/ducks/auth';
import { authenticationV2 } from 'api/E2E/Authentication/authV2';
import scopes from 'auth/scopes';
import { residQuotationSelector } from '../selectors/residQuotation';

const selectResidential = store => store.residencial;
const selectSimulation = store => store.residencial.simulacao.resposta;
const selectProponent = store => store.residencial.simulacao.requisicao.proponente;

export default function* auth(action) {
  const { data } = action;
  try {
    const simulacao = yield select(selectSimulation);
    const idSimulacao = simulacao.id_simulacao;
    const proponente = yield select(selectProponent);
    const email = proponente.contatos.find(c => c.tipo === 'email');
    const telefone = proponente.contatos.find(c => c.tipo === 'telefone');

    const residentialData = yield select(selectResidential);

    const quotationData = yield select(residQuotationSelector);

    const dadosParaAutenticacao = {
      id_simulacao: idSimulacao,
      usuario: {
        nome: proponente.nome,
        cpf: proponente.cpf,
        email: email.valor,
        telefone: telefone.valor,
      },
      captcha: {
        ...data,
      },
      sessao: {
        residencial: {
          ...residentialData,
        },
        residQuotation: {
          ...quotationData,
        },
      },
    };
    yield call(authenticationV2, dadosParaAutenticacao, scopes.RESID);
    yield put({ type: Types.AUTH_RESID_SUCCESS });
  } catch (err) {
    yield put({ type: Types.AUTH_RESID_ERROR });
  }
}
