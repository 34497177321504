import produce from 'immer';
import { createActions } from 'reduxsauce';

const initialState = {
  leads: {
    data: [],
    loading: false,
  },
};

const productsReducer = types =>
  produce((state = initialState, action) => {
    switch (action.type) {
      case types.RESET: {
        return initialState;
      }
      case types.SET_PRODUCTS_LEADS:
        state.leads.loading = false;
        state.leads.error = false;
        state.leads.data = action.data;
        return state;
      case types.START_GET_PRODUCTS_LEADS:
        state.leads.loading = true;
        state.leads.error = false;
        return state;
      case types.ERROR_GET_PRODUCTS_LEADS:
        state.leads.loading = false;
        state.leads.error = true;
        return state;
      default:
        return state;
    }
  });

const productsActions = type =>
  createActions(
    {
      setProductsLeads: ['data'],
      loadProductsLeads: ['data'],
      errorGetProductsLeads: ['data'],
      startGetProductsLeads: ['data'],
    },
    {
      prefix: type,
    },
  );

export const { Types, Creators } = productsActions('products/');
export default productsReducer(Types);
