/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable react/destructuring-assignment */
import { useRef, useEffect, useState } from 'react';
import './_Modal.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import IconButton from '../IconButton/IconButton';
import Snackbar from '../Snackbar/Snackbar';
import { focusElement, setFocusVisible, turnOffAppScroll } from '../../utils/util';
import { closeModalMessage, resetModalsState } from '../../store/ducks/modal';

const Modal = (props) => {
  const modalContentRef = useRef(null);
  const modalDiv = useRef(null);
  const closeRef = useRef(null);

  // eslint-disable-next-line no-shadow
  const { children, modal, customModal, customResetModalState, resetModalsState } = props;
  const currentResetModalState = customResetModalState || resetModalsState;
  const currentModal = customModal || modal;
  const { message, closeModal, fullScreen, showCloseButton, containerStyle } = currentModal;
  const fullScreenClass = fullScreen ? 'full-screen' : '';

  const [firstElementFocusable, setFirstElementFocusable] = useState(null);
  const [lastElementFocusable, setLastElementFocusable] = useState(null);
  const [listFocusableElements, setListFocusableElements] = useState([]);
  const [forceReadingNVDA, setForceReadingNVDA] = useState(false);

  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();

  const getAllfocusableElements = () => {
    if (modalDiv && modalDiv.current) {
      const focusableElementsString =
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[contenteditable]';

      const focusable = [...modalContentRef.current.querySelectorAll(focusableElementsString)];
      const firstElement = focusable[0];
      const lastElement = focusable[focusable.length - 1];
      setListFocusableElements(focusable);
      setFirstElementFocusable(firstElement);
      setLastElementFocusable(lastElement);
    }
  };

  const close = () => {
    modalDiv.current.classList.add('exit');
    if (currentModal?.gaEvent?.onClose) {
      const { flowName, eventCode } = currentModal.gaEvent.onClose;
      dispatchAnalyticsEvent(flowName, eventCode);
    }

    currentResetModalState();

    if (currentModal && currentModal.lastClickedElementId) {
      setTimeout(() => {
        focusElement(currentModal.lastClickedElementId);
      }, 100);
    }
  };

  useEffect(() => {
    getAllfocusableElements();
    setTimeout(() => setForceReadingNVDA(true), 1000);
    if (closeRef && closeRef.current) {
      setTimeout(() => {
        closeRef.current.focus();
      }, 300);
    }
  }, [closeRef]);

  if (closeModal) {
    close();
  }

  const handleBackwardTab = (e) => {
    if (document.activeElement === closeRef.current) {
      lastElementFocusable.focus();
      e.preventDefault();
    }

    if (document.activeElement === firstElementFocusable) {
      closeRef.current.focus();
      e.preventDefault();
    }
  };

  const handleForwardTab = (e) => {
    if (
      document.activeElement !== firstElementFocusable &&
      document.activeElement !== closeRef.current &&
      document.activeElement === lastElementFocusable
    ) {
      closeRef.current.focus();
      e.preventDefault();
    }
  };

  const handleModalKeyDown = (e) => {
    setTimeout(() => getAllfocusableElements());

    const KEY_TAB = 9;
    const ESC_KEY = 27;
    switch (e.keyCode) {
      case KEY_TAB:
        if (listFocusableElements.length === 0) {
          e.preventDefault();
          break;
        }

        if (e.shiftKey) {
          handleBackwardTab(e);
        } else {
          handleForwardTab(e);
        }
        break;
      case ESC_KEY:
        close();
        break;
      default:
        break;
    }
  };

  const closeMessage = () => {
    if (message.onCloseCallback && typeof message.onCloseCallback === 'function') {
      message.onCloseCallback();
    }

    props.closeModalMessage();
    if (message.refFocus) {
      setFocusVisible(document.querySelector(`${message.refFocus}`));
    }
    setTimeout(() => getAllfocusableElements(), 300);
    turnOffAppScroll();
  };

  const focusMessage = () => {
    const snackbar = document.querySelector('.SnackbarContent');
    const modalContent = document.querySelector('.modal-content');
    snackbar.setAttribute('role', 'alert');
    modalContent.scrollTop = modalContent.scrollHeight;
    setTimeout(() => getAllfocusableElements());
  };

  return (
    <div className={`Modal ${fullScreenClass}`} onKeyDown={(e) => handleModalKeyDown(e)} ref={modalDiv}>
      <div className="backdrop" />
      <div className={`${containerStyle} modal slideInUp`}>
        <span
          className="text-visually-hidden"
          aria-hidden={forceReadingNVDA}
          aria-live="assertive"
          aria-label="fechar"
          role="heading"
        />
        {showCloseButton && (
          <IconButton autoFocus iconClass="itaufonts_fechar" ariaLabel="fechar" onClick={close} divRef={closeRef} />
        )}
        <div className="modal-content" ref={modalContentRef}>
          {children}
          <Snackbar
            action={message.action}
            message={message.message}
            subtitle={message.subtitle}
            open={message.open}
            type={message.type}
            onClose={closeMessage}
            onEntered={focusMessage}
          />
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  modal: PropTypes.shape({
    message: PropTypes.shape({
      onCloseCallback: PropTypes.func,
      refFocus: PropTypes.string,
      message: PropTypes.string,
      type: PropTypes.string,
      open: PropTypes.bool,
    }),
  }),
  resetModalsState: PropTypes.any,
  closeModalMessage: PropTypes.any,
  children: PropTypes.any,
};

const mapStateToProps = ({ modal }) => ({
  modal,
});

const mapDispatchToProps = {
  resetModalsState: (show) => resetModalsState(show),
  closeModalMessage: () => closeModalMessage(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
