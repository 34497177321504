import i18next from 'i18next';
import moment from 'moment';
import {
  CELLPHONE_REGEX,
  CEP_REGEX,
  CPF_REGEX,
  DATE_REGEX,
  NAME_ABREVIATED_REGEX,
  NAME_REGEX,
  NO_UPPER_CASE_REGEX,
  ONLY_NUMBER,
  ONLY_TEXT,
  RG_REGEX,
} from 'utils/regex';
import { BIRTHDATE_MASK, CELLPHONE, CEP_MASK, CPF_MASK } from 'utils/mask';
import { checkCreditCardFlag } from 'utils/validations';
import { obtainCreditCardBanner } from 'api/E2E/Realiza/obtainCreditCardBanner';
import StringField from '../fields/stringField';
import EnumeratorField from '../fields/enumeratorField';
import MaskedField from '../fields/maskedField';
import { cardLengthTest, cpfTest, dateFormatTest, isFutureValidation } from './commonTests';

export const nameModel = (overrides = {}) => {
  const defaultValues = {
    name: 'name',
    label: i18next.t('COMMON_MODELS.NAME.TITLE'),
    required: true,
    requiredMessage: i18next.t('COMMON_MODELS.NAME.MESSAGE'),
    matches: {
      pattern: NAME_REGEX,
      message: i18next.t('COMMON_MODELS.NAME.MESSAGE'),
    },
    helperText: null,
    disabled: false,
  };

  const values = Object.assign(defaultValues, overrides);

  return new StringField(values.name, values.label, {
    disabled: values.disabled,
    ariaLabel: values.ariaLabel,
    required: values.required,
    requiredMessage: values.requiredMessage,
    matches: values.matches,
    helperText: values.helperText,
  });
};

export const genderModel = (overrides = {}) => {
  const defaultValues = {
    name: 'gender',
    ariaLabel: i18next.t('GENDER.LABEL'),
    label: i18next.t('GENDER.LABEL'),
    options: [
      { value: 'feminino', label: i18next.t('GENDER.FEMALE') },
      { value: 'masculino', label: i18next.t('GENDER.MALE') },
    ],
  };

  const values = Object.assign(defaultValues, overrides);

  return new EnumeratorField(values.name, values.label, {
    product: values.product,
    ariaLabel: values.ariaLabel,
    options: values.options,
    required: true,
    type: values.type,
    requiredMessage: i18next.t('GENDER.REQUIRED_MESSAGE'),
  });
};

export const cpfModel = (overrides = {}) => {
  const defaultValues = {
    name: 'cpf',
    label: i18next.t('COMMON_MODELS.CPF.TITLE'),
    required: true,
    requiredMessage: i18next.t('COMMON_MODELS.CPF.REQUIRED_MESSAGE'),
    disabled: false,
    test: [],
    disableUnderline: false,
    invalidMessage: i18next.t('COMMON_MODELS.CPF.MESSAGE'),
  };

  const values = Object.assign(defaultValues, overrides);

  return new MaskedField(values.name, values.label, CPF_MASK, {
    disabled: values.disabled,
    ariaLabel: values.ariaLabel,
    type: 'tel',
    required: values.required,
    requiredMessage: values.requiredMessage,
    disableUnderline: values.disableUnderline,
    matches: {
      pattern: CPF_REGEX,
      message: values.invalidMessage,
    },
    test: [cpfTest(values.invalidMessage), ...values.test],
  });
};

export const birthdateModel = (overrides = {}) => {
  const defaultValues = {
    name: 'birthdate',
    label: i18next.t('COMMON_MODELS.BIRTH_DATE.LABEL'),
    ariaLabel: i18next.t('COMMON_MODELS.BIRTH_DATE.LABEL'),
    required: true,
    requiredMessage: i18next.t('COMMON_MODELS.BIRTH_DATE.REQUIRED_MESSAGE'),
    test: [],
  };

  const values = Object.assign(defaultValues, overrides);

  return new MaskedField(values.name, values.label, BIRTHDATE_MASK, {
    ariaLabel: values.ariaLabel,
    type: 'tel',
    required: values.required,
    requiredMessage: values.requiredMessage,
    matches: {
      pattern: DATE_REGEX,
      message: i18next.t('COMMON_MODELS.BIRTH_DATE.MESSAGE'),
    },
    test: [isFutureValidation(), dateFormatTest(i18next.t('COMMON_MODELS.BIRTH_DATE.MESSAGE')), ...values.test],
  });
};

export const emailModel = (overrides = {}) => {
  const defaultValues = {
    name: 'email',
    label: i18next.t('COMMON_MODELS.EMAIL.TITLE'),
    ariaLabel: i18next.t('COMMON_MODELS.EMAIL.ARIA_LABEL'),
    required: true,
    type: 'email',
    requiredMessage: i18next.t('COMMON_MODELS.EMAIL.REQUIRED_MESSAGE'),
    matches: {
      pattern: NO_UPPER_CASE_REGEX,
      message: i18next.t('COMMON_MODELS.EMAIL.MESSAGE'),
    },
    isEmail: true,
    emailErrorMessage: i18next.t('COMMON_MODELS.EMAIL.INVALID'),
  };

  const values = Object.assign(defaultValues, overrides);

  return new StringField(values.name, values.label, {
    ariaLabel: values.ariaLabel,
    required: values.required,
    type: values.type,
    requiredMessage: values.requiredMessage,
    matches: values.matches,
    isEmail: values.isEmail,
    emailErrorMessage: values.emailErrorMessage,
  });
};

export const cepModel = (overrides = {}) => {
  const defaultValues = {
    id: '',
    name: 'cep',
    label: i18next.t('COMMON_MODELS.CEP.TITLE'),
    ariaLabel: i18next.t('COMMON_MODELS.CEP.ARIA_LABEL'),
    required: false,
    requiredMessage: i18next.t('COMMON_MODELS.CEP.REQUIRED_MESSAGE'),
    test: [],
    disabled: false,
  };

  const values = Object.assign(defaultValues, overrides);

  return new MaskedField(values.name, values.label, CEP_MASK, {
    id: values.id,
    ariaLabel: values.ariaLabel,
    type: 'tel',
    required: values.required,
    requiredMessage: values.requiredMessage,
    matches: {
      pattern: CEP_REGEX,
      message: i18next.t('COMMON_MODELS.CEP.MESSAGE'),
    },
    test: values.test,
    disabled: values.disabled,
  });
};

export const cellphoneModel = (overrides = {}) => {
  const defaultValues = {
    name: 'cellphone',
    label: i18next.t('COMMON_MODELS.CELL_PHONE.TITLE'),
    mask: CELLPHONE,
    ariaLabel: i18next.t('COMMON_MODELS.CELL_PHONE.ARIA_LABEL'),
    type: 'tel',
    required: true,
    requiredMessage: i18next.t('COMMON_MODELS.CELL_PHONE.REQUIRED_MESSAGE'),
    matches: {
      pattern: CELLPHONE_REGEX,
      message: i18next.t('COMMON_MODELS.CELL_PHONE.MESSAGE'),
    },
  };

  const values = Object.assign(defaultValues, overrides);

  return new MaskedField(values.name, values.label, values.mask, {
    ariaLabel: values.ariaLabel,
    type: values.type,
    required: values.required,
    requiredMessage: values.requiredMessage,
    matches: values.matches,
  });
};

export const motherNameModel = () =>
  nameModel({
    name: 'motherName',
    label: i18next.t('COMMON_MODELS.MOTHER_NAME.LABEL'),
    requiredMessage: i18next.t('COMMON_MODELS.MOTHER_NAME.REQUIRED_MESSAGE'),
    matches: {
      pattern: NAME_REGEX,
      message: i18next.t('COMMON_MODELS.MOTHER_NAME.MESSAGE'),
    },
    helperText: i18next.t('COMMON_MODELS.MOTHER_NAME.HELPER'),
  });

export const maritalStatusModel = (overrides = {}) => {
  const defaultValues = {
    name: 'maritalStatus',
    type: '',
    options: [
      { value: '0', label: 'solteiro' },
      { value: '1', label: 'casado' },
      { value: '2', label: 'viúvo' },
      { value: '3', label: 'desquitado' },
      { value: '4', label: 'separado' },
    ],
  };

  const values = Object.assign(defaultValues, overrides);

  return new EnumeratorField(values.name, i18next.t('COMMON_MODELS.MARTIAL_STATUS.LABEL'), {
    options: values.options,
    type: values.type,
    required: true,
    requiredMessage: i18next.t('COMMON_MODELS.MARTIAL_STATUS.REQUIRED_MESSAGE'),
  });
};

export const radioBooleanModel = (overrides = {}) => {
  const defaultValues = {
    name: 'isTruth',
    label: 'verdadeiro ou falso?',
    type: 'radio',
    horizontalOrientation: false,
    columnContainer: false,
    required: true,
    options: [
      { value: 'true', label: 'sim' },
      { value: 'false', label: 'não' },
    ],
  };

  const values = Object.assign(defaultValues, overrides);
  return new EnumeratorField(values.name, values.label, {
    id: values.id,
    options: values.options,
    required: values.required,
    type: values.type,
    ariaLabel: values.ariaLabel,
    horizontalOrientation: values.horizontalOrientation,
    columnContainer: values.columnContainer,
    nestedQuestionSpacing: values.nestedQuestionSpacing,
  });
};

export const cnhModel = (overrides = {}) => {
  const defaultValues = {
    name: 'CNH',
    label: 'CNH',
    requiredMessage: 'o campo CNH é obrigatório',
    ariaLabel: 'CNH',
    required: true,
    matches: {
      pattern: ONLY_NUMBER,
      message: 'informe um número de CNH válido',
    },
    test: [
      {
        name: 'cnh',
        message: 'informe uma CNH válida com no máximo 11 caracteres',
        test: (value) => value && value.length <= 11,
      },
    ],
  };

  const values = Object.assign(defaultValues, overrides);

  return new StringField(values.name, values.label, {
    requiredMessage: values.requiredMessage,
    ariaLabel: values.ariaLabel,
    required: values.required,
    matches: values.matches,
    test: values.test,
  });
};

export const rgModel = (overrides = {}) => {
  const defaultValues = {
    name: 'rgModel',
    label: 'número do RG',
    ariaLabel: 'número do RG',
    required: true,
    requiredMessage: 'o campo RG é obrigatório',
    max: { length: 11 },
    matches: {
      pattern: RG_REGEX,
      message: 'informe um RG válido',
    },
  };

  const values = Object.assign(defaultValues, overrides);

  return new StringField(values.name, values.label, {
    ariaLabel: values.ariaLabel,
    required: values.required,
    requiredMessage: values.requiredMessage,
    matches: values.matches,
    max: values.max,
  });
};

export const emittingOrganModel = (overrides = {}) => {
  const defaultValues = {
    name: 'emittingOrganModel',
    label: 'órgão emissor',
    ariaLabel: 'órgão emissor',
    required: true,
    requiredMessage: i18next.t('PERSONAL_FORM.FIELDS.ISSUING_ORGAN.REQUIRED_MESSAGE'),
    matches: {
      pattern: ONLY_TEXT,
      message: i18next.t('PERSONAL_FORM.FIELDS.ISSUING_ORGAN.INVALID_ORGAN_MESSAGE'),
    },
    test: [
      {
        name: 'issuingOrgan',
        message: 'você atingiu a quantidade máxima de 20 caracteres',
        test: (value) => !value || (value && value.toString().length <= 20),
      },
    ],
  };

  const values = Object.assign(defaultValues, overrides);

  return new StringField(values.name, values.label, {
    ariaLabel: values.ariaLabel,
    required: values.required,
    requiredMessage: values.requiredMessage,
    matches: values.matches,
    test: values.test,
  });
};

export const shippingDateModel = (overrides = {}) => {
  const defaultValues = {
    name: 'shippingDate',
    label: 'data de expedição',
    ariaLabel: 'data de expedição',
    requiredMessage: 'o campo data de expedição é obrigatório',
    required: true,
    type: 'tel',
    matches: {
      pattern: DATE_REGEX,
      message: 'data de expedição inválida',
    },
    test: [
      {
        name: 'shippingDate',
        message: i18next.t('PERSONAL_FORM.FIELDS.SHIPPING_DATE.MESSAGE'),
        test: (value) => moment(value, 'DD/MM/YYYY').isValid(),
      },
      {
        name: 'shippingDate',
        message: i18next.t('PERSONAL_FORM.FIELDS.SHIPPING_DATE.MESSAGE'),
        test: (value) => moment(value, 'DD/MM/YYYY').isBefore(moment().add(1)),
      },
    ],
  };

  const values = Object.assign(defaultValues, overrides);

  return new MaskedField(values.name, values.label, BIRTHDATE_MASK, {
    ariaLabel: values.ariaLabel,
    requiredMessage: values.requiredMessage,
    required: values.required,
    type: values.type,
    matches: values.matches,
    test: values.test,
  });
};

export const validityDateModel = (overrides = {}) => {
  const defaultValue = {
    name: 'validityDate',
    label: 'Data de Validade',
    ariaLabel: 'Data de Validade',
    type: 'tel',
    required: true,
    requiredMessage: 'o campo data de validade é obrigatório',
    matches: {
      pattern: DATE_REGEX,
      message: 'data de validade inválida',
    },
    test: [
      {
        name: 'validityDate',
        message: 'data de validade incorreta',
        test: (value) => moment(value, 'DD/MM/YYYY').isValid(),
      },
      {
        name: 'validityDate',
        message: 'data de validade incorreta',
        test: (value) => moment(value, 'DD/MM/YYYY').isAfter(moment().add(1)),
      },
    ],
  };

  const values = Object.assign(defaultValue, overrides);

  return new MaskedField(values.name, values.label, BIRTHDATE_MASK, {
    ariaLabel: values.ariaLabel,
    type: values.type,
    required: values.required,
    requiredMessage: values.requiredMessage,
    matches: values.matches,
    test: values.test,
  });
};

export const cardNameModel = (overrides = {}) => {
  const defaultValues = {
    name: 'cardName',
    label: 'nome impresso no cartão',
    ariaLabel: 'nome impresso',
    required: true,
    placeholder: 'NOME IMPRESSO',
    requiredMessage: 'obrigatório',
    matches: {
      pattern: NAME_ABREVIATED_REGEX,
      message: 'cartão deve ter nome e sobrenome',
    },
  };
  const values = Object.assign(defaultValues, overrides);
  return new StringField(values.name, values.label, values);
};

export const cvvModel = (overrides = {}) => {
  const defaultValues = {
    name: 'cvv',
    label: 'CVV',
    ariaLabel: 'CVV',
    required: true,
    type: 'tel',
    requiredMessage: 'obrigatório',
    test: [
      {
        name: 'CVV',
        message: 'CVV inválido',
        test: (value) => value && (value.toString().length === 3 || value.toString().length === 4),
      },
    ],
    tooltipProps: {
      title: i18next.t('PAYMENT.WHAT_IS_CVV'),
      content: i18next.t('PAYMENT.CVV_INFO'),
    },
  };
  const values = Object.assign(defaultValues, overrides);
  return new StringField(values.name, values.label, values);
};

export const cardNumberFrontEndValidationModel = (
  acceptedBadges = ['MasterCard', 'Visa'],
  overrides = {},
  othersCardNumberValidations = [],
) => {
  const defaultValues = {
    name: 'cardNumber',
    label: 'número do cartão',
    mask: '9999999999999999',
    ariaLabel: 'número do cartão',
    type: 'tel',
    required: true,
    requiredMessage: 'obrigatório',
    test: [],
  };

  defaultValues.test.push({
    name: 'cardNumber',
    message: 'ops! Bandeira não aceita :(',
    test: (value) => {
      if (value && value.toString().length < 1) return false;
      const bin = checkCreditCardFlag(value?.replace(/\s/g, ''));
      return acceptedBadges.includes(bin);
    },
  });

  for (const otherCardNumberValidation of othersCardNumberValidations) {
    defaultValues.test.push({
      ...otherCardNumberValidation,
      name: 'cardNumber',
    });
  }

  defaultValues.test.push({
    name: 'cardNumber',
    message: 'cartão inválido',
    test: (value) =>
      value?.toString().replace(/\s/g, '').length >= 13 && value?.toString().replace(/\s/g, '').length <= 16,
  });

  console.info(defaultValues);

  const values = Object.assign(defaultValues, overrides);
  return new MaskedField(values.name, values.label, values.mask, values);
};

let validatedCreditCardNumber = null;

export const cardNumberAPIValidationModel = (
  acceptedBadges = ['MasterCard', 'Visa'],
  overrides = {},
  binApiCallback
) => {
  const defaultValues = {
    name: 'cardNumber',
    label: 'número do cartão',
    mask: '9999999999999999',
    type: 'tel',
    required: true,
    requiredMessage: 'obrigatório',
    test: [
      cardLengthTest(),
      {
        name: 'cardNumber',
        message: 'ops! Bandeira não aceita :(',
        test: async (value) => {
          if (!value || value.toString().replace(/\s/g, '').length < 13) return false;
          const bin = value.replace(/ /g, '').slice(0, 6);
          if (validatedCreditCardNumber === bin) return true;
          const binInfo = await obtainCreditCardBanner(bin);
          if (!acceptedBadges.includes(binInfo.bandeira)) return false;
          if (binApiCallback) binApiCallback(binInfo);
          validatedCreditCardNumber = bin;
          return true;
        },
      },
    ],
  };

  const values = Object.assign(defaultValues, overrides);
  return new MaskedField(values.name, values.label, values.mask, values);
};

export const cardExpirationDateModel = (overrides = {}) => {
  const defaultValues = {
    name: 'validationDate',
    label: 'validade',
    mask: '99/9999',
    placeholder: 'MM/AAAA',
    type: 'tel',
    required: true,
    requiredMessage: 'obrigatório',
    test: [
      {
        name: 'validationDate',
        message: 'data inválida',
        test: (value) => (value && value.toString().length > 1 ? moment(value, 'MM/YYYY').isValid() : false),
      },
      {
        name: 'validationDate',
        message: 'data inválida',
        test: (value) =>
          value && value.toString().length === 7 ? !moment(value, 'MM/YYYY').isBefore(moment(), 'month') : false,
      },
    ],
  };
  const values = Object.assign(defaultValues, overrides);
  return new MaskedField(values.name, values.label, values.mask, values);
};

export const ccModel = (overrides = {}) => {
  const defaultValues = {
    name: 'cc',
    label: i18next.t('PAYMENT_MODULES.CC.TITLE'),
    required: true,
    requiredMessage: i18next.t('PAYMENT_MODULES.CC.REQUIRED_MESSAGE'),
    matches: {
      pattern: /^[0-9xX]+$/,
      message: i18next.t('PAYMENT_MODULES.CC.ONLY_NUMBERS_AND_LETTERS'),
    },
    test: [
      {
        name: 'CC',
        message: i18next.t('PAYMENT_MODULES.CC.INVALID_MESSAGE'),
        test: (value) => value && value.toString().length >= 2,
      },
      {
        name: 'CC',
        message: i18next.t('PAYMENT_MODULES.CC.INVALID_MESSAGE'),
        test(cc) {
          const { bank } = this.parent;
          if (!bank || bank !== i18next.t('PAYMENT_MODULES.BANK.OPTIONS.ITAU.VALUE')) return true;
          const onlyNumbers = new RegExp(/^[0-9]+$/);
          return onlyNumbers.test(cc);
        },
      },
    ],
  };
  const values = Object.assign(defaultValues, overrides);
  return new StringField(values.name, values.label, values);
};

export const agencyModel = (overrides = {}) => {
  const defaultValues = {
    name: 'agency',
    label: i18next.t('PAYMENT_MODULES.AGENCY.TITLE'),
    mask: '9999',
    ariaLabel: i18next.t('PAYMENT_MODULES.AGENCY.NUMBER_AGENCY'),
    type: 'tel',
    required: true,
    requiredMessage: i18next.t('PAYMENT_MODULES.AGENCY.REQUIRED_MESSAGE'),
  };
  const values = Object.assign(defaultValues, overrides);
  return new MaskedField(values.name, values.label, values.mask, values);
};

export const bankModel = (overrides = {}) => {
  const defaultValues = {
    name: 'bank',
    label: i18next.t('PAYMENT_MODULES.BANK.TITLE'),
    options: [
      {
        value: i18next.t('PAYMENT_MODULES.BANK.OPTIONS.ITAU.VALUE'),
        label: i18next.t('PAYMENT_MODULES.BANK.OPTIONS.ITAU.LABEL'),
      },
      {
        value: i18next.t('PAYMENT_MODULES.BANK.OPTIONS.BRADESCO.VALUE'),
        label: i18next.t('PAYMENT_MODULES.BANK.OPTIONS.BRADESCO.LABEL'),
      },
      {
        value: i18next.t('PAYMENT_MODULES.BANK.OPTIONS.SANTANDER.VALUE'),
        label: i18next.t('PAYMENT_MODULES.BANK.OPTIONS.SANTANDER.LABEL'),
      },
    ],
    ariaLabel: i18next.t('PAYMENT_MODULES.BANK.TITLE'),
    required: true,
    requiredMessage: i18next.t('PAYMENT_MODULES.BANK.REQUIRED_MESSAGE'),
  };
  const values = Object.assign(defaultValues, overrides);
  return new EnumeratorField(values.name, values.label, values);
};
