import { put, select } from 'redux-saga/effects';
import { setInstallmentOption } from 'store/ducks/residencial';
import {
  residencialHiringResponsePaymentOptionsSelector,
  residencialSelectedInstallmentSelector,
} from '../selectors/residencial';

export default function* setInstallmentPaymentTypeAction({ data }) {
  const hiringResponsePaymentOptions = yield select(residencialHiringResponsePaymentOptionsSelector);
  const hiringResponseInstallment = yield select(residencialSelectedInstallmentSelector);
  const filterInstallmentsByMethod = (method, paymentOptions) =>
    paymentOptions.filter(option => option?.metodo === method);

  const installment = filterInstallmentsByMethod(data, hiringResponsePaymentOptions)?.filter(
    item => item?.parcelas === hiringResponseInstallment?.parcelas,
  );

  yield put(
    setInstallmentOption({
      valor_premio: installment[0].valor_parcela,
      ...installment[0],
    }),
  );
}
