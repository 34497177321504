const personal = {
  PERSONAL: {
    HEADER: {
      TITLE: 'dados do **titular do seguro**',
      SUBTITLE: 'vamos preencher seus dados para continuar a contratação do seu seguro',
    },
    DOCUMENTS_TITLE: 'documentos do titular do seguro',
    MAILING_ADDRESS: 'endereço pessoal **para correspondência**',
  },
};

export default personal;
