import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../theme/palette';
import insuranceLogo from '../../../assets/images/logo-seguros-itau-branco.png';
import { hasStyleMapper } from '../../../pages/Welcome/utils/util';

export const useStyles = (styleMapper, location) =>
  makeStyles((theme) => {
    const footer = {
      colorHeading: hasStyleMapper(styleMapper, location) ? styleMapper.footer.font.colorHeading : '#F1AE2F',
      fontColor: hasStyleMapper(styleMapper, location) ? styleMapper.footer.font.color : palette.white,
      fontWeight: hasStyleMapper(styleMapper, location) ? styleMapper.footer.font.weight : 'ItauText-XBold',
      backGroundColor: hasStyleMapper(styleMapper, location)
        ? styleMapper.footer.backgroundColor
        : palette.backgroundDarkBlue,
      image: hasStyleMapper(styleMapper, location) ? styleMapper.footer.image : insuranceLogo,
    };

    return {
      information: {
        color: footer.fontColor,
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        '& h2': {
          fontSize: '22px',
          fontWeight: 100,
          color: footer.colorHeading,
          fontFamily: 'ItauText-Regular',
          margin: 'auto',
          width: 'fit-content',
          [theme.breakpoints.up('md')]: {
            margin: '0px',
          },
        },
        description: {
          fontSize: '14px',
          fontFamily: 'ItauText-Regular',
          margin: '0px 0px 45px 0px',
          [theme.breakpoints.up('md')]: {
            color: footer.fontColor,
            margin: '0px 0px 45px 0px',
          },
        },
        '& p': {
          fontSize: '14px',
          fontFamily: 'ItauText-Regular',
          margin: '10px auto',
        },
        '& h3': {
          fontSize: '14px',
          fontFamily: 'ItauText-Regular',
          color: footer.fontColor,
          textAlign: 'left',
          margin: '10px auto',
          width: 'fit-content',
          [theme.breakpoints.up('md')]: {
            fontFamily: 'ItauText-XBold',
            margin: '10px 0px',
          },
        },
        [theme.breakpoints.up('md')]: {
          width: '100%',
          textAlign: 'left',
          padding: '42px 0 30px 0',
          borderBottom: '1px solid #969290',
        },
      },
      '& a': {
        color: footer.fontColor,
        fontSize: '14px',
        fontFamily: 'ItauText-Regular',
        margin: '10px 0px',
        '& .description': {
          margin: '0px 0px 45px 0px',
        },
      },
      footerTopic: {
        width: '40%',
      },
      footerItems: {
        alignItems: 'baseline',
        display: 'flex',
        flexDirection: 'row',
        width: '60%',
        flexWrap: 'wrap',
        '& > div': {
          marginBottom: '10px',
        },
      },
      footerItemContent: {
        width: '33%',
        display: 'flex',
      },
      central: {
        display: 'flex',
        margin: 'auto',
        alignItems: 'baseline',
        width: '80%',
        paddingTop: '20px',
      },
      cancellation: {
        display: 'flex',
        margin: 'auto',
        alignItems: 'baseline',
        width: '80%',
        paddingTop: '20px',
      },
      doubts: {
        borderBottom: '1px solid #969290',
        display: 'flex',
        margin: 'auto',
        alignItems: 'baseline',
        width: '80%',
        paddingBottom: '20px',
      },
    };
  })();
