import ANALYTICS_CATEGORY from '../category.json';

export default {
  ODONTO_SELECT_CITY: {
    action: 'odonto_select_city',
    label: 'BTN:Odonto',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  BEGIN_CHECKOUT_ODONTO: {
    action: 'begin_checkout_odonto',
    label: 'BTN:Odonto',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_ODONTO_PERSONAL_DATA: {
    action: 'checkout_progress_odonto_personal_data',
    label: 'BTN:Odonto:Contratar:DadosPessoais',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_ODONTO_BENEFICIARIES: {
    action: 'checkout_progress_odonto_beneficiaries',
    label: 'BTN:Odonto:Contratar:Beneficiarios',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_ODONTO_AUTH_EMAIL: {
    action: 'checkout_progress_odonto_auth_email',
    label: 'BTN:Odonto:Contratar:AutenticacaoEmail',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_ODONTO_AUTH_CELLPHONE_RESEND: {
    action: 'checkout_progress_odonto_auth_celphone_resend',
    label: 'BTN:Odonto:ReenviarTelefoneAutenticacao',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_ODONTO_AUTH_CELLPHONE: {
    action: 'checkout_progress_odonto_auth_celphone',
    label: 'BTN:Odonto:Contratar:AutenticacaoCelular',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_ODONTO_PAYMENT: {
    action: 'checkout_progress_odonto_payment',
    label: 'BTN:Odonto:Contratar:Pagamento',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  ADD_PAYMENT_INFO: {
    action: 'add_payment_info',
    label: 'BTN:Odonto:Contratar:Pagamento',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  CHECKOUT_PROGRESS_ODONTO_SUMMARY: {
    action: 'checkout_progress_odonto_summary',
    label: 'BTN:Odonto:Contratar:Resumo',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  PURCHASE: {
    action: 'purchase',
    label: 'BTN:Odonto:Contratar:Sucesso',
    category: ANALYTICS_CATEGORY.SUCESSO,
  },
  EMAIL_RESEND: {
    action: 'email_resend',
    label: 'BTN:Odonto:Contratar:AutenticacaoEmail:Reenviar',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  EMAIL_UPDATE: {
    action: 'email_update',
    label: 'BTN:Odonto:Contratar:AutenticacaoCelular:Reenviar',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  GENERATE_LEAD: {
    action: 'generate_lead',
    label: 'BTN:Odonto:GerarLead',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  NEW_HOME: {
    SPOTLIGHT_CLICK: {
      action: 'Home',
      label: 'BTN:Home:Odonto:Destaque:{1}:{0}',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    PLAN_DETAIL_CLICK: {
      action: 'Home',
      label: 'BTN:Home:Odonto:Cotacao:{1}:{0}DetalhesDoPlano',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    PLAN_SELECT_CLICK: {
      action: 'Home',
      label: 'BTN:Home:Odonto:Cotacao:{0}:QueroEssePlano',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    LINK_METLIFE_SITE: {
      action: 'Home',
      label: 'BTN:Home:Odonto:SiteMetLife',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    LINK_ACCREDITED_NETWORK: {
      action: 'Home',
      label: 'BTN:Home:Odonto:RedeCredenciada',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    PLAN_SUMMARY: {
      action: 'Home',
      label: 'BTN:Home:Odonto:ResumoDosPlanos:{1}:{0}',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    FAQ_EXPAND: {
      action: 'Home',
      label: 'BTN:Home:Odonto:ExpandirPerguntaFrequente:{0}',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    FAQ_SEE_MORE: {
      action: 'Home',
      label: 'BTN:Home:Odonto:VerMaisPerguntasFrequente',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    QUOTATION_SWITCH_PLAN: {
      action: 'Home',
      label: 'BTN:Home:Odonto:Cotacao:{0}:TrocarPorOutro',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    QUOTATION_SEARCH_CLICK: {
      action: 'Home',
      label: 'BTN:Home:Odonto:Cotacao:{1}:Buscar:{0}',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    QUOTATION_SEARCH_SUCESS: {
      action: 'Home',
      label: 'Alerta:Home:Odonto:Cotacao:{0}:Buscar:Sucesso',
      category: ANALYTICS_CATEGORY.OBJETO_VISTO,
    },
    QUOTATION_SEARCH_REGION_FAIL: {
      action: 'Home',
      label: 'Alerta:Home:Odonto:Cotacao:{0}:NaoAtendemosEssaRegiao',
      category: ANALYTICS_CATEGORY.OBJETO_VISTO,
    },
    QUOTATION_SEARCH_TRY_DIFF_REGION: {
      action: 'Home',
      label: 'BTN:Home:Odonto:Cotacao:{0}:TentarOutroCep',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    QUOTATION_SEE_MORE_COVERAGES: {
      action: 'Home',
      label: 'BTN:Home:Odonto:Cotacao:{0}:VerMaisCoberturas',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
  },
};
