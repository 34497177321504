import breakpoints from './breakpoints';

export default {
  footer: {
    minHeight: 224,
    [breakpoints.up('sm')]: {
      minHeight: 256,
    },
  },
};
