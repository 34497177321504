import { makeStyles, RadioGroup } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  radioButton: {
    width: '30%',
    marginRight: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  radioGroup: {
    width: '100%',
  },
  nestedQuestionSpacing: {
    justifyContent: 'space-between',
  },
  radioGroupNoWrap: {
    width: '100%',
    flexWrap: 'noWrap',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px',
  },
  inputLabel: {
    fontSize: theme.toRem(16),
    fontFamily: 'ItauText-Regular',
    fontWeight: 400,
    lineHeight: 1.5,
    justifyItems: 'center',
    color: `${theme.palette.text.primary}`,
    position: 'inherit',
    '&:focused': {
      color: `${theme.palette.inputLabelColor}`,
    },
  },
  error: {
    color: theme.palette.error.main,
    fontFamily: 'ItauText-Bold',
    fontSize: theme.toRem(16),
  },
  errorIcon: {
    fontSize: theme.toRem(32),
    marginLeft: theme.spacing(4),
    color: theme.palette.error.main,
  },
}));

export const StyledRadioGroup = withStyles({
  root: {
    marginTop: '0.625rem',
  },
})(RadioGroup);
