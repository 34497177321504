import {
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ENVIRONMENTS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
} from 'trackingV2/constants';

const productName = 'Bem-Estar';

export const ANALYTICS_WELFARE = {
  T1: {
    nome: `LS:NL:NCC:${productName}:DadosDoTitular`,
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:CardDoSeguro:{0}:{1}`,
    noInteraction: false,
  },
  A2: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:CardDoSeguro:CondicoesGerais`,
    noInteraction: false,
  },
  A3: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:DadosTitular:Passo1:Voltar`,
    noInteraction: false,
  },
  A4: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:DadosTitular:Passo1:Continuar`,
    noInteraction: false,
  },
  T2: {
    nome: `LS:NL:NCC:${productName}:Validacao`,
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A5: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Validacao:Passo1:Voltar`,
    noInteraction: false,
  },
  A6: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Validacao:Passo1:Continuar`,
    noInteraction: false,
  },
  A7: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:ReenviarEmail`,
    noInteraction: false,
  },
  A8: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:AlterarEmail`,
    noInteraction: false,
  },
  A9: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Validacao:Passo2:Voltar`,
    noInteraction: false,
  },
  A10: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:ReenviarCodigo`,
    noInteraction: false,
  },
  A11: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Validacao:Passo3:Continuar`,
    noInteraction: false,
  },
  T3: {
    nome: `LS:NL:NCC:${productName}:Pagamento`,
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A12: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:CondicoesGerais`,
    noInteraction: false,
  },
  A13: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Pagamento:Voltar`,
    noInteraction: false,
  },
  A14: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Pagamento:Continuar`,
    noInteraction: false,
  },
  A15: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:CardDoSeguro:{0}:{1}`,
    noInteraction: false,
  },
  A16: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:CardDoSeguro:CondicoesGerais`,
    noInteraction: false,
  },
  T4: {
    nome: `LS:NL:NCC:${productName}:Resumo`,
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A17: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Resumo:Voltar`,
    noInteraction: false,
  },
  A18: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Resumo:Finalizar`,
    noInteraction: false,
  },
  T5: {
    nome: `LS:NL:NCC:${productName}:Sucesso`,
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A19: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `Alerta:${productName}:Sucesso`,
    noInteraction: true,
  },
  A20: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Likert:{0}`,
    noInteraction: false,
  },
  A21: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Plano:PacotePrincipal:{0}`,
    noInteraction: false,
  },
  A22: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.BEM_ESTAR,
    label: `BTN:${productName}:Pagamento:{0}`,
    noInteraction: false,
  },
};
