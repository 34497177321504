import { createContext, useContext } from 'react';
import config from 'config';
import { useRemoteConfigConsumer } from 'providers/RemoteConfigProvider';
import { assembleAnalyticsCustomEvent, assembleAnalyticsPageLoadEvent, ANALYTICS_FLOWS } from 'trackingV2';
import { AnalyticsPageLoadEventPageInterface } from 'interfaces/analytics/AnalyticsPageLoadEvent.interface';
import { AnalyticsCustomEventInterface } from 'interfaces/analytics/AnalyticsCustomEvent.interface';

export const AnalyticsV2Context = createContext({});
const { ENABLE_GOOGLE_ANALYTICS }: any = config;

interface AnalyticsV2ProviderProps {
  children: JSX.Element;
}

interface AnalyticsLogInterface {
  message: string;
  showData?: boolean;
}

interface FrameworkDA {
  Track: () => {};
}

interface Window {
  analyticsData: object;
  _frameworkDA: FrameworkDA;
}

declare var window: Window;

export default function AnalyticsV2Provider({ children }: AnalyticsV2ProviderProps) {
  const remoteConfig = useRemoteConfigConsumer();

  const analyticsLog = ({ message, showData }: AnalyticsLogInterface) => {
    const newMessage = `[ANALYTICS] ${message}`;

    console.log(newMessage);
    if (showData) console.log('\nEvent loaded:', window.analyticsData);
  };

  const feedFrameworkDA = () => {
    if (!ENABLE_GOOGLE_ANALYTICS) return analyticsLog({ message: 'Analytics tracking is disabled.', showData: true });
    if (window?._frameworkDA) return window._frameworkDA.Track();

    return analyticsLog({ message: 'Analytics tracking is activated but FramewordDA is not found', showData: true });
  };

  const registerAnalytcsPageLoadEvent = (pageLoadEvent: AnalyticsPageLoadEventPageInterface) => {
    const payload = assembleAnalyticsPageLoadEvent(pageLoadEvent);
    window.analyticsData = { ...payload };
    feedFrameworkDA();
  };

  const registerAnalyticsCustomEvent = (
    event: AnalyticsCustomEventInterface,
    labelValues: string[] = [],
    options: object,
  ) => {
    const payload = assembleAnalyticsCustomEvent(event, labelValues, options);
    window.analyticsData = { ...payload };
    feedFrameworkDA();
  };

  const checkFlowStatus = (flow: string) => {
    const { DISABLED_GA_FLOWS } = remoteConfig;
    const isThisFlowDisabled = DISABLED_GA_FLOWS.find((f: string) => flow === f);
    return isThisFlowDisabled;
  };

  const dispatchAnalyticsEvent = (flow: string, eventCode: string, labelValues: string[], options: object) => {
    const pageLoadEventCodeMask: RegExp = /[Tt][0-9]+/;
    const customEventCodeMask: RegExp = /[Aa][0-9]+/;
    const isPageLoadEvent: RegExpMatchArray | null = eventCode.match(pageLoadEventCodeMask);
    const isCustomEvent: RegExpMatchArray | null = eventCode.match(customEventCodeMask);
    const wantedAnalyticsEvent: any = ANALYTICS_FLOWS[flow]?.[eventCode];
    const isThisFlowDisabled = checkFlowStatus(flow);

    if (isThisFlowDisabled) return;
    if (!wantedAnalyticsEvent) return analyticsLog({ message: `Analytics event not found (${flow}.${eventCode})` });
    if (isPageLoadEvent) return registerAnalytcsPageLoadEvent(wantedAnalyticsEvent);
    if (isCustomEvent) return registerAnalyticsCustomEvent(wantedAnalyticsEvent, labelValues, options);
  };

  return <AnalyticsV2Context.Provider value={dispatchAnalyticsEvent}>{children}</AnalyticsV2Context.Provider>;
}

function useAnalyticsV2ProviderConsumer() {
  return useContext(AnalyticsV2Context);
}

export { AnalyticsV2Provider, useAnalyticsV2ProviderConsumer };
