import axios from 'axios';

import config, { performance } from '../../../config';

const { E2E_ODONTO_PATH } = config;

export const hasAvailablePlans = async (idCity, idUF, formatedCity) => {
  const trace = performance.trace('hasAvailablePlans');
  trace.start();

  const response = await axios.get(
    `${E2E_ODONTO_PATH}/produtos?id_municipio=${idCity}&uf=${idUF}&municipio_formatado=${formatedCity}`,
  );

  trace.stop();
  return response.data.map(r => r.id_produto);
};
