import { createTheme, responsiveFontSizes } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import palette from './palette';
import baseTheme from '../base';

const theme = createTheme(
  {
    ...baseTheme,
    palette,
  },
  ptBR,
);

export default responsiveFontSizes(theme);
