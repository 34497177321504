import { call } from 'redux-saga/effects';
import scopes from 'auth/scopes';
import recoveryAuthScope from './authScopes';
import startRecoveryE2EOdonto from '../odonto/recoveryE2EData';
import startRecoveryE2ECancellation from '../cancellation/recoveryE2EData';
import genericStartRecoveryE2E from './genericRecoveryE2E';

export default function* recoveryE2EData(action) {
  const { scope } = action.data;
  switch (scope) {
    case scopes.ODONTO:
      yield call(startRecoveryE2EOdonto, action);
      break;
    case scopes.CANCELLATION:
      yield call(startRecoveryE2ECancellation, action);
      break;
    case scopes.RESID:
      yield call(genericStartRecoveryE2E, action);
      break;
    case scopes.AUTO:
      yield call(genericStartRecoveryE2E, action);
      break;
    case scopes.WELFARE:
      yield call(genericStartRecoveryE2E, action);
      break;
    case scopes.PET:
      yield call(genericStartRecoveryE2E, action);
      break;
    default:
      yield call(recoveryAuthScope, action);
      break;
  }
}
