import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  margin-left: 8px;
  background-color: #ec7000;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
`;

const Label = styled.label``;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  flex: 1;
`;

export function VirtualEnvironmentsTab() {
  const KEY = 'x-environment';
  const [xEnvironment, setXEnvironment] = useState('');

  const setToStorage = () => {
    window.sessionStorage.setItem(KEY, xEnvironment);
  };

  useEffect(() => {
    setXEnvironment(window.sessionStorage.getItem(KEY));
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <Input
        value={xEnvironment}
        placeholder={'digite um valor para o ' + KEY}
        onChange={e => setXEnvironment(e.target.value)}
      />
      <Button onClick={setToStorage}>persistir</Button>
    </div>
  );
}
