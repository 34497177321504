import breakpoints from './breakpoints';
import mixins from './mixins';
import overrides from './overrides';
import palette from './palette';
import props from './props';
import shape from './shape';
import typography from './typography';
import functions from './functions';
import zIndex from './zIndex';

export default {
  breakpoints,
  mixins,
  overrides,
  palette,
  props,
  shape,
  typography,
  functions,
  zIndex,
};
