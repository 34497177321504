import { call, put } from 'redux-saga/effects';
import { Types, Creators } from '../../ducks/products';
import { getProductsLeads } from '../../../api/Products/leads';

export default function* loadProductsLeads() {
  try {
    yield put({ type: Types.START_GET_PRODUCTS_LEADS });
    const productsLeads = yield call(getProductsLeads);
    yield put(Creators.setProductsLeads(productsLeads));
  } catch (e) {
    yield put({ type: Types.ERROR_GET_PRODUCTS_LEADS });
  }
}
