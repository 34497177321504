export const Types = {
  SET_SELECTED_PLAN: 'odonto/SET_SELECTED_PLAN',
  SET_ODONTO: 'odonto/SET_ODONTO',
  SET_PERSONAL_DATA: 'odonto/SET_PERSONAL_DATA',
  LOAD_AVAILABLE_PLANS: 'odonto/LOAD_AVAILABLE_PLANS',
  LOAD_PENDING_AVAILABLE_PLANS: 'odonto/LOAD_PENDING_AVAILABLE_PLANS',
  LOAD_SUCCESS_AVAILABLE_PLANS: 'odonto/LOAD_SUCCESS_AVAILABLE_PLANS',
  LOAD_ERROR_AVAILABLE_PLANS: 'odonto/LOAD_ERROR_AVAILABLE_PLANS',
  RESET_LOAD_AVAILABLE_PLANS: 'odonto/RESET_LOAD_AVAILABLE_PLANS',
  SET_PRODUCT_INFORMATION: 'odonto/SET_PRODUCT_INFORMATION',
  UPDATE_PRODUCT_INFORMATION: 'odonto/UPDATE_PRODUCT_INFORMATION',
  CHANGE_QUOTATION_STATE_TRIGGER: 'odonto/CHANGE_QUOTATION_STATE_TRIGGER',
  RESET_DATA: 'odonto/RESET_DATA',
  SET_KINSHIP: 'odonto/SET_KINSHIP',
  SET_JURIDIC_TEXT: 'odonto/SET_JURIDIC_TEXT',
  SET_PAYMENT_TYPE: 'odonto/SET_PAYMENT_TYPE',
  SET_GENDER: 'odonto/SET_GENDER',
  SET_ODONTO_DIVERGENT_DATA_HOLDER: 'odonto/SET_ODONTO_DIVERGENT_DATA_HOLDER',
  SET_ODONTO_DIVERGENT_DATA_HOLDER_CONTINUE_BUTTON: 'odonto/SET_ODONTO_DIVERGENT_DATA_HOLDER_CONTINUE_BUTTON',
};

export const initialState = {
  data: null,
  selectedPlan: null,
  availablePlans: {
    data: null,
    load: { status: null, error: null },
  },
  productInformation: {
    idProduct: null,
    state: null,
    city: null,
    uf: null,
  },
  paymentType: null,
  gender: null,
  personalData: null,
  odontoDivergentDataHolder: false,
  odontoDivergentDataHolderContinueButton: false,
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.RESET_DATA: {
      return initialState;
    }
    case Types.SET_ODONTO: {
      return {
        ...action.data,
      };
    }
    case Types.SET_PERSONAL_DATA: {
      return {
        ...state,
        personalData: action.personalData
      }
    }
    case Types.SET_SELECTED_PLAN: {
      return {
        ...state,
        selectedPlan: action.plan,
      };
    }
    case Types.LOAD_PENDING_AVAILABLE_PLANS: {
      return {
        ...state,
        availablePlans: {
          ...state.availablePlans,
          load: { status: 'pending', error: null },
        },
      };
    }
    case Types.LOAD_SUCCESS_AVAILABLE_PLANS: {
      return {
        ...state,
        availablePlans: {
          data: action.data,
          load: { status: 'success', error: null },
        },
      };
    }
    case Types.LOAD_ERROR_AVAILABLE_PLANS: {
      return {
        ...state,
        availablePlans: {
          ...state.availablePlans,
          load: { status: 'error', error: action.error },
        },
      };
    }
    case Types.RESET_LOAD_AVAILABLE_PLANS: {
      return {
        ...state,
        availablePlans: {
          data: null,
          load: { status: null, error: null },
        },
      };
    }
    case Types.SET_PRODUCT_INFORMATION: {
      return {
        ...state,
        productInformation: action.data,
      };
    }
    case Types.UPDATE_PRODUCT_INFORMATION: {
      return {
        ...state,
        productInformation: { ...state.productInformation, ...action.data },
      };
    }
    case Types.CHANGE_QUOTATION_STATE_TRIGGER: {
      return {
        ...state,
        quotationTrigger: action.trigger,
      };
    }
    case Types.SET_JURIDIC_TEXT: {
      return {
        ...state,
        juridicText: action.data,
      };
    }
    case Types.SET_KINSHIP: {
      return {
        ...state,
        kinship: action.data,
      };
    }
    case Types.SET_PAYMENT_TYPE: {
      return {
        ...state,
        paymentType: action.paymentType,
      };
    }
    case Types.SET_GENDER: {
      return {
        ...state,
        gender: action.gender,
      };
    }
    case Types.SET_ODONTO_DIVERGENT_DATA_HOLDER: {
      return {
        ...state,
        odontoDivergentDataHolder: action.odontoDivergentDataHolder,
      };
    }
    case Types.SET_ODONTO_DIVERGENT_DATA_HOLDER_CONTINUE_BUTTON: {
      return {
        ...state,
        odontoDivergentDataHolderContinueButton: action.odontoDivergentDataHolderContinueButton,
      };
    }
    default:
      return state;
  }
}

export function setOdonto(data) {
  return {
    type: Types.SET_ODONTO,
    data,
  };
}

export function setSelectedPlan(plan) {
  return {
    type: Types.SET_SELECTED_PLAN,
    plan,
  };
}

export function setPersonalData(personalData) {
  return {
    type: Types.SET_PERSONAL_DATA,
    personalData
  };
}

export function hasAvailablePlans(data) {
  return {
    type: Types.LOAD_AVAILABLE_PLANS,
    data,
  };
}

export function updateProductInformation(data) {
  return {
    type: Types.UPDATE_PRODUCT_INFORMATION,
    data,
  };
}

export function resetAvailablePlans() {
  return {
    type: Types.RESET_LOAD_AVAILABLE_PLANS,
  };
}

export function changeQuotationCardStateTrigger(trigger) {
  return {
    type: Types.CHANGE_QUOTATION_STATE_TRIGGER,
    trigger,
  };
}

export function resetData() {
  return {
    type: Types.RESET_DATA,
  };
}

export function setJuridicalText(data) {
  return {
    type: Types.SET_JURIDIC_TEXT,
    data,
  };
}

export function setKinship(data) {
  return {
    type: Types.SET_KINSHIP,
    data,
  };
}

export function setPaymentType(paymentType) {
  return {
    type: Types.SET_PAYMENT_TYPE,
    paymentType,
  };
}

export function setGender(gender) {
  return {
    type: Types.SET_GENDER,
    gender,
  };
}

export function setOdontoDivergentDataHolder(odontoDivergentDataHolder) {
  return {
    type: Types.SET_ODONTO_DIVERGENT_DATA_HOLDER,
    odontoDivergentDataHolder,
  };
}

export function setOdontoDivergentDataHolderContinueButton(odontoDivergentDataHolderContinueButton) {
  return {
    type: Types.SET_ODONTO_DIVERGENT_DATA_HOLDER_CONTINUE_BUTTON,
    odontoDivergentDataHolderContinueButton,
  };
}
