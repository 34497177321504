import axios from 'axios';

import config, { performance } from 'config';

const { E2E_PATH } = config;

const assistanceQuotation = async (offerType, data, headers) => {
  const trace = performance.trace('assistanceDetail');
  trace.start();
  const response = await axios.post(`${E2E_PATH}/simulacoes/${offerType}`, data, { headers });
  trace.stop();
  return response.data;
};

export default assistanceQuotation;
