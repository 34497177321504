import { call, put, select } from 'redux-saga/effects';
import { cancelRetry, setErrorSagaAction } from 'store/ducks/httpErrors';
import deburr from 'lodash/deburr';
import { postAutoSimulation } from 'api/E2E/Auto/postAutoSimulation';
import { getAutoSimulation } from 'api/E2E/Auto/getAutoSimulation';
import { automobileRequestSelector } from 'store/auto/store/sagas/selectors/auto';
import { finishQuotation, Types } from 'store/auto/store/ducks/automovel';
import { currentFranchiseSelector } from 'store/auto/store/sagas/selectors/franchise';
import {
  startSimulation,
  errorSimulation,
  successSimulation,
  addAuthData,
  rejectQuotation,
} from 'store/auto/store/ducks/autoFlow';

const fuelMapper = [
  'alcool',
  'gasolina',
  'diesel',
  'gnv',
  'tetrafuel',
  'outros',
  'gnv/gasolina/alcool',
  'gasolina/alcool',
  'tracionado',
  'gasolina/eletrico',
  'eletrico',
];

const preparePayload = data => {
  const { oferta, dados_renovacao, item_risco, proponente, questoes, condutores, franquia } = data;
  const { coberturas, assistencias } = oferta.produtos[0];
  const { combustivel } = item_risco.veiculo;
  const normalizeFuel = deburr(combustivel);
  const formattedFuel = fuelMapper.findIndex(fuel => fuel === normalizeFuel.replace(/\s/g, '').toLowerCase());

  const formattedRenewingData = dados_renovacao;
  if (!dados_renovacao.classe_bonus) {
    formattedRenewingData.classe_bonus = undefined;
  }
  if (!dados_renovacao.codigo_irb) {
    formattedRenewingData.codigo_irb = undefined;
  }
  const formattedCoverages = coberturas.map(coverage => {
    const currentCoverage = coverage.importancias_segurada ? coverage.importancias_segurada[0] : {};
    return {
      id_cobertura: coverage.id_cobertura,
      franquia,
      importancias_segurada: currentCoverage?.maximo || 0,
    };
  });

  const formattedAssists = assistencias.map(coverage => ({
    id_assistencia: coverage.id_assistencia,
  }));
  const currentConductor = condutores[0][0];
  const formattedQuestions = questoes.filter(question => question.resposta);
  const newData = {
    oferta: {
      id_oferta: oferta.id_oferta,
      produtos: [
        {
          id_produto: oferta.produtos[0].id_produto,
          importancia_base: oferta.produtos[0].importancia_base.maximo,
          coberturas: formattedCoverages,
          assistencias: formattedAssists,
        },
      ],
    },
    dados_renovacao: formattedRenewingData,
    item_risco: {
      veiculo: {
        ...item_risco.veiculo,
        blindado: item_risco.veiculo.blindado || false,
        chassi_remarcado: item_risco.veiculo.chassi_remarcado || false,
        gnv: item_risco.veiculo.gnv || false,
        financiado: item_risco.veiculo.financiado || false,
        placa: undefined,
        combustivel: formattedFuel,
        rastreadores: item_risco.veiculo.rastreadores || [],
      },
    },
    proponente: {
      ...proponente,
      endereco: {
        cidade: proponente.endereco.cidade,
        estado: proponente.endereco.estado,
        cep: proponente.endereco.cep,
      },
    },
    questoes: formattedQuestions,
    condutores: [
      {
        ...currentConductor,
        residentes_risco:
          currentConductor.residentes_risco && Object.keys(currentConductor.residentes_risco).length > 0
            ? {
                ...currentConductor.residentes_risco,
                sexo: parseInt(currentConductor.residentes_risco.sexo),
                utiliza_veiculo: !!currentConductor.residentes_risco.utiliza_veiculo,
              }
            : undefined,
      },
    ],
  };
  return newData;
};

export default function* loadQuotationAction({ data }) {
  try {
    const franchise = yield select(currentFranchiseSelector);
    yield put(startSimulation());
    const { onStart } = data;
    if (onStart) {
      onStart();
    }
    const request = yield select(automobileRequestSelector);
    let payload = preparePayload({ ...request, franquia: franchise });
    payload = {
      ...payload,
      captcha: data.captcha,
    };
    const url = yield call(postAutoSimulation, data.franchise, payload);
    yield put(cancelRetry());
    const response = yield call(getAutoSimulation, url);
    const responseData = {
      franquia: data.franchise,
      data: response,
    };
    const cellphone = payload.proponente.contatos.find(contact => contact.tipo === 'telefone').valor;
    const email = payload.proponente.contatos.find(contact => contact.tipo === 'email').valor;
    const authData = {
      name: payload.proponente.nome,
      cpf: payload.proponente.cpf,
      cellphone,
      email,
    };
    yield put(addAuthData(authData));
    yield put(successSimulation());
    yield put(finishQuotation(responseData));
  } catch (err) {
    if (err?.response) {
      if (err.response.status === 406) {
        yield put(rejectQuotation());
      } else {
        yield put(errorSimulation());
      }
      yield put(setErrorSagaAction({ data, type: Types.LOAD_QUOTATION }));
    } else {
      yield put(errorSimulation());
      yield put(setErrorSagaAction({ data, type: Types.LOAD_QUOTATION }));
    }
  }
}
