export const Types = {
  ADD_DATA: 'CACELLATION/ADD_DATA',
  ADD_CANCELLED_DATA: 'CACELLATION/ADD_CANCELLED_DATA',
  RESET: 'CACELLATION/RESET',
};

const initialState = {
  data: {
    idProposta: null,
    nome: null,
    cpf: null,
    celular: null,
    email: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.ADD_CANCELLED_DATA: {
      return {
        ...state,
        cancelled: { ...state.cancelled, ...action.data },
      };
    }
    case Types.ADD_DATA: {
      return {
        data: { ...state.data, ...action.data },
      };
    }
    case Types.RESET: {
      return {
        initialState,
      };
    }
    default:
      return state;
  }
}

export function addCancelledData(data) {
  return {
    type: Types.ADD_CANCELLED_DATA,
    data,
  };
}

export function addCancellationData(data) {
  return {
    type: Types.ADD_DATA,
    data,
  };
}

export function resetCancellationData() {
  return {
    type: Types.RESET,
  };
}
