import { put } from 'redux-saga/effects';

export default function genericCreateOfferAction(creator) {
  return function* createOfferAction({ data: { id_oferta, id_produto, assistencias, valor_mensal_minimo } }) {
    const oferta = {
      id_oferta,
      produtos: [
        {
          id_produto,
          assistencias: assistencias.map(assistance => ({
            id_assistencia: assistance.id_assistencia,
          })),
        },
      ],
    };
    yield put(creator.addSelectedAssistances({ assistencias_selecionadas: assistencias, valor_mensal_minimo }));
    yield put(creator.createQuotationRequestOffer(oferta));
  };
}
