/* eslint-disable import/no-cycle */

import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Message, Loading } from '../../../components';
import Footer from '../../../layout/Footer/Footer';
import { useProjectContextProvider } from '../providers/ProjectContextProvider';

const LandPage = React.lazy(() => import('../pages/LandPage'));

function Routes() {
  const context = useProjectContextProvider();
  const { ready, theme, config } = context;

  if (!ready.loaded) return <Loading />;
  return (
    <React.Suspense fallback={<Loading />}>
      <MuiThemeProvider theme={theme}>
        <div>
          <div className="content" role="main">
            <Switch>
              <Route
                exact
                path={config.route}
                component={() => <LandPage redirect={config.dispatchTo} theme={config.source} />}
              />
              <Redirect to="/" />
            </Switch>
          </div>
          <Message />
          <Footer />
        </div>
      </MuiThemeProvider>
    </React.Suspense>
  );
}

export default Routes;
