import ANALYTICS_CATEGORY from '../category.json';

export default {
  BANNER_BTN_KNOW_PRODUCTS: {
    action: 'banner_btn_know_products',
    label: 'BTN:Menu:BannerPrincipal',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_HOME: {
    action: 'menu_home',
    label: 'BTN:Menu:Header:Home',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_AUTO: {
    action: 'menu_auto',
    label: 'BTN:Menu:Header:Auto',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_RESID: {
    action: 'menu_resid',
    label: 'BTN:Menu:Header:Resid',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_ODONTO: {
    action: 'menu_odonto',
    label: 'BTN:Menu:Header:Odonto',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_BEM_ESTAR: {
    action: 'menu_bem-estar',
    label: 'BTN:Menu:Header:BemEstar',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_ASSISTENCIA_PET: {
    action: 'menu_assistencia-pet',
    label: 'BTN:Menu:Header:AssistenciaPet',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_VIDA: {
    action: 'menu_testevida',
    label: 'BTN:Menu:Header:Vida',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_ASSESSORIA: {
    action: 'menu_assessoria',
    label: 'BTN:Menu:Header:Assessoria',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_AJUDA_AUTO: {
    action: 'menu_ajuda-auto',
    label: 'BTN:Menu:Header:Ajuda:Auto',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_AJUDA_RESID: {
    action: 'menu_ajuda-resid',
    label: 'BTN:Menu:Header:Ajuda:Resid',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_AJUDA_ODONTO: {
    action: 'menu_ajuda-odonto',
    label: 'BTN:Menu:Header:Ajuda:Odonto',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_AJUDA_CANCELAMENTO: {
    action: 'menu_ajuda-cancelamento',
    label: 'BTN:Menu:Header:Ajuda:Cancelamento',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  MENU_AJUDA_CONTATOS: {
    action: 'menu_ajuda-contatos',
    label: 'BTN:Menu:Header:Ajuda:Contatos',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  GENERATE_LEAD: {
    action: 'generate_lead',
    label: 'BTN:Menu:GerarLead',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
};
