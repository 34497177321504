import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_ENVIRONMENTS,
} from 'trackingV2/constants';

export const ANALYTICS_HOME_FLOW = {
  T1: {
    nome: 'LS:NL:NCC:Home:LojaDeSeguros',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:SegurosItau',
    noInteraction: false,
  },
  A2: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Menu:{0}',
    noInteraction: false,
  },
  A3: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Menu:{0}:{1}',
    noInteraction: false,
  },
  A4: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Destaque:{0}:{1}',
    noInteraction: false,
  },
  A5: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Jumper:{0}:{1}',
    noInteraction: false,
  },
  A6: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Jumper:{0}:ContratarAgora',
    noInteraction: false,
  },
  A7: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:Carrossel:{0}',
    noInteraction: false,
  },
  A8: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:QueroAjudaParaEncontrarOProduto',
    noInteraction: false,
  },
  A9: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:FormLead:{0}:EnviarDados',
    noInteraction: false,
  },
  A10: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:CancelarUmProduto',
    noInteraction: false,
  },
  A11: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:TermosDeUsoRodape',
    noInteraction: false,
  },
  A12: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:VidaFakeDoor:{0}:Idade:{1}',
    noInteraction: false,
  },
  A13: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:VidaFakeDoor:{0}:DetalhesCobertura:Continuar',
    noInteraction: false,
  },
  A14: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:VidaFakeDoor:{0}:TempoDoResgate:{1}',
    noInteraction: false,
  },
  A15: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:VidaFakeDoor:{0}:TipoDoResgate:{1}',
    noInteraction: false,
  },
  A16: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:VidaFakeDoor:{0}:Motivo:{1}',
    noInteraction: false,
  },
  A17: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:VidaFakeDoor:[0]:Premios:{1}',
    noInteraction: false,
  },
  A18: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:VidaFakeDoor:{0}:Coberturas:{1}',
    noInteraction: false,
  },
  A19: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:VidaFakeDoor:{0}:Dados:Enviar',
    noInteraction: false,
  },
  A20: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:ExclusivosItau:{0}',
    noInteraction: false,
  },
  A21: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:DeixeSuaSugestao:{0}',
    noInteraction: false,
  },
  A22: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.HOME,
    label: 'BTN:Home:DeixeSuaSugestao:Enviar:{0}',
    noInteraction: false,
  },
};
