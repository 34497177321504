/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import { toRem } from 'theme/functions';
import ModalWrapperExtern from '../ModalWrapperExtern/ModalWrapperExtern';
import IconButton from '../IconButton/IconButton';
import FormGrid from '../FormGrid/FormGrid';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    zIndex: 100000,
  },
  closeIcon: {
    height: toRem(60),
    padding: `${toRem(24)} ${toRem(24)} ${toRem(12)} ${toRem(24)}`,
    textAlign: 'right',
  },
  titleWrapper: {
    textAlign: 'center',
    height: toRem(60),
    paddingTop: toRem(15),
    paddingLeft: toRem(70),
  },
  inputWrapper: {
    padding: '0px 24px 0px 24px',
    width: '100%',
  },
  listWrapper: {
    width: '100%',
    padding: 0,
    overflowY: 'scroll',
    height: `calc(${window.innerHeight}px - 124px)`,
  },
  itemWrapper: {
    listStyleType: 'none',
    minHeight: 48,
    padding: '12px 24px 12px 24px',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.wizardLineBorderColor}`,
    '&:active': {
      backgroundColor: theme.palette.wizardLineBorderColor,
    },
  },
  scrolledList: {
    borderTop: `1px solid ${theme.palette.wizardLineBorderColor}`,
  },
  itemTitle: {
    paddingLeft: 24,
  },
  listContainer: {
    marginTop: 12,
  },
  notFound: {
    textAlign: 'center',
    color: theme.palette.disabledColor,
  },
  title: {
    fontSize: 16,
    lineHeight: '1rem',
    fontFamily: 'ItauText-Regular',
  },
}));

const SelectMobileOptions = ({
  open,
  onClose,
  list = [],
  keySelector,
  keyFilter,
  onSelect,
  value = '',
  title,
  menuItemId,
  id,
}) => {
  const classes = useStyles();
  const [results, setResults] = useState(list);
  const [scrolled, setScrolled] = useState(false);
  const titleRef = useRef();

  let defaultValue = list.filter(e => e[keySelector] === value);
  if (defaultValue && defaultValue.length > 0) {
    defaultValue = defaultValue[0][keyFilter];
  }

  const prepareComparativeText = text => {
    if (text) {
      return text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim();
    }
    return '';
  };

  const filterResults = e => {
    const currentText = e.target ? e.target.value : e;
    if (currentText.length === 0) setResults(list);
    const filteredList = list.filter(_ =>
      prepareComparativeText(_[keyFilter]).includes(prepareComparativeText(currentText)),
    );
    setResults(filteredList);
  };

  useEffect(() => {
    if (list.length > 0) {
      setResults(list);
    }
  }, [list]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (titleRef && titleRef.current) {
          titleRef.current.focus();
        }
      }, 100);
    }
    filterResults('');
  }, [open]);

  useEffect(() => {
    if (value.length > 0) filterResults(defaultValue);
  }, [value]);

  const onScroll = e => {
    const position = e.target.scrollTop;
    if (position > 5 && position < 30) {
      if (!scrolled) setScrolled(!scrolled);
    } else if (position < 5) {
      if (scrolled) setScrolled(!scrolled);
    }
  };

  return (
    <ModalWrapperExtern opened={open}>
      <div
        className={classes.container}
        role="combobox"
        aria-expanded={open}
        aria-controls={`lstbx_${title.replace(/\s/g, '')}`}
        aria-labelledby="modalTitle"
      >
        <Grid container>
          <FormGrid className={classes.titleWrapper} xs={10}>
            <h2 tabIndex={-1} ref={titleRef} className={classes.title} id="modalTitle">
              {title}
            </h2>
          </FormGrid>
          <FormGrid xs={2} className={classes.closeIcon}>
            <IconButton iconClass="itaufonts_fechar" ariaLabel="fechar" onClick={onClose} />
          </FormGrid>
          <FormGrid xs={12}>
            <TextField
              className={classes.inputWrapper}
              id="input-with-icon-textfield"
              placeholder="filtre os resultados"
              onChange={filterResults}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="icon-itaufonts_busca_consulta" aria-hidden />
                  </InputAdornment>
                ),
              }}
            />
          </FormGrid>
          <FormGrid className={classes.listContainer} xs={12}>
            {results.length > 0 ? (
              <div className={clsx(classes.listWrapper, scrolled ? classes.scrolledList : '')} onScroll={onScroll}>
                <div role="listbox" id={`lstbx_${title.replace(/\s/g, '')}`}>
                  {results.map((item, i) => (
                    <div
                      id={id || menuItemId ? `${id || menuItemId}_${i}` : `listItem${i}`}
                      role="option"
                      aria-checked={item.value === value}
                      aria-selected={item.value === value}
                      key={`item-${i}`}
                      onClick={() => onSelect({ target: { value: item[keySelector] } })}
                      aria-label={item.ariaLabel || item[keyFilter]}
                      value={item[keySelector]}
                      className={classes.itemWrapper}
                      tabIndex="0"
                    >
                      <div aria-hidden="false">
                        <ReactMarkdown
                          source={item[keyFilter]}
                          unwrapDisallowed
                          disallowedTypes={['paragraph']}
                          plugins={[breaks]}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <FormGrid className={classes.notFound} xs={12}>
                <span>não há resultados para sua busca</span>
              </FormGrid>
            )}
          </FormGrid>
        </Grid>
      </div>
    </ModalWrapperExtern>
  );
};

const ListItem = ({ title, onClick, value }) => {
  const classes = useStyles();
  return (
    <li tab onClick={() => onClick(value)} value={value} className={classes.itemWrapper}>
      <span className={classes.itemTitle}>{title}</span>
    </li>
  );
};

ListItem.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
};

SelectMobileOptions.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  list: PropTypes.array,
  keySelector: PropTypes.string,
  keyFilter: PropTypes.string,
  onSelect: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string,
  menuItemId: PropTypes.string,
  id: PropTypes.string,
};

export default SelectMobileOptions;
