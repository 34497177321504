import i18next from 'i18n';
import BannerSubtitle from './components/BannerSubtitle/index';

export default {
  PRODUCTS: {
    ODONTO: {
      BANNER: {
        TITLE: i18next.t('ODONTO.BANNER.MALA_DIGITAL.TITLE'),
        SUBTITLE: BannerSubtitle,
      },
      SCHEDULE_CONTACT: {
        TITLE: i18next.t('ODONTO.SCHEDULE_CONTACT.TITLE'),
        SUBTITLE: i18next.t('ODONTO.SCHEDULE_CONTACT.MALA_DIGITAL.SUBTITLE'),
        SMALLER_SUBTITLE: i18next.t('ODONTO.SCHEDULE_CONTACT.MALA_DIGITAL.SMALLER_SUBTITLE'),
      },
    },
  },
};
