import _ from 'lodash';
import config from '../config';

const { TURN_ADOBE_ANALYTICS_ON, ENVIRONMENT } = config;

const isAnalyticsTurnedOn = () => {
  if (TURN_ADOBE_ANALYTICS_ON === true) {
    return true;
  }

  return false;
};

const getEnv = () => {
  switch (ENVIRONMENT) {
    case 'development': {
      return 'dev';
    }

    case 'qa': {
      return 'staging';
    }

    case 'hml': {
      return 'staging';
    }

    case 'internal': {
      return 'prod';
    }

    case 'production': {
      return 'prod';
    }

    default: {
      return 'local';
    }
  }
};

export class DefaultAnalyticsData {
  constructor() {
    this.site = {
      nome: 'LJSEG',
      ambiente: 'NL',
      negocio: 'LojaDeSeguros',
      platformdetails: `AM:${getEnv()}`,
      platform: 'web',
      vrsid: 'LojaDeSeguros',
    };
  }
}

export const analyticsTrack = (analyticsData, pageLoad) => {
  if (isAnalyticsTurnedOn()) {
    window.analyticsData = analyticsData;
    window.analyticsData.rule = pageLoad ? 'pageLoad' : 'customLink';
    if (window._satellite) {
      window._satellite.track('async_content_load');
    }
  }
};

export const analyticsTrackLoad = _.debounce(data => {
  analyticsTrack(data, true);
}, 300);
