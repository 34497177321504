import React, { useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import Loading from 'components/Loading';

interface LazyLoadInterface {
  children: JSX.Element;
  offSet: number;
}

const LazyLoad = ({ children, offSet = 0 }: LazyLoadInterface) => {
  const screenHeight = window.innerHeight;
  const [lazyRef, bounds] = useMeasure({ scroll: true, polyfill: ResizeObserver });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading === true && bounds.top !== 0 && screenHeight + offSet > bounds.top) {
      setLoading(false);
    }
  });

  return <div ref={lazyRef}>{loading ? <Loading /> : children}</div>;
};

export default LazyLoad;
