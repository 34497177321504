import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiTypography from './MuiTypography';
import MuiStepLabel from './MuiStepLabel';
import MuiStepper from './MuiStepper';
import MuiDivider from './MuiDivider';
import MuiStepIcon from './MuiStepIcon';
import MuiLinearProgress from './MuiLinearProgress';
import MuiMobileStepper from './MuiMobileStepper';
import MuiTab from './MuiTab';
import MuiSvgIcon from './MuiSvgIcon';
import MuiInput from './MuiInput';
import MuiSelect from './MuiSelect';
import MuiMenuItem from './MuiMenuItem';
import MuiListItem from './MuiListItem';
import MuiInputLabel from './MuiInputLabel';
import MuiFormLabel from './MuiFormLabel';
import MuiAppBar from './MuiAppBar';
import MuiToolbar from './MuiToolbar';
import MuiMenu from './MuiMenu';
import MuiFormHelperText from './MuiFormHelperText';
import MuiDialog from './MuiDialog';
import MuiButtonBase from './MuiButtonBase';
import typography from '../typography';
import MuiAccordion from './MuiAccordion';

export default {
  MuiButtonBase,
  MuiListItem,
  MuiFormLabel,
  MuiInputLabel,
  MuiLinearProgress,
  MuiStepIcon,
  MuiStepper,
  MuiDivider,
  MuiStepLabel,
  MuiButton,
  MuiIconButton,
  MuiTypography,
  MuiOutlinedInput,
  MuiMobileStepper,
  MuiTab,
  MuiSvgIcon,
  MuiInput,
  MuiSelect,
  MuiMenuItem,
  MuiAppBar,
  MuiToolbar,
  MuiMenu,
  MuiFormHelperText,
  MuiDialog,
  MuiCssBaseline: {
    '@global': {
      '@font-face': [typography.fontFamily],
      h1: {
        ...typography.h1,
      },
      h2: {
        ...typography.h2,
      },
      h3: {
        ...typography.h3,
      },
      h4: {
        ...typography.h4,
      },
      h5: {
        ...typography.h5,
      },
      h6: {
        ...typography.h6,
      },
      p: {
        ...typography.p,
      },
      a: {
        ...typography.a,
      },
      b: {
        ...typography.b,
      },
    },
  },
  MuiAccordion,
};
