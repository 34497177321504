import React, { useState } from 'react';
import { FormLabel, Grid } from '@material-ui/core';
import { useStyles } from './RadioCardList.styles';
import RadioCard from '../RadioCard/RadioCard';

const RadioCardList = ({ options, label, onChange, name, value, className, labelLayout, required }) => {
  const [selected, setSelected] = useState();
  const styles = useStyles();

  const handleChangeCard = e => {
    onChange({ target: { value: e } });
    setSelected(e);
  };

  return (
    <div className={className}>
      <Grid container spacing={4}>
        <FormLabel className={styles.text} required={required} id="formLabelRadioCardList">
          {label}
        </FormLabel>
      </Grid>
      <Grid container role="group" aria-labelledby="formLabelRadioCardList" spacing={4}>
        {options.map((element, index) => (
          <Grid item xs={6} justify="center" {...labelLayout}>
            <RadioCard
              id={`rdoCard_${index}`}
              name={name}
              label={element.label}
              image={element.image}
              value={element.value}
              onClick={handleChangeCard}
              selected={
                value !== undefined ? value?.toString() === element?.value?.toString() : selected === element.value
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RadioCardList;
