import { put } from 'redux-saga/effects';
import { createOffer } from 'store/auto/store/ducks/automovel';

export default function* offerSuccessHandle({ responseData }) {
  const offer = {
    id_oferta: responseData.id_oferta,
    produtos: responseData.produtos,
  };
  yield put(createOffer(offer));
}
