const homeModule = {
  HOME: {
    PRODUCT_SUGGESTION: {
      ACCORDION_TEXT_1: 'Não encontrou o que queria?&nbsp;',
      ACCORDION_TEXT_2: 'Deixe sua sugestão pra gente',
      SUGGESTION_DESCRIPTION:
        'queremos sempre te oferecer os melhores produtos e ninguém melhor do que você para nos ajudar',
      SUGGESTION_LABEL: 'qual seguro ou assistência você gostaria de ter aqui?',
      CHARACTERS_LIMIT: 'até {{max}} caracteres ({{current}}/{{max}})',
      SEND_BUTTON: 'enviar',
      SUCCESS_MESSAGE: 'sua sugestão foi enviada com sucesso!',
    },
  },
};

export default homeModule;
