import { makeStyles, colors } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: 'ItauText-XBold',
  },
  link: {
    marginTop: '1rem',
    borderBottom: `solid 1px ${colors.grey[400]}`,
  },
  information: {
    borderTop: `solid 1px ${colors.grey[400]}`,
    fontFamily: 'ItauText-Regular',
    borderBottom: props => (props.details ? '0' : `solid 1px ${colors.grey[400]}`),
    fontSize: theme.toRem(12),
    height: props => props.fixedHeight || 'auto',
    '& p': {
      padding: `0 0.5rem`,
    },
  },
}));
