/* eslint-disable prefer-const */
export const Types = {
  ADD_SUSPENSE_LISTS: 'suspenseLists/ADD_SUSPENSE_LISTS',
  CALL_SUSPENSE_LISTS: 'suspenseLists/CALL_SUSPENSE_LISTS',
};

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.ADD_SUSPENSE_LISTS: {
      return {
        ...action.data,
      };
    }
    default:
      return state;
  }
}

export function addSuspenseLists(data) {
  return {
    type: Types.ADD_SUSPENSE_LISTS,
    data,
  };
}

export function callSuspenseLists() {
  return {
    type: Types.CALL_SUSPENSE_LISTS,
  };
}
