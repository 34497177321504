import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Markdown from 'components/Markdown';
import { useStyles } from './styles';

export default function HeaderNav({ title, description, handleBack, pageId, headerRef }) {
  const styles = useStyles({ handleBack });
  const pageIndex = pageId + 1;
  let pageIndexIndicator;

  if (pageIndex <= 0) pageIndexIndicator = '';
  else pageIndexIndicator = `página ${pageIndex}`;

  return (
    <>
      <div className={styles.titleWrapper}>
        {handleBack && (
          <button
            type="button"
            className={styles.backButton}
            aria-label="voltar à página anterior"
            onClick={handleBack}
          >
            <i className={clsx('icon-itaufonts_seta', styles.backArrow)} />
          </button>
        )}
        <h1 className={styles.title} aria-label={`${title} ${pageIndexIndicator}`} ref={headerRef} tabIndex={-1}>
          {title}
        </h1>
      </div>
      {description && <Markdown className={styles.description} source={description} />}
    </>
  );
}

HeaderNav.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  handleBack: PropTypes.func,
  pageId: PropTypes.number,
};
