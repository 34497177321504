import { toRem } from '../functions';

export default {
  root: {
    minHeight: toRem(26),
    '&$error': {
      width: '100%',
    },
  },
};
