/* eslint-disable prefer-object-spread */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-use-before-define */
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import queryString from 'query-string';
import moment from 'moment';
import { store } from './storeElements';
import { analyticsTrackLoad, DefaultAnalyticsData } from './analytics';
import { setLastScrollPosition } from '../store/ducks/modal';
import { isValidEmail } from './validations';
import { OBFUSCATOR_TYPE } from './constants';
import config from '../config';

export const responseExtractor = response => {
  const bodyPromise = response.json();
  const newAccessTokenPromise = response.headers.get('x-access-token');
  return Promise.all([bodyPromise, newAccessTokenPromise]);
};

export const verifyiOS = () => navigator.userAgent.match(/iPhone|iPod|iPad/i) !== null;

export const getPathnameWithoutTrailingSlash = () => {
  const { pathname } = window.location;
  const formatedPathname = pathname.replace(/\/+$/, '');

  if (pathname === '/') return pathname;

  return formatedPathname;
};

export const handleScrollDiv = (ref, qs) => {
  const input = Array.from(document.querySelectorAll(qs)).find(item => isVisibleDom(item));
  scrollTo(ref.current.offsetTop);
  setTimeout(() => setFocusVisible(input), 700);
};

export function redirectToErrorPage() {
  const { history } = this.props;
  history.push('/error');
}

export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

// Receives an object and return a deep clone
export const deepClone = obj => _.cloneDeep(obj);

/**
 * Focus an element
 * @param {String} elementId Element ID to be focused
 */
export const focusElement = elementId => {
  if (elementId) setFocusVisible(document.querySelector(`#${elementId}`));
};

export const focusFirstHeaderElement = () => {
  if (document.querySelector('.modal-content')) {
    setFocusVisible(document.querySelector('.modal-content').firstElementChild.querySelector('h1,h2,h3'));
  }
};

// Turn the scroll on, on the entire app
export const turnOnAppScroll = () => {
  // const { modals } = store.getState();

  document.querySelector('html').classList.remove('no-scroll');
  document.querySelector('body').classList.remove('no-scroll');

  // TODO :ARRUMAR SOMENTE NO MODAL
  // setTimeout(() => {
  //   scrollTo(modals.lastScrollPosition, null, 'smooth');
  // }, 100);
};

// Turns the app readable on mobile the devices
export const turnOnAppReadability = () => {
  document.querySelector('.content').removeAttribute('aria-hidden');
};

export const turnOnModalReadability = () => {
  document.querySelector('.modal-content').removeAttribute('aria-hidden');
  document.querySelector('.IconButton').removeAttribute('aria-hidden');
};

export const turnOnContentReadability = () => {
  document.querySelector('.content').removeAttribute('aria-hidden');
};

// Removes the scroll of the entire app
export const turnOffAppScroll = () => {
  store.dispatch(
    setLastScrollPosition(document.querySelector('html').scrollTop || document.querySelector('body').scrollTop),
  );
  setTimeout(() => {
    document.querySelector('html').classList.add('no-scroll');
    document.querySelector('body').classList.add('no-scroll');
  }, 300);
};

export const isVisibleDom = elem => !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

// Turns the app unredable on mobile the devices
export const turnOffAppReadability = () => {
  document.querySelector('.content').setAttribute('aria-hidden', 'true');
};

export const turnOffModalReadability = () => {
  document.querySelector('.modal-content').setAttribute('aria-hidden', 'true');
  document.querySelector('.IconButton').setAttribute('aria-hidden', 'true');
};

export const turnOffContentReadability = () => {
  document.querySelector('.content').setAttribute('aria-hidden', 'true');
};

export const wait = (ms = 300) =>
  new Promise(res => {
    setTimeout(() => {
      res();
    }, ms);
  });

export const scrollTo = (top = 0, left = 0, behavior = 'smooth') => {
  if (!document.querySelector('.content-wrapper')) return;
  document.querySelector('.content-wrapper').scrollTo({
    top,
    left,
    behavior,
  });
};

export const modalScrollTo = (top = 0, left = 0, behavior = 'smooth') => {
  document.querySelector('.modal-content').scrollTo({
    top,
    left,
    behavior,
  });
};

export const getEnv = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'dev': {
      return 'dev';
    }

    case 'prod': {
      return 'prod';
    }

    case 'hml': {
      return 'staging';
    }

    default: {
      return 'local';
    }
  }
};

export const getUserAgent = () => navigator.userAgent;

export const setFocusVisible = (element, preventScrolling = false) => {
  if (element) {
    element.focus({
      preventScroll: preventScrolling,
    });
    if (!isMobileDevice()) {
      setTimeout(() => element.classList.add('focus-visible'), 100);
    }
  }
};

export const analyticsPageLoad = page => {
  const analyticsData = new DefaultAnalyticsData();
  analyticsData.site.platformdetails = `AM:${getEnv()}|UA:${getUserAgent()}|`;
  analyticsData.page = {
    nome: page,
  };
  analyticsTrackLoad(analyticsData);
};

// export function setLocalStorage(chave, valor) {
//   localStorage.setItem(
//     chave,
//     JSON.stringify({
//       value: valor,
//     }),
//   );
// }

// export function getLocalStorage(chave) {
//   const itemValue = localStorage[chave];

//   if (itemValue && /^\{(.*?)\}$/.test(itemValue)) {
//     const current = JSON.parse(itemValue);

//     return current.value;
//   }

//   return false;
// }

export function getSessionStorage(chave) {
  const itemValue = sessionStorage[chave];

  if (itemValue && /^\{(.*?)\}$/.test(itemValue)) {
    const current = JSON.parse(itemValue);

    return current.value;
  }

  return false;
}

export function setSessionStorage(chave, valor) {
  sessionStorage.setItem(
    chave,
    JSON.stringify({
      value: valor,
    }),
  );
}

export function setWizardStepUrl(step) {
  const search = queryString.parse(location.search);
  const currentStep = step;
  // eslint-disable-next-line eqeqeq
  if (currentStep != search.passo) {
    search.subpasso = 0;
  }
  const parsed = { ...search, passo: currentStep };
  updateWindowUrl(parsed);
  scrollTo(0, 0, 'auto');
}

export function setBeneficiaryUrl(beneficiary) {
  const search = queryString.parse(location.search);
  const parsed = { ...search, beneficiario: beneficiary };
  updateWindowUrl(parsed);
}

function updateWindowUrl(parsed) {
  const stringified = queryString.stringify(parsed, { sort: false });
  const newUrl = `${window.location.origin}${window.location.pathname}?${stringified}`;
  window.history.pushState(parsed, 'wizard', newUrl);
}

export function findURLParams(url) {
  const params = {};
  const urlSearchParamsRegex = /[?&]+([^=&]+)=([^&]*)/gi;
  url.replace(urlSearchParamsRegex, (m, key, value) => {
    params[key] = value;
  });
  return params;
}

export const callFunction = (func, ...args) => {
  if (func && typeof func === 'function') {
    func(...args);
  } else {
    console.error('callFunction Error: params invalid');
  }
};

export const extractDDDByCellphone = cellphone => cellphone.replace(/\D/g, '').substring(0, 2);

export const extractNumberByCellphone = cellphone => cellphone.replace(/\D/g, '').substring(2, cellphone.length);

export const moneyFormatter = money => {
  if (!money || typeof money !== 'number') return null;
  return `R$ ${money
    .toFixed(2)
    .toString()
    .replace(/\./, ',')}`;
};

export const formatANSRegistry = ansRegistry => {
  if (!ansRegistry && ansRegistry.length !== 9) return ansRegistry;
  return ansRegistry.replace(/(...)(...)(..)(.)/, `$1.$2/$3-$4`);
};

export const cellphoneFormatter = phone => {
  if (!phone) return phone;
  return phone
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');
};

export const cpfFormatter = cpf => cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');

export const cnpjFormatter = cpf => cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');

export const ObfuscateField = (string, type) => {
  if (!(string && typeof string === 'string')) {
    return string;
  }
  if (type === OBFUSCATOR_TYPE.EMAIL) {
    return _ObfuscateEmail(string);
  }
  if (type === OBFUSCATOR_TYPE.CELLPHONE) {
    return _ObfuscateCellphone(string);
  }
  if (type === OBFUSCATOR_TYPE.CPF) {
    return _ObfuscateCPF(string);
  }
};

const _ObfuscateCellphone = cellphone =>
  cellphone.replace(/[()\s-]/g, '').replace(/(^.{2})(.{3}).*(.)$/, '($1) $2**-***$3');

const _ObfuscateCPF = cpf => cpf.replace(/[()\s-]/g, '').replace(cpf.substr(0, 9), '*********-');

const _ObfuscateEmail = email => {
  if (isValidEmail(email)) {
    // eslint-disable-next-line
    const [_, userEmail, domain] = email.match(/(.*)(@.+)/);
    let regex;
    if (userEmail.length === 3) {
      regex = /(.)(.*)(.)$/;
    } else if (userEmail.length > 3) {
      regex = /(.{2})(.*)(.)$/;
    } else {
      return email;
    }
    const emailParts = userEmail.match(regex);
    emailParts[2] = emailParts[2].replace(/./g, '*');
    emailParts.shift();
    return emailParts.join('').concat(domain);
  }
  return email;
};

export const stringFormatterCEP = cep => cep.replace(/\D/g, '').replace(/^(\d{5})(\d)/, '$1-$2');

const transformQuotationPayloadBase = quotation => {
  const quotationPayload = Object.assign({}, quotation);
  quotationPayload.coberturas = undefined;
  quotationPayload.link_condicoes_gerais = undefined;
  quotationPayload.link_resumo_plano = undefined;
  quotationPayload.segmento = undefined;
  quotationPayload.id_produto = JSON.parse(quotationPayload.id_produto);
  quotationPayload.id_produto_externo = JSON.parse(quotationPayload.id_produto_externo);
  quotationPayload.data_contratacao = moment().format('YYYY-MM-DDTHH:mm');
  return quotationPayload;
};

export const transformQuotationPayloadToAuthenticate = (quotation, secondCallProposal = false) => {
  let quotationPayload = Object.assign({}, quotation);
  quotationPayload = transformQuotationPayloadBase(quotationPayload);
  const owner = quotationPayload.beneficiarios.find(user => user.titular === true || user.titular === 'true');
  quotationPayload.pagador = {
    ...quotationPayload.pagador,
    enderecos: owner.enderecos,

  };

  if(secondCallProposal) {
    const titularIdx = quotationPayload.beneficiarios.findIndex(user => user.titular === true || user.titular === 'true')

    quotationPayload.beneficiarios[titularIdx] = {
      ...quotationPayload.beneficiarios[titularIdx],
      cpf_beneficiario: null,
      email: null,
    }

    quotationPayload.pagador = {
      ...quotationPayload.pagador,
      cpf: null,
      email: null,
    }
  }

  console.log('quotationPayload', quotationPayload);
  return quotationPayload;
};

export const transformQuotationPayload = quotation => {
  const quotationPayload = Object.assign({}, quotation);
  return transformQuotationPayloadBase(quotationPayload);
};

export const markdownToString = markdown => {
  if (markdown instanceof ReactMarkdown || typeof markdown.type === 'object') {
    return markdown.props?.source.replace(/\*/g, '');
  }
  if (typeof markdown === 'string') {
    return markdown.replace(/\*/g, '');
  }
  return markdown;
};

export const isAuthenticationMode = scopes => {
  const { AUTH_MODE_ROUTES } = config;
  return AUTH_MODE_ROUTES.includes(location.pathname) || scopes.data.length > 0;
};

export const normalizeCEP = cep => {
  if (cep.toString().length === 7) {
    return `0${cep}`;
  }
  return cep;
};

/**
 * Focus on element by given reference
 * @param {object} reference Element reference
 */
export const focusByRef = reference => {
  // Executes lastly or after page is completely loaded
  setTimeout(() => {
    if (reference.current) {
      reference.current.focus();
    }
  });
};

export const isEmpty = value => typeof value === 'undefined' || value === null || value === false;
export const isNumber = value => !isEmpty(value) && !Number.isNaN(Number(value));

/**
 * Returns a random ID
 * @param {integer} length Hash length
 */
export const makeId = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * This function formats an UpperCamelcase string to another string
 * whose first letter only is in uppercase
 * @param {String} name
 * @returns {String}
 */
export const formatNameCase = name => {
  let formatedName = '';

  try {
    formatedName = name
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } catch {
    formatedName = name;
  }

  return formatedName;
};
