import ROUTES from 'routes/routes';
import NEW_HELP from '../Help/newHelp.resource';
import i18n from '../../../i18n';
import { ANALYTICS_MENU_FLOW } from '../../../tracking';

const HEADER = {
  PRODUCTS: [
    {
      ID: 'lnkInsuranceAuto_componentHeader',
      TEXT: i18n.t('HEADER.CAR_INSURANCE'),
      LINK: ROUTES.LANDING_AUTO,
      ANALYTICS_EVENT_ID: ANALYTICS_MENU_FLOW.MENU_AUTO,
    },
    {
      ID: 'lnkInsuranceResid_componentHeader',
      TEXT: 'seguro residencial',
      LINK: ROUTES.LANDING_RESID,
      ANALYTICS_EVENT_ID: ANALYTICS_MENU_FLOW.MENU_RESID,
    },
    {
      ID: 'lnkPlanOdonto_componentHeader',
      TEXT: 'plano odontológico',
      LINK: ROUTES.LANDING_ODONTO,
      ANALYTICS_EVENT_ID: ANALYTICS_MENU_FLOW.MENU_ODONTO,
    },
  ],
  WELFARE_DESKTOP_HELP_ITEMS: [
    {
      ...NEW_HELP.DESKTOP_ITEMS[0],
      items: [
        ...NEW_HELP.DESKTOP_ITEMS[0].items,
        {
          text: 'assistência bem-estar',
          link: `${ROUTES.FAQ_PAGE}?productType=BemEstar`,
          id: ANALYTICS_MENU_FLOW.MENU_AJUDA_BEM_ESTAR,
        },
      ],
    },
    ...NEW_HELP.DESKTOP_ITEMS.slice(1, NEW_HELP.DESKTOP_ITEMS.length),
  ],
  DESKTOP_HELP_ITEMS: NEW_HELP.DESKTOP_ITEMS,
  MOBILE_HELP_ITEMS: NEW_HELP.MOBILE_ITEMS,
  HELP_ITEMS: [
    {
      TITLE: 'dúvidas sobre seguros',
      CONTENT_ITEM: [
        {
          TITLE: 'seguro auto',
          LINK: 'Auto',
        },
        {
          TITLE: 'seguro residencial',
          LINK: 'Residencial',
        },
        {
          TITLE: 'plano odontológico',
          LINK: 'Odonto',
        },
      ],
    },
  ],
};

export default HEADER;
