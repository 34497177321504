export const Types = {
  CREATE_HIRING_REQUEST_PAYMENT: 'payment/CREATE_HIRING_REQUEST_PAYMENT',
};

const initialState = {
  dados_opcao_pagamento: {
    cartao_credito: {
      pagador: {},
    },
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.CREATE_HIRING_REQUEST_PAYMENT:
      return {
        ...state,
        dados_opcao_pagamento: action.data,
      };
    default:
      return state;
  }
}

export function createPaymentData(data) {
  return {
    type: Types.CREATE_HIRING_REQUEST_PAYMENT,
    data,
  };
}
