import { makeStyles, colors } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  componentWrapper: {
    margin: `${theme.toRem(20)} 0`,
    display: 'block',
  },
  elementWrapper: {
    padding: `0 ${theme.toRem(15)}`,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      padding: 0,
    },
  },
  radioButton: {
    margin: 0,
  },
  description: {
    borderTop: `solid 1px ${colors.grey[400]}`,
    borderBottom: `solid 1px ${colors.grey[400]}`,
    textAlign: 'justify',
    fontSize: theme.toRem(12),
    padding: theme.toRem(7),
    height: props => props.descriptionHeight || 'auto',
    fontFamily: 'ItauText-Regular',

    '& p': {
      margin: 0,
    },
  },
}));
