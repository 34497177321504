import axios from 'axios';

import config, { performance } from '../../config';

const { GATEWAY_BFF_BASE_URL } = config;

export const createCarts = async data => {
  const trace = performance.trace(data?.trace);
  trace.start();
  const response = await axios.post('abandonedcart/createcart', data, {
    disableException: true,
    baseURL: GATEWAY_BFF_BASE_URL
  });
  trace.stop();
  return response;
};

export const createResidCart = async data => {
  const trace = performance.trace(data?.trace);
  trace.start();
  const response = await axios.post('abandonedcart/createcart', data, {
    disableException: true,
    baseURL: GATEWAY_BFF_BASE_URL
  });
  trace.stop();
  return response;
};
