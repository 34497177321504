import axios from 'axios';
import config, { performance } from 'config';
import { getBaseURL } from 'utils';

const { E2E_PATH } = config;
const MOCK_NAME = 'RESID_SET_HIRING_MOCK';

const postHiring = async (payload, hash) => {
  const baseURL = await getBaseURL(MOCK_NAME);

  const trace = performance.trace('postHiring');
  trace.start();

  const response = await axios.post(`${E2E_PATH}/contratacoes/automovel`, payload, {
    headers: {
      hash,
    },
    baseURL,
  });

  trace.stop();

  return response.data;
};

export default postHiring;
