/* eslint-disable no-template-curly-in-string */
const quotationCard = {
  QUOTATION_CARD: {
    RESID: {
      INSURANCE_DETAILS: {
        SELECTED_ASSISTS: {
          TITLE: 'coberturas selecionadas',
          TITLE_SINGLE: 'cobertura selecionada',
        },
        ASSISTS: {
          CONTENT: 'serviços de assistência',
        },
        PAYMENT_METHODS: {
          TITLE: 'forma de pagamento',
          METHODS: {
            CREDIT: 'cartão de crédito',
            DEBIT: 'débito em conta',
          },
        },
        VALIDITY: {
          TITLE: 'vigência',
          DURATION_TIME: '12 meses',
        },
        LACK: {
          TITLE: 'carência',
          RULE: 'não há nenhuma regra de carência',
        },
      },
    },
  },
};

export default quotationCard;
