export const Types = {
    CREATE_CART: 'CART/CREATE_CART',
    DELETE_CART: 'CART/DELETE_CART',
    CREATE_RESID_CART: 'CART/CREATE_RESID_CART',
    DELETE_RESID_CART: 'CART/DELETE_RESID_CART',
}

const initialState = {
  payload: null,
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
      case Types.CREATE_CART: {
        return {
          ...state,
          payload: action.payload,
        };
      }
      case Types.DELETE_CART: {
          return {
              ...state,
              payload: action.payload,
          }
      }
      case Types.CREATE_RESID_CART: {
        return {
          ...state,
          payload: action.payload,
        };
      }
      case Types.DELETE_RESID_CART: {
          return {
              ...state,
              payload: action.payload,
          }
      }
      default:
        return state;
    }
  }

export function createCart (payload) {
  return {
    type: Types.CREATE_CART,
    payload,
  };
}

export function deleteCart (payload) {
  return {
    type: Types.DELETE_CART,
    payload,
  };
}

export function createResidCart (payload) {
  return {
    type: Types.CREATE_RESID_CART,
    payload,
  };
}

export function deleteResidCart (payload) {
  return {
    type: Types.DELETE_RESID_CART,
    payload,
  };
}