export const Types = {
  RECOVERY_E2E_START: 'recovery/RECOVERY_E2E_START',
  RECOVERY_E2E_LOAD: 'recovery/RECOVERY_E2E_LOAD',
  RECOVERY_E2E_SUCCESS: 'recovery/RECOVERY_E2E_SUCCESS',
  RECOVERY_E2E_ERROR: 'recovery/RECOVERY_E2E_ERROR',
};

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.RECOVERY_E2E_START: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case Types.RECOVERY_E2E_LOAD: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case Types.RECOVERY_E2E_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
      };
    }
    case Types.RECOVERY_E2E_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
      };
    }
    default:
      return state;
  }
}

export function startRecoveryE2E(data) {
  return {
    type: Types.RECOVERY_E2E_START,
    data,
  };
}
