// searchVehicle
import { createSelector } from 'reselect';

export const autoSelectors = state => state.autoFlow;

export const initialQuotationSelector = createSelector(autoSelectors, item => item.initQuotation);

export const loadingInitQuotationSelector = createSelector(initialQuotationSelector, item => item.loading);

export const autoFlowAuthSelector = createSelector(autoSelectors, item => item.auth);

export const inspectionLoadingSelector = createSelector(autoSelectors, item => item.inspection);

export const autoFlowSelectedPaymentOptionSelector = createSelector(
  autoSelectors,
  item => item.opcaoParcelaSelecionada,
);

export const autoFlowInstallmentNumberSelector = createSelector(
  autoFlowSelectedPaymentOptionSelector,
  item => item?.parcelas,
);

export const autoFlowPaymentTypeSelector = createSelector(autoSelectors, item => item.paymentType);

export const autoFlowCheckBondSelector = createSelector(autoSelectors, item => item.checkBond);
