import { rules } from './rules';
import { messages } from './messages';
import { defaultVulnerabilityFieldNames } from './translator';

export class VulnerabilityValidate {
  constructor(fieldName) {
    this.fieldName = VulnerabilityValidate.pureName(fieldName);
  }

  static pureName(fieldName) {
    const pureName = defaultVulnerabilityFieldNames.find(fieldNameModel =>
      fieldName.toLowerCase().includes(fieldNameModel),
    );
    return pureName;
  }

  execute(value) {
    if (this.fieldName) {
      const applyRule = rules[this.fieldName];
      if (applyRule) {
        return applyRule(value);
      }
    }
  }

  get message() {
    return messages[this.fieldName];
  }
}
