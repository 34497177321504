const { REACT_APP_GATEWAY_BFF_BASE_URL } = process.env;

const AUTH_KEY = 'auth:safe';
const URL = `${REACT_APP_GATEWAY_BFF_BASE_URL}/refreshtoken`;

const getStoredAuthentication = () => {
  try {
    const authentication = window.sessionStorage.getItem(AUTH_KEY);
    return JSON.parse(authentication);
  } catch (error) {
    console.error(error?.message);
  }
};

const updateStoredAuthentication = (accessToken, refreshToken) => {
  const authentication = getStoredAuthentication();

  const updatedTokens = JSON.stringify({
    ...authentication,
    accessToken,
    refreshToken,
  });

  try {
    window.sessionStorage.setItem(AUTH_KEY, updatedTokens);
  } catch (error) {
    console.error(error?.message);
  }
};

const removeStoredAuthentication = () => {
  try {
    window.sessionStorage.removeItem(AUTH_KEY);
  } catch (error) {
    console.error(error?.message);
  }
};

const getRefreshToken = async (accessToken, refreshToken, identifier) => {
  const body = { token: refreshToken, userIdentifier: identifier };

  return await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
};

export default async function validateAuthentication() {
  const authentication = getStoredAuthentication();
  const accessTokenFound = Boolean(authentication?.accessToken);

  if (!accessTokenFound) return false;

  const accessToken = authentication?.accessToken;
  const refreshToken = authentication?.refreshToken;
  const identifier = authentication.identifier;

  try {
    const tokens = await getRefreshToken(accessToken, refreshToken, identifier);

    if (tokens?.success) {
      const { access_token, refresh_token } = tokens?.value;
      updateStoredAuthentication(access_token, refresh_token);
      return true;
    }

    removeStoredAuthentication();
    return false;
  } catch (error) {
    removeStoredAuthentication();
    return false;
  }
}
