import axios from 'axios';

import config, { performance } from 'config';

const { E2E_PATH } = config;

export const obtainCreditCardBanner = async binNumber => {
  const trace = performance.trace('obtainCreditCardBanner');
  trace.start();

  const response = await axios.post(`${E2E_PATH}/cartoes/bandeira`, {
    bin: binNumber,
  });
  trace.stop();

  return response.data;
};
