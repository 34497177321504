const hasEvolutionRoutes = () =>
  window.location.href.includes('blog') || window.location.href.includes('v2/seguro-automovel');

const MOCK_TYPES = {
  ENABLED: 'mock/enabled',
  DISABLED: 'mock/disabled',
};

export const paths = [];

const DEFAULT_ENVS_MOCK_MODULES = {
  localhost: ['shared', 'resid', 'odonto', 'cancellation', 'auto', 'realiza'],
  development: ['shared', 'resid', 'odonto', 'cancellation', 'auto', 'realiza'],
  testenv: [],
  unittest: ['shared', 'resid', 'odonto', 'cancellation', 'auto', 'realiza'],
  ci: ['shared', 'resid', 'odonto', 'cancellation', 'auto', 'realiza'],
  qa: [],
  staging: [],
  production: [],
};

export const ENVS_MOCK_MODULES_KEY = {
  localhost: 'localhostMockModules',
  development: 'developmentMockModules',
  testenv: 'testenvMockModules',
  ci: 'ciMockModules',
  unittest: 'unittestMockModules',
  qa: 'qaMockModules',
  staging: 'stagingMockModules',
  production: 'prodMockModules',
};

export const getStoredMockModules = (env) =>
  sessionStorage.getItem(ENVS_MOCK_MODULES_KEY[env]) || DEFAULT_ENVS_MOCK_MODULES[env];
export const setStoredMockModules = ({ env, newMockModules }) =>
  sessionStorage.setItem(ENVS_MOCK_MODULES_KEY[env], JSON.stringify(newMockModules));

const envs = {
  localhost: {
    mode: MOCK_TYPES[!hasEvolutionRoutes() ? 'ENABLED' : 'DISABLED'],
    modules: getStoredMockModules('localhost'),
  },
  development: {
    mode: MOCK_TYPES[!hasEvolutionRoutes() ? 'ENABLED' : 'DISABLED'],
    modules: getStoredMockModules('development'),
  },
  testenv: {
    mode: MOCK_TYPES.ENABLED,
    modules: getStoredMockModules('testenv'),
  },
  ci: {
    mode: MOCK_TYPES.ENABLED,
    modules: getStoredMockModules('ci'),
  },
  unittest: {
    mode: MOCK_TYPES.ENABLED,
    modules: getStoredMockModules('unittest'),
  },
  qa: {
    mode: MOCK_TYPES.DISABLED,
    modules: getStoredMockModules('qa'),
  },
  staging: {
    mode: MOCK_TYPES.DISABLED,
    modules: getStoredMockModules('staging'),
  },
  internal: {
    mode: MOCK_TYPES.DISABLED,
  },
  production: {
    mode: MOCK_TYPES.DISABLED,
  },
};

export const enabledMock = (env) => {
  const { mode } = envs[env];

  return mode !== MOCK_TYPES.DISABLED;
};

export const applyMockRoutes = (env, envConfig) => {
  const { mode } = envs[env];

  if (mode === MOCK_TYPES.ENABLED) {
    const { modules } = envs[env];
    const permitedMockPaths = paths.filter((path) => modules.includes(path.name));
    return permitedMockPaths.map((path) => (context) => path.config.applyRoutes(context, envConfig));
  }
};

export const applyMockFixtures = (env) => {
  const { mode } = envs[env];
  if (mode === MOCK_TYPES.ENABLED) {
    const { modules } = envs[env];
    const permitedMockPaths = paths.filter((path) => modules.includes(path.name));
    return permitedMockPaths.map((path) => path.config.fixtures);
  }
};

export const applyMockPassthrough = (env, envConfig) => {
  const { mode } = envs[env];
  if (mode === MOCK_TYPES.ENABLED) {
    const { modules } = envs[env];
    const notPermitedMockPaths = paths.filter((path) => !modules.includes(path.name));
    if (notPermitedMockPaths) {
      return (context) => notPermitedMockPaths.map((path) => path.config.applyPassthrough(context, envConfig));
    }
  }
};
