import palette from '../palette';
import { spacing } from '../functions';

export default {
  root: {
    padding: `${spacing(2)} ${spacing(4)}`,
    fontFamily: 'ItauText-Bold',
    minWidth: 0,
  },
  contained: {
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: palette.white,
    '&:hover': {
      boxShadow: 'none',
    },
  },
  outlined: {
    padding: `${spacing(2)} ${spacing(4)}`,
  },
  label: {
    textTransform: 'lowercase',
  },
};
