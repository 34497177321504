/* eslint-disable prefer-const */
export const Types = {
  SET_INSPECTION_INITIAL_DATA: 'inspection/SET_INSPECTION_INITIAL_DATA',
  SET_INSPECTION_INFO: 'inspection/SET_INSPECTION_INFO',
  GET_INSPECTION_DATES: 'inspection/GET_INSPECTION_DATES',
  CREATE_HOUSEHOLD_CUSTOM_SERVICE: 'inspection/CREATE_HOUSEHOLD_CUSTOM_SERVICE',
  CREATE_INSPECTION_POST: 'inspection/CREATE_INSPECTION_POST',
  SET_HOUSEHOLD_INSPECTION_REQUEST: 'inspection/SET_HOUSEHOLD_INSPECTION_REQUEST',
  SET_RESPONSE_INSPECTION: 'inspection/SET_RESPONSE_INSPECTION',
  SET_ALL_PERIODS: 'inspection/SET_ALL_PERIODS',
  SET_ADDRESS_CAPS: 'inspection/SET_ADDRESS_CAPS',
  RESET_SELECT_CAPS: 'inspection/RESET_SELECT_CAPS',
  SET_FLOW_APP: 'inspection/SET_FLOW_APP',
  SET_FLOW_DISPENSED: 'inspection/SET_FLOW_DISPENSED',
  SET_FLOW_SCHEDULED: 'inspection/SET_FLOW_SCHEDULED',
  SET_FLOW_CAPS: 'inspection/SET_FLOW_CAPS',
  SET_FLOW_SUCCESS: 'inspection/SET_FLOW_SUCCESS',
};

const initialState = {
  initialData: {
    id_contratacao: '',
    id_parceiro: '',
    veiculo: '',
    etapa: '',
    init: false,
  },
  domiciliar: {
    customService: {},
    allPeriods: {},
  },
  caps: {
    endereco_agendamento: {},
  },
  requisicao: {},
  resposta: {},
  controlFlow: {
    app: false,
    dispensed: false,
    caps: false,
    scheduled: false,
    success: {
      caps: false,
      household: false,
    },
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_INSPECTION_INFO:
      return {
        ...state,
        initialData: {
          ...action.data,
        },
        requisicao: {
          ...state.requisicao,
        },
      };
    case Types.CREATE_HOUSEHOLD_CUSTOM_SERVICE:
      return {
        ...state,
        domiciliar: {
          ...state.domiciliar,
          customService: {
            ...action.data,
          },
        },
      };
    case Types.SET_HOUSEHOLD_INSPECTION_REQUEST:
      return {
        ...state,
        requisicao: {
          ...action.data,
        },
      };
    case Types.SET_RESPONSE_INSPECTION:
      return {
        ...state,
        domiciliar: {
          ...state.domiciliar,
          resposta: {
            ...action.data,
          },
        },
      };
    case Types.SET_ALL_PERIODS:
      return {
        ...state,
        domiciliar: {
          ...state.domiciliar,
          allPeriods: action.data,
        },
      };
    case Types.SET_ADDRESS_CAPS:
      return {
        ...state,
        caps: {
          ...state.caps,
          endereco_agendamento: {
            ...action.data,
          },
        },
      };
    case Types.RESET_SELECT_CAPS:
      return {
        ...state,
        caps: {},
      };
    case Types.SET_FLOW_APP:
      return {
        ...state,
        controlFlow: {
          app: action.data,
          scheduled: false,
          dispensed: false,
          caps: false,
          success: {
            caps: false,
            household: false,
          },
        },
      };
    case Types.SET_FLOW_DISPENSED:
      return {
        ...state,
        controlFlow: {
          app: false,
          scheduled: false,
          dispensed: action.data,
          caps: false,
          success: {
            caps: false,
            household: false,
          },
        },
      };
    case Types.SET_FLOW_SCHEDULED:
      return {
        ...state,
        controlFlow: {
          app: false,
          scheduled: action.data,
          dispensed: false,
          caps: false,
          success: {
            caps: false,
            household: false,
          },
        },
      };
    case Types.SET_FLOW_CAPS:
      return {
        ...state,
        controlFlow: {
          app: false,
          scheduled: false,
          dispensed: false,
          caps: action.data,
          success: {
            caps: false,
            household: false,
          },
        },
      };
    case Types.SET_FLOW_SUCCESS: {
      const { caps: capsIn, household: householdIn } = action.data;
      return {
        ...state,
        controlFlow: {
          app: false,
          scheduled: false,
          dispensed: false,
          caps: false,
          success: {
            caps: capsIn,
            household: householdIn,
          },
        },
      };
    }
    default:
      return state;
  }
}

export function setInspectionInitialData(data) {
  return {
    type: Types.SET_INSPECTION_INITIAL_DATA,
    data,
  };
}

export function getInspectionDates(data) {
  return {
    type: Types.GET_INSPECTION_DATES,
    data,
  };
}

export function createHouseholdCustomService(data) {
  return {
    type: Types.CREATE_HOUSEHOLD_CUSTOM_SERVICE,
    data,
  };
}

export function setInspectionInfo(data) {
  return {
    type: Types.SET_INSPECTION_INFO,
    data,
  };
}

export function createAutoInspectionPost(data) {
  return {
    type: Types.CREATE_INSPECTION_POST,
    data,
  };
}

export function setHouseholdInspectionRequest(data) {
  return {
    type: Types.SET_HOUSEHOLD_INSPECTION_REQUEST,
    data,
  };
}

export function setResponseInspection(data) {
  return {
    type: Types.SET_RESPONSE_INSPECTION,
    data,
  };
}

export function setAllPeriods(data) {
  return {
    type: Types.SET_ALL_PERIODS,
    data,
  };
}

export function setAddressCaps(data) {
  return {
    type: Types.SET_ADDRESS_CAPS,
    data,
  };
}

export function resetSelectCaps(data) {
  return {
    type: Types.RESET_SELECT_CAPS,
    data,
  };
}
export function setFlowApp(data) {
  return {
    type: Types.SET_FLOW_APP,
    data,
  };
}

export function setFlowDispensed(data) {
  return {
    type: Types.SET_FLOW_DISPENSED,
    data,
  };
}

export function setFlowScheduled(data) {
  return {
    type: Types.SET_FLOW_SCHEDULED,
    data,
  };
}

export function setFlowCaps(data) {
  return {
    type: Types.SET_FLOW_CAPS,
    data,
  };
}

export function setFlowSuccess(data) {
  return {
    type: Types.SET_FLOW_SUCCESS,
    data,
  };
}
