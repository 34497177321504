export const Types = {
  SET_REQUEST_DATA: 'httpErrors/SET_REQUEST_DATA',
  INCREASE_ERROR_TRIES: 'httpErrors/INCREASE_ERROR_TRIES',
  RESET_HTTP_ERRORS: 'httpErrors/RESET_HTTP_ERRORS',
  SET_ERROR: 'httpErrors/SET_ERROR',
  SET_STATUS_CODE: 'httpErros/SET_STATUS_CODE',
  DISABLE_GLOBAL_ERROR: 'httpErros/DISABLE_GLOBAL_ERROR',
  SET_ERROR_SAGA_ACTION: 'httpErros/SET_ERROR_SAGA_ACTION',
  START_RETRY: 'httpErros/START_RETRY',
  CANCEL_RETRY: 'httpErrors/CANCEL_RETRY',
  RESET_RETRY_HTTP_ERRORS: 'httpErrors/RESET_RETRY_HTTP_ERRORS',
};

const initHttpErrorState = {
  config: null,
  statusCode: null,
  tries: 0,
  hasError: false,
  sagaAction: undefined,
  retry: false,
};

export default function reducer(state = { ...initHttpErrorState }, action) {
  switch (action.type) {
    case Types.SET_REQUEST_DATA: {
      return {
        ...state,
        config: action.config,
      };
    }
    case Types.INCREASE_ERROR_TRIES: {
      return {
        ...state,
        tries: state.tries + 1,
      };
    }
    case Types.SET_ERROR: {
      return {
        ...state,
        hasError: true,
        config: action.data,
      };
    }
    case Types.SET_STATUS_CODE: {
      return {
        ...state,
        statusCode: action.data,
      };
    }
    case Types.SET_ERROR_SAGA_ACTION: {
      return {
        ...state,
        sagaAction: action.data,
      };
    }
    case Types.RESET_HTTP_ERRORS: {
      return {
        ...state,
        ...initHttpErrorState,
      };
    }
    case Types.RESET_RETRY_HTTP_ERRORS: {
      return {
        ...state,
        retry: false,
      };
    }
    case Types.DISABLE_GLOBAL_ERROR: {
      return {
        ...state,
        hasError: false,
        disableGlobalError: action.disable,
      };
    }
    case Types.START_RETRY: {
      return {
        ...state,
        retry: true,
      };
    }
    case Types.CANCEL_RETRY: {
      return {
        ...state,
        retry: false,
      };
    }
    default: {
      return state;
    }
  }
}

export function setRequestData(config) {
  return {
    type: Types.SET_REQUEST_DATA,
    config,
  };
}

export function increaseErrorTries() {
  return {
    type: Types.INCREASE_ERROR_TRIES,
  };
}

export function resetHttpErrors() {
  return {
    type: Types.RESET_HTTP_ERRORS,
  };
}

export function resetRetryHttpErrors() {
  return {
    type: Types.RESET_RETRY_HTTP_ERRORS,
  };
}

export function setError(config) {
  return {
    type: Types.SET_ERROR,
    data: config,
  };
}

export function setStatusCode(data) {
  return {
    type: Types.SET_STATUS_CODE,
    data,
  };
}

export function disableGlobalError(disable) {
  return {
    type: Types.DISABLE_GLOBAL_ERROR,
    disable,
  };
}

export function setErrorSagaAction(action) {
  return {
    type: Types.SET_ERROR_SAGA_ACTION,
    data: action,
  };
}

export function startRetry() {
  return {
    type: Types.START_RETRY,
  };
}

export function cancelRetry() {
  return {
    type: Types.CANCEL_RETRY,
  };
}
