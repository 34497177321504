const RESOURCES = {
  HEADER: {
    TEXT: 'HEADER TEXT'
  },
  FOOTER: {
    REGISTRY: '© 2021 Itaú Corretora de Seguros S.A. | CNPJ 43.644.285/0001-06 | Registro Susep: 20.203503-3',
    MOBILE_REGISTRY:
      '© 2021 Itaú Corretora de Seguros S.A. <br /> CNPJ 43.644.285/0001-06 <br /> Registro Susep: 20.203503-3',
    LOCATION:
      'Praça Alfredo Egydio de Souza Aranha, 100, Torre Olavo Setubal, Parque Jabaquara - CEP 04344-902 - São Paulo - Brasil',
    MOBILE_LOCATION:
      'Praça Alfredo Egydio de Souza Aranha, 100, Torre Olavo Setubal, <br /> Parque Jabaquara - CEP 04344-902 - São Paulo - Brasil',
    HELP_ITEM: [
      {
        TITLE: 'vendas',
        TIME: '2ª a 6ª das 9h às 18h',
        CONTENT: [
          {
            TITLE: 'capitais e regiões metropolitanas',
            TEL: '4020 2031',
            TIME: '2ª a 6ª das 9h às 18h'
          },
          {
            TITLE: 'demais regiões',
            TEL: '0800 545 1150',
            TIME: '2ª a 6ª das 9h às 18h'
          }
        ]
      },
      {
        TITLE: 'atendimento cliente Itaú corretora de seguros',
        CONTENT: [
          {
            TITLE: 'capitais e regiões metropolitanas',
            TEL: '4090 1125',
            TIME: '2ª a 6ª das 8h às 20h'
          },
          {
            TITLE: 'demais regiões',
            TEL: '0800 791 1125',
            TIME: '2ª a 6ª das 8h às 20h'
          },
          {
            TITLE: 'sac',
            TEL: '0800 728 0728',
            TIME: 'Todos os dias, 24 hrs por dia'
          },
          {
            TITLE: 'deficiente auditivo / fala',
            TEL: '0800 722 1722',
            TIME: '2ª a 6ª das 8h às 20h'
          },
          {
            TITLE: 'ouvidoria',
            TEL: '0800 570 0011',
            TIME: '2ª a 6ª das 9h às 18h'
          }
        ]
      },
      {
        TITLE: 'cancelamento',
        TIME: '24hs por dia, 7 dias por semana (on-line)',
        CONTENT: [
          {
            TITLE: 'comprou e deseja cancelar?',
            LINK: '-'
          }
        ]
      }
    ]
  },
  SHOWCASE: {
    TITLE: 'por onde você quer começar?',
    CARDS: {
      IMG_AUTO: '/images/img-auto.png',
      IMG_RESID: '/images/img-resid.png',
      IMG_ODONTO: '/images/img-odonto.png',
      SHOW_MORE: 'saiba mais'
    }
  },
  LEARN_MORE: {
    TITLE: 'quer saber mais?'
  },
  PARTNERS: {
    TITLE: 'seja qual for sua necessidade a gente traz <b>ótimas opções pra você</b>'
  },
  ODONTO: {
    PLANS: {
      SUB_TITLE: 'Mas, trouxemos também outras opções para que você compare.'
    }
  }
};

export default RESOURCES;

