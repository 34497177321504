import React, { useState } from 'react';
import FormItem from 'shared/form/FormItem';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import Button from 'components/Button/Button';
import { useStyles } from './ContactInfo.styles';
import model from './ContactInfo.model';

export default function ContactInfo({ onSubmit }) {
  const [sent, setSent] = useState(false);
  const styles = useStyles();

  const fields = model.createfields();
  const schema = model.createSchema(fields);

  const onSubmitForm = values => {
    if (typeof onSubmit === 'function') {
      setSent(true);
      onSubmit(values);
    }
  };

  return (
    <Formik initialValues={{}} validationSchema={schema.schema} validateOnBlur onSubmit={onSubmitForm}>
      {form => (
        <form onSubmit={form.handleSubmit}>
          <Grid container direction="row">
            <Grid item xs={12} sm={4} className={styles.field}>
              <FormItem field={fields.email} data-testid="field-email" disabled={sent} />
            </Grid>
            <Grid item xs={12} sm={4} className={styles.field}>
              <FormItem field={fields.telephone} data-testid="field-telephone" disabled={sent} />
            </Grid>
            <Grid item xs={12} sm={4} className={styles.button}>
              <Button
                type="submit"
                text={sent ? 'enviado' : 'enviar'}
                data-testid="button-send"
                disabled={!form.isValid || !form.dirty || sent}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

ContactInfo.propTypes = {
  onSubmit: PropTypes.func,
};
