import axios from 'axios';

import config, { performance } from '../../../../config';

const { E2E_ODONTO_PATH } = config;

export const getCities = async idState => {
  const trace = performance.trace('getCities');
  trace.start();
  const response = await axios.get(`${E2E_ODONTO_PATH}/estados/municipios`, {
    headers: {
      'id-estado': idState,
    },
  });
  trace.stop();
  return response.data.map(state => ({
    value: {
      id: state.id_municipio,
      nomeFormatado: state.nome_formatado,
    },
    label: state.nome,
  }));
};
