import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1rem',
    lineHeight: '.5rem',
    margin: '.7rem 0',
  },
  link: {
    color: 'inherit !important',
    margin: '0 !important',
    marginBottom: '.3rem !important',
    fontSize: 'inherit !important',
  },
}));

export default useStyles;
