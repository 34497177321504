import moment from 'moment';
import i18next from 'i18next';
import { validarCPF } from 'utils/validations';

export const cpfTest = (message = 'CPF inválido') => ({
  name: 'cpf',
  message,
  test: value => validarCPF(value),
});

export const dateFormatTest = (message = 'data inválida') => ({
  name: 'validDate',
  message,
  test: value => moment(value, 'DD/MM/YYYY').isValid(),
});

export const over18DateValidation = (message = i18next.t('COMMON_MODELS.OVER_18')) => ({
  name: 'birthdate',
  message,
  test: value => {
    const date = moment(value, 'DD/MM/YYYY');
    const dateDiffInYears = moment().diff(date, 'years');
    if (!date.isValid()) return true;
    if (dateDiffInYears < 0) return true;
    return dateDiffInYears >= 18;
  },
});

export const under18DateValidation = (message = 'o titular deve ser menor de 18 anos') => ({
  name: 'birthdate',
  message,
  test: value => {
    if (!moment(value, 'DD/MM/YYYY').isValid()) return true;
    return moment().diff(moment(value, 'DD/MM/YYYY'), 'years') < 18;
  },
});

export const underOrEqualThan18DateValidation = (message = 'o titular deve ter entre 0 e 18 anos') => ({
  name: 'birthdate',
  message,
  test: value => {
    if (!moment(value, 'DD/MM/YYYY').isValid()) return true;
    return moment().diff(moment(value, 'DD/MM/YYYY'), 'years') <= 18;
  },
});

export const isFutureValidation = (message = 'a data não deve ser futura') => ({
  name: 'futureDate',
  message,
  test: value => {
    const date = moment(value, 'DD/MM/YYYY');
    if (!date.isValid()) return true;
    return !date.isAfter(moment());
  },
});

export const under70DateValidation = (message = 'Você deve ter menos de 70 anos para contratar a assistência') => ({
  name: 'maxbirthdate',
  message,
  test: value => {
    if (!moment(value, 'DD/MM/YYYY').isValid()) return true;
    return moment().diff(moment(value, 'DD/MM/YYYY'), 'years') < 70;
  },
});

export const cardLengthTest = () => ({
  name: 'cardNumber',
  message: 'cartão inválido',
  test: value => value && value.toString().replace(/\s/g, '').length >= 13,
});
