// inspection infos
import { createSelector } from 'reselect';

export const inspectionSelector = state => state.inspection;

export const householdSelector = createSelector(inspectionSelector, item => item.domiciliar);

export const hashSelector = createSelector(inspectionSelector, item => item.scope);

export const initialDataSelector = createSelector(inspectionSelector, item => item.initialData);

export const requestHouseholdSelector = createSelector(inspectionSelector, item => item.requisicao);

export const controlFlowSelector = createSelector(inspectionSelector, item => item.controlFlow);

export const responseHouseholdSelector = createSelector(householdSelector, item => item.resposta);

export const customServiceSelector = createSelector(householdSelector, item => item.customService);

export const capsSelector = createSelector(inspectionSelector, item => item.caps);

export const addressScheduleCapsSelector = createSelector(capsSelector, item => item.endereco_agendamento);
