import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Grid } from '@material-ui/core';
import RadioButton from 'components/RadioButton/RadioButton';
import { useStyles } from './DescriptiveRadio.styles';

function DescriptiveRadio({ options, onChange, displayMode, value = '', descriptionHeight = '4.375rem' }) {
  const styles = useStyles({ descriptionHeight });

  const getDisplayMode = () => {
    switch (displayMode) {
      case 'vertical':
        return 12;
      default:
        return true;
    }
  };

  return (
    <Grid container className={styles.componentWrapper}>
      <RadioGroup onChange={onChange} value={value} aria-labelledby="fakedoor-question-title" row>
        {options.map(option => (
          <Grid key={option.id} item xs={12} sm={getDisplayMode()} className={styles.elementWrapper}>
            <RadioButton option={option} className={styles.radioButton} />
            {option.description && (
              <div className={styles.description} data-testid={option.id}>
                <p>{option.description}</p>
              </div>
            )}
          </Grid>
        ))}
      </RadioGroup>
    </Grid>
  );
}

DescriptiveRadio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      description: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  displayMode: PropTypes.string,
  descriptionHeight: PropTypes.string,
};

export default DescriptiveRadio;
