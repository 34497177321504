import { call, put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { getAutoInspection } from 'api/E2E/Auto/getAutoInspection';
import { startInspection, Types } from 'store/auto/store/ducks/autoFlow';
import { setError, setErrorSagaAction } from 'store/ducks/httpErrors';
import {
  resetSelectCaps,
  setFlowDispensed,
  setFlowScheduled,
  setInspectionInfo,
} from 'store/auto/store/ducks/inspection';

const EXCEPTION = 422;
const REDIRECT = {
  DISPENSED: '3',
};

function* setControlInspectionFlow(response) {
  if (!isEmpty(response)) {
    if (response.status === EXCEPTION) {
      if (response.data.codigo === REDIRECT.DISPENSED) {
        yield put(setFlowDispensed(true));
      } else {
        yield put(setFlowScheduled(true));
      }
    }
  }
}

export default function* setInspectionInitialDataAction(value) {
  const { hash } = value.data;
  try {
    yield put(startInspection());
    const response = yield call(getAutoInspection, hash);
    const data = {
      ...response,
      init: true,
    };
    yield put(setInspectionInfo(data));
    yield put(resetSelectCaps({}));
    yield setControlInspectionFlow(response);
    yield put({ type: Types.SUCCESS_INSPECTION });
  } catch (err) {
    const { response } = err;
    if (response) {
      if (response.status === 422) {
        yield setControlInspectionFlow(response);
        yield put({ type: Types.SUCCESS_INSPECTION });
      } else {
        yield put(setError());
        yield put({ type: Types.ERROR_INSPECTION });
        yield put(setErrorSagaAction(hash));
      }
    }
  }
}
