import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ breakpoints, palette, toRem }) => ({
  acceptCookies: {
    backgroundColor: '#F7F7F7',
    borderRadius: toRem(4),
    border: `${toRem(1)} solid ${palette.text.primary}`,
    bottom: toRem(16),
    color: palette.text.primary,
    fontSize: '.9rem',
    overflow: 'hidden',
    padding: toRem(24),
    position: 'fixed',
    width: '100%',
    maxWidth: toRem(1293),
    zIndex: 1,
    [breakpoints.up('md')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    [breakpoints.down('md')]: {
      left: '5%',
      width: '90%',
    },
  },
  text: {
    margin: 0,
    fontSize: '1rem',
    [breakpoints.down('md')]: {
      fontSize: '.9rem',
    },
  },
  termsLink: {
    fontSize: '1rem',
    [breakpoints.down('md')]: {
      fontSize: '.9rem',
    },
  },
  confirmButton: {
    paddingLeft: `${toRem(25)} !important`,
    paddingRight: `${toRem(25)} !important`,
    height: '100%',
    maxHeight: '3rem',
  },
  confirmButtonText: {
    fontSize: toRem(19),
  },
  acceptedCookies: {
    display: 'none',
  },
  fitContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    height: 'auto !important',

    [breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  acceptButton: {
    margin: '1rem 0 0 0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [breakpoints.up('md')]: {
      margin: '0 0 0 2rem',
    },
  },
}));
