import { call, put } from 'redux-saga/effects';
import { Types as AuthScopesTypes } from 'store/ducks/authScopes';
import moment from 'moment';

const isValidScope = scope => scope !== null && scope !== undefined;

export default function* recoveryAuthScope({ hash, scope }) {
  try {
    const validatedScope = yield call(isValidScope, scope);
    if (!validatedScope) {
      return false;
    }
    const authScope = {
      hash,
      expiresAt: moment().add(3, 'hours'),
      scope,
      token: false,
    };
    yield put({
      type: AuthScopesTypes.ADD,
      data: authScope,
    });
    return validatedScope;
  } catch (err) {
    return false;
  }
}
