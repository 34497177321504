import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '../Default';
import { useStyles } from './styles';

export default function SelectableChip({ onChange, checked = false, ...props }) {
  const [selected, setSelected] = useState(checked);
  const styles = useStyles();

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  const handleClick = e => {
    if (onChange) onChange(e, !selected);
    setSelected(state => !state);
  };

  const handleKeyDown = e => {
    if (e.keyCode !== 32 && e.keyCode !== 13) return;
    if (onChange) onChange(e, !selected);
  };

  return (
    <Chip
      {...props}
      className={styles.root}
      variant={selected ? 'default' : 'outlined'}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="checkbox"
      aria-checked={selected}
      tabIndex={0}
    />
  );
}

SelectableChip.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};
