/* eslint-disable no-template-curly-in-string */
const cardImpl = {
  CARD_IMPL: {
    COVERAGE: {
      COVERAGE_SINISTER_VALUE: 'valor da cobertura em caso de sinistro até:',
      PARCEL_VALUE: 'valor por parcela:',
      ASK_VALUE_COVERAGE: 'Qual valor você quer contratar para essa cobertura?',
      SUGGESTED_VALUE: '<0>valor sugerido: {{value}}</0>',
    },
    FRANCHISING: {
      OBSERVATION: 'o valor de franquia será de 10% das indenizações com mínimo de R$ 20,00',
    },
  },
};

export default cardImpl;
