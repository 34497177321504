
import { withStyles, makeStyles } from '@material-ui/styles';
import Switch from '@material-ui/core/Switch';

export const CustomSwitch = withStyles(({ spacing, palette, transitions }) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    margin: spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: palette.white,
      '& + $track': {
        backgroundColor: palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${palette.primary.gray42}`,
    backgroundColor: palette.primary.gray42,
    opacity: 1,
    transition: transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

export const useStyles = makeStyles(({ palette }) => ({
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '.5rem 0',
  },
  label: {
    fontSize: '0.875rem',
    color: palette.inputLabelColor,
    fontFamily: 'ItauText-Regular',
    lineHeight: '1rem',
  },
  trueOption: {
    color: 'black',
  },
  falseOption: {
    color: palette.primary.gray42,
  },
}));
