import { createSelector } from 'reselect';
import moment from 'moment';

export const paymentSelectors = state => state.payment;

export const paymentOptionsSelector = createSelector(paymentSelectors, item => item.dados_opcao_pagamento);

export const paymentIdSelector = createSelector(paymentOptionsSelector, item => item.id_opcao_pagamento);

export const paymentInitialValuesSelector = createSelector(paymentOptionsSelector, item => ({
  cardName: item?.cartao_credito?.pagador?.nome,
  cardNumber: item?.cartao_credito?.numero_cartao,
  validationDate: item ? moment(item?.cartao_credito?.data_validade, 'MM/YY').format('MM/YYYY') : null,
  cvv: item?.cartao_credito?.cvv,
}));
