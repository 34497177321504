/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-dynamic-require */
// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/remote-config';
import 'firebase/auth';
import { mapParsedEnvs } from './parser';

const { NODE_ENV, REACT_APP_ENVIRONMENT, ...CONFIG } = process.env;

let performance = null;
let remoteConfig = null;
let database = null;
let analytics = null;
let auth = null;

const PARSED_CONFIG = mapParsedEnvs(CONFIG);

if (REACT_APP_ENVIRONMENT !== 'testenv') {
  firebase.initializeApp(PARSED_CONFIG.FIREBASE_CONFIG);

  performance = firebase.performance();
  remoteConfig = firebase.remoteConfig();
  analytics = firebase.analytics();
  database = firebase.firestore();
  auth = firebase.auth();

  if (REACT_APP_ENVIRONMENT === 'production') remoteConfig.settings = { minimumFetchIntervalMillis: 3600000 };
  else remoteConfig.settings = { minimumFetchIntervalMillis: 0 };
}

if (NODE_ENV === 'test') {
  performance = {
    trace: () => ({
      start: () => {},
      stop: () => {},
    }),
  };
  remoteConfig = {
    fetchAndActivate: () => {},
    getAll: () => ({}),
  };

  auth = {
    signInAnonymously: () => {},
    onAuthStateChanged: callback => {
      callback();
    },
  };

  const obj = {};
  obj.collection = () => obj;
  obj.add = () => obj;
  obj.then = callback => {
    callback();
    return obj;
  };
  obj.catch = callback => {
    callback();
    return obj;
  };
  database = obj;
}

export default PARSED_CONFIG;
export { remoteConfig, performance, database, auth, analytics };
