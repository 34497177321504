const STEP_TO_STEP = {
  TITLE: 'como funciona?',
  STEP_ITEMS: [
    {
      IMAGE_URL:
        'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/home/ilustracoes/icon_01_conta_pra_gente.svg',
      TEXT: 'você conta pra gente tudo o que precisa',
    },
    {
      IMAGE_URL:
        'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/home/ilustracoes/icon_02_selecione_opcoes.svg',
      TEXT: 'selecionamos as melhores opções',
    },
    {
      IMAGE_URL:
        'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/home/ilustracoes/icon_03_entramos_contato.svg',
      TEXT: 'entramos em contato com você',
    },
    {
      IMAGE_URL:
        'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/home/ilustracoes/icon_04_assessoramos.svg',
      TEXT: 'assessoramos no momento da escolha',
    },
  ],
};

export default STEP_TO_STEP;
