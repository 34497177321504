import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './checkBoxAuto.styles';

function CheckboxAuto({ label, name, onChange, checked, value, hint, labelLayout, onBlur }) {
  const styles = useStyles();
  return (
    <Grid item className={styles.container} {...labelLayout}>
      <FormControlLabel
        control={
          <Checkbox
            data-testid="CheckboxAuto"
            size="medium"
            color="primary"
            value={value}
            checked={checked}
            name={name}
            inputProps={{
              'data-testid': 'CheckboxAuto_input',
            }}
            onChange={onChange}
            onBlur={onBlur}
          />
        }
        label={label}
      />
      {checked && hint}
    </Grid>
  );
}

export default CheckboxAuto;
