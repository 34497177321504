import axios from 'axios';

const sleep = ms => new Promise(res => setTimeout(res, ms));

export const retry = async (url, options, maximumRetry = 0, attempt = 0, delay = 0) => {
  try {
    await sleep(delay);
    const response = await axios.request({ url, ...options });
    if (response.status === 202) {
      throw new Error('Quote in process...');
    }

    return response;
  } catch (e) {
    if (attempt >= maximumRetry) throw e;

    return retry(url, options, maximumRetry, attempt + 1, (delay || 1000) * 2);
  }
};
