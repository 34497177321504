import { put, select } from 'redux-saga/effects';
import { Types } from 'store/ducks/residencial';
import {
  residencialSelectedAssistanceSelector,
  residencialSelectedCoverageSelector,
  residencialSelectedInstallmentSelector,
} from 'store/sagas/selectors/residencial';

export default function* updateQuotationPrice() {
  const coverages = yield select(residencialSelectedCoverageSelector);
  const assistance = yield select(residencialSelectedAssistanceSelector);
  const installment = yield select(residencialSelectedInstallmentSelector);
  const selectedCoveragesValue = coverages && coverages.reduce((total, coverage) => total + coverage.valor, 0);
  // eslint-disable-next-line camelcase
  const selectedAssistanceValue = (assistance && assistance.valor_assistencia) || 0;
  const totalValue = installment?.valor_total || selectedCoveragesValue + selectedAssistanceValue;
  const installmentQuantity = installment?.parcelas || 6;
  const installmentsValue = installment?.valor_parcela || totalValue / installmentQuantity;
  const data = {
    selectedCoveragesValue,
    selectedAssistanceValue,
    totalValue,
    installmentsValue,
    installmentQuantity,
  };
  yield put({ type: Types.UPDATE_PRICE_QUOTATION, data });
}
