import { Button as MuiButton, styled } from '@material-ui/core';
import React from 'react';
import Loading from '../../Loading';

export const ButtonV2 = styled(({ loading, variant = 'contained', color = 'primary', ...props }) => (
  <MuiButton
    disableRipple
    disableFocusRipple
    startIcon={loading ? <Loading color="#fff" size={25} /> : null}
    variant={variant}
    color={color}
    {...props}
  />
))(() => ({
  fontFamily: 'ItauDisplay-Regular',
  fontSize: '19px',
  fontWeight: 'bold',
  width: 'inherit',
}));
