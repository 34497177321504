/* eslint-disable import/no-cycle */
import { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { oneOfType, arrayOf, node, string } from 'prop-types';
import getCustomRoomContent from 'theme/customRooms';
import { createTheme } from '@material-ui/core';
import { useRemoteConfigConsumer } from 'providers/RemoteConfigProvider';
import { generateDynamicConfigBySource } from '../routes/routes';

const ProjectContext = createContext();

function ProjectContextProvider({ children, currentProject }) {
  const [theme, setTheme] = useState({});
  const [config, setConfig] = useState({});
  const [styleMapper, setStyleMapper] = useState({});
  const [ready, setReady] = useState({ loaded: false });
  const location = useLocation();
  const remoteConfig = useRemoteConfigConsumer();
  const customRoomContent = getCustomRoomContent(currentProject, location, remoteConfig);

  useEffect(() => {
    const currentConfig = generateDynamicConfigBySource(currentProject); // passei por aqui
    if (currentConfig) {
      const currentTheme = createTheme(currentConfig.theme);

      setTheme(currentTheme);
      setConfig(currentConfig);
      const { createStyleMapper } = currentConfig;
      const currentStyleMapper = createStyleMapper(currentTheme);
      setStyleMapper(currentStyleMapper);
      setReady({
        loaded: true,
        error: false,
      });
    } else {
      setReady({
        loaded: true,
        error: true,
      });
    }
  }, [config.theme, currentProject]);

  return (
    <ProjectContext.Provider
      value={{
        theme,
        config,
        ready,
        styleMapper,
        customRoomContent,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

ProjectContextProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  currentProject: string,
};

function useProjectContextProvider() {
  return useContext(ProjectContext);
}

export { ProjectContextProvider, useProjectContextProvider };
