import palette from '../palette';
import { toRem } from '../functions';

export default {
  root: {
    '&:focus': {
      backgroundColor: palette.backgroundSelect,
    },
  },
  select: {
    color: palette.defaultTextColor,
    fontSize: toRem(16),
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    color: palette.black,
    fontSize: toRem(24),
    cursor: 'pointer',
    top: 'calc(50% - 8px)',
  },
  selectMenu: {
    height: null,
  },
};
