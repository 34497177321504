const payment = {
  PAYMENT: {
    ACCESSIBILITY: {
      PAY_WITH: 'você pode pagar com',
    },
    FINANCIAL_OFFICER: {
      LABEL: 'o titular do plano é o responsável financeiro?',
      RADIO: {
        YES: 'sim',
        NO: 'não',
      },
    },
    TITLE: '**pagamento**',
    RESPONSIBLE_DATA: 'dados do **responsável pelo pagamento**',
    PAY_WITH: 'você pode **pagar com**',
    IOF: 'o valor do IOF (Imposto Sobre Operação Financeira) é 7,38% para todas as compras.',
    DEFAULT_INTEREST:
      'Havendo inadimplência o pagamento só poderá ser efetuado na seguradora acrescido de uma taxa de 0,30% ao dia.',
    TIP:
      '**débito ocorre em até 5 dias úteis após a contratação**, e as demais parcelas respeitarão a data de vigência da apólice',
    CVV_INFO: 'Código de segurança (CVV) de 3 ou 4 dígitos que fica geralmente na parte de trás do cartão',
    WHAT_IS_CVV: 'O quê é CVV?',
    BROKERAGE_FEE:
      'pela sua atividade de intermediação, a Itaú Corretora de Seguros S.A receberá uma comissão de corretagem de {{tax}}%. Informamos que incidem as alíquotas de PIS 0,65% e COFINS 4,00% sobre a formação do preço.',
  },
};

export default payment;
