import ANALYTICS_CATEGORY from '../category.json';

export default {
  HOME_SCROLL: {
    action: 'home_scroll',
    label: 'SCLL_MENU',
    category: ANALYTICS_CATEGORY.SCROLL,
  },
  ORIGIN: {
    action: 'origin',
    label: 'origin',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  PROMOTIONINDOOR: {
    action: 'promotionindoor',
    label: 'BTN:Home:FaçaCotação',
    category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
  },
  ITAU_EXCLUSIVES_SECTION: {
    CARTAO_PROTEGIDO: {
      action: 'cartao_protegido',
      label: 'BTN:Home:ExclusivosItau:CartaoProtegido',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    CAPITALIZACAO: {
      action: 'capitalizacao',
      label: 'BTN:Home:ExclusivosItau:Capitalizacao',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    SEGURO_VIAGEM: {
      action: 'seguro_viagem',
      label: 'BTN:Home:ExclusivosItau:SeguroViagem',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    SEGURO_VIDA: {
      action: 'seguro_vida',
      label: 'BTN:Home:ExclusivosItau:SeguroVida',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
    ABRA_SUA_CONTA: {
      action: 'abra_sua_conta',
      label: 'BTN:Home:ExclusivosItau:AbraSuaConta',
      category: ANALYTICS_CATEGORY.OBJETO_CLICADO,
    },
  },
};
