import React from 'react';
import './_AriaReader.scss';
import PropTypes from 'prop-types';

const AriaReader = ({ reader, classSpan, ariaLive }) => {
  const style = `element-invisible ${classSpan || ''}`;
  return (
    <span className={style} aria-live={ariaLive}>
      {reader}
    </span>
  );
};

AriaReader.propTypes = {
  reader: PropTypes.string.isRequired,
  classSpan: PropTypes.string,
};

export default AriaReader;
