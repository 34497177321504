import { createContext, useContext } from 'react';
import { oneOfType, arrayOf, node, string, array, shape } from 'prop-types';
import config from 'config';

export const AllowedChannelsContext = createContext();

const { REACT_APP_ENVIRONMENT } = process.env;

function AllowedChannelsProvider({ children, origin, allowedChannels }) {
  const getAllowedChannels = product => {
    const channels = allowedChannels.data;
    if (!channels) return false;

    const channelsByProduct = channels[product];
    if (!channelsByProduct) return false;

    return channelsByProduct.includes(origin);
  };

  const testEnvEnableAllProducts = REACT_APP_ENVIRONMENT === 'testenv';

  const isResidE2EAllowed = testEnvEnableAllProducts || getAllowedChannels('residencial');
  const isAutoE2EAllowed = testEnvEnableAllProducts || getAllowedChannels('automovel');
  const isWelfareE2EAllowed = testEnvEnableAllProducts || getAllowedChannels('bemestar');
  const isPetE2EAllowed = testEnvEnableAllProducts || getAllowedChannels('pet');
  const isAutoHiringAllowed = testEnvEnableAllProducts || getAllowedChannels('automovel-contratacao');
  const isOdontoV2Allowed = testEnvEnableAllProducts || getAllowedChannels('odonto-novos-planos');
  const isCarglassAllowed = testEnvEnableAllProducts || getAllowedChannels('carglass');
  const isBlluAllowed = testEnvEnableAllProducts || getAllowedChannels('bllu');
  const isConsorcioAllowed = testEnvEnableAllProducts || getAllowedChannels('consorcio');
  const isConectcarAllowed = testEnvEnableAllProducts || getAllowedChannels('conectcar');

  return (
    <AllowedChannelsContext.Provider
      value={{
        isResidE2EAllowed,
        isAutoE2EAllowed,
        isWelfareE2EAllowed,
        isPetE2EAllowed,
        isAutoHiringAllowed,
        isOdontoV2Allowed,
        isCarglassAllowed,
        isBlluAllowed,
        isConsorcioAllowed,
        isConectcarAllowed,
      }}
    >
      {children}
    </AllowedChannelsContext.Provider>
  );
}

AllowedChannelsProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  origin: string,
  allowedChannels: shape({
    residencial: array,
    automovel: array,
  }),
};

function useAllowedChannelsConsumer() {
  return useContext(AllowedChannelsContext);
}

export { AllowedChannelsProvider, useAllowedChannelsConsumer };
