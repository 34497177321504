import axios from 'axios';

import config, { performance } from '../../config';

const { SEGUROS_GATEWAY_APP_KEY, GATEWAY_PATH } = config;

export const getHome = async () => {
  const trace = performance.trace('getHome');
  trace.start();

  const response = await axios.get(`${GATEWAY_PATH}/pages/home?gw-app-key=${SEGUROS_GATEWAY_APP_KEY}`);

  trace.stop();
  return response.data;
};
