import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(0, 2),
    [breakpoints.up('sm')]: {
      padding: spacing(0, 4),
    },
  },
}));
