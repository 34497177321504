import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import useStyles, { DefaultTooltip } from './Tooltip.styles';

const Tooltip = ({
  title,
  content,
  placement,
  isOpen = false,
  TooltipComponent = DefaultTooltip,
  iconClass,
  onClickChipInfo,
}) => {
  const styles = useStyles();
  const [openText, setOpenText] = useState(isOpen);

  const handleClick = e => {
    e.stopPropagation();

    if (!openText && onClickChipInfo && typeof onClickChipInfo === 'function') onClickChipInfo();

    setOpenText(!openText);
  };

  return (
    <TooltipComponent
      enterTouchDelay={0}
      leaveDelay={1500}
      disableHoverListener
      open={openText}
      title={openText ? content : ''}
      placement={placement}
      data-testid="TooltipComponent"
    >
      <IconButton
        data-testid="TooltipComponent_button"
        onClick={handleClick}
        aria-expanded={openText}
        aria-label={title}
        className={clsx(iconClass, styles.tooltipIcon)}
      >
        <i className="icon-itaufonts_full_informacao" />
      </IconButton>
    </TooltipComponent>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  placement: PropTypes.string,
  isOpen: PropTypes.bool,
  TooltipComponent: PropTypes.node,
  iconClass: PropTypes.object,
  onClickChipInfo: PropTypes.func,
};

export default Tooltip;
