import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18n';
import { database, auth } from 'config';
import { AnalyticsContext } from 'providers/AnalyticsProvider';
import NavigationButtons from './components/NavigationButtons';
import HeaderNav from './components/HeaderNav';
import { useStyles } from './styles';
import LIFE_INSURANCE from './researchs/lifeInsurance';
import InfoCardList from './components/InfoCardList';
import DeliverContent, { PAGE_TYPES } from './components/DeliverContent';

function ProductsResearch({ expanded, research }) {
  const styles = useStyles();
  const [pageId, setPageId] = useState(-1);
  const [researchId, setResearchId] = useState(null);
  const [lastResearchId, setLastResearchId] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [breadCrump, setBreadCrump] = useState('');
  const headerRef = useRef();
  const startResearchLinkRef = useRef();
  const analytics = useContext(AnalyticsContext);

  let selectedPage;
  const selectedResearch = research.researchs.find(item => item.id === researchId);
  if (selectedResearch) selectedPage = selectedResearch.pages.find(item => item.id === pageId);
  const pageTitle = researchId === null ? research.main.title : selectedResearch.title;
  const pageSubtitle = researchId === null ? research.main.subtitle : selectedPage.description;
  const pageAnswer = answers.find(answer => answer.pageId === selectedPage?.id);
  const isQuestionTypePage = !!Object.values(PAGE_TYPES.QUESTIONS).find(type => type === selectedPage?.type);

  const dispatchAnalyticsEvent = (actionType, valueParam) => {
    const selectedPageOrMain = selectedPage || research.main;
    if (!selectedPageOrMain.analytics[actionType]) return;
    let { value, event, constant } = selectedPageOrMain.analytics[actionType];

    if (actionType === 'goBack') value = `${pageId}`;
    if (actionType === 'close') value = `${pageId}`;
    if (value === '$Value$') value = valueParam;
    if (constant) {
      setBreadCrump(value);
    }
    const getPathToTrack = () => {
      if (breadCrump && value && pageId !== -1) {
        if (Array.isArray(value)) {
          return [breadCrump].concat(value);
        }
        return [breadCrump, value];
      }

      if (!value) {
        return breadCrump;
      }

      return value;
    };

    analytics.logEvent({ ...event, value: getPathToTrack() });
  };

  useEffect(() => {
    if (pageId === -1) setAnswers([]);
  }, [pageId]);

  useEffect(() => {
    if (expanded) {
      setBreadCrump('TelaPrincipal');
    }
    if (!expanded && breadCrump) {
      dispatchAnalyticsEvent('close');
    }
  }, [expanded]);

  const handleNextPage = () => {
    dispatchAnalyticsEvent('continue', answers.find(answer => answer.pageId === selectedPage.id)?.answer);
    setTimeout(() => {
      if (headerRef.current) headerRef.current.focus();
    });
    setPageId(state => state + 1);
  };

  const handleBackPage = ({ fromNavigationButtons }) => {
    if (fromNavigationButtons) dispatchAnalyticsEvent('goBack');

    setTimeout(() => {
      if (headerRef.current) headerRef.current.focus();
    });

    if (pageId === 0) {
      setResearchId(null);
      setPageId(-1);
      setTimeout(() => {
        if (startResearchLinkRef.current) startResearchLinkRef.current.firstChild.firstChild.focus();
        else if (headerRef.current) headerRef.current.focus();
      });
    } else setPageId(state => state - 1);
  };

  const mountMainInfoCardList = () =>
    research.main.researchs.map(item => ({
      title: item.title,
      information: item.description,
      details: item.startButtonText,
      nextDetails: () => {
        setPageId(0);
        setResearchId(item.researchId);
        setLastResearchId(item.researchId);
      },
      analytics: () => {},
    }));

  const onSubmit = document => {
    auth.signInAnonymously();
    auth.onAuthStateChanged(() => {
      dispatchAnalyticsEvent('continue', answers.find(answer => answer.pageId === selectedPage.id)?.answer);
      database.collection(research.main.firebaseCollection).add({ ...document, research: selectedResearch?.title });
    });
  };

  const onClickChipInfo = clickedValue => {
    dispatchAnalyticsEvent('clickChipInfo', clickedValue);
  };

  const onClickCardLink = clickedValue => {
    dispatchAnalyticsEvent('click', clickedValue);
    setTimeout(() => {
      if (headerRef.current) headerRef.current.focus();
    });
  };

  const inputIsValid = () => {
    if (selectedPage.type === 'question-selectable_chip_group') {
      return pageAnswer?.answer.length <= 8 && pageAnswer?.answer.length > 0;
    }
    return !!pageAnswer?.answer;
  };

  return (
    <div className={styles.root}>
      <div role="alert" className={styles.a11yAlert} aria-hidden>
        {i18next.t('LIFE_INSURANCE_RESEARCH.LOAD_ALERT')}
      </div>
      <HeaderNav
        handleBack={pageId < 0 ? undefined : () => handleBackPage({ fromNavigationButtons: true })}
        title={pageTitle}
        description={pageSubtitle}
        pageId={pageId}
        headerRef={headerRef}
      />
      {researchId === null ? (
        <InfoCardList
          options={mountMainInfoCardList()}
          selectedIndex={lastResearchId}
          linkRef={startResearchLinkRef}
          onClickCardLink={onClickCardLink}
        />
      ) : (
        <>
          <h2 className={styles.pageTitle} id="fakedoor-question-title">
            {selectedPage.title}
          </h2>
          {selectedPage.subtitle && <p className={styles.pageSubtitle}>{selectedPage.subtitle}</p>}
          <>
            <DeliverContent
              page={selectedPage}
              answers={answers}
              setAnswers={setAnswers}
              onSubmit={onSubmit}
              onClickChipInfo={onClickChipInfo}
            />
            {selectedPage?.id !== selectedResearch.pages[selectedResearch.pages.length - 1].id && (
              <NavigationButtons
                onClickContinue={handleNextPage}
                onClickBack={() => handleBackPage({ fromNavigationButtons: true })}
                hideBackButton={!selectedPage.backButton}
                backText={selectedPage.backButton}
                isValid={!isQuestionTypePage || inputIsValid()}
                float="right"
              />
            )}
          </>
        </>
      )}
    </div>
  );
}

ProductsResearch.propTypes = {
  research: PropTypes.any,
};

export default ProductsResearch;
export { LIFE_INSURANCE };
