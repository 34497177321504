import { put } from 'redux-saga/effects';
import {
  updateVehicle,
  createRenovationData,
  createProponent,
  createQuestions,
} from 'store/auto/store/ducks/automovel';

export default function* createAutoInformation({ data: { veiculo, dados_renovacao, proponente, questoes } }) {
  delete veiculo.novo;
  yield put(updateVehicle(veiculo));
  yield put(createRenovationData(dados_renovacao));
  yield put(createProponent(proponente));
  yield put(createQuestions(questoes));
}
