import config from 'config';

/** Put here all features description according to each key name defined at src/config/[envs].js */
const DESCRIPTIONS = {
  FAKEDOOR: 'Pesquisa do produto **Seguro de Vida** no menu do header.',
  ADVISORY: 'Página do ChatBot de **assessoria**.',
  NEWAUTO: 'Nova landing page do produto **Auto**.',
  PROMOTIONINDOOR_RESID: 'indoor de promoção de **Resid**',
  ITAU_EXCLUSIVES_SECTION: 'Seção de exclusivos itaú na home da loja',
  ITAU_EXCLUSIVES_BANNER: 'Banner de Campanha dos exclusivos itaú',
  SUMMARY_0KM: 'Resumo do produto **Auto** com informações de carro 0km ou não.',
  CARGLASS_LANDING: 'Nova landing page da **Carglass**',
  ACCEPT_BANNER: 'Adiciona mensagem de bandeiras aceitas no **pagamento**',
  ENABLE_NEW_BADGES: 'Utiliza a nova regra de cartão de credito em resid/odonto/auto',
  PRODUCT_SUGGESTION: 'Nova seção de seguestão de produto.',
  ODONTO_NEW_PLANS_BANNER: 'Item do carrossel da campanha dos novos planos de odonto',
  ODONTO_HOME_CAMPAIGN: 'Campanha na home de odonto pega dinâmicamente na API do Contentstack (CMS Headless)',
  RESID_ASYNC_QUOTATION: 'Fluxo de cotação assincrona de **Resid**',
};

const featureToggleItems = Object.keys(config)
  .map(key => ({ key, data: config[key] }))
  .filter(item => item.data?.metadata?.dataType === 'FEATURE_TOGGLE')
  .map(item => ({ name: item.key, description: DESCRIPTIONS[item.key], enabled: item.data.enabled }));

export default featureToggleItems;
