/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import { FormControl, FormHelperText, InputLabel, makeStyles, Select } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Loading from 'components/Loading';
import { isMobileDevice } from 'utils/util';
import SelectMobileOptions from './SelectMobileOptions';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    minWidth: '100%',
    paddingRight: '25px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px',
  },
  inputLabel: {
    fontSize: theme.toRem(14),
    color: `${theme.palette.inputLabelColor}`,
    marginBottom: theme.spacing(4),
    '&:focused': {
      color: `${theme.palette.inputLabelColor}`,
    },
  },
  error: {
    color: theme.palette.error.main,
    fontFamily: 'ItauText-Bold',
    fontSize: theme.toRem(16),
  },
  errorIcon: {
    fontSize: theme.toRem(32),
    marginLeft: theme.spacing(4),
    color: theme.palette.error.main,
  },
  selectMenu: {
    overflow: 'hidden',
    height: 'auto',
    'white-space': 'normal',
    'text-overflow': 'initial',
    '& input': {
      borderColor: 'red !important',
    },
  },
  selectItem: {
    display: 'block',
    whiteSpace: 'break-spaces',
  },
  disabledLabel: {
    color: `${theme.palette.disabledColor} !important`,
    '& span': {
      color: `${theme.palette.disabledColor} !important`,
    },
    '& .Auto-MuiInput-underline.Mui-error:after': {
      border: 0,
    },
  },
}));

const SelectWrapper = ({
  label,
  options,
  status,
  value,
  handleChange,
  placeholder = null,
  className,
  name,
  error,
  helperText,
  onBlur,
  useAriaLabel,
  ariaLabel,
  enableMobileOptions,
  mobileOptionsTitle,
  required,
  menuItemId,
  id,
  labelLayout,
  loading,
  ...props
}) => {
  const styles = useStyles();
  const [selectId] = useState(_uniqueId('selectId-'));
  const selectRef = useRef(null);
  const [mobileOptions, setMobileOptions] = useState();
  const { disabled } = props;
  if (status && status.error) {
    props.error = status.error;
  }
  const labelId = `labelId${(name || label).replace(/ /g, '')}`;
  let selectedValue;
  let ariaLabelSelect = '';
  let ariaLabelInstallments = '';
  if (options) {
    selectedValue = options.find(e => {
      if (e.value?.id) {
        return JSON.stringify(e.value?.id) === JSON.stringify(value?.id);
      }
      return JSON.stringify(e.value) === JSON.stringify(value);
    });
    ariaLabelSelect = selectedValue ? selectedValue.label : '';
    ariaLabelInstallments = selectedValue ? selectedValue.ariaLabel : '';
  }

  useEffect(() => {
    if (mobileOptions === false) {
      setTimeout(() => selectRef.current.focus());
    }
  }, [mobileOptions]);

  const onSelect = e => {
    handleChange(e);
    setMobileOptions(false);
  };

  return (
    <>
      {status && <FormHelperText className={styles.error}>{status.message}</FormHelperText>}
      <span id={labelId} hidden>
        {`${ariaLabel || label} ${useAriaLabel ? ariaLabelSelect : ariaLabelInstallments || ''}`}
      </span>
      <div aria-hidden={mobileOptions} className={`${styles.wrapper} ${className}`}>
        <FormControl
          className={`${styles.formControl} formControl ${disabled && styles.disabledLabel}`}
          {...props}
          error={error}
          required={required}
          disabled={disabled}
        >
          <InputLabel
            className={`${styles.inputLabel} ${disabled && styles.disabledLabel}`}
            htmlFor={selectId}
            shrink={false}
            {...labelLayout}
            aria-hidden
          >
            {label}
          </InputLabel>
          <Select
            native={!isMobileDevice()}
            id={id || name}
            name={name}
            classes={{
              selectMenu: styles.selectMenu,
            }}
            className={styles.root}
            onClick={enableMobileOptions ? () => setMobileOptions(true) : null}
            value={selectedValue?.value || ''}
            onChange={handleChange}
            endAdornment={loading ? <Loading /> : undefined}
            displayEmpty
            fullWidth
            role="none"
            inputProps={{
              readOnly: enableMobileOptions,
              tabIndex: 0,
              ref: selectRef,
              'aria-required': required,
            }}
            labelId={labelId}
            IconComponent={KeyboardArrowDownIcon}
            SelectDisplayProps={{
              'aria-describedby': `${selectId}-helper-text`,
            }}
            MenuProps={{
              style: { marginTop: '8px' },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            }}
            onBlur={onBlur}
          >
            {placeholder !== null && (
              <option disabled value="5300108">
                5300108
              </option>
            )}
            {options.map((option, index) => (
              <option
                id={`${id || menuItemId}_${index}`}
                className={styles.selectItem}
                aria-label={`${label} ${ariaLabelInstallments ? option.ariaLabel : option.label}`}
                key={index}
                value={option.value?.id?.toString() || option.value}
              >
                {option.label}
              </option>
            ))}
          </Select>
          <FormHelperText hidden={!helperText} aria-live="polite" id={`${selectId}-helper-text`}>
            {helperText}
          </FormHelperText>
        </FormControl>
        {status && <i aria-hidden className={`${styles.errorIcon} icon-itaufonts_exclamacao`} />}
      </div>

      {enableMobileOptions && (
        <SelectMobileOptions
          menuItemId={menuItemId}
          open={mobileOptions}
          onClose={() => setMobileOptions(false)}
          onSelect={onSelect}
          value={selectedValue?.value || ''}
          list={options}
          label={label}
          keySelector="value"
          keyFilter="label"
          title={mobileOptionsTitle}
          id={id}
        />
      )}
    </>
  );
};

SelectWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  status: PropTypes.shape({
    error: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  props: PropTypes.any,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  useAriaLabel: PropTypes.bool,
  ariaLabel: PropTypes.string,
  onMobileOptionsChanged: PropTypes.func,
  enableMobileOptions: PropTypes.bool,
  mobileOptionsTitle: PropTypes.string,
  menuItemId: PropTypes.string,
};

export default SelectWrapper;
