import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  margin-left: 8px;
  background-color: #ec7000;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
`;

export function MwsToggleTab() {
  const handleOpen = () => window.qatools.open();
  const handleClose = () => window.qatools.close();

  return (
    <div style={{ display: 'flex' }}>
      <Button onClick={handleOpen}>Abrir</Button>
      <Button onClick={handleClose}>Fechar</Button>
    </div>
  );
}
