export const Types = {
  SHOW_MODAL: 'modal/SHOW_MODAL',
  CLOSE_MODAL: 'modal/CLOSE_MODAL',
  RESET_MODALS_STATE: 'modal/RESET_MODALS_STATE',
  LAST_SCOLL_POSITION: 'modal/LAST_SCOLL_POSITION',
  OPEN_MODAL_MESSAGE: 'modal/OPEN_MODAL_MESSAGE',
  CLOSE_MODAL_MESSAGE: 'modal/CLOSE_MODAL_MESSAGE',
  LAST_REF_CLICK: 'modal/LAST_REF_CLICK',
};

export const initialState = {
  message: {
    action: null,
    message: null,
    subtitle: null,
    open: false,
    type: null,
  },
  fullScreen: false,
  showModal: false,
  component: null,
  showCloseButton: true,
  closeModal: false,
  gaEvent: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SHOW_MODAL: {
      return {
        ...state,
        showModal: action.show,
        component: action.component,
        fullScreen: action.fullScreen,
        showCloseButton: action.showCloseButton,
        containerStyle: action.containerStyle,
        gaEvent: action.gaEvent,
      };
    }
    case Types.LAST_SCOLL_POSITION: {
      return {
        ...state,
        lastScrollPosition: action.scrollPosition,
      };
    }
    case Types.CLOSE_MODAL: {
      return {
        ...state,
        closeModal: action.closeModal,
      };
    }
    case Types.LAST_REF_CLICK: {
      return {
        ...state,
        lastClickedElementId: action.divRef,
      };
    }
    case Types.OPEN_MODAL_MESSAGE: {
      return {
        ...state,
        message: {
          ...action.data,
          open: true,
        },
      };
    }
    case Types.CLOSE_MODAL_MESSAGE: {
      return {
        ...state,
        message: {
          ...state.message,
          open: false,
        },
      };
    }
    case Types.RESET_MODALS_STATE: {
      return {
        ...initialState,
        lastClickedElementId: state.lastClickedElementId,
      };
    }
    default:
      return state;
  }
}

export function showModal(show, component, fullScreen = false, showCloseButton = true, containerStyle, gaEvent) {
  return {
    type: Types.SHOW_MODAL,
    show,
    component,
    fullScreen,
    showCloseButton,
    containerStyle,
    gaEvent,
  };
}

export function closeModal(close) {
  return {
    type: Types.CLOSE_MODAL,
    closeModal: close,
  };
}

export function resetModalsState() {
  return {
    type: Types.RESET_MODALS_STATE,
  };
}

export function openModalMessagedata(data) {
  return {
    type: Types.OPEN_MODAL_MESSAGE,
    data,
  };
}

export function closeModalMessage() {
  return {
    type: Types.CLOSE_MODAL_MESSAGE,
  };
}

export function setLastScrollPosition(scrollPosition) {
  return {
    type: Types.LAST_SCOLL_POSITION,
    scrollPosition,
  };
}

export function setLastDivRef(divRef) {
  return {
    type: Types.LAST_REF_CLICK,
    divRef,
  };
}
