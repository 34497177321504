/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormItem from 'shared/form/FormItem';
import visa from 'assets/images/svg/visa.svg';
import mastercard from 'assets/images/svg/mastercard.svg';
import amex from 'assets/images/svg/amex.svg';
import diners from 'assets/images/svg/diners-club.svg';
import elo from 'assets/images/svg/elo.svg';
import aura from 'assets/images/svg/aura.svg';
import { checkCreditCardFlag } from 'utils/validations';
import { Box, Paper } from '@material-ui/core';
import useStyles from './CreditCard.styles';

const CreditCard = ({ fields, form, onChange }) => {
  const classes = useStyles();
  const [cardFlag, setCardFlag] = useState('');
  const [ariaLabelFlag, setAriaLabelFlag] = useState('');

  const handleNumberChange = ({ cardNumber }) => {
    if (cardNumber) {
      const creditCardNormalized = cardNumber.replace(/\s/g, '');
      switch (checkCreditCardFlag(creditCardNormalized)) {
        case 'MasterCard':
          setCardFlag(mastercard);
          setAriaLabelFlag('mastercard');
          break;
        case 'Visa':
          setCardFlag(visa);
          setAriaLabelFlag('visa');
          break;
        case 'Amex':
          setCardFlag(amex);
          setAriaLabelFlag('amex');
          break;
        case 'Diners':
          setCardFlag(diners);
          setAriaLabelFlag('diners');
          break;
        case 'Elo':
          setCardFlag(elo);
          setAriaLabelFlag('elo');
          break;
        case 'Aura':
          setCardFlag(aura);
          setAriaLabelFlag('aura');
          break;
        default:
          setCardFlag('');
          setAriaLabelFlag('');
      }
    } else {
      setCardFlag('');
      setAriaLabelFlag('');
    }
  };

  const _onMount = () => {
    if (form.values) {
      handleNumberChange({ cardNumber: form.values.cardNumber });
    }
  };

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(_onMount, []);
  return (
    <Paper elevation={4} className={classes.root}>
      <Box className={classes.card}>
        <Box className={classes.cardNumber}>
          <FormItem
            field={fields.cardNumber}
            onChange={event => {
              handleNumberChange({ cardNumber: event.target.value });
              handleChange(event);
            }}
          />
        </Box>
        <Box className={classes.flag}>
          {cardFlag && <img src={cardFlag} alt={ariaLabelFlag} className={classes.image} />}
        </Box>
        <Box className={classes.cardName}>
          <FormItem field={fields.cardName} onChange={handleChange} />
        </Box>
        <Box className={classes.validationDate}>
          <FormItem field={fields.validationDate} onChange={handleChange} />
        </Box>
        <Box className={classes.cvv}>
          <FormItem field={fields.cvv} placeholder="123" onChange={handleChange} />
        </Box>
      </Box>
    </Paper>
  );
};

CreditCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.any,
    validationDate: PropTypes.any,
    brand: PropTypes.any,
  }),
};

export default CreditCard;
