import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

export const useStyles = makeStyles(theme => ({
  label: {
    fontSize: theme.toRem(16),
    fontFamily: 'ItauText-Regular',
    fontWeight: '400',
    color: palette.juridicTextColor,
  },
  labelInsurer: {
    fontSize: theme.toRem(14),
    fontFamily: 'ItauText-Regular',
    fontWeight: '400',
    lineHeight: theme.toRem(16),
    color: palette.inputLabelColor,
  },
  containerInfo: {
    backgroundColor: '#F5F5F5',
    padding: ` 0 ${theme.toRem(16)} ${theme.toRem(16)} ${theme.toRem(16)}`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.toRem(40),
      marginTop: theme.toRem(24),
    },
  },
  titleInfo: {
    margin: 0,
    marginTop: theme.toRem(16),
    fontSize: theme.toRem(14),
    fontFamily: 'ItauText-Bold',
    fontWeight: '700',
    color: palette.juridicTextColor,
  },
  description: {
    margin: 0,
    fontSize: theme.toRem(14),
    fontFamily: 'ItauText-Regular',
    fontWeight: '400',
    color: palette.juridicTextColor,
  },
  subtitleInfo: {
    marginBottom: 0,
    fontSize: theme.toRem(14),
    fontFamily: 'ItauText-Regular',
    fontWeight: '700',
    color: palette.juridicTextColor,
  },
}));
