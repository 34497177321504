/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { verifyiOS } from './util';

export const getCurrencyObjectFormated = (coinType, value) => {
  const currencyObject = {
    formattedValue: null,
    accessibilityText: '',
  };
  if (coinType) {
    switch (coinType) {
      case 'BRL': {
        currencyObject.formattedValue = formatToBRLCurrency(value);
        currencyObject.accessibilityText = formatToCurrencyRead(coinType, value);
        break;
      }
      case 'USD': {
        currencyObject.formattedValue = formatToUSDCurrency(value);
        currencyObject.accessibilityText = formatToCurrencyRead(coinType, value);
        break;
      }
      default: {
        return;
      }
    }
  }

  return currencyObject;
};

export const formatToBRLDecimal = value => {
  const nf = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return nf.format(value);
};

export const formatToBRLCurrency = value => `R$ ${formatToBRLDecimal(value)}`;

export const formatToUSDecimal = value => {
  const nf = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumIntegerDigits: 2,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return nf.format(value);
};

export const formatToUSDCurrency = value => `US$ ${formatToUSDecimal(value)}`;

export const formatToPercentValue = value => `${Math.round(value)}%`;

export const formatToCurrencyRead = (coinType, value) => {
  switch (coinType) {
    case 'BRL': {
      return verifyiOS() ? `${formatToBRLCurrency(value)} ` : `R$${formatToUSDecimal(value)}`;
    }
    case 'USD': {
      return formatToUSDCurrency(value);
    }
    default: {
      return verifyiOS() ? `${formatToBRLCurrency(value)} ` : `R$${formatToUSDecimal(value)}`;
    }
  }
};

export const convertValueDolarTextToReal = (valueText, valueReplaceEconomy, valueReplaceOriginal) => {
  let regex = /US\$ [0-9]*(\.[0-9]{3})*,([0-9]{2})?/gim; // Valor monetario
  let arrayOfValueMonetary = valueText.match(regex);
  if (arrayOfValueMonetary !== null) {
    const newArray = [
      {
        oldValue: arrayOfValueMonetary[0],
        newValue: `R$${valueReplaceEconomy}`,
      },
      {
        oldValue: arrayOfValueMonetary[1],
        newValue: `R$${valueReplaceOriginal}`,
      },
    ];
    newArray.map(it => (valueText = valueText.replace(it.oldValue, it.newValue)));
  }
  regex = /US\$[0-9]*(\.[0-9]{3})*,([0-9]{2})?/gim;
  arrayOfValueMonetary = valueText.match(regex);
  if (arrayOfValueMonetary !== null) {
    const newArraySecond = arrayOfValueMonetary.map(function(e) {
      return {
        oldValue: e,
        newValue: e
          .replace(',', '/')
          .replace('.', ',')
          .replace('/', '.')
          .replace(' ', '')
          .replace('US$', '$'),
      };
    });
    newArraySecond.map(it => (valueText = valueText.replace(it.oldValue, it.newValue)));
  }

  return valueText;
};

export const convertValueTextToReal = value => {
  const isIOS = verifyiOS();
  let newArray;
  let newArraySecond;
  let regex = /R\$ [0-9]*(\.[0-9]{3})*,([0-9]{2})?/gim; // Valor monetario
  let arrayOfValueMonetary = value.match(regex);

  if (arrayOfValueMonetary !== null) {
    if (!isIOS) {
      newArray = arrayOfValueMonetary.map(function(e) {
        return {
          oldValue: e,
          newValue: e
            .replace(',', '/')
            .replace('.', ',')
            .replace('/', '.')
            .replace(' ', ''),
        };
      });
    } else {
      newArray = arrayOfValueMonetary.map(function(e) {
        return {
          oldValue: e,
          newValue: e.replace(' ', ''),
        };
      });
    }
  } else {
    newArray = null;
  }

  regex = /R\$[0-9]*(\.[0-9]{3})*,([0-9]{2})?/gim;

  arrayOfValueMonetary = value.match(regex);
  if (arrayOfValueMonetary !== null) {
    if (!isIOS) {
      newArraySecond = arrayOfValueMonetary.map(function(e) {
        return {
          oldValue: e,
          newValue: e
            .replace(',', '/')
            .replace('.', ',')
            .replace('/', '.')
            .replace(' ', ''),
        };
      });
    } else {
      newArraySecond = arrayOfValueMonetary.map(function(e) {
        return {
          oldValue: e,
          newValue: e.replace(' ', ''),
        };
      });
    }
  } else {
    newArraySecond = null;
  }

  if (newArray !== null) {
    newArray.map(it => (value = value.replace(it.oldValue, it.newValue)));
  }
  if (newArraySecond !== null) {
    newArraySecond.map(it => (value = value.replace(it.oldValue, it.newValue)));
  }

  return `${value} `;
};
