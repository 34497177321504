const comparative = {
  COMPARATIVE: {
    MOBILE: {
      OPTIONS: {
        SELECT_ASSISTANCE: 'selecione uma assistência',
        SELECT_COVERAGE: 'selecione uma cobertura',
      },
    },
  },
};
export default comparative;
