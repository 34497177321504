import React from 'react';
import { setStoredMockModules } from 'mocks/config.mock';
import ItemsList from './itemsList';
import GenericToggleTab from '../GenericToggleTab';

export default function MockToggleTab() {
  const handleClickApply = values => {
    setStoredMockModules({
      env: process.env.REACT_APP_ENVIRONMENT,
      newMockModules: values.filter(mockStatus => mockStatus.enabled).map(mockStatus => mockStatus.name),
    });
  };

  return (
    <GenericToggleTab
      items={ItemsList}
      handleClickApply={handleClickApply}
      colNames={['Serviço', 'Descrição', 'Mockado?']}
    />
  );
}
