/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState, useEffect, useCallback, cloneElement } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import FocusTrap from 'focus-trap-react';
import useWindowWidth from 'hooks/useWindowWidth';
import clsx from 'clsx';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { useStyles } from './styles';

export default function DropdownContainer({ label, children, className, ...props }) {
  const windowWidth = useWindowWidth();
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();
  const [dropdownContainerRef, dropdownContainerBounds] = useMeasure({ polyfill: ResizeObserver });
  const [expanded, setExpanded] = useState(false);
  const [containerFix, setContainerFix] = useState(0);

  const getLeftContainerOverlap = useCallback(() => {
    const leftContainerOverlap = dropdownContainerBounds.left;
    return leftContainerOverlap < 0 ? Math.abs(leftContainerOverlap) + 32 : 0;
  }, [dropdownContainerBounds]);

  const getRightContainerOverlap = useCallback(() => {
    const rightContentOverlap = dropdownContainerBounds.left + dropdownContainerBounds.width - windowWidth;
    return rightContentOverlap > 0 ? rightContentOverlap + 32 : 0;
  }, [dropdownContainerBounds, windowWidth]);

  useEffect(() => {
    const newContainerFix = getLeftContainerOverlap() - getRightContainerOverlap();
    if (newContainerFix !== 0) setContainerFix(newContainerFix);
  }, [getLeftContainerOverlap, getRightContainerOverlap]);

  const styles = useStyles({
    containerOverlapFix: containerFix,
  });

  useEffect(() => {
    if (expanded) dispatchAnalyticsEvent('HOME', 'A2', [label]);
  }, [expanded]);

  const handleClickAway = () => {
    setExpanded(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FocusTrap active={expanded} focusTrapOptions={{ allowOutsideClick: true }}>
        <div className={clsx(styles.root, className)} {...props}>
          <button
            className={clsx(styles.button, expanded && styles.buttonExpanded)}
            aria-expanded={expanded}
            tabIndex={0}
            type="button"
            onClick={() => setExpanded(state => !state)}
          >
            <span className={clsx(styles.title, expanded && styles.titleSelected)}>{label}</span>
            {expanded ? (
              <i aria-hidden="true" className={clsx('icon-itaufonts_seta_up', expanded && styles.titleSelected)} />
            ) : (
              <i aria-hidden="true" className={clsx('icon-itaufonts_seta_down', expanded && styles.titleSelected)} />
            )}
          </button>
          <div className={styles.buttonExpandedShadowHider} />
          <div
            className={clsx(styles.content, expanded ? styles.contentOpen : styles.contentHide)}
            ref={dropdownContainerRef}
          >
            {cloneElement(children, { expanded })}
          </div>
        </div>
      </FocusTrap>
    </ClickAwayListener>
  );
}

DropdownContainer.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};
