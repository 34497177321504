import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ toRem, breakpoints, palette }) => ({
  card: {
    padding: `${toRem(16)}`,
    margin: '0 auto',
    borderRadius: toRem(8),
    height: '100%',
    width: '100%',
    minWidth: toRem(136),
    maxWidth: toRem(144),
    display: 'flex',
    alignItems: 'center',
    [breakpoints.up('md')]: {
      margin: 0,
    },
    '&:focus': {
      outlineColor: palette.text.link,
    },
  },
  selected: {
    boxShadow: `0 0 0 ${toRem(2)} ${palette.primary.main}`,
    borderRadius: toRem(5),
  },
  textContainer: {
    marginTop: toRem(8),
    maxWidth: toRem(100),
    textAlign: 'center',
    '& span': {
      fontSize: toRem(14),
      lineHeight: 1.15,
    },
  },
  image: {
    height: toRem(58),
    width: toRem(56),
  },
}));
