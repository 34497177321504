export default {
  wrapper: {
    width: '100%',
  },
  wrapperInner: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
