import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ButtonLink from 'components/Button/ButtonImpl/ButtonLink/ButtonLink';
import { useStyles } from './InfoCard.styles';

function InfoCard({ cardNo, linkRef, title, information, details, fixedHeight, nextDetails, onClickCardLink }) {
  const styles = useStyles({ fixedHeight, details });

  const linkClick = () => {
    if (typeof nextDetails == 'function') nextDetails();
    if (typeof onClickCardLink == 'function') onClickCardLink(title);
  };

  const cardId = `infoCard-${cardNo}`;

  return (
    <Grid item xs={12} md={4} data-testid={cardId}>
      {title && (
        <h2 id={cardId} className={styles.title}>
          {title}
        </h2>
      )}

      <div className={styles.information}>
        <p>{information}</p>
      </div>
      {details && (
        <div className={styles.link} ref={linkRef}>
          <ButtonLink
            ariaLabel={`${details} ${title}`}
            text={details}
            onClick={() => linkClick()}
            tabIndex={-1}
            linkRef={linkRef}
            role="link"
          />
        </div>
      )}
    </Grid>
  );
}

InfoCard.propTypes = {
  title: PropTypes.string,
  information: PropTypes.string,
  details: PropTypes.string,
  fixedHeight: PropTypes.string,
  nextDetails: PropTypes.func,
  onClickCardLink: PropTypes.func,
};

export default InfoCard;
