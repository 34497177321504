import { toRem } from '../functions';

export default {
  root: {
    fontSize: toRem(16),
    fontFamily: 'ItauText-Bold',
    textTransform: 'lowercase',
    '&:focus': {
      border: '3px solid #0067f4',
    },
  },
  textColorPrimary: {
    color: 'white',
  },
};
