export const Types = {
  AUTH_AUTO: 'auth/AUTH_AUTO',
  AUTH_AUTO_LOAD: 'auth/AUTH_AUTO_LOAD',
  AUTH_AUTO_SUCCESS: 'auth/AUTH_AUTO_SUCCESS',
  AUTH_AUTO_ERROR: 'auth/AUTH_AUTO_ERROR',
  AUTH_REMOVE: 'auth/AUTH_REMOVE',
};

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.AUTH_AUTO_LOAD: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case Types.AUTH_AUTO: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case Types.AUTH_AUTO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
      };
    }
    case Types.AUTH_AUTO_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
      };
    }
    case Types.AUTH_REMOVE: {
      return {};
    }
    default:
      return state;
  }
}

export function authAuto(data) {
  return {
    type: Types.AUTH_AUTO,
    data,
  };
}

export function authAutoLoad() {
  return {
    type: Types.AUTH_AUTO_LOAD,
  };
}

export function authAutoError() {
  return {
    type: Types.AUTH_AUTO_ERROR,
  };
}

export function authRemove() {
  return {
    type: Types.AUTH_REMOVE,
  };
}
