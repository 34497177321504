/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid, useMediaQuery } from '@material-ui/core';
import FormGrid from '../FormGrid/FormGrid';
import BannerInfo from '../BannerInfo/BannerInfo';
import Loading from '../Loading';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { classes, onClose, message, ...other } = props;
  return (
    <MuiDialogTitle style={{ backgroundColor: '#531E6D' }} className={classes.root} {...other}>
      <div>
        <Typography variant="h6" style={{ color: '#FFF' }}>
          {message}
        </Typography>
        {onClose ? (
          <IconButton
            autoFocus
            aria-live="polite"
            aria-label="fechar"
            data-testid="alert-close-button"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon htmlColor="#FFF" />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

export default function Alert({ handleClose, action, title, subtitle, info, loading, bannerMessage, showBanner }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      data-testid="alert-dialog"
      fullScreen={fullScreen}
      fullWidth
      open
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose} message={title} />
      <DialogContent dividers>
        {showBanner && (
          <Grid container spacing={8}>
            <FormGrid xs={12} lg={12}>
              <BannerInfo message={bannerMessage} type="success" />
            </FormGrid>
          </Grid>
        )}
        <Typography variant="p" component="p" gutterBottom>
          {subtitle}
        </Typography>
        <Typography variant="p" component="p" gutterBottom>
          {info}
        </Typography>
        {action !== undefined ? (
          loading ? (
            <Loading />
          ) : (
            <span tabIndex={0} role="link" onClick={action.onClick}>
              {action.label}
            </span>
          )
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
