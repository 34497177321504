import axios from 'axios';

import config, { performance } from '../../../../config';

const { E2E_PATH } = config;

const postSimulation = async (coverages, captcha, headers, enableLoginResid) => {
  const trace = performance.trace('postSimulation');
  const payload = {
    ...coverages,
    captcha,
  };

  trace.start();

  const response = await axios.post(`${E2E_PATH}/simulacoes`, payload, {
    disableException: enableLoginResid ? true : false,
    headers,
  });

  trace.stop();
  return response.data;
};

export default postSimulation;
