import { put, call, delay } from 'redux-saga/effects';
import resources from 'store/resources';
import { processAsyncSimulationSaga, setResidential } from 'store/ducks/residencial';
import { setResidQuotation } from 'store/ducks/residQuotation';
import loadAsyncQuotation from 'api/E2E/Resid/Simulations/loadAsyncQuotation';
import { loadingQuotation, successQuotation, failedQuotation } from 'store/ducks/asyncQuotation';

function* restoreSession({ residencial, residQuotation }) {
  yield put(setResidential(residencial));
  yield put(setResidQuotation(residQuotation));
}

export default function* validateUserDataToAsyncQuotationFlow(action) {
  try {
    const { hash, cpf } = action.data;

    yield put(resources.residCoverages.actions.load());
    yield put(loadingQuotation());
    const response = yield call(loadAsyncQuotation, { hash, cpf: cpf.replace(/[.()\s-]/g, '') });
    yield call(restoreSession, response.sessao);
    yield delay(150);
    yield put(processAsyncSimulationSaga({ simulacao: response.resposta }));
    yield put(successQuotation());
    yield delay(150);
    window.location.href = `/residencial/contratacao?hash=${hash}`;
  } catch (e) {
    yield put(failedQuotation());
    yield delay(150);
    window.location.href = '/error-quotacao-assincrona';
  }
}
