import {
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_ENVIRONMENTS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
} from 'trackingV2/constants';

export const ANALYTICS_ODONTO_HIRING_FLOW = {
  T1: {
    nome: 'LS:NL:NCC:Odonto:Contratacao:DadosDoTitular',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T2: {
    nome: 'LS:NL:NCC:Odonto:Contratacao:Dependentes',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T3: {
    nome: 'LS:NL:NCC:Odonto:Contratacao:Validacao',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T4: {
    nome: 'LS:NL:NCC:Odonto:Contratacao:Pagamento',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T5: {
    nome: 'LS:NL:NCC:Odonto:Contratacao:Resumo',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T6: {
    nome: 'LS:NL:NCC:Odonto:Contratacao:Sucesso',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  T7: {
    nome: 'LS:NL:NCC:Odonto:Contratacao:FormLead',
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  A1: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:CardDoSeguro:{0}:{1}',
    noInteraction: false,
  },
  A2: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:CardDoSeguro:CondicoesGerais',
    noInteraction: false,
  },
  A3: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:CardDoSeguro:ResumoDoPlano',
    noInteraction: false,
  },
  A4: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:SePreferirDeixeSeusDados',
    noInteraction: false,
  },
  A5: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:DadosTitular:Passo1:Voltar',
    noInteraction: false,
  },
  A6: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:DadosTitular:Passo1:Continuar',
    noInteraction: false,
  },
  A7: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:DadosDoTitular:Genero:{0}',
    noInteraction: false,
  },
  A8: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Dependente:Passo1:Continuar:{0}',
    noInteraction: false,
  },
  A9: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Dependente:Passo1:Voltar',
    noInteraction: false,
  },
  A10: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:AdicionarDependente',
    noInteraction: false,
  },
  A11: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:MaisAlgumDependente:Passo2:Continuar:{0}',
    noInteraction: false,
  },
  A12: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Dependente:Passo2:Voltar',
    noInteraction: false,
  },
  A13: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Validacao:Passo1:Voltar',
    noInteraction: false,
  },
  A14: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:ReenviarEmail',
    noInteraction: false,
  },
  A15: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:AlterarEmail',
    noInteraction: false,
  },
  A16: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Validacao:Passo2:Continuar',
    noInteraction: false,
  },
  A17: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:ReenviarCodigo',
    noInteraction: false,
  },
  A18: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Pagamento:Passo1:Voltar',
    noInteraction: false,
  },
  A19: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Pagamento:Passo1:Continuar',
    noInteraction: false,
  },
  A20: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Resumo:Voltar',
    noInteraction: false,
  },
  A21: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Resumo:Continuar',
    noInteraction: false,
  },
  A22: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'Alerta:Odonto:Contratacao:Sucesso',
    noInteraction: false,
  },
  A23: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Sucesso:DadosDoTitular:Genero:{0}',
    noInteraction: false,
  },
  A24: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Sucesso:Pagamento:FormaDePagamento:{0}',
    noInteraction: false,
  },
  A25: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:Sucesso:Plano:{0}',
    noInteraction: false,
  },
  A26: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:FormLead:Fechar',
    noInteraction: false,
  },
  A27: {
    category: ANALYTICS_EVENT_CATEGORIES.CLICK,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'BTN:Odonto:Contratacao:FormLead:Enviar',
    noInteraction: false,
  },
  A28: {
    category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
    action: ANALYTICS_EVENT_ACTIONS.ODONTO,
    label: 'Alerta:Odonto:Contratacao:Dependente:PlanoFamiliar:{0}:Dependentes:{1}',
    noInteraction: false,
  },
};
