/* eslint-disable import/no-duplicates */
import { createTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import mixins from './mixins';
import { spacing, toRem } from './functions';
import breakpoints from './breakpoints/breakpoints';
import constants from './constants';

const theme = createTheme({
  palette,
  typography,
  overrides,
  spacing,
  toRem,
  mixins,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  breakpoints,
  shape: {
    borderRadius: 5,
  },
  constants,
});

export default theme;
