import React from 'react';
import PropTypes from 'prop-types';
import { SelectableChipGroup } from '../../../../components/Chip';
import DescriptiveRadio from '../DescriptiveRadio';
import RadioWithInfoCard from '../RadioWithInfoCard';
import InfoCardList from '../InfoCardList';
import ContactInfo from '../ContactInfo';

const PAGE_TYPES = {
  INFORMATION: {
    INFO_CARD_GROUP: 'information-infocardgroup',
  },
  QUESTIONS: {
    RADIO_GROUP: 'question-radiogroup',
    RADIO_GROUP_WITH_INFORMATION: 'question-radiogroup_with_information',
    SELECTABLE_CHIP_GROUP: 'question-selectable_chip_group',
    CONTACT: 'question-contact',
  },
};

export default function DeliverContent({ page, answers, setAnswers, onSubmit, onClickChipInfo }) {
  const pageAnswer = answers.find(answer => answer.pageId === page?.id);

  const setPageAnswer = ({ pageId, answer }) => {
    setAnswers(state => [...state.filter(item => item.pageId !== page.id), { pageId, answer }]);
  };

  const handleChangeSelectableChip = values => {
    setPageAnswer({ pageId: page.id, answer: values });
  };

  const handleChangeRadioGroup = e => {
    e.persist();
    setAnswers(state => [
      ...state.filter(item => item.pageId !== page.id),
      { pageId: page.id, answer: e.target.value },
    ]);
  };

  switch (page.type) {
    case PAGE_TYPES.INFORMATION.INFO_CARD_GROUP:
      return <InfoCardList options={page.content.items} answers={answers} />;
    case PAGE_TYPES.QUESTIONS.RADIO_GROUP:
      return (
        <DescriptiveRadio
          options={page.content.options}
          displayMode={page.content.displayMode}
          onChange={handleChangeRadioGroup}
          value={pageAnswer?.answer}
        />
      );
    case PAGE_TYPES.QUESTIONS.RADIO_GROUP_WITH_INFORMATION:
      return (
        <RadioWithInfoCard
          options={page.content.options}
          onChange={handleChangeRadioGroup}
          answers={answers}
          value={pageAnswer?.answer}
        />
      );
    case PAGE_TYPES.QUESTIONS.SELECTABLE_CHIP_GROUP:
      return (
        <SelectableChipGroup
          options={page.content.options}
          onChange={handleChangeSelectableChip}
          value={pageAnswer?.answer}
          onClickChipInfo={onClickChipInfo}
        />
      );
    case PAGE_TYPES.QUESTIONS.CONTACT:
      return <ContactInfo onSubmit={onSubmit} />;
    default:
      return undefined;
  }
}

export { PAGE_TYPES };

DeliverContent.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string,
    subtitle: PropTypes.string,
    content: PropTypes.object,
  }),
  onClickChipInfo: PropTypes.func,
};
