import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createEffects } from '@zup-next/redux-resource';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import mapValues from 'lodash/mapValues';
import resources from 'store/auto/store/resources';
import ducksReducers from 'store/auto/store/ducks';
import { Types as automobileTypes } from 'store/auto/store/ducks/automovel';
import { Types as questionsTypes } from 'store/auto/store/ducks/questions';
import { Types as suspenseListsTypes } from 'store/auto/store/ducks/suspenseLists';
import { Types as authTypes } from 'store/auto/store/ducks/auth';
import { Types as inspectionTypes } from 'store/auto/store/ducks/inspection';
import createAutoInformation from 'store/auto/store/sagas/automobile/createAutoInformation';
import createProfileAction from 'store/auto/store/sagas/automobile/createProfileAction';
import createAditionalDataAction from 'store/auto/store/sagas/automobile/createAditionalDataAction';
import loadQuotationAction from 'store/auto/store/sagas/automobile/loadQuotationAction';
import loadSuspenseLists from 'store/auto/store/sagas/automobile/loadSuspenseLists';
import auth from 'store/auto/store/sagas/auth/auth';
import prepareHiringPayloadAction from 'store/auto/store/sagas/automobile/prepareHiringPayloadAction';
import setInitialHiringData from 'store/auto/store/sagas/automobile/setInitialHiringData';
import setUpdateInitialData from 'store/auto/store/sagas/automobile/setUpdateInitialData';
import questionsLoading from 'store/auto/store/sagas/questions/questionsLoading';
import loadHiring from 'store/sagas/auto/hiring';
import setInspectionInitialDataAction from 'store/auto/store/sagas/inspection/setInspectionInitialDataAction';
import getInspectionDateAction from 'store/auto/store/sagas/inspection/getInspectionDateAction';
import createHouseholdInpectionPostAction from 'store/auto/store/sagas/inspection/createHouseholdInpectionPostAction';
import createHiringPaymentAction from 'store/auto/store/sagas/automobile/createHiringPaymentAction';
import setInstallmentPaymentTypeAction from 'store/sagas/auto/setInstallmentPaymentTypeAction';

const persistConfig = {
  key: 'auto',
  storage: storageSession,
  whitelist: [
    'auth',
    'auto',
    'automovel',
    'wizardStep',
    'questions',
    'landing',
    'searchVehicle',
    'offer',
    'suspenseLists',
    'franchise',
    'httpErrors',
  ],
};

const reducers = combineReducers({
  ...mapValues(resources, 'reducer'),
  ...ducksReducers,
});

const sagas = function* run() {
  yield createEffects({
    ...resources.offer.sagas,
    ...resources.landing.sagas,
    ...resources.faq.sagas,
    [questionsTypes.LOAD_QUESTIONS]: questionsLoading,
    [automobileTypes.CREATE_PROFILE]: createProfileAction,
    [automobileTypes.CREATE_ADITIONAL_DATA]: createAditionalDataAction,
    [automobileTypes.PREPARE_HIRING_PAYLOAD_DATA]: prepareHiringPayloadAction,
    [automobileTypes.CREATE_AUTO_INFORMATION]: createAutoInformation,
    [automobileTypes.LOAD_QUOTATION]: loadQuotationAction,
    [suspenseListsTypes.CALL_SUSPENSE_LISTS]: loadSuspenseLists,
    [automobileTypes.SET_INITIAL_HIRING_DATA]: setInitialHiringData,
    [automobileTypes.CREATE_HIRING_PAYMENT_ACTION]: createHiringPaymentAction,
    [automobileTypes.SET_UPDATE_INITIAL_HIRING_DATA]: setUpdateInitialData,
    [authTypes.AUTH_AUTO]: auth,
    [automobileTypes.LOAD_HIRING]: loadHiring,
    [inspectionTypes.SET_INSPECTION_INITIAL_DATA]: setInspectionInitialDataAction,
    [inspectionTypes.GET_INSPECTION_DATES]: getInspectionDateAction,
    [inspectionTypes.CREATE_INSPECTION_POST]: createHouseholdInpectionPostAction,
    [automobileTypes.SET_INSTALLMENT_PAYMENT_TYPE]: setInstallmentPaymentTypeAction,
  });
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools({ name: 'AutoStore' })(applyMiddleware(sagaMiddleware)),
  );
  sagaMiddleware.run(sagas);
  const persistor = persistStore(store);

  return { autoStore: store, autoPersistor: persistor };
};
